import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-certificate',
    templateUrl: './certificate.component.html',
    styleUrls: ['./certificate.component.scss'],
})
export class CertificateComponent {
    @Input()
    label: string = '';

    @Input()
    bgColor: string = 'bg-gray-100';

    @Input()
    bgHoverColor: string = 'bg-gray-200';

    @Output()
    onClick = new EventEmitter<any>();

    isHovered = false;

    constructor() {}

    onClickButton(event): void {
        this.onClick.emit(event);
    }
}
