import { IParticipantRegistrationMetricQuery } from '../interfaces/participant-registration-metric.query.interface';

export class ParticipantRegistrationMetric {
    static props_to_search = ['name'];

    id: string;
    sectionId: string;
    moduleId: string;
    userId: string;
    completed: boolean;
    success: boolean;
    totalTime: string;
    totalTimeInSeconds: number;
    rawScore: number;
    maxScore: number;
    scaledScore: number;

    constructor(query?: IParticipantRegistrationMetricQuery) {
        if (query) {
            this.id = query.id.toString();
            this.sectionId = query.section ? query.section.id.toString() : null;
            this.moduleId = query.module ? query.module.id.toString() : null;
            this.userId = query.user.id.toString();
            this.completed = query.completed;
            this.success = query.success;
            this.totalTime = this.hhmm(query.total_time);
            this.totalTimeInSeconds = query.total_time;
            this.rawScore = query.raw_score;
            this.maxScore = query.max_score;
            this.scaledScore = query.scaled_score;
        }
    }

    hhmm(secs: number): string {
        let minutes = Math.floor(secs / 60);
        const hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        return `${hours}h ${minutes}min`;
    }
}
