import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FeatureService } from 'app/library/feature/services/feature.service';
import { UserService } from 'app/library/user/services/user.service';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { AddUsers } from 'app/projects/user/src/lib/actions/add-entities.action';
import { RefreshUsersOnlineState } from 'app/projects/user/src/lib/actions/refresh-users-online-state.action';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { map } from 'rxjs/operators';
import {
    DeselectSelectedUsers,
    FetchAllUsers,
    FetchAllUsersFailure,
    FetchAllUsersSuccess,
    FetchAvailableUsers,
    FetchCommunityUsers,
    FetchCommunityUsersFailure,
    FetchCommunityUsersSuccess,
    FetchCourseOfferingUsers,
    FetchCourseOfferingUsersFailure,
    FetchCourseOfferingUsersSuccess,
    FetchParticipantsAndResponsibles,
    FetchParticipantsAndResponsiblesFailure,
    FetchParticipantsAndResponsiblesSuccess,
    FetchRootAudienceUsers,
    FetchRootAudienceUsersFailure,
    FetchRootAudienceUsersSuccess,
    RemoveSearchAllUsers,
    SearchAllUsers,
    SelectFilteredUsers,
    SetAllUsers,
    SortAllUsers,
    ToggleSelectedUser,
    UnsetAllUsers,
} from '../actions';
import { FetchAvailableUsersFailure, FetchAvailableUsersSuccess } from '../actions/index';
import { AllUsersApiService } from '../services/all-users.api-service';
import { AllUsersStateModel } from './all-users.state-model';

@State<AllUsersStateModel>({
    name: 'allUsers',
    defaults: new AllUsersStateModel(),
})
@Injectable()
export class AllUsersState extends SubentityState<User> {
    @Selector([UserState.getEntities])
    static getEntities(state: AllUsersStateModel, users: User[]): User[] {
        if (!users) {
            return null;
        }

        return users
            .filter((user) => state.list.indexOf(user.id) !== -1)
            .map((user) => {
                return [state.list.indexOf(user.id), user];
            })
            .sort((a: [number, User], b: [number, User]) => {
                return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
            })
            .map((x: [number, User]) => x[1]);
    }

    @Selector([UserState.getEntities])
    static getFilteredEntities(state: AllUsersStateModel, users: User[]): User[] {
        if (!users) {
            return null;
        }

        return users
            .filter((user) => state.filtered.indexOf(user.id) !== -1)
            .map((user) => {
                return [state.list.indexOf(user.id), user];
            })
            .sort((a: [number, User], b: [number, User]) => {
                return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
            })
            .map((x: [number, User]) => x[1]);
    }

    @Selector()
    static getSelectedEntityIds(state: AllUsersStateModel): string[] {
        return state.selected;
    }

    @Selector()
    static getFiltersList(state: AllUsersStateModel): string[] {
        return state.filters.list;
    }

    @Selector()
    static getSearchTerm(state: AllUsersStateModel): string {
        return state.searchTerm;
    }

    @Selector()
    static getTotalCount(state: AllUsersStateModel): number {
        return state.totalCount;
    }

    @Selector()
    static getSortBy(state: AllUsersStateModel): string {
        return state.sortBy;
    }

    @Selector()
    static getSortOrder(state: AllUsersStateModel): SortDirection {
        return state.sortOrder;
    }

    @Selector()
    static getPageSize(state: AllUsersStateModel): number {
        return state.pageSize;
    }

    @Selector()
    static getPageNumber(state: AllUsersStateModel): number {
        return state.pageNumber || 1;
    }

    @Selector()
    static isFetchingList(state: AllUsersStateModel): boolean {
        return state.fetchingList;
    }

    @Selector()
    static getPageIndex(state: AllUsersStateModel): number {
        return state.pageNumber ? state.pageNumber - 1 : 0;
    }

    constructor(private _allUsersService: AllUsersApiService, private store: Store, private _userService: UserService, private _featureService: FeatureService) {
        super(null, 0);

        this._propstoSearch = ['user', ...User.props_to_search];
    }

    @Action(SetAllUsers)
    setAll(ctx: StateContext<AllUsersStateModel>, action: SetAllUsers): void {
        this._setSubentities(ctx, action, AddUsers);

        if (this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)) {
            this.store.dispatch(new RefreshUsersOnlineState(action.payload));
            this._userService.setOnlineStateInterval(RefreshUsersOnlineState, AllUsersState.getEntities);
        }
    }

    @Action(UnsetAllUsers)
    unsetAll(ctx: StateContext<AllUsersStateModel>): void {
        this._unsetSubentities(ctx);
    }

    @Action(FetchAllUsers)
    fetchAll(ctx: StateContext<AllUsersStateModel>, action: FetchAllUsers): void {
        if (action.payload) {
            ctx.patchState({
                pageSize: action.payload.pageSize,
                pageNumber: action.payload.pageNumber,
            });
        }

        this._fetchSubentities(
            ctx,
            { payload: 'all' },
            SetAllUsers,
            FetchAllUsersSuccess,
            FetchAllUsersFailure,
            action.payload
                ? this._allUsersService
                      .findUsers(
                          action.payload.moduleIds,
                          action.payload.entityId,
                          action.payload.search,
                          action.payload.sortBy,
                          action.payload.sortOrder,
                          action.payload.pageSize,
                          action.payload.pageNumber,
                          action.payload.isUnassignedSelected,
                          action.payload.role,
                          action.payload.includeAnalytics,
                          action.payload.experienceIds,
                          action.payload.audienceIds,
                          action.payload.teamIds,
                          action.payload.extendedAttributesFilters,
                          action.payload.isUnassignedSelectedExperiences,
                          action.payload.isUnassignedSelectedAudiences,
                          action.payload.isUnassignedSelectedTeams,
                          action.payload.columns,
                          action.payload.userRole
                      )
                      .pipe(
                          map((res) => {
                              ctx.patchState({
                                  totalCount: res.pagination.number_of_results,
                              });

                              return res.data.map((query) => new User(query));
                          })
                      )
                : this._allUsersService.getAll()
        );
    }

    @Action(FetchAvailableUsers)
    fetchAvailableUsers(ctx: StateContext<AllUsersStateModel>, action: FetchAvailableUsers): void {
        this._allUsersService
            .getAvailableUsers(action.payload.groupId, action.payload.search, action.payload.sortBy, action.payload.sortOrder, action.payload.pageSize, action.payload.pageNumber)
            .pipe(
                map((data) => {
                    ctx.patchState({
                        totalCount: data.pagination.number_of_results,
                    });

                    return data.data.map((query) => new User(query));
                })
            )
            .subscribe(
                (entities) => {
                    this.store.dispatch([new FetchAvailableUsersSuccess(entities), new SetAllUsers(entities)]);
                },
                (error: HttpErrorResponse) => {
                    this.store.dispatch(new FetchAvailableUsersFailure());
                }
            );
    }

    @Action(FetchParticipantsAndResponsibles)
    fetchParticipantsAndResponsibles(ctx: StateContext<AllUsersStateModel>, action: FetchParticipantsAndResponsibles): void {
        this._allUsersService
            .getParticipantsAndResponsibles(
                action.payload.moduleId,
                action.payload.groupId,
                action.payload.search,
                action.payload.sortBy,
                action.payload.sortOrder,
                action.payload.pageSize,
                action.payload.pageNumber
            )
            .pipe(
                map((data) => {
                    ctx.patchState({
                        totalCount: data.pagination.number_of_results,
                        pageNumber: data.pagination.number_of_pages,
                    });

                    return data.data.map((query) => new User(query));
                })
            )
            .subscribe(
                (entities) => {
                    this.store.dispatch([new FetchParticipantsAndResponsiblesSuccess(entities), new SetAllUsers(entities)]);
                },
                (error: HttpErrorResponse) => {
                    this.store.dispatch(new FetchParticipantsAndResponsiblesFailure());
                }
            );
    }

    @Action(FetchRootAudienceUsers)
    fetchRootAudienceUsers(ctx: StateContext<AllUsersStateModel>, action: FetchRootAudienceUsers): void {
        this._allUsersService
            .getRootAudienceUsers(
                action.payload.audienceId,
                action.payload.search,
                action.payload.sortBy,
                action.payload.sortOrder,
                action.payload.pageSize,
                action.payload.pageNumber
            )
            .pipe(
                map((data) => {
                    ctx.patchState({
                        totalCount: data.pagination.number_of_results,
                    });
                    return data.data.map((query) => new User(query.user));
                })
            )
            .subscribe(
                (entities) => {
                    this.store.dispatch([new FetchRootAudienceUsersSuccess(entities), new SetAllUsers(entities)]);
                },
                (error: HttpErrorResponse) => {
                    this.store.dispatch(new FetchRootAudienceUsersFailure());
                }
            );
    }

    @Action(FetchAllUsersSuccess)
    fetchAllSuccess(ctx: StateContext<AllUsersStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchAllUsersFailure)
    fetchAllFailure(ctx: StateContext<AllUsersStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(ToggleSelectedUser)
    toggleSelect(ctx: StateContext<AllUsersStateModel>, action: ToggleSelectedUser): void {
        this._toggleSelectedSubentity(ctx, action);
    }

    @Action(SelectFilteredUsers)
    selectAll(ctx: StateContext<AllUsersStateModel>): void {
        this._selectFilteredSubentities(ctx);
    }

    @Action(DeselectSelectedUsers)
    deselectAll(ctx: StateContext<AllUsersStateModel>): void {
        this._deselectSelectedSubentities(ctx);
    }

    @Action(SearchAllUsers)
    search({ patchState }: StateContext<AllUsersStateModel>, { payload }: SearchAllUsers): void {
        sessionStorage.setItem('allUsersSearchTerm', payload);

        patchState({
            searchTerm: payload,
        });
    }

    @Action(RemoveSearchAllUsers)
    removeSearch({ patchState }: StateContext<AllUsersStateModel>, { payload }: SearchAllUsers): void {
        sessionStorage.setItem('allUsersSearchTerm', payload);

        patchState({
            searchTerm: payload,
        });
    }

    @Action(SortAllUsers)
    sort({ patchState }: StateContext<AllUsersStateModel>, { payload }: SortAllUsers): void {
        sessionStorage.setItem('allUsersSortBy', payload.active);
        sessionStorage.setItem('allUsersSortOrder', payload.direction);

        patchState({
            sortBy: payload.active,
            sortOrder: payload.direction,
        });
    }

    @Action(FetchCourseOfferingUsers)
    fetchCourseOfferingUsers(ctx: StateContext<AllUsersStateModel>, action: FetchCourseOfferingUsers): void {
        this._allUsersService
            .getCourseOfferingUsers(
                action.payload.entityId,
                action.payload.search,
                action.payload.sortBy,
                action.payload.sortOrder,
                action.payload.pageSize,
                action.payload.pageNumber
            )
            .pipe(
                map((data) => {
                    ctx.patchState({
                        totalCount: data.pagination.number_of_results,
                    });

                    return data.data.map((query) => new User(query));
                })
            )
            .subscribe(
                (entities) => {
                    this.store.dispatch([new FetchCourseOfferingUsersSuccess(entities), new SetAllUsers(entities)]);
                },
                (error: HttpErrorResponse) => {
                    this.store.dispatch(new FetchCourseOfferingUsersFailure());
                }
            );
    }

    @Action(FetchCommunityUsers)
    fetchCommunityUsers(ctx: StateContext<AllUsersStateModel>, action: FetchCommunityUsers): void {
        this._allUsersService
            .getCommunityUsers(
                action.payload.entityId,
                action.payload.search,
                action.payload.sortBy,
                action.payload.sortOrder,
                action.payload.pageSize,
                action.payload.pageNumber,
                action.payload.extendedAttributesFilters,
                action.payload.columns
            )
            .pipe(
                map((data) => {
                    ctx.patchState({
                        totalCount: data.pagination.number_of_results,
                    });

                    return data.data.map((query) => new User(query));
                })
            )
            .subscribe(
                (entities) => {
                    this.store.dispatch([new FetchCommunityUsersSuccess(entities), new SetAllUsers(entities)]);
                },
                (error: HttpErrorResponse) => {
                    this.store.dispatch(new FetchCommunityUsersFailure());
                }
            );
    }
}
