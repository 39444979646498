import { ExtendedAttributeOption } from 'app/library/extended-attribute-options/models/extended-attribute-option';
import { IExtendedAttributeQuery } from '../interfaces/extended-attribute.query.interface';

export class ExtendedAttribute {
    id: string;
    name: string;
    sort: number;
    type: number;
    visible: boolean;
    editable: boolean;
    sharedByDefault: boolean;
    optionsEnabled: boolean;
    translationKey: string;
    'translation_en-GB': string;
    'translation_de-DE': string;
    'translation_fr-FR': string;
    'translation_it-CH': string;
    options: ExtendedAttributeOption[];
    missingTranslation: boolean;

    isOptionsMissingTranslation: boolean;

    constructor(query: IExtendedAttributeQuery) {
        if (query) {
            this.id = query.id.toString();
            this.name = query.name;
            this.sort = query.sort;
            this.type = query.type;
            this.visible = query.visible;
            this.editable = query.editable;
            this.sharedByDefault = query.shared_by_default;
            this.optionsEnabled = query.options_enabled;
            this.options = query.options ? query.options.map((option) => new ExtendedAttributeOption(option)) : [];
            this.translationKey = query.translation_key;
            if (query.translations) {
                if (query.translations['translation_en-GB']) {
                    this['translation_en-GB'] = query.translations['translation_en-GB'];
                }
                if (query.translations['translation_de-DE']) {
                    this['translation_de-DE'] = query.translations['translation_de-DE'];
                }
                if (query.translations['translation_fr-FR']) {
                    this['translation_fr-FR'] = query.translations['translation_fr-FR'];
                }
                if (query.translations['translation_it-CH']) {
                    this['translation_it-CH'] = query.translations['translation_it-CH'];
                }
            }
            this.missingTranslation = query.missing_translation;
        }
    }
}
