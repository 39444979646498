<app-dialog-header (onClick)="closeDialog()"></app-dialog-header>

<div mat-dialog-content class="p-24" fxLayout="column" fxLayoutAlign="start">
    <div class="wrapper" *ngIf="url">
        <iframe [src]="safeUrl" title="Welcome Tour" frameborder="0"></iframe>
    </div>
</div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'WELCOME_TOUR.LABEL.SKIP_FOR_NOW'" (onClick)="closeDialog()"></app-white-button>

    <app-flat-button [label]="'WELCOME_TOUR.LABEL.DONT_SHOW_THIS_AGAIN'" (onClick)="dontShowDialogAgain()"></app-flat-button>
</div>
