import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as momentImported from 'moment';
const moment = momentImported;
@Pipe({
    name: 'expectedDuration',
})
@Injectable()
export class ExcpectedDurationPipe implements PipeTransform {
    constructor(private _translateService: TranslateService) {}
    transform(expectedDuration: number, returnOnlyTime: boolean): string {
        if (!expectedDuration || !this._translateService) {
            return '';
        }
        let minutes = Math.floor(expectedDuration / 60);
        const hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        return returnOnlyTime
            ? `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
            : `${hours + this._translateService.instant('GENERAL.LABEL.HOURS_SHORT')} ${minutes + this._translateService.instant('GENERAL.LABEL.MINUTES_SHORT')}`;
    }
}
