import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FuseConfigService } from 'app/projects/fuse/src/lib/services/config.service';
import { TalkService } from 'app/services/talk.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'horizontal-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HorizontalLayout1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    isKmpAppActive = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        const n = this.talkService.getNumberOfConversationsToClose(event.target.innerWidth) + 1; // add one for the uploads drawers

        this.talkService.closeOldestConversations(n);
    }

    constructor(private _fuseConfigService: FuseConfigService, public talkService: TalkService, private _router: Router) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        const mobileAppValue = localStorage.getItem('kmpMobileApp');

        if (mobileAppValue === 'ios' || mobileAppValue === 'android') {
            this.isKmpAppActive = true;
        }
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;
        });

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._router.url === '/modules') {
                    document.getElementById('container-1').classList.add('white');
                } else {
                    document.getElementById('container-1').classList.remove('white');
                }
            });
    }

    ngAfterViewInit(): void {
        if (!this.isKmpAppActive) {
            const scrollTopContainer = document.getElementById('scroll-top-container');

            scrollTopContainer.hidden = true;

            let lastScrollTop = 0;
            document.getElementById('container-3').addEventListener('scroll', (e) => {
                const scrollTop = (e.target as HTMLElement).scrollTop;

                if (scrollTop > lastScrollTop) {
                    scrollTopContainer.hidden = false;
                } else {
                    scrollTopContainer.hidden = true;
                }
                lastScrollTop = scrollTop;
            });
        }
    }

    trackByFn(index: number): number {
        return index;
    }

    scrollTop(): void {
        document.getElementById('container-3').scrollTop = 0;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
