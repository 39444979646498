import { HttpErrorResponse } from '@angular/common/http';

export class ChangeEmailAddressFailure {
    static readonly type = '[User] ChangeEmailAddressFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class ChangeNotificationEmailAddressFailure {
    static readonly type = '[User] ChangeNotificationEmailAddressFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}
