import { SectionType } from '../enums/section-type.enum';
import { ISectionQuery } from '../interfaces/section.query.interface';
import { Section } from './section.model';

export class FilesSection extends Section {
    constructor(query?: ISectionQuery) {
        super(query);

        this._type = SectionType.Files;
    }
}
