import { IEntity } from 'app/projects/entity/src/lib/interfaces/entity.interface';
import { IGradeQuery } from '../interfaces/grade.query.interface';

export class Grade implements IEntity {
    static label_singular = 'grade';
    static label_plural = 'grades';

    static props_to_search = ['name', 'description'];

    id: string;
    displayValue: string;
    numericValue: number;
    description: string;
    order: number;
    passing: boolean;
    gradingSchema?: { id: string; name: string };

    constructor(query?: IGradeQuery) {
        if (query) {
            this.id = query.id.toString();
            this.displayValue = query.display_value;
            this.numericValue = query.numeric_value;
            this.description = query.description;
            this.order = query.sequence;
            this.passing = query.passing;
            if (query.grading_scheme) {
                this.gradingSchema = {
                    id: query.grading_scheme.id.toString(),
                    name: query.grading_scheme.name,
                };
            }
        }
    }
}
