import { IContentNodeQuery } from '../interfaces/content-node.query.interface';
import { ContentNodeBreadcrumb } from './content-node-breadcrumb.model';

export class ContentNode {
    static label_singular = 'node';
    static label_plural = 'nodes';

    static props_to_search = ['name'];

    id: string;
    name: string;
    rootId: string;
    rootType: 'user' | 'module';
    createdAt: Date;
    // createdBy: User;
    updatedAt: Date;
    // updatedBy: User;
    pendingSync: boolean;
    isFolder: boolean;
    size: number;
    breadcrumbs: ContentNodeBreadcrumb[];
    ownership: 'module' | 'personal';
    allowDistribution: boolean;
    version: string;

    constructor(query?: IContentNodeQuery) {
        if (query) {
            this.id = query.node;
            this.name = query.name;
            this.rootId = query.root_id;
            this.rootType = query.root_type;
            this.createdAt = query.created_at;
            // this.createdBy = query.created_by ? new User(query.created_by) : null;
            this.updatedAt = query.updated_at;
            // this.updatedBy = query.updated_by ? new User(query.updated_by) : null;
            this.pendingSync = query.pending_sync;
            this.isFolder = query.is_folder;
            this.size = query.size;
            this.breadcrumbs = query.breadcrumbs.map((breadcrumb) => new ContentNodeBreadcrumb(breadcrumb));
            this.ownership = query.ownership;
            this.allowDistribution = query.allow_distribution;
            this.version = query.version_label || query.versionLabel;
        } else {
            this.id = null;
            this.name = '';
            this.rootId = null;
            this.rootType = null;
            this.createdAt = null;
            // this.createdBy = null;
            this.updatedAt = null;
            // this.updatedBy = null;
            this.pendingSync = null;
            this.isFolder = null;
            this.size = null;
            this.breadcrumbs = [];
            this.ownership = null;
            this.allowDistribution = null;
            this.version = null;
        }
    }
}
