import { ICreateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/create-entity-failure-payload.interface';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { MaxBrainExperience } from '../models/experience.model';

export class SetExperience {
    static readonly type = '[Experience] SetExperience';

    constructor(public payload: MaxBrainExperience) {}
}

export class UnsetExperience {
    static readonly type = '[Experience] UnsetExperience';
}

export class FetchExperience {
    static readonly type = '[Experience] FetchExperience';

    constructor(public payload: string) {}
}

export class FetchExperienceSuccess {
    static readonly type = '[Experience] FetchExperienceSuccess';

    constructor(public payload: MaxBrainExperience) {}
}

export class FetchExperienceFailure {
    static readonly type = '[Experience] FetchExperienceFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class CreateExperience {
    static readonly type = '[Experience] CreateExperience';

    constructor(public payload: MaxBrainExperience) {}
}

export class CreateExperienceSuccess {
    static readonly type = '[Experience] CreateExperienceSuccess';

    constructor(public payload: MaxBrainExperience) {}
}

export class CreateExperienceFailure {
    static readonly type = '[Experience] CreateExperienceFailure';

    constructor(public payload: ICreateEntityFailureActionPayload) {}
}

export class UpdateExperience {
    static readonly type = '[Experience] UpdateExperience';

    constructor(public payload: MaxBrainExperience) {}
}

export class UpdateExperienceSuccess {
    static readonly type = '[Experience] UpdateExperienceSuccess';

    constructor(public payload: MaxBrainExperience) {}
}

export class UpdateExperienceFailure {
    static readonly type = '[Experience] UpdateExperienceFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}
export class DeleteExperience {
    static readonly type = '[Experience] DeleteExperience';

    constructor(public payload: string) {}
}

export class DeleteExperienceSuccess {
    static readonly type = '[Experience] DeleteExperienceSuccess';

    constructor(public payload: string) {}
}

export class DeleteExperienceFailure {
    static readonly type = '[Experience] DeleteExperienceFailure';

    constructor(public payload: string) {}
}

export class SetExperiences {
    static readonly type = '[Experience] SetExperiences';

    constructor(public payload: MaxBrainExperience[]) {}
}

export class FetchExperiences {
    static readonly type = '[Experience] FetchExperiences';
}

export class FetchExperiencesSuccess {
    static readonly type = '[Experience] FetchExperiencesSuccess';
}

export class FetchExperiencesFailure {
    static readonly type = '[Experience] FetchExperiencesFailure';
}

export class ToggleSelectedExperience {
    static readonly type = '[Experience] ToggleSelectedExperience';

    constructor(public payload: string) {}
}

export class SelectFilteredExperiences {
    static readonly type = '[Experience] SelectFilteredExperiences';
}

export class DeselectSelectedExperiences {
    static readonly type = '[Experience] DeselectSelectedExperiences';
}

export class SearchExperiences {
    static readonly type = '[Experience] SearchExperiences';

    constructor(public payload: string) {}
}

export class FetchGrowKeywords {
    static readonly type = '[Experience] FetchGrowKeywords';

    constructor() {}
}

export class FetchGrowKeywordsSuccess {
    static readonly type = '[Experience] FetchGrowKeywordsSuccess';

    constructor(public payload: string[]) {}
}

export class FetchGrowKeywordsFailure {
    static readonly type = '[Experience] FetchGrowKeywordsFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}
