import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ISectionQuery } from 'app/library/section/interfaces/section.query.interface';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SurveySection } from '../../section/models/survey-section.model';
import { ISurveyReportQuestionQuery } from '../interfaces/survey-report-question.query.interface';
import { ISurveyTemplateQuery } from '../interfaces/survey-template.query.interface';
import { SetSurveySectionCommandModel } from '../models/set-survey-section.command-model';
import { SurveyInfo } from '../models/survey-info.model';
import { SurveyReportQuestion } from '../models/survey-report-question.model';
import { SurveyTemplate } from '../models/survey-template.model';

@Injectable()
export class SurveySectionApiService {
    constructor(private _http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) private _environment: IEnvironment) {}

    getSurveyTemplates(): Observable<SurveyTemplate[]> {
        return this._http
            .get<ISurveyTemplateQuery[]>(`${this._environment.apiUrl}/survey/survey-templates`)
            .pipe(map((queries) => queries.map((query) => new SurveyTemplate(query))));
    }

    setSurveySection(sectionId: string, surveyInfo: SurveyInfo, descriptionVisibility: boolean, description?: string, templateId?: string): Observable<SurveySection> {
        const data = new SetSurveySectionCommandModel(surveyInfo, descriptionVisibility, description, templateId);
        return this._http.patch<ISectionQuery>(`${this._environment.apiUrl}/survey-sections/${sectionId}`, data).pipe(map((query) => new SurveySection(query)));
    }

    setSurveySectionExternal(sectionId: string, url: string, description?: string): Observable<SurveySection> {
        return this._http
            .patch<ISectionQuery>(`${this._environment.apiUrl}/survey-sections/external/${sectionId}`, {
                url,
                description,
            })
            .pipe(map((query) => new SurveySection(query)));
    }

    setSurveyStatus(sectionId: string, status: string): Observable<SurveySection> {
        return this._http
            .patch<ISectionQuery>(`${this._environment.apiUrl}/survey-sections/${sectionId}/status`, {
                status,
            })
            .pipe(map((query) => new SurveySection(query)));
    }

    downloadSurveyResultsAsExcel(sectionId: string): Promise<Blob> {
        return this._http
            .get(`${this._environment.apiUrl}/survey-sections/${sectionId}/results`, { responseType: 'blob' })
            .pipe(tap((file) => MaxBrainUtils.downloadFile(file, 'Survey results.xlsx')))
            .toPromise();
    }

    sendReminder(sectionId: string, subject: string, message: string, is_urgent: boolean): Promise<void> {
        return this._http
            .post<any>(`${this._environment.apiUrl}/survey-sections/${sectionId}/send-reminder`, {
                subject,
                message,
                is_urgent,
            })
            .toPromise();
    }

    submitResponses(sectionId: string): Observable<void> {
        return this._http.post<void>(`${this._environment.apiUrl}/survey-sections/${sectionId}/submit-responses`, {});
    }

    fetchSurveySectionReportData(sectionId: string): Observable<SurveyReportQuestion[][]> {
        return this._http.get<ISurveyReportQuestionQuery[][]>(`${this._environment.apiUrl}/survey-sections/${sectionId}/reporting-data`).pipe(
            map((queries) => {
                return queries.map((set) => {
                    return set.map((question) => new SurveyReportQuestion(question));
                });
            })
        );
    }

    refreshSurveySectionReportData(sectionId: string): Observable<void> {
        return this._http.post<void>(`${this._environment.apiUrl}/survey-sections/${sectionId}/reporting-data/refresh`, {});
    }

    refreshQuizSectionReportData(sectionId: string): Observable<void> {
        return this._http.post<void>(`${this._environment.apiUrl}/assessment-sections/${sectionId}/reporting-data/refresh`, {});
    }

    refreshFeedbackSectionReportData(sectionId: string): Observable<void> {
        return this._http.post<void>(`${this._environment.apiUrl}/assessment-sections/${sectionId}/reporting-data/refresh`, {});
    }

    fetchSurveyKeywords(): Observable<string[]> {
        return this._http.get<string[]>(`${this._environment.apiUrl}/survey-sections/reminder/keywords`);
    }
}
