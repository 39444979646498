import { HttpErrorResponse } from '@angular/common/http';
import { StateContext } from '@ngxs/store';
import { IActionWithPayload } from 'app/projects/core/src/lib/interfaces/action_with_payload.interface';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { merge, Observable } from 'rxjs';
import { reduce, takeLast } from 'rxjs/operators';
import { IEntity } from '../interfaces/entity.interface';
import { IEntityApiService } from '../interfaces/entity.service.interface';
import { IFetchEntityFailureActionPayload } from '../interfaces/fetch-entity-failure-payload.interface';
import { EntityStateModel } from './entity.state-model';

export abstract class EntityState<T extends IEntity> {
    private _expirationTime = {};
    protected _propstoSearch = null;

    constructor(protected entityApiService: IEntityApiService<T>, private _timeoutDuration: number = 0) {
        if (this._timeoutDuration < 0) {
            this._timeoutDuration = 0;
        }
    }

    protected setExpirationTime(key: string): void {
        this._expirationTime[key] = this._timeoutDuration * 1000 + Date.now();
    }

    protected _filterEntitiesByStringAndMapToIds(entities: T[], searchString: string): string[] {
        return MaxBrainUtils.filterArrayByString(entities, searchString, this._propstoSearch).map((entity) => entity.id);
    }

    protected _setEntity(ctx: StateContext<EntityStateModel<T>>, { payload }: IActionWithPayload<T>, AddEntitiesAction = null): void {
        ctx.patchState({
            item: payload.id,
        });

        if (!!AddEntitiesAction) {
            // TODO: maybe move in front of patchState
            ctx.dispatch(new AddEntitiesAction([payload]));
        }
    }

    protected _unsetEntity({ patchState }: StateContext<EntityStateModel<T>>): void {
        patchState({
            item: null,
        });
    }

    protected _addEntities(ctx: StateContext<EntityStateModel<T>>, { payload }: IActionWithPayload<T[]>): void {
        const state = ctx.getState();
        let entities: T[];

        payload = payload ? payload.filter((entity) => !!entity) : [];

        const stateList = state.map ? Array.from(state.map.values()) : null;

        if (!stateList) {
            entities = [...payload];
        } else {
            const payloadIds = payload.map((item) => item.id);

            entities = [
                ...stateList.filter((entity) => {
                    return payloadIds.indexOf(entity.id) === -1;
                }),
                ...payload,
            ];
        }

        this._setEntities(ctx, { payload: entities });
    }

    protected async _createEntity(
        ctx: StateContext<EntityStateModel<T>>,
        { payload }: IActionWithPayload<T>,
        SuccessAction: any,
        FailureAction: any,
        AddEntitiesAction: any,
        SetEntityAction = null,
        serviceMethod: Observable<T> = this.entityApiService ? this.entityApiService.create(payload) : null
    ): Promise<T> {
        if (!serviceMethod) {
            throw new Error('A service method must be provided to use the _createEntity protected method. ');
        }

        try {
            const entity = await serviceMethod.toPromise();

            this.setExpirationTime(entity.id);

            const actions = [];

            let setEntity = !!SetEntityAction;

            if (setEntity) {
                // FIXME: I can't remember why this exists, try to remove soon!
                for (const key in payload) {
                    if (key !== 'id' && entity[key] !== payload[key]) {
                        setEntity = false;
                    }
                }
            }

            if (setEntity) {
                actions.push(new SetEntityAction(entity));
            } else if (!AddEntitiesAction) {
                throw new Error('Either AddEntitiesAction or SetEntityAction property must be defined!');
            } else {
                actions.push(new AddEntitiesAction([entity]));
            }

            setTimeout(() => {
                ctx.dispatch([...actions, new SuccessAction(entity)]);
            });

            return entity;
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                ctx.dispatch(new FailureAction({ response: e }));
            } else {
                throw new Error(e);
            }
        }
    }

    protected async _updateEntity(
        ctx: StateContext<EntityStateModel<T>>,
        { payload }: IActionWithPayload<T>,
        SuccessAction: any,
        FailureAction: any,
        AddEntitiesAction = null,
        serviceMethod: Observable<T> = this.entityApiService ? this.entityApiService.update(payload) : null
    ): Promise<T> {
        if (!serviceMethod) {
            throw new Error('A service method must be provided to use the _updateEntity protected method. ');
        }

        try {
            const entity = await serviceMethod.toPromise();

            this.setExpirationTime(entity.id);

            const actions = [];

            if (!!AddEntitiesAction) {
                actions.push(new AddEntitiesAction([entity]));
            }

            ctx.dispatch([...actions, new SuccessAction(entity)]);

            return entity;
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                ctx.dispatch(new FailureAction(payload.id));
            } else {
                throw new Error(e);
            }
        }
    }

    protected async _deleteEntity(
        ctx: StateContext<EntityStateModel<T>>,
        { payload }: IActionWithPayload<string>,
        SuccessAction: any,
        FailureAction: any,
        serviceMethod: Observable<string> = this.entityApiService ? this.entityApiService.delete(payload) : null
    ): Promise<boolean> {
        if (!serviceMethod) {
            throw new Error('A service method must be provided to use the _deleteEntity protected method. ');
        }

        try {
            await serviceMethod.toPromise();

            this._removeEntitiesFromState(ctx, [payload]);

            ctx.dispatch(new SuccessAction(payload));

            return true;
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                ctx.dispatch(new FailureAction(payload));
            } else {
                throw new Error(e);
            }

            return false;
        }
    }

    protected async _fetchEntity(
        ctx: StateContext<EntityStateModel<T>>,
        { payload }: IActionWithPayload<string>,
        SuccessAction: any,
        FailureAction: any,
        AddEntitiesAction: any,
        SetEntityAction = null,
        serviceMethod: Observable<T> = this.entityApiService ? this.entityApiService.read(payload) : null
    ): Promise<T> {
        if (!serviceMethod) {
            throw new Error('A service method must be provided to use the _fetchEntity protected method. ');
        }

        const state = ctx.getState();

        if (state.fetchingItem.indexOf(payload) !== -1) {
            return;
        }

        ctx.patchState({
            fetchingItem: [...state.fetchingItem, payload],
        });

        const actions = [];

        /**
         * Checks if the entity shuold be fetched in case the timeoutDuration hasn't elapsed since the last fetch
         */
        if (this._expirationTime[payload] && this._expirationTime[payload] > Date.now()) {
            let fetchEntity = false;
            let entity = state.map ? state.map.get(state.item) || null : null;

            if (!entity || entity.id !== payload) {
                entity = state.map ? (state.map.has(payload) ? state.map.get(payload) : entity) : null;

                fetchEntity = !entity;
            }

            if (!fetchEntity) {
                setTimeout(() => {
                    if (SetEntityAction) {
                        actions.push(new SetEntityAction(entity));
                    }

                    ctx.dispatch([...actions, new SuccessAction(entity)]);
                });
                return;
            }
        }

        try {
            const entity = await serviceMethod.toPromise();

            if (SetEntityAction) {
                actions.push(new SetEntityAction(entity));
            } else if (!AddEntitiesAction) {
                throw new Error('Either AddEntitiesAction or SetEntityAction property must be defined!');
            } else {
                actions.push(new AddEntitiesAction([entity]));
            }

            ctx.dispatch([...actions, new SuccessAction(entity)]);

            return entity;
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                ctx.dispatch(new FailureAction({ entityId: payload, e }));
            } else {
                throw new Error(e);
            }
        } finally {
            this.setExpirationTime(payload);
        }
    }

    protected _fetchEntitySuccess({ getState, patchState }: StateContext<EntityStateModel<T>>, { payload }: IActionWithPayload<T>): void {
        const state = getState();

        patchState({
            fetchingItem: state.fetchingItem.filter((entityId) => entityId !== payload.id),
        });
    }

    protected _fetchEntityFailure({ getState, patchState }: StateContext<EntityStateModel<T>>, { payload }: IActionWithPayload<IFetchEntityFailureActionPayload>): void {
        const state = getState();

        patchState({
            fetchingItem: state.fetchingItem.filter((entityId) => entityId !== payload.entityId),
        });
    }

    protected _setEntities({ getState, patchState }: StateContext<EntityStateModel<T>>, { payload }: IActionWithPayload<T[]>): void {
        if (!payload) {
            return;
        }

        const state = getState();

        let mapHasChanged = !state.map || state.map.size !== payload.length;

        const stateMap = new Map(mapHasChanged ? null : state.map);

        payload
            .filter((entity) => !!entity)
            .forEach((entity) => {
                if (mapHasChanged || !stateMap.has(entity.id) || !MaxBrainUtils.areObjectsEqual(entity, stateMap.get(entity.id))) {
                    this.setExpirationTime(entity.id);
                    stateMap.set(entity.id, entity);
                    mapHasChanged = true;
                }
            });

        if (mapHasChanged) {
            patchState({
                map: stateMap,
            });
        }
    }

    protected async _fetchEntities(
        ctx: StateContext<EntityStateModel<T>>,
        SuccessAction: any,
        FailureAction: any,
        SetEntitiesAction: any,
        serviceMethod: Observable<T[]> = this.entityApiService ? this.entityApiService.getAll() : null
    ): Promise<void> {
        if (!serviceMethod) {
            throw new Error('A service method must be provided to use the _fetchEntities protected method. ');
        }

        const state = ctx.getState();

        if (state.fetchingList) {
            return;
        }

        ctx.patchState({
            fetchingList: true,
        });

        /**
         * Skips fetching the entities in case the timeoutDuration hasn't elapsed since the last fetch
         */
        if (this._expirationTime['list'] && this._expirationTime['list'] > Date.now() && state.map) {
            setTimeout(() => {
                ctx.dispatch(new SuccessAction());
            });
            return;
        }

        try {
            const entities = await serviceMethod.toPromise();

            ctx.dispatch([new SetEntitiesAction(entities), new SuccessAction()]);
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                console.error(e);

                ctx.dispatch(new FailureAction());
            } else {
                throw new Error(e);
            }
        } finally {
            this.setExpirationTime('list');
        }
    }

    protected _fetchEntitiesSuccess({ patchState }: StateContext<EntityStateModel<T>>): void {
        patchState({
            fetchingList: false,
        });
    }

    protected _fetchEntitiesFailure({ patchState }: StateContext<EntityStateModel<T>>): void {
        patchState({
            fetchingList: false,
        });
    }

    protected _removeEntitiesFromState(ctx: StateContext<EntityStateModel<T>>, entityIds: string[]): void {
        const state = ctx.getState();

        if (entityIds.includes(state.item)) {
            this._unsetEntity(ctx);
        }

        const payload = state.map ? Array.from(state.map.values()).filter((entity) => !entityIds.includes(entity.id)) : null;

        this._setEntities(ctx, { payload });
    }

    protected async _deleteEntities(ctx: StateContext<EntityStateModel<T>>, ids: string[], SuccessAction: any, FailureAction: any): Promise<void> {
        if (!this.entityApiService) {
            throw new Error('A entityService method must be provided in the constructor to use the _deleteEntities protected method. ');
        }

        let observable: Observable<string>;

        ids.forEach((entityId, index) => {
            observable = index === 0 ? this.entityApiService.delete(entityId) : merge(observable, this.entityApiService.delete(entityId));
        });

        try {
            const entityIds = await observable
                .pipe(
                    reduce<string, string[]>((acc, val) => {
                        if (!acc) {
                            return [val];
                        }

                        if (typeof acc === 'string') {
                            return [acc, val];
                        }

                        return val ? [...acc, val] : acc;
                    }),
                    takeLast(1)
                )
                .toPromise();

            this._removeEntitiesFromState(ctx, entityIds);

            ctx.dispatch(new SuccessAction({ entityIds }));
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                console.error(e);

                ctx.dispatch(new FailureAction());
            } else {
                throw new Error(e);
            }
        }
    }
}
