import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from '../enums/job-status.enum';

@Pipe({ name: 'getJobStatusIcon' })
export class GetJobStatusIconPipe implements PipeTransform {
    transform(jobStatus: JobStatus, jobStats: any): any {
        switch (jobStatus) {
            case JobStatus.Created:
            case JobStatus.InProgress:
                return 'status_in_progress';
            case JobStatus.TimedOut:
                return 'status_timed_out';
            case JobStatus.Canceled:
                return 'status_error';
            case JobStatus.Finished:
                return jobStats.failed > 0 ? 'status_error' : 'status_succeeded';
            default:
                return 'status_error';
        }
    }
}
