<app-dialog-header [title]="'FORM.EXPORT_CALENDAR.TITLE'" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content fxLayout="column" fxLayoutAlign="start">
    <form name="calendarForm" class="w-100-p" fxLayout="column">
        <div class="p-24">
            <div class="description" [innerHTML]="'FORM.EXPORT_CALENDAR.DESCRIPTION' | translate"></div>

            <mat-form-field class="w-100-p">
                <input matInput name="syncUrl" [(ngModel)]="calendarSyncUrl.url" [readonly]="true" />
            </mat-form-field>

            <div fxLayout="row">
                <app-flat-button class="mr-16" [label]="'GENERAL.BUTTON.COPY_TO_CLIPBOARD'" (onClick)="copyToClipboard()" [participantViewButton]="true"></app-flat-button>
            </div>
        </div>
    </form>
</div>

<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close()"></app-white-button>
</div>
