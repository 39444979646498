<mat-card class="tag-color-card" [hidden]="hide" #card>
    <mat-form-field>
        <input matInput [placeholder]="placeholder | translate" [formControl]="nameCtrl" (keydown.enter)="editTag(nameCtrl.value)" />
    </mat-form-field>
    <mat-icon class="close" (click)="toggle($event)">close</mat-icon>
    <div class="colors-wrapper" fxLayout="start">
        <div
            class="color-block"
            *ngFor="let color of colors; let i = index"
            [style.background]="color"
            (click)="editTag(nameCtrl.value, color); clicked = color"
            [class.active]="clicked === color"
        ></div>
    </div>
    <div class="text-wrapper">
        <span
            ><strong>{{ 'GENERAL.IMPORTANT' | translate }}</strong></span
        >
        <span>{{ 'GENERAL.COLOR_CHANGES_GLOBALLY' | translate }}</span>
    </div>
</mat-card>
