import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotifyType } from '../../../enums/notify-type.enum';
import { IConfirmNotifyDialogResult } from '../../../interfaces/confirm-dialog-result.interface';
import { MaxBrainConfirmDialogComponent } from '../confirm/confirm.component';

@Component({
    selector: 'app-confirm-notify-dialog',
    templateUrl: './confirm-notify.component.html',
    styleUrls: ['./confirm-notify.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MaxBrainConfirmNotifyDialogComponent extends MaxBrainConfirmDialogComponent {
    public notifyText: string = 'GENERAL.MESSAGE.NOTIFY_USER';
    isNotifyChecked: boolean = false;

    constructor(dialogRef: MatDialogRef<MaxBrainConfirmNotifyDialogComponent>) {
        super(dialogRef);
    }

    closeDialog(result: boolean, additionalInfo?: string): void {
        const data: IConfirmNotifyDialogResult = {
            result,
            additionalButtonInfo: additionalInfo,
            isNotifyChecked: this.isNotifyChecked ? NotifyType.Notify : NotifyType.DoNotNotify,
        };

        this.dialogRef.close(data);
    }
}
