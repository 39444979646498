<button
    fxLayout="row"
    fxLayoutAlign="center center"
    type="button"
    class="bg-white rounded-sm py-8 px-12 pointer border border-gray-300"
    [class.is-active]="isActive"
    [class.is-rounded]="isRounded"
    (click)="onClickButton($event)"
    [disabled]="disabled"
>
    <mat-icon class="mr-8" *ngIf="icon">{{ icon }}</mat-icon>
    <mat-icon class="mr-8" *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>

    <span class="text-sm text-nowrap text-black">{{ label | translate }}<span *ngIf="showThreeDots">...</span></span>
    <mat-icon class="ml-8" *ngIf="showArrowRight">keyboard_arrow_right</mat-icon>
    <span class="text-sm" *ngIf="counter"> &nbsp;({{ counter }})</span>
</button>
