import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { TenantDataService } from 'app/config/services/tenant-data.service';
import { Feature } from 'app/library/feature/models/feature.model';
import { FeatureState } from 'app/library/feature/models/feature.state';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable()
export class NewModuleGuard implements CanActivate {
    @Select(UserState.getMyInfo)
    me$: Observable<User>;

    @Select(FeatureState.getEntities)
    feature$: Observable<Feature[]>;

    constructor(private _tenantDataService: TenantDataService, private _store: Store) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return combineLatest(this.me$, this.feature$).pipe(
            filter(([me, features]) => {
                return !!me && !!features;
            }),
            map(([me, features]) => {
                if (me.permissions.indexOf('tenant_management') !== -1) {
                    return true;
                } else if (next.params.type === 'choose') {
                    return false;
                }

                if (features.filter((feature) => feature.id === 'module_visibility' && feature.status === 'active').length === 1) {
                    if (next.params.type === 'private') {
                        return this._tenantDataService.data.canCreatePrivateModule;
                    }

                    return true;
                }

                return false;
            }),
            tap((success) => {
                if (!success) {
                    this._store.dispatch(new Navigate(['/']));
                }
            })
        );
    }
}
