<div class="nav" [class.hasRightSideNavigation]="hasRightSideNavigation" [ngClass]="{ horizontal: layout === 'horizontal', vertical: layout === 'vertical' }">
    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">
        <ng-container *ngFor="let item of navigation">
            <fuse-nav-vertical-group *ngIf="item.type == 'group'" [item]="item"></fuse-nav-vertical-group>
            <fuse-nav-vertical-collapsable *ngIf="item.type == 'collapsable'" [item]="item"></fuse-nav-vertical-collapsable>
            <fuse-nav-vertical-item
                *ngIf="item.type == 'item' && item.action !== 'openAsyncDrawer'"
                [item]="item"
                [unreadFeedItemsCount$]="unreadFeedItemsCount$"
            ></fuse-nav-vertical-item>
            <fuse-nav-vertical-item
                *ngIf="
                    item.type == 'item' &&
                    item.action == 'openAsyncDrawer' &&
                    (asyncJobApiService.jobsPagination.numberOfResults > 0 || asyncJobApiService.numberOfActiveAsyncJobs > 0)
                "
                (click)="openAsyncDrawer()"
                [item]="item"
                [isDrawerOpen]="isDrawerOpen"
                [numberOfActiveAsyncJobs]="asyncJobApiService.numberOfActiveAsyncJobs"
            >
            </fuse-nav-vertical-item>
        </ng-container>
    </ng-container>
    <!-- / Vertical Navigation Layout -->

    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">
        <ng-container *ngFor="let item of navigation">
            <fuse-nav-horizontal-collapsable *ngIf="item.type == 'group'" [item]="item"></fuse-nav-horizontal-collapsable>
            <fuse-nav-horizontal-collapsable *ngIf="item.type == 'collapsable'" [item]="item"> </fuse-nav-horizontal-collapsable>
            <fuse-nav-horizontal-item *ngIf="item.type == 'item' && !item.action" [item]="item" [unreadFeedItemsCount$]="unreadFeedItemsCount$"></fuse-nav-horizontal-item>
            <fuse-nav-horizontal-item
                style="display: flex; justify-content: flex-end"
                *ngIf="
                    item.type == 'item' &&
                    item.action == 'openAsyncDrawer' &&
                    (asyncJobApiService.jobsPagination.numberOfResults > 0 || asyncJobApiService.numberOfActiveAsyncJobs > 0)
                "
                (click)="openAsyncDrawer()"
                [item]="item"
                [isDrawerOpen]="isDrawerOpen"
                [numberOfActiveAsyncJobs]="asyncJobApiService.numberOfActiveAsyncJobs"
            >
            </fuse-nav-horizontal-item>
        </ng-container>
    </ng-container>
    <!-- / Horizontal Navigation Layout -->
</div>
