import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../../../core/src/lib/models/unsubscribe-on-destroy';

const HIDE_SMART_BANNER = 'hideSmartBanner';

@Component({
    selector: 'lib-smart-banner',
    templateUrl: './smart-banner.component.html',
    styleUrls: ['./smart-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartBannerComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    isAndroid: boolean;
    @Input()
    isIOS: boolean;
    @Input()
    href: string;
    @Input()
    src: string;
    @Input()
    title: string;
    @Input()
    urls: string[];

    @Output()
    hide = new EventEmitter<void>();

    isVisible = !localStorage.getItem(HIDE_SMART_BANNER);

    constructor(private cdr: ChangeDetectorRef, private router: Router) {
        super();
    }

    ngOnInit(): void {
        if (this.isVisible) {
            const routeSubscription = this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    takeUntil(this._unsubscribeAll)
                )
                .subscribe(({ url }: NavigationEnd) => {
                    this.isVisible = !this.urls.length || this.urls.indexOf(url) !== -1;
                    this.cdr.detectChanges();
                });

            const hideSubscription = this.hide.subscribe(() => {
                this.isVisible = false;
                localStorage.setItem(HIDE_SMART_BANNER, `${!this.isVisible}`);
                routeSubscription.unsubscribe();
                hideSubscription.unsubscribe();
                this.cdr.detectChanges();
            });
        }
    }
}
