<app-dialog-header [title]="title" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="p-24" fxLayout="column" fxLayoutAlign="start">
    <mat-form-field *ngIf="isRoleLabelVisible">
        <input matInput [placeholder]="'GENERAL.PROPERTY.ROLE' | translate" [(ngModel)]="roleLabel" />
    </mat-form-field>

    <ng-container *ngIf="list.length">
        <h3 class="mb-8 text-base font-medium text-gray-600">{{ 'GENERAL.LABEL.SELECTED_USERS' | translate }}</h3>
        <mat-chip-list class="chip-list wrap">
            <mat-chip class="chip accent" [removable]="removable" (removed)="removeFromList(item)" *ngFor="let item of list">
                {{ item.fullName }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </ng-container>

    <div fxLayout="row" fxLayoutAlign="flex-end center" class="mb-12">
        <app-search-outline [searchInput]="searchInput"></app-search-outline>
    </div>

    <div class="w-100-p table-wrapper" *ngIf="dataSource">
        <mat-table #table [dataSource]="dataSource" matSort>
            <!-- Avatar Column -->
            <ng-container matColumnDef="avatar">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <div class="relative" #avatarCell>
                        <img class="avatar" *ngIf="user?.avatar.src; else initials" [alt]="user.fullName" [src]="user.avatar.src" />
                        <ng-template #initials>
                            <div fxLayoutAlign="center center" class="avatar-initials avatar">{{ user.fullName | initials }}</div>
                        </ng-template>
                        <ng-template [onlineStateIndicator]="user.isOnline"> </ng-template>
                    </div>
                </mat-cell>
            </ng-container>

            <!-- First Name Column -->
            <ng-container matColumnDef="firstName">
                <mat-header-cell class="ml-16 narrow" *matHeaderCellDef mat-sort-header> {{ 'GENERAL.PROPERTY.FIRST_NAME' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let user" class="ml-16 narrow">
                    <p #firstNameRow class="text-truncate font-semibold">
                        {{ user.firstName }}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- Last Name Column -->
            <ng-container matColumnDef="lastName">
                <mat-header-cell class="narrow" *matHeaderCellDef mat-sort-header>
                    {{ 'GENERAL.PROPERTY.LAST_NAME' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let user" class="narrow">
                    <p #lastNameRow class="text-truncate font-semibold">
                        {{ user.lastName }}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <mat-header-cell class="wider" *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                    {{ 'GENERAL.PROPERTY.EMAIL' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let user" class="wider" fxHide fxShow.gt-xs>
                    <p #lastNameRow class="text-truncate font-semibold">{{ user.email }}</p>
                </mat-cell>
            </ng-container>

            <!-- Buttons Column -->
            <ng-container matColumnDef="buttons">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <div fxFlex="row" fxLayoutAlign="end center">
                        <app-flat-button [label]="'GENERAL.BUTTON.ADD'" (onClick)="addToList(user)" *ngIf="!user.alreadyMember && !user.selected && !isChat"></app-flat-button>
                        <app-flat-button [label]="'GENERAL.PROPERTY.SEND_MESSAGE'" [svgIcon]="'chat_bubble'" (onClick)="confirm(user)" *ngIf="isChat"></app-flat-button>
                        <p *ngIf="user.alreadyMember && !isChat">{{ 'GENERAL.LABEL.IN_GROUP' | translate }}</p>
                        <app-white-button [label]="'GENERAL.BUTTON.UNSELECT'" (onClick)="removeFromList(user)" *ngIf="user.selected && !isChat"></app-white-button>
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let user; columns: displayedColumns" class="user" matRipple> </mat-row>
        </mat-table>
        <mat-paginator [length]="isChat ? (totalCountChatUsers$ | async) : (totalCount$ | async)" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]"> </mat-paginator>
    </div>
</div>

<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center" *ngIf="!isChat">
    <mat-checkbox *ngIf="isNotifyCheckboxVisible" class="mr-24" [(ngModel)]="notifyMembers">
        {{ notifyText | translate }}
    </mat-checkbox>
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>
    <app-flat-button [label]="'GENERAL.BUTTON.SAVE'" [disabled]="0 === list.length" (onClick)="saveSelected()"></app-flat-button>
</div>
