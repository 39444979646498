import { Feature } from '../models/feature.model';

export class SetFeatures {
    static readonly type = '[Feature] SetFeatures';

    constructor(public payload: Feature[]) {}
}

export class FetchFeatures {
    static readonly type = '[Feature] FetchFeatures';
}

export class FetchFeaturesSuccess {
    static readonly type = '[Feature] FetchFeaturesSuccess';
}

export class FetchFeaturesFailure {
    static readonly type = '[Feature] FetchFeaturesFailure';
}
