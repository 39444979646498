import { PaginatedSubentityStateModel } from './paginated-subentity.state-model';
import { StateContext } from '@ngxs/store';
import { Sort } from '@angular/material/sort';

export abstract class UtilsState {
    /**
     *
     * @param state
     * @param entities
     */
    static getEntities<T>(state: PaginatedSubentityStateModel, entities: Map<string, T>): T[] {
        if (!entities) {
            return null;
        }

        return state.list.map((id) => (entities.has(id) ? entities.get(id) : null)).filter((entity) => entity);
    }

    /**
     *
     * @param state
     * @param entities
     */
    static getFilteredEntities<T>(state: PaginatedSubentityStateModel, entities: Map<string, T>): T[] {
        if (!entities) {
            return null;
        }

        return state.filtered.map((id) => (entities.has(id) ? entities.get(id) : null)).filter((entity) => entity);
    }

    /**
     *
     * @param patchState
     * @param active
     * @param direction
     * @param identifier
     */
    static sort({ patchState }: StateContext<PaginatedSubentityStateModel>, { active, direction }: Sort, identifier: string): void {
        if (identifier) {
            sessionStorage.setItem(`${identifier}SortBy`, active);
            sessionStorage.setItem(`${identifier}SortOrder`, direction);
        }

        patchState({
            sortBy: active,
            sortOrder: direction,
        });
    }

    static language({ patchState }: StateContext<PaginatedSubentityStateModel>, payload: string, identifier: string): void {
        if (identifier) {
            sessionStorage.setItem(`${identifier}Language`, payload);
        }

        patchState({
            language: payload,
        });
    }

    /**
     *
     * @param patchState
     * @param payload
     * @param identifier
     */
    static search({ patchState }: StateContext<PaginatedSubentityStateModel>, payload: string, identifier: string): void {
        if (identifier) {
            sessionStorage.setItem(`${identifier}SearchTerm`, payload);
        }

        patchState({
            searchTerm: payload,
        });
    }

    /**
     * serch team for the manager mode
     * @param patchState
     * @param payload
     * @param identifier
     */
    static searchManager({ patchState }: StateContext<PaginatedSubentityStateModel>, payload: string, identifier: string): void {
        if (identifier) {
            sessionStorage.setItem(`${identifier}ManagerSearchTerm`, payload);
        }

        patchState({
            managerSearchTerm: payload,
        });
    }
}
