<app-dialog-header [title]="title" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="form-wrapper" fxLayout="column" fxLayoutAlign="start">
    <form name="eventForm" [formGroup]="eventForm" class="event w-100-p p-24" fxLayout="column">
        <mat-form-field class="w-100-p">
            <input matInput name="name" formControlName="name" [placeholder]="'FORM.EVENT.FIELD.NAME' | translate" />
        </mat-form-field>

        <mat-form-field class="w-100-p">
            <input matInput name="startEndDate" formControlName="startEndDate" [placeholder]="'GENERAL.PROPERTY.CHOOSE_DATE' | translate" autocomplete="off" ngxDaterangepickerMd />
            <mat-icon class="timepicker-icon">date_range</mat-icon>
        </mat-form-field>

        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="0" fxLayoutGap.gt-xs="6%">
            <mat-form-field fxFlex="1 0 100%" fxFlex.gt-xs="1 0 47%">
                <input matInput [cleave]="{ time: true, timePattern: ['h', 'm'] }" formControlName="startTime" [placeholder]="'GENERAL.PROPERTY.START_TIME' | translate" />
                <mat-icon class="timepicker-icon">access_time</mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="1 0 100%" fxFlex.gt-xs="1 0 47%">
                <input matInput [cleave]="{ time: true, timePattern: ['h', 'm'] }" formControlName="endTime" [placeholder]="'GENERAL.PROPERTY.END_TIME' | translate" />
                <mat-icon class="timepicker-icon">access_time</mat-icon>
            </mat-form-field>
        </div>

        <mat-form-field class="w-100-p">
            <input matInput id="location" [placeholder]="'LOCATION.SINGULAR' | translate" aria-label="Location" [matAutocomplete]="auto" formControlName="location" />
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let location of locations" [value]="location.name">
                    <span>{{ location.name }}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-label>{{ 'FORM.EVENT.FIELD.DESCRIPTION' | translate }}</mat-label>
        <div class="mat-form-field w-100-p mat-form-field-can-float mat-form-field-should-float">
            <div class="mat-form-field-wrapper pb-0">
                <div class="mat-form-field-flex">
                    <div class="mat-form-field-infix">
                        <p class="event-description p-16" [innerHTML]="eventForm.value.description | safeHtml" (click)="editDescription()"></p>
                    </div>

                    <div class="mat-form-field-underline ng-tns-c32-4 ng-star-inserted">
                        <span class="mat-form-field-ripple"></span>
                    </div>

                    <div class="mat-form-field-subscript-wrapper" ng-reflect-ng-switch="hint">
                        <div
                            class="mat-form-field-hint-wrapper ng-tns-c32-4 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                            style="opacity: 1; transform: translateY(0%)"
                        >
                            <div class="mat-form-field-hint-spacer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div fxLayout="column" *ngIf="isModuleFeatureAttendanceActive && isModuleEvent && (moduleKind === ModuleKind.Normal || moduleKind === ModuleKind.Execution)">
            <mat-label class="mt-24 attendance-tracking-label">{{ 'FORM.EVENT.FIELD.ATTENDANCE_TRACKING' | translate }}</mat-label>
            <span>{{ 'FORM.EVENT.FIELD.ATTENDANCE_TRACKING_DESCRIPTION' | translate }}</span>
            <mat-checkbox class="enable-attendance-tracking-label mt-24" formControlName="trackAttendance">
                {{ 'FORM.EVENT.FIELD.ENABLE_ATTENDANCE_TRACKING' | translate }}
            </mat-checkbox>
        </div>
    </form>
</div>

<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close()"></app-white-button>

    <app-flat-button [label]="'GENERAL.BUTTON.SAVE'" (onClick)="save()" [disabled]="eventForm.invalid"></app-flat-button>
</div>
