import { Component, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Actions, Select, Store } from '@ngxs/store';
import { AllUserDataSource } from 'app/library/all-users/components/active-user-list/datasource';
import { AllUsersState } from 'app/library/all-users/models/all-users.state';
import { ChatUsersState } from 'app/library/all-users/models/chat-users.state';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { NotifyType } from 'app/projects/shared/src/lib/enums/notify-type.enum';
import { User } from 'app/projects/user/src/lib/models/user';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { SearchAllUsers, SearchChatUsers, SortAllUsers, SortChatUsers } from '../../../../all-users/actions/index';
import { FeatureService } from '../../../../feature/services/feature.service';

@Component({
    selector: 'app-select-group-members-dialog',
    templateUrl: './select-group-members-dialog.component.html',
    styleUrls: ['./select-group-members-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SelectGroupMembersDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    entitie$: Observable<User[]>;

    @Input()
    entityId: string;

    @Input()
    isNotifyCheckboxVisible = false;

    @Input()
    notifyText = 'GENERAL.MESSAGE.NOTIFY_PARTICIPANTS';

    @Input()
    isRoleLabelVisible = false;

    @Input()
    title: string;

    @Input()
    dataType: string;

    @Input()
    role: string;

    @Input()
    moduleId: string;

    @Input()
    isChat: boolean;

    onConfirm = new EventEmitter<User>();

    notifyMembers = false;
    dataSource: AllUserDataSource;
    displayedColumns = [];
    removable = true;
    searchInput = new FormControl();
    list: User[] = [];
    hideEmail: boolean;
    roleLabel: string;

    @Select(AllUsersState.getTotalCount)
    totalCount$: Observable<number>;

    @Select(ChatUsersState.getTotalCount)
    totalCountChatUsers$: Observable<number>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;
    @ViewChild(MatSort)
    sort: MatSort;

    sortBy = this._store.selectSnapshot(AllUsersState.getSortBy);
    sortOrder = this._store.selectSnapshot(AllUsersState.getSortOrder);

    constructor(private _store: Store, public dialogRef: MatDialogRef<SelectGroupMembersDialogComponent>, private _action$: Actions, private _featureService: FeatureService) {
        super();

        this.hideEmail = this._featureService.checkFeatureStatus(FeatureSwitchName.ParticipantEmailVisibility, 'inactive');
    }

    async ngOnInit(): Promise<void> {
        this.searchInput.valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(1000), distinctUntilChanged()).subscribe((searchText) => {
            if (this.isChat) {
                this._store.dispatch(new SearchChatUsers(searchText));
            } else {
                this._store.dispatch(new SearchAllUsers(searchText));
            }
            this.loadUsersPage(true);
        });

        this.dataSource = new AllUserDataSource(this.entitie$, this._store, this._action$);

        setTimeout(() => {
            if (this.isChat) {
                this.sortBy = this._store.selectSnapshot(ChatUsersState.getSortBy);
                this.sortOrder = this._store.selectSnapshot(ChatUsersState.getSortOrder);
            }

            if (this.sortBy && this.sortOrder) {
                this.sort.sort({
                    id: this.sortBy || '',
                    start: this.sortOrder || 'asc',
                    disableClear: false,
                });
            }

            this.loadUsersPage(false);

            this.sort.sortChange.pipe(takeUntil(this._unsubscribeAll)).subscribe((sort) => {
                if (this.isChat) {
                    this._store.dispatch(new SortChatUsers(sort));
                } else {
                    this._store.dispatch(new SortAllUsers(sort));
                }

                this.loadUsersPage(true);
            });

            this.paginator.page.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                this.loadUsersPage(false);
            });

            if (this.hideEmail) {
                this.displayedColumns = ['avatar', 'firstName', 'lastName', 'buttons'];
            } else {
                this.displayedColumns = ['avatar', 'firstName', 'lastName', 'email', 'buttons'];
            }
        });
    }

    removeFromList(user: User): void {
        this.list = [...this.list.filter((item) => item.id !== user.id)];

        this.dataSource = new AllUserDataSource(
            this.entitie$.pipe(
                map((users) => {
                    return users.map((item) => {
                        if (item.id === user.id) {
                            item = Object.assign(new User(), item, {
                                selected: false,
                            });
                        } else if (this.list.find((item2) => item2.id === item.id)) {
                            item = Object.assign(new User(), item, {
                                selected: true,
                            });
                        }
                        return item;
                    });
                })
            ),
            this._store,
            this._action$
        );
    }

    addToList(user: User): void {
        this.list = [...this.list, user];
        this.dataSource = new AllUserDataSource(
            this.entitie$.pipe(
                map((users) => {
                    return users.map((item) => {
                        if (item.id === user.id || this.list.find((item2) => item2.id === item.id)) {
                            item = Object.assign(new User(), item, {
                                selected: true,
                            });
                        }
                        return item;
                    });
                })
            ),
            this._store,
            this._action$
        );
    }

    saveSelected(): void {
        this.dialogRef.close({ list: this.list, notifyMembers: this.notifyMembers ? NotifyType.Notify : NotifyType.DoNotNotify, roleLabel: this.roleLabel });
    }

    bookIntoCourses(): void {
        this.dialogRef.close({ list: this.list, notifyMembers: this.notifyMembers ? NotifyType.Notify : NotifyType.DoNotNotify, roleLabel: this.roleLabel, bookIntoCourses: true });
    }

    confirm(user: User): void {
        this.onConfirm.next(user);
    }

    async loadUsersPage(resetPageIndex = false, isUnassignedSelected = false): Promise<void> {
        if (resetPageIndex) {
            this.paginator.pageIndex = 0;
        }

        if (this.dataSource) {
            this.dataSource.loadUsers(
                this.dataType,
                [],
                this.entityId,
                this.searchInput.value,
                this.sort.active,
                this.sort.direction,
                this.paginator.pageSize,
                this.paginator.pageIndex + 1,
                isUnassignedSelected,
                this.role,
                false,
                this.moduleId
            );
        }
    }
}
