import { Component, AfterViewInit, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { MaxBrainAuthState } from 'app/projects/auth/src/lib/models/auth.state';
import { MaxBrainAuthStateModel } from 'app/projects/auth/src/lib/models/auth.state-model';

@Component({
    selector: 'app-ms-office-web-viewer',
    templateUrl: './ms-office-web-viewer.component.html',
    styleUrls: ['./ms-office-web-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MSOfficeWebViewerComponent implements AfterViewInit {
    @Input()
    officeActionUrl: string;

    accessTokenValue: string;
    accessTokenTTLValue: string;

    constructor(private _store: Store) {
        const authStateSnapshot = this._store.selectSnapshot<MaxBrainAuthStateModel>(MaxBrainAuthState);

        this.accessTokenValue = authStateSnapshot.accessToken;
        this.accessTokenTTLValue = authStateSnapshot.expiresAt.toString();
    }

    ngAfterViewInit(): void {
        const office_frame = document.createElement('iframe');
        office_frame.name = 'office_frame';
        office_frame.id = 'office_frame';
        // The title should be set for accessibility
        office_frame.title = 'Office Frame';
        // This attribute allows true fullscreen mode in slideshow view
        // when using PowerPoint's 'view' action.
        office_frame.setAttribute('allowfullscreen', 'true');
        // The sandbox attribute is needed to allow automatic redirection to the O365 sign-in page in the business user flow
        office_frame.setAttribute('sandbox', 'allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox');

        const frameholder = document.getElementById('frameholder');
        frameholder.appendChild(office_frame);

        const office_form = document.getElementById('office_form') as HTMLFormElement;
        office_form.submit();
    }
}
