import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'formatTimeSpent' })
export class FormatTimeSpentPipe implements PipeTransform {
    transform(timeSpent: number): string {
        const tempTime = moment.duration(timeSpent);
        return Math.floor(tempTime.asHours()) + 'h ' + tempTime.minutes() + 'min';
    }
}
