import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IModuleFeatureQuery } from '../interfaces/module-feature.query.interface';
import { ModuleFeatureCommand } from '../models/module-feature.command.model';
import { ModuleFeature } from '../models/module-feature.model';

@Injectable()
export class ModuleFeatureApiService extends EntityService<IModuleFeatureQuery> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'modules');
    }

    getAll(moduleId: string): Observable<any[]> {
        return this.http.get<any>(`${this.apiUrl}/modules/${moduleId}/features`).pipe(map((queries: any[]) => queries.map((query) => new ModuleFeature(query))));
    }

    update(moduleId: string, moduleFeature: ModuleFeature): Observable<any> {
        const data = new ModuleFeatureCommand(moduleFeature);
        return this.http.put<any>(`${this.apiUrl}/modules/${moduleId}/features`, Array(data)).pipe(map((queries: any[]) => queries.map((query) => new ModuleFeature(query))));
    }
}
