import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { ModuleEventAttendanceListComponent } from './components/module-event-attendance-list/list.component';
import { ModuleParticipantAttendanceListComponent } from './components/module-participant-attendance-list/list.component';
import { ModuleEventAttendanceApiService } from './services/module-event-attendance.api-service';
import { ModuleEventAttendanceService } from './services/module-event-attendance.service';

@NgModule({
    imports: [
        MaxBrainSharedModule,
        MatChipsModule,
        MatIconModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatCardModule,
        MatButtonModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatMenuModule,
        MatSelectModule,
    ],
    providers: [ModuleEventAttendanceApiService, ModuleEventAttendanceService],
    entryComponents: [],
    declarations: [ModuleEventAttendanceListComponent, ModuleParticipantAttendanceListComponent],
    exports: [ModuleEventAttendanceListComponent, ModuleParticipantAttendanceListComponent],
})
export class ModuleEventAttendanceModule {
    static forRoot(): ModuleWithProviders<ModuleEventAttendanceModule> {
        return {
            ngModule: ModuleEventAttendanceModule,
            providers: [ModuleEventAttendanceApiService],
        };
    }
}
