import { ISurveyQuery } from '../interfaces/survey.query-interface';
import { SurveyInfo } from './survey-info.model';

export class Survey {
    id: string;
    surveyInfo: SurveyInfo;
    collectorUrl: string;
    description: string;
    hideDescriptionAfterCompletion: boolean;

    constructor(query: ISurveyQuery) {
        if (query) {
            this.id = query.id.toString();
            this.surveyInfo = new SurveyInfo(query.survey_info);
            this.collectorUrl = query.collector_url;
            this.description = query.description;
            this.hideDescriptionAfterCompletion = !query.show_description_after_completion;
        }
    }
}
