import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { map } from 'rxjs/operators';
import { MaintenanceMessage } from '../models/maintenance-message.model';
import { IMaintenanceMessageQuery } from '../models/maintenance-message.query.interface';

@Injectable()
export class MaintenanceMessageService {
    constructor(@Inject(MAXBRAIN_ENVIRONMENT) private _environment: IEnvironment, private _http: HttpClient) {}

    fetchMaintenanceMessage(): Promise<MaintenanceMessage> {
        const options = {
            headers: new HttpHeaders({ 'content-type': 'application/json' }),
        };

        return this._http
            .get<IMaintenanceMessageQuery>(`https://${window.location.hostname}/maintenance-message`, options)
            .pipe(map((query) => new MaintenanceMessage(query)))
            .toPromise();
    }
}
