import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'calculateDigicompDuration',
})
@Injectable()
export class CalculateDigicompDurationPipe implements PipeTransform {
    constructor(private _translateService: TranslateService) {}

    transform(duration: any): string {
        let toReturn = '';

        if (duration && this._translateService) {
            if (duration.y) {
                toReturn += `${duration.y} ${this._translateService.instant(duration.y === 1 ? 'GENERAL.YEAR' : 'GENERAL.YEARS')} `;
            }

            if (duration.m) {
                toReturn += `${duration.m} ${this._translateService.instant(duration.m === 1 ? 'GENERAL.MONTH' : 'GENERAL.MONTHS')} `;
            }

            if (duration.w) {
                toReturn += `${duration.w} ${this._translateService.instant(duration.w === 1 ? 'GENERAL.WEEK' : 'GENERAL.WEEKS')} `;
            }

            if (duration.d) {
                toReturn += `${duration.d} ${this._translateService.instant(duration.d === 1 ? 'GENERAL.DAY' : 'GENERAL.DAYS')} `;
            }

            if (duration.hh) {
                toReturn += `${duration.hh} ${this._translateService.instant(duration.hh === 1 ? 'GENERAL.HOUR' : 'GENERAL.HOURS')} `;
            }

            if (duration.mm) {
                toReturn += `${duration.mm} ${this._translateService.instant(duration.mm === 1 ? 'GENERAL.MINUTE' : 'GENERAL.MINUTES')} `;
            }
        }

        return toReturn;
    }
}
