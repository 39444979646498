import { ICreateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/create-entity-failure-payload.interface';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { ModuleEventAttendance } from '../models/module-event-attendance.model';
import { ModuleEventAttendanceStatistics } from '../../module-event-attendance/models/module-event-attendance-statistics.model';

export class SetModuleEventAttendance {
    static readonly type = '[ModuleEventAttendance] SetModuleEventAttendance';

    constructor(public payload: ModuleEventAttendance) {}
}

export class UnsetModuleEventAttendance {
    static readonly type = '[ModuleEventAttendance] UnsetModuleEventAttendance';
}

export class FetchModuleEventAttendance {
    static readonly type = '[ModuleEventAttendance] FetchModuleEventAttendance';

    constructor(public payload: string) {}
}

export class FetchModuleEventAttendanceSuccess {
    static readonly type = '[ModuleEventAttendance] FetchModuleEventAttendanceSuccess';

    constructor(public payload: ModuleEventAttendance) {}
}

export class FetchModuleEventAttendanceFailure {
    static readonly type = '[ModuleEventAttendance] FetchModuleEventAttendanceFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class CreateModuleEventAttendance {
    static readonly type = '[ModuleEventAttendance] CreateModuleEventAttendance';

    constructor(public payload: ModuleEventAttendance) {}
}

export class CreateModuleEventAttendanceSuccess {
    static readonly type = '[ModuleEventAttendance] CreateModuleEventAttendanceSuccess';

    constructor(public payload: ModuleEventAttendance) {}
}

export class CreateModuleEventAttendanceFailure {
    static readonly type = '[ModuleEventAttendance] CreateModuleEventAttendanceFailure';

    constructor(public payload: ICreateEntityFailureActionPayload) {}
}

export class UpdateModuleEventAttendance {
    static readonly type = '[ModuleEventAttendance] UpdateModuleEventAttendance';

    constructor(public payload: { eventId: string; moduleEventAttendance: ModuleEventAttendance }) {}
}

export class UpdateModuleEventAttendanceSuccess {
    static readonly type = '[ModuleEventAttendance] UpdateModuleEventAttendanceSuccess';

    constructor(public payload: ModuleEventAttendance) {}
}

export class UpdateModuleEventAttendanceFailure {
    static readonly type = '[ModuleEventAttendance] UpdateModuleEventAttendanceFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class SetModuleEventAttendances {
    static readonly type = '[ModuleEventAttendance] SetModuleEventAttendances';

    constructor(public payload: ModuleEventAttendance[]) {}
}

export class FetchModuleEventAttendances {
    static readonly type = '[ModuleEventAttendance] FetchModuleEventAttendances';

    constructor(
        public payload?: {
            eventId: string;
            search: string;
            sortBy: string;
            sortOrder: string;
            pageSize: number;
            pageNumber: number;
            attendanceStatuses: number[];
            sortChanged: boolean;
        }
    ) {}
}

export class FetchModuleEventAttendancesSuccess {
    static readonly type = '[ModuleEventAttendance] FetchModuleEventAttendancesSuccess';
}

export class FetchModuleEventAttendancesFailure {
    static readonly type = '[ModuleEventAttendance] FetchModuleEventAttendancesFailure';
}

export class SearchModuleEventAttendances {
    static readonly type = '[ModuleEventAttendance] SearchModuleEventAttendances';

    constructor(public payload: string) {}
}

export class FetchModuleEventAttendanceStatistics {
    static readonly type = '[ModuleEventAttendance] FetchModuleEventAttendanceStatistics';

    constructor(public moduleId: string) {}
}
export class FetchModuleEventAttendanceStatisticsSuccess {
    static readonly type = '[ModuleEventAttendance] FetchModuleEventAttendanceStatisticsSuccess';

    constructor(public payload: ModuleEventAttendanceStatistics[]) {}
}

export class FetchModuleEventAttendanceStatisticsFailure {
    static readonly type = '[ModuleEventAttendance] FetchModuleEventAttendanceStatisticsFailure';
}

export class FetchParticipantAttendances {
    static readonly type = '[ModuleEventAttendance] FetchParticipantAttendances';

    constructor(public payload: { moduleId: string; participantId: string; sortBy: string; sortOrder: string }) {}
}

export class FetchParticipantAttendancesSuccess {
    static readonly type = '[ModuleEventAttendance] FetchParticipantAttendancesSuccess';

    constructor(public payload: ModuleEventAttendance[]) {}
}

export class FetchParticipantAttendancesFailure {
    static readonly type = '[ModuleEventAttendance] FetchParticipantAttendancesFailure';
}

export class RefreshModuleEventAttendanceUsersOnlineState {
    static readonly type = '[ModuleEventAttendance] RefreshModuleEventAttendanceUsersOnlineState';

    constructor(public payload?: ModuleEventAttendance[]) {}
}

export class UnsetModuleEventAttendances {
    static readonly type = '[ModuleEventAttendances] UnsetModuleEventAttendances';
}
