import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { User } from 'app/projects/user/src/lib/models/user';
import { ProfilePhotoActionWrapperService } from './profile-photo-action-wrapper.service';

@Component({
    selector: 'app-profile-photo-action-wrapper',
    templateUrl: './profile-photo-action-wrapper.component.html',
    styleUrls: ['./profile-photo-action-wrapper.component.scss'],
})
export class ProfilePhotoActionWrapperComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
    @Input()
    user: User;

    @Input()
    target: HTMLElement;

    @Input()
    profilePictureSrc: string;

    @Output()
    choosePictureClicked = new EventEmitter<boolean>();

    @Output()
    removePictureClicked = new EventEmitter<boolean>();

    @Output()
    cursorOnCard = new EventEmitter<boolean>();

    hide = true;
    eventName = 'mouseenter';
    isCursorOnCard = false;
    isCursorOnTarget = false;

    @ViewChild('card')
    card: ElementRef;

    constructor(private _renderer: Renderer2, private _service: ProfilePhotoActionWrapperService) {
        super();
    }

    toggle(): void {
        this._service.toggle(this);
    }

    ngOnInit(): void {
        this._renderer.listen(this.target, this.eventName, (event) => {
            this.isCursorOnTarget = true;

            setTimeout(() => {
                this.toggle();
            }, 50);
        });

        this._renderer.listen(this.target, 'mouseleave', (event) => {
            this.isCursorOnTarget = false;

            setTimeout(() => {
                if (!this.isCursorOnCard && !this.hide) {
                    this.toggle();
                }
            }, 150);
        });
    }

    ngAfterViewInit(): void {
        this._renderer.listen(this.card.nativeElement, 'mouseleave', (event) => {
            this.isCursorOnCard = false;
            this.cursorOnCard.emit(false);

            setTimeout(() => {
                if (!this.isCursorOnTarget && !this.hide) {
                    this.toggle();
                }
            }, 50);
        });

        this._renderer.listen(this.card.nativeElement, 'mouseenter', () => {
            this.isCursorOnCard = true;
            this.cursorOnCard.emit(true);
        });
    }
}
