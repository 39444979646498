import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Actions, Store } from '@ngxs/store';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';
import { UserStatuses } from 'app/projects/user/src/lib/models/user-statuses';
import { UpdateModuleEventAttendance } from '../../actions';
import { AttendanceStatus } from '../../enums/attendance-status.enum';
import { IAttendanceStatus } from '../../interfaces/attendance-status.interface';
import { AttendanceStatuses } from '../../models/attendance-status';
import { ModuleEventAttendance } from '../../models/module-event-attendance.model';
import { ModuleEventAttendanceService } from '../../services/module-event-attendance.service';

@Component({
    selector: 'app-module-participant-attendance-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ModuleParticipantAttendanceListComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    moduleId: string;

    @Input()
    participantId: string;

    @Input()
    participantAttendances: ModuleEventAttendance[];

    userStatuses = UserStatuses;

    @ViewChild(MatSort) sort: MatSort;

    dataSource: MatTableDataSource<ModuleEventAttendance>;
    displayedColumns = ['eventName', 'status', 'updatedBy'];

    attendanceStatuses = AttendanceStatuses.all;
    AttendanceStatus = AttendanceStatus;

    constructor(private _store: Store, private _action$: Actions, public matDialog: MatDialog, private _moduleEventAttendanceService: ModuleEventAttendanceService) {
        super();
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.participantAttendances);
    }
    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    focusCommentInput(commentInput: MatFormField) {
        commentInput._elementRef.nativeElement.classList.add('ng-dirty');
    }

    onCommentSave(moduleEventAttendance: ModuleEventAttendance, commentInput: MatFormField) {
        commentInput._elementRef.nativeElement.classList.remove('ng-dirty');
        this.updateModuleEventAttendanceStatus(moduleEventAttendance);
    }

    onSelection(moduleEventAttendance: ModuleEventAttendance, attendanceStatus: IAttendanceStatus) {
        this.updateModuleEventAttendanceStatus(moduleEventAttendance, attendanceStatus);
    }

    updateModuleEventAttendanceStatus(moduleEventAttendance: ModuleEventAttendance, attendanceStatus?: IAttendanceStatus) {
        if (attendanceStatus) {
            moduleEventAttendance.status = attendanceStatus.value;
        }
        this._store.dispatch(new UpdateModuleEventAttendance({ eventId: moduleEventAttendance.id, moduleEventAttendance: moduleEventAttendance }));
    }
}
