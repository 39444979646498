<app-dialog-header [title]="dialogTitle" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="p-24" fxLayout="column" fxLayoutAlign="start">
    <mat-form-field>
        <input matInput [placeholder]="placeholder | translate" attr.aria-label="{{ placeholder }}" [matAutocomplete]="auto" [(ngModel)]="data" />
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let item of items" [value]="item">
                <span>{{ value ? item[value] : item }}</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>
    <app-flat-button [label]="'GENERAL.BUTTON.SAVE'" (onClick)="save()" [disabled]="!data"></app-flat-button>
</div>
