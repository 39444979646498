import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { BehaviorSubject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FUSE_ASYNC_DRAWER, IAsyncJobsApiService } from '../../services/async-drawer.token';
import { FEED_SERVICE, IFeedService } from '../../services/feed-service.token';
import { FuseNavigationService } from './navigation.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseNavigationComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    isDrawerOpen = false;
    unreadFeedItemsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    hasRightSideNavigation: boolean;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        @Inject(FUSE_ASYNC_DRAWER) public asyncJobApiService: IAsyncJobsApiService,
        @Inject(FEED_SERVICE) private _feedService: IFeedService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            // Load the navigation
            this.navigation = this._fuseNavigationService.getCurrentNavigation();

            if (this.navigation && this.navigation.length) {
                this.hasRightSideNavigation = this.navigation.some((navItem) => navItem.id === 'new' || navItem.id === 'background-tasks');
            }

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        // Subscribe to navigation item
        merge(this._fuseNavigationService.onNavigationItemAdded, this._fuseNavigationService.onNavigationItemUpdated, this._fuseNavigationService.onNavigationItemRemoved)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this.isDrawerOpen = Boolean(localStorage.getItem('isDrawerOpen'));
        this.asyncJobApiService.jobAdded.pipe(takeUntil(this._unsubscribeAll)).subscribe((job) => {
            if (job) {
                this.isDrawerOpen = Boolean(localStorage.getItem('isDrawerOpen'));
                this._changeDetectorRef.markForCheck();
            }
        });

        this.asyncJobApiService.jobsPaginationChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });

        this._feedService.getUnreadCountObservable().subscribe((count) => {
            this.unreadFeedItemsCount$.next(count);
        });
    }

    openAsyncDrawer(): void {
        const isDrawerOpen = Boolean(localStorage.getItem('isDrawerOpen'));
        if (!isDrawerOpen) {
            this.asyncJobApiService.numberOfActiveAsyncJobs = 0;
            this.asyncJobApiService.getAllJobs(true);
        }
    }
}
