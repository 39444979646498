<app-dialog-header [title]="title" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content fxLayout="column" fxLayoutAlign="start">
    <form class="w-100-p" fxLayout="column">
        <div class="p-24">
            <div class="description" [innerHTML]="description | translate"></div>

            <mat-form-field class="w-100-p">
                <input matInput [value]="textToCopy" [placeholder]="placeholder | translate" [readonly]="true" />
            </mat-form-field>
        </div>
    </form>
</div>

<div mat-dialog-actions class="pt-0 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close()"></app-white-button>

    <app-flat-button [label]="actionButton" (onClick)="copyToClipboard()"></app-flat-button>
</div>
