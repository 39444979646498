import { MaxbrainImage } from 'app/library/image-library/models/maxbrain-image.model';
import { IEventModuleQuery } from '../interfaces/event-module.query.interface';

export class EventModule {
    protected _type: string;

    get type(): string {
        return this._type;
    }

    id: string;
    name: string;
    description: string;
    status: string;
    kind: string;
    image: MaxbrainImage;

    constructor(moduleQuery: IEventModuleQuery) {
        if (moduleQuery) {
            this.name = moduleQuery.name;
            this.description = moduleQuery.description;
            this.id = moduleQuery.id.toString();
            this.status = moduleQuery.status;
            this._type = moduleQuery.type;
            this.kind = moduleQuery.kind;
            this.image = moduleQuery.image ? new MaxbrainImage(moduleQuery.image) : null;
        } else {
            this.name = null;
            this.description = null;
            this.id = null;
            this.status = 'unpublished';
            this._type = null;
            this.kind = null;
            this.image = null;
        }
    }
}
