import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { IEntityApiService } from 'app/projects/entity/src/lib/interfaces/entity.service.interface';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILocationQuery } from '../interfaces/location.query.interface';
import { LocationCommand } from '../models/location.command.model';
import { MaxBrainLocation } from '../models/location.model';

@Injectable()
export class LocationService extends EntityService<ILocationQuery, LocationCommand> implements IEntityApiService<MaxBrainLocation> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'locations');
    }

    getAll(): Observable<MaxBrainLocation[]> {
        return this._getAll().pipe(map((queries) => queries.map((query) => new MaxBrainLocation(query))));
    }

    create(entity: MaxBrainLocation): Observable<MaxBrainLocation> {
        const data = new LocationCommand(entity);

        return this._create(data).pipe(map((query) => new MaxBrainLocation(query)));
    }

    read(id: string): Observable<MaxBrainLocation> {
        return this._read(id).pipe(map((query) => new MaxBrainLocation(query)));
    }

    update(entity: MaxBrainLocation): Observable<MaxBrainLocation> {
        const data = new LocationCommand(entity);

        return this._update(entity.id, data).pipe(map((query) => new MaxBrainLocation(query)));
    }

    delete(id: string): Observable<any> {
        return this._delete(id);
    }
}
