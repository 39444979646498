import { MaxBrainTag } from './tag.model';

export class TagCommand {
    name: string;
    color: string;
    category: string;

    constructor(tempTap: MaxBrainTag) {
        this.name = tempTap.name;
        this.color = tempTap.color;
        this.category = tempTap.category;
    }
}
