import { Pipe, PipeTransform } from '@angular/core';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @param {string} searchText
     * @param {string} property
     * @returns {any}
     */
    transform(mainArr: any[], searchText: string, properties: string): any {
        return MaxBrainUtils.filterArrayByString(
            mainArr,
            searchText,
            properties.split(',').map((prop) => prop.trim())
        );
    }
}
