<div class="input-container" fxLayout="row" fxLayoutAlign="start center" [class.defaultFormStyle]="defaultFormStyle">
    <input
        [placeholder]="inputPlaceholder | translate"
        class="input"
        type="text"
        [value]="value"
        #input
        [class.inputFocus]="inputFocus"
        [matTooltip]="value"
        (click)="inputFocus = true"
    />
    <div class="warn-tooltip" *ngIf="isTooltipVisible">{{ 'GENERAL.LABEL.SPECIFY_TITLE' | translate }}</div>
    <button mat-icon-button class="edit-button action-btn pointer" (click)="editInputValue()">
        <mat-icon>edit</mat-icon>
    </button>
    <div class="save-delete-container">
        <button mat-icon-button fxLayoutAlign="center center" class="save-button action-btn pointer" (click)="saveChanges()" [disabled]="!input.nativeElement?.value">
            <mat-icon>done</mat-icon>
        </button>
        <button mat-icon-button fxLayoutAlign="center center" class="delete-button action-btn pointer" (click)="cancelChanges()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
