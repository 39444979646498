import { IEntity } from 'app/projects/entity/src/lib/interfaces/entity.interface';
import { IJobQuery } from '../interfaces/job.query.interface';

export class Job implements IEntity {
    static label_singular = 'job';
    static label_plural = 'jobs';

    static props_to_search = ['name'];

    id: string;
    type: string;
    communicatedStatus: string;
    closed: boolean;
    isRead: boolean;
    name: string;
    entityId: string;
    entityType: string;
    stats: { pending: number; succeeded: number; failed: number; total: number } = { pending: 0, succeeded: 0, failed: 0, total: 0 };
    progressPercentage: number;
    additionalInfo: string;

    constructor(query?: IJobQuery) {
        if (query) {
            this.id = query.id.toString();
            this.type = query.type;
            this.communicatedStatus = query.communicated_status;
            this.isRead = query.is_read;
            this.closed = query.closed;
            this.name = query.name;
            this.entityId = query.entity_id.toString();
            this.entityType = query.entity_type;
            this.additionalInfo = query.additional_info ? query.additional_info : null;

            if (query.stats) {
                this.stats.pending = query.stats.pending;
                this.stats.succeeded = query.stats.succeeded;
                this.stats.failed = query.stats.failed;
                this.stats.total = query.stats.total;
                this.progressPercentage = query.stats.progress_percentage;
            }
        }
    }
}
