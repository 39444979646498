import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

/* Provide millisecond to get Days, Hours, Minutes time format */
@Pipe({
    name: 'msToHumanizeTimePipe',
})
export class MsToHumanizeTimePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(milliseconds: number): string {
        const duration = moment.duration(milliseconds, 'milliseconds');
        const formattedResult = duration.humanize();

        const translatedResult = formattedResult
            .replace('days', this.translateService.instant('GENERAL.DAYS'))
            .replace('hours', this.translateService.instant('GENERAL.HOURS'))
            .replace('minutes', this.translateService.instant('GENERAL.MINUTES'))
            .replace('a few seconds', `0 ${this.translateService.instant('GENERAL.MINUTES')}`);

        return translatedResult;
    }
}
