<app-dialog-header [title]="'TAG.TITLE.EDIT'" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="p-24" fxLayout="column" fxLayoutAlign="start">
    <form name="tagForm" [formGroup]="tagForm" class="tag w-100-p" fxLayout="column">
        <mat-form-field>
            <input matInput name="name" [placeholder]="placeholder | translate" formControlName="name" />
        </mat-form-field>
        <div class="colors-picker">
            <div class="colors-wrapper" fxLayout="start">
                <div
                    class="color-block"
                    *ngFor="let color of colors; let i = index"
                    [style.background]="color"
                    (click)="selectColor(color); clicked = color"
                    [class.active]="clicked === color"
                ></div>
            </div>
            <div class="text-wrapper">
                <span
                    ><strong>{{ 'GENERAL.IMPORTANT' | translate }}</strong></span
                >
                <span>{{ 'GENERAL.COLOR_CHANGES_GLOBALLY' | translate }}</span>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>
    <app-flat-button [label]="'GENERAL.BUTTON.SAVE'" (onClick)="save()" [disabled]="tagForm.invalid"></app-flat-button>
</div>
