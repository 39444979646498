import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from 'app/projects/fuse/src/lib/shared.module';
import { FooterComponent } from './footer.component';

@NgModule({
    declarations: [FooterComponent],
    imports: [RouterModule, MatButtonModule, MatIconModule, MatToolbarModule, FuseSharedModule],
    exports: [FooterComponent],
})
export class FooterModule {}
