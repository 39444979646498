import { MaxBrainTag } from 'app/library/tag/models/tag.model';
import * as momentImported from 'moment';
import { ICopyModuleToNewActionPayload } from '../interfaces/copy-module-to-new.action-payload.interface';
import { IModuleDuration } from '../interfaces/module-duration.interface';
const moment = momentImported;

export class CopyModuleToNewCommand {
    source_module_id: number;
    name: string;
    start_date: string;
    end_date: string;
    expected_duration: number;
    duration: IModuleDuration;
    self_paced: boolean;
    tags: MaxBrainTag[];

    constructor(tempModule: ICopyModuleToNewActionPayload) {
        this.source_module_id = parseInt(tempModule.sourceModuleId, 10);
        this.name = tempModule.name;
        this.start_date = tempModule.startDate ? (moment.isMoment(tempModule.startDate) ? tempModule.startDate.format() : moment(tempModule.startDate).format()) : null;
        this.end_date = tempModule.endDate ? (moment.isMoment(tempModule.endDate) ? tempModule.endDate.format() : moment(tempModule.endDate).format()) : null;
        this.expected_duration = tempModule.duration;
        this.self_paced = tempModule.selfPaced;
        this.tags = tempModule.tags;
    }
}
