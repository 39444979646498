// @dynamic
export class MaxBrainUtils {
    public static baseUrl = window.location.href.split('/').slice(0, 3).join('/');

    /**
     * Filter array by string
     */
    public static filterArrayByString(mainArr: any[], searchText: string, propsToSearch?: string[]): any[] {
        if (!mainArr) {
            return [];
        }

        if (!searchText) {
            return mainArr;
        }

        const searchTerms = searchText.toLowerCase().split(' ');

        return mainArr.filter((itemObj) => {
            let doesMatch = true;

            searchTerms.forEach((searchTerm) => {
                if (doesMatch) {
                    doesMatch = MaxBrainUtils.searchInObj(itemObj, searchTerm, propsToSearch);
                }
            });

            return doesMatch;
        });
    }

    public static searchByPropValue(value: any, searchText: string, propsToSearch?: string[]): boolean {
        if (typeof value === 'string') {
            if (MaxBrainUtils.searchInString(value, searchText)) {
                return true;
            }
        } else if (Array.isArray(value)) {
            if (MaxBrainUtils.searchInArray(value, searchText, propsToSearch)) {
                return true;
            }
        }

        if (typeof value === 'object') {
            if (MaxBrainUtils.searchInObj(value, searchText, propsToSearch)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Search in object
     */
    public static searchInObj(itemObj: any, searchText: string, propsToSearch?: string[]): boolean {
        for (const prop in itemObj) {
            if (!itemObj.hasOwnProperty(prop)) {
                continue;
            }

            if (propsToSearch && propsToSearch.indexOf(prop) === -1) {
                continue;
            }

            if (MaxBrainUtils.searchByPropValue(itemObj[prop], searchText, propsToSearch)) {
                return true;
            }
        }

        return false;
    }

    /**
     *
     * @param arr
     * @param searchText
     * @param propsToSearch
     */
    public static searchInArray(arr: any[], searchText: string, propsToSearch?: string[]): boolean {
        for (const value of arr) {
            if (MaxBrainUtils.searchByPropValue(value, searchText, propsToSearch)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Search in string
     */
    public static searchInString(value: string, searchText: string): any {
        return value.toLowerCase().indexOf(searchText) !== -1;
    }

    /**
     * capitalizeFirstCharacter
     */
    public static capitalizeFirstCharacter(text: string): string {
        if (!text) {
            return '';
        }

        return text.charAt(0).toUpperCase() + text.substr(1);
    }

    /**
     * Generate a unique GUID
     */
    public static generateGUID(): string {
        function S4(): string {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return S4() + S4();
    }

    /**
     * Download file
     */
    public static downloadFile(blob: Blob, name: string = ''): void {
        const url = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');

        downloadLink.href = url;
        downloadLink.download = name;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    private static urlBase64Decode(str: string): string {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                // tslint:disable-next-line:no-string-throw
                throw 'Illegal base64url string!';
        }
        return decodeURIComponent((window as any).escape(window.atob(output)));
    }

    public static decodeToken(token: string = ''): any {
        if (token === null || token === '') {
            return { upn: '' };
        }

        const parts = token.split('.');

        if (parts.length !== 3) {
            throw new Error('JWT must have 3 parts');
        }

        const decoded = MaxBrainUtils.urlBase64Decode(parts[1]);

        if (!decoded) {
            throw new Error('Cannot decode the token');
        }

        return JSON.parse(decoded);
    }

    public static sortCompareFn<T>(sortProperties: string[]): (a: T, b: T, index?: number) => number {
        return (a, b, index = 0) => {
            if (sortProperties.length === index) {
                return 0;
            }

            return a[sortProperties[index]] > b[sortProperties[index]]
                ? 1
                : b[sortProperties[index]] > a[sortProperties[index]]
                ? -1
                : MaxBrainUtils.sortCompareFn(sortProperties)(a, b, index + 1);
        };
    }

    public static areObjectsEqual(objA: any, objB: any): boolean {
        if (!objA || !objB) {
            return objA === objB;
        }

        let isEqual = true;

        for (const key of Object.keys(objA)) {
            if (objA[key] instanceof Array) {
                if (!MaxBrainUtils.areArraysEqual(objA[key], objB[key])) {
                    isEqual = false;
                }
            } else if (objA[key] instanceof Date) {
                if (objA[key] !== objB[key]) {
                    isEqual = false;
                }
            } else if (objA[key] instanceof Object) {
                if (!MaxBrainUtils.areObjectsEqual(objA[key], objB[key])) {
                    isEqual = false;
                }
            } else if (objA[key] !== objB[key]) {
                isEqual = false;
            }
        }

        return isEqual;
    }

    public static arrayComparer(otherArray: any[]): any {
        return (current: any) => {
            return (
                otherArray.filter((other) => {
                    return MaxBrainUtils.areObjectsEqual(current, other);
                }).length === 0
            );
        };
    }

    public static areArraysEqual(A: any[], B: any[]): boolean {
        if (!A || !B) {
            return A === B;
        }

        if (A.length !== B.length) {
            return false;
        }

        if (!A.length) {
            return true;
        }

        const uniqueToA = A.filter(MaxBrainUtils.arrayComparer(B));

        if (uniqueToA.length) {
            return false;
        }

        const uniqueToB = B.filter(MaxBrainUtils.arrayComparer(A));

        return !uniqueToB.length;
    }

    public static addDays(date: string, days: number): string {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days); // check if locale screws it up
        return newDate.toISOString();
    }
}
