import { IUserExtendedAttributeValue } from '../interfaces/user-extended-attribute-value.interface';

export class UserExtendedAttributeValue {
    hiddenFromOthers: boolean = null;
    translationKey: string = '';
    valueAddress: {
        city: string;
        country: string;
        streetName: string;
        streetNumber: string;
        zipCode: string;
    };
    valueDate: string = '';
    valueNumber: number = null;
    valueText: string = '';

    constructor(query?: IUserExtendedAttributeValue) {
        if (query) {
            this.hiddenFromOthers = query.hidden_from_others;
            this.translationKey = query.translation_key;
            if (query.value_address) {
                this.valueAddress = {
                    city: query.value_address.city || null,
                    country: query.value_address.country || null,
                    streetName: query.value_address.street_name || null,
                    streetNumber: query.value_address.street_number || null,
                    zipCode: query.value_address.zip_code || null,
                };
            }
            this.valueDate = query.value_date;
            this.valueNumber = query.value_number;
            this.valueText = query.value_text;
        }
    }
}
