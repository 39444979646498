import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-subject-text',
    templateUrl: './subject-text.component.html',
    styleUrls: ['./subject-text.component.scss'],
})
export class SubjectTextDialogComponent {
    @Input()
    dialogTitle: string;

    @Input()
    saveButtonColor = 'accent';

    @Input()
    saveButtonText: string;

    @Input()
    init: any;

    @Input()
    description: string;

    @Input()
    subject: string;

    @Input()
    message: string;

    tinymceIsLoading = true;

    constructor(public dialogRef: MatDialogRef<SubjectTextDialogComponent>) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Save selected entities
     */
    save(): void {
        const data = {
            subject: this.subject,
            message: this.message,
        };

        this.dialogRef.close({ data: data });
    }

    public handleEventInit(event: any): void {
        this.tinymceIsLoading = false;
    }
}
