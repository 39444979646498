import { Inject, Pipe, PipeTransform } from '@angular/core';
import { TenantDataService } from 'app/config/services/tenant-data.service';
import { ILanguage, ILanguageService } from '../interfaces/language.interface';
import { MAXBRAIN_LANGUAGE_SERVICE } from '../services/language.token';

@Pipe({ name: 'filterLanguages' })
export class FilterLanguagesPipe implements PipeTransform {
    constructor(@Inject(MAXBRAIN_LANGUAGE_SERVICE) public languageService: ILanguageService, private _tenantDataService: TenantDataService) {}

    transform(allLanguages: ILanguage[]): ILanguage[] {
        const languages = this._tenantDataService.languages;
        let selectedLanguages: ILanguage[] = [];

        if (languages.length) {
            selectedLanguages = allLanguages.filter((language) => {
                let tempLanguage: ILanguage;

                languages.forEach((value) => {
                    if (language.id === value[0]) {
                        tempLanguage = language;
                    }
                });

                return tempLanguage;
            });
        }

        return selectedLanguages;
    }
}
