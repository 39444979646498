import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Select } from '@ngxs/store';
import { ConfigService } from 'app/config/services/config.service';
import { ModuleApiService } from 'app/library/module/services/module.api-service';
import { ModuleService } from 'app/library/module/services/module.service';
import { PasswordlessAccessApiService } from 'app/library/passwordless-access/services/services/passwordless-access.api-service';
import { ScheduledJobType } from 'app/library/scheduler/enums/scheduledJob-type.enum';
import { ScheduledJob } from 'app/library/scheduler/models/scheduled-job.model';
import { SurveySectionApiService } from 'app/library/survey-section/services/survey-section.api-service';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { ScheduleDialogType } from 'app/projects/shared/src/lib/enums/schedule-dialog-type.enum';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import tinymce from 'assets/tinymce/tinymce';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NotifyType } from '../../../enums/notify-type.enum';
import { HelperService } from '../../../services/helper';
import { MaxBrainConfirmDialogComponent } from '../confirm/confirm.component';

interface ISaveScheduleSubjectTextPayload {
    subject?: string;
    message?: string;
    isScheduled?: boolean;
    executionTimestamp?: number;
    node?: string;

    // used in POST /nodes/publish
    notify?: NotifyType;
    /**
     * used in these 4 endpoint:
     * POST /modules/{id}/notification
     * POST /modules/{id}/notify-users
     * POST /course-structure/course-offerings/{id}/notification
     * POST /survey-sections/{id}/send-reminder
     */
    isUrgent?: boolean;
}

@Component({
    selector: 'app-schedule-subject-text',
    templateUrl: './schedule-subject-text.component.html',
    styleUrls: ['./schedule-subject-text.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ScheduleSubjectTextDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    dialogTitle: string;

    @Input()
    saveButtonColor = 'accent';

    @Input()
    init: any;

    @Input()
    description: string;

    @Input()
    subject: string;

    @Input()
    message: string;

    @Input()
    urgentOptionText = 'GENERAL.LABEL.SEND_IMMEDIATELY';

    @Input()
    isNotificationCheckboxVisible: boolean;

    @Input()
    scheduledJob: ScheduledJob;

    @Input()
    saveButtonText = 'GENERAL.BUTTON.PUBLISH';

    @Input()
    actionNowText = 'MODULE.SCHEDULER.PUBLISH_NOW';

    @Input()
    dialogType = ScheduleDialogType.PublishSection;

    @Input()
    contentNodeId: string;

    @Input()
    keywords: string[] = [];

    @Output()
    scheduleRemoved = new EventEmitter<{ scheduledJob: ScheduledJob; showSnackBar: boolean }>();

    @Output()
    scheduleSubjectTextSaved = new EventEmitter<ISaveScheduleSubjectTextPayload>();

    @Select(UserState.getMyInfo)
    profile$: Observable<User>;

    minDate = new Date();
    sendNotification = new FormControl();
    notifyOption = new FormControl('send');
    tinymceIsLoading: boolean = false;
    scheduleForm: FormGroup;
    subjectValue: string;
    isScheduled: boolean = false;
    isUserTenantAdmin: boolean = false;
    isUrgent: boolean = false;
    isUrgentOptionVisible: boolean = false;
    isScheduleOptionVisible: boolean = true;
    isSendUrgentButtonVisible: boolean = false;

    ScheduledJobType = ScheduledJobType;
    ScheduleDialogType = ScheduleDialogType;
    confirmDialogRef: MatDialogRef<MaxBrainConfirmDialogComponent>;

    constructor(
        public dialogRef: MatDialogRef<ScheduleSubjectTextDialogComponent>,
        private _formBuilder: FormBuilder,
        private _moduleService: ModuleService,
        private _matDialog: MatDialog,
        private _configService: ConfigService,
        private _surveySectionApiService: SurveySectionApiService,
        private _moduleApiService: ModuleApiService,
        private _passwordlessAccessApiService: PasswordlessAccessApiService,
        private _helperService: HelperService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        if (!this.isNotificationCheckboxVisible) {
            this.tinymceIsLoading = true;
        }

        this.init.plugins = `link autolink export lists anchor pagebreak searchreplace wordcount visualblocks visualchars
        code fullscreen insertdatetime nonbreaking save powerpaste
        directionality emoticons textpattern
        formatpainter`;

        if (this.scheduledJob) {
            this.isScheduled = true;
            this.notifyOption.setValue('schedule');
        }

        this.scheduleForm = this._formBuilder.group({
            scheduleDate: [this.scheduledJob ? moment(this.scheduledJob.info.execDate) : null],
            scheduleTime: [this.scheduledJob ? this.scheduledJob.info.execTime : null],
        });

        this.sendNotification.setValue(this.scheduledJob ? this.scheduledJob.info.context.sendNotification : false);
        this.subjectValue = this.subject;

        this.profile$
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((profile) => !!profile)
            )
            .subscribe((profile) => {
                this.isUserTenantAdmin = profile.permissions.includes('tenant_management');
            });

        switch (this.dialogType) {
            case ScheduleDialogType.PublishSection:
                this.isUrgentOptionVisible = false;
                this.isSendUrgentButtonVisible = true;
                break;
            case ScheduleDialogType.PublishNode:
                this.isUrgentOptionVisible = true;
                this.isScheduleOptionVisible = false;
                break;
            case ScheduleDialogType.NotifyAll:
                this.isUrgentOptionVisible = true;

                this._moduleApiService
                    .fetchNotificationKeywords()
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((keywords) => {
                        this.keywords = keywords;
                    });
                break;
            case ScheduleDialogType.NotifySelected:
                this.isUrgentOptionVisible = true;
                this.isScheduleOptionVisible = false;

                this._moduleApiService
                    .fetchNotificationKeywords()
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((keywords) => {
                        this.keywords = keywords;
                    });
                break;
            case ScheduleDialogType.SurveyReminder:
                this.isUrgentOptionVisible = true;

                this._surveySectionApiService
                    .fetchSurveyKeywords()
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((keywords) => {
                        this.keywords = keywords;
                    });
                break;
            case ScheduleDialogType.PasswordlessAccess:
                this._passwordlessAccessApiService
                    .fetchPasswordlessKeywords()
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((keywords) => {
                        this.keywords = keywords;
                    });
                break;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    notificationCheckboxChange(event: MatCheckboxChange): void {
        if (event.checked) {
            this.tinymceIsLoading = true;
        }
    }

    optionChange(event: MatRadioChange): void {
        if (event.value === 'schedule') {
            this.scheduleForm.controls.scheduleDate.setValidators(Validators.required);
            this.scheduleForm.controls.scheduleTime.setValidators(Validators.required);
            this.isUrgent = false;
            this.isScheduled = true;
        } else {
            this.scheduleForm.controls.scheduleDate.clearValidators();
            this.scheduleForm.controls.scheduleTime.clearValidators();
            this.isScheduled = false;

            if (event.value === 'urgent') {
                this.isUrgent = true;
            } else {
                this.isUrgent = false;
            }
        }

        this.scheduleForm.controls.scheduleDate.updateValueAndValidity();
        this.scheduleForm.controls.scheduleTime.updateValueAndValidity();
    }

    /**
     * Save selected entities
     */
    save(isUrgently: boolean = false): void {
        let timestamp: number;

        if (isUrgently) {
            this.isUrgent = true;
        }

        if (this.isScheduled) {
            const scheduleTime = this._helperService.cleaveTimeFixer(this.scheduleForm.controls.scheduleTime.value);
            let currentDate = new Date(`${this.scheduleForm.controls.scheduleDate.value.format('YYYY-MM-DD'.replace(/-/g, '/'))} ${scheduleTime}`);
            timestamp = currentDate.getTime() / 1000;
        }

        let data: ISaveScheduleSubjectTextPayload = {
            subject: this.subjectValue,
            message: this.message,
            isScheduled: this.isScheduled,
            executionTimestamp: timestamp || null,
            isUrgent: this.isUrgent,
        };

        if (this.dialogType === ScheduleDialogType.PublishSection || this.dialogType === ScheduleDialogType.PublishNode) {
            data = Object.assign(data, {
                notify: this.isUrgent ? NotifyType.NotifyUrgently : this.sendNotification.value ? NotifyType.Notify : NotifyType.DoNotNotify,
                node: this.contentNodeId,
            });
        }

        if (this.scheduledJob && !data.isScheduled) {
            this.removeSchedule(false);
        }

        this.scheduleSubjectTextSaved.emit(data);
    }

    removeSchedule(showSnackBar = true): void {
        this.scheduleRemoved.emit({ scheduledJob: this.scheduledJob, showSnackBar });
    }

    addKeywordToEditor(value: string): void {
        tinymce.activeEditor.execCommand('mceInsertContent', false, `[${value}]`);
    }

    saveAsDefaultText(): void {
        this.confirmDialogRef = this._matDialog.open(MaxBrainConfirmDialogComponent, {
            disableClose: false,
        });

        this.confirmDialogRef.componentInstance.confirmTitle = 'GENERAL.BUTTON.CONFIRM';
        this.confirmDialogRef.componentInstance.confirmButtonColor = 'accent';
        this.confirmDialogRef.componentInstance.confirmMessage = 'GENERAL.MESSAGE.SAVE_AS_DEFAULT_TEXT_OVERWRITE';
        this.confirmDialogRef.componentInstance.actionButtonText = 'GENERAL.BUTTON.SAVE_AS_DEFAULT_TEXT';

        this.confirmDialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                await this._moduleService.saveNotificationDefaultText(this.message);

                this._helperService.showSnackBarMessage('GENERAL.MESSAGE.SAVE_AS_DEFAULT_TEXT_SUCCESS');

                this._configService.fetchConfig(localStorage.getItem('accessToken'));
            }

            this.confirmDialogRef = null;
        });
    }

    public handleEventInit(): void {
        this.tinymceIsLoading = false;
    }
}
