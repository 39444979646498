import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { NgxsActionHelper } from 'app/projects/core/src/lib/services/action.helper';
import { Observable, race } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FinalGradeMetrics } from '../../final-grade/models/final-grade-metric.model';
import {
    ConvertModuleToExecution,
    ConvertModuleToExecutionFailure,
    ConvertModuleToExecutionSuccess,
    FetchMinimumAttendance,
    FetchMinimumAttendanceFailure,
    FetchMinimumAttendanceSuccess,
    FetchModule,
    FetchModuleFailure,
    FetchModuleFinalGradeMetrics,
    FetchModules,
    FetchModulesFailure,
    FetchModulesSuccess,
    FetchModuleSuccess,
    FetchTenantAdminModuleStats,
    FetchTenantAdminModuleStatsFailure,
    FetchTenantAdminModuleStatsSuccess,
    GetCourseStructureModuleBreadcrumbs,
    GetCourseStructureModuleBreadcrumbsFailure,
    GetCourseStructureModuleBreadcrumbsSuccess,
    SaveNotificationDefaultText,
    SaveNotificationDefaultTextFailure,
    SaveNotificationDefaultTextSuccess,
    UpdateMinimumAttendance,
    UpdateMinimumAttendanceFailure,
    UpdateMinimumAttendanceSuccess,
} from '../actions';
import { FetchModuleFinalGradeMetricsFailure, FetchModuleFinalGradeMetricsSuccess } from '../actions/index';
import { SetModuleKeyValue } from '../actions/set-module-key-value.action';
import { CourseStructureModuleBreadcrumb } from '../models/course-structure-module-breadcrumb.model';
import { Module } from '../models/module.model';
import { ModuleState } from '../models/module.state';
import { TenantAdminModuleStats } from '../models/tenant-admin-module-stats.model';

@Injectable()
export class ModuleService {
    @Select(ModuleState.getEntities)
    module$: Observable<Module[]>;

    @Select(ModuleState.getEntity)
    currentModule$: Observable<Module>;

    constructor(private _store: Store, private _actions$: Actions, private _actionHelper: NgxsActionHelper) {}

    getModules(): Module[] {
        return this._store.selectSnapshot(ModuleState.getEntities);
    }

    getModuleById(moduleId: string): Module {
        return ModuleState.getEntity(this._store.selectSnapshot(ModuleState), moduleId);
    }

    getCurrentModule(): Module {
        return this._store.selectSnapshot(ModuleState.getEntity);
    }

    getCurrentModuleMetrics(): FinalGradeMetrics {
        return this._store.selectSnapshot(ModuleState.getMetrics);
    }

    async fetchModuleFinalGradeMetrics(moduleId: string): Promise<boolean> {
        this._store.dispatch(new FetchModuleFinalGradeMetrics(moduleId));

        return await this._actionHelper.race(FetchModuleFinalGradeMetricsSuccess, FetchModuleFinalGradeMetricsFailure).toPromise();
    }

    async convertModuleToExecution(sourceModuleId: string, courseOfferingId: string): Promise<boolean> {
        this._store.dispatch(new ConvertModuleToExecution({ sourceModuleId, courseOfferingId }));

        return await this._actionHelper.race(ConvertModuleToExecutionSuccess, ConvertModuleToExecutionFailure).toPromise();
    }

    async fetchModules(): Promise<boolean> {
        this._store.dispatch(new FetchModules());

        return await this._actionHelper.race(FetchModulesSuccess, FetchModulesFailure).toPromise();
    }

    async fetchModuleById(moduleId: string, force: boolean = false): Promise<boolean> {
        if (!force) {
            const currentModule = this.getCurrentModule();

            if (currentModule && currentModule.id === moduleId) {
                return true;
            }
        }

        this._store.dispatch(new FetchModule(moduleId));

        return await this._actionHelper.race(FetchModuleSuccess, FetchModuleFailure).toPromise();
    }

    async setModuleKeyValue(moduleId: string, key: string, value: any): Promise<void> {
        this._store.dispatch(new SetModuleKeyValue({ moduleId, key, value }));
    }

    async getCourseStructureModuleBreadcrumbs(moduleId: string): Promise<CourseStructureModuleBreadcrumb[]> {
        this._store.dispatch(new GetCourseStructureModuleBreadcrumbs(moduleId));

        return race(
            this._actions$.pipe(
                ofActionDispatched(GetCourseStructureModuleBreadcrumbsSuccess),
                map((action: GetCourseStructureModuleBreadcrumbsSuccess) => action.payload)
            ),
            this._actions$.pipe(
                ofActionDispatched(GetCourseStructureModuleBreadcrumbsFailure),
                map((action: GetCourseStructureModuleBreadcrumbsFailure) => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    async updateMinimumAttendance(id: string, minimumAttendance: number): Promise<boolean> {
        this._store.dispatch(new UpdateMinimumAttendance({ id, minimumAttendance }));

        return await this._actionHelper.race(UpdateMinimumAttendanceSuccess, UpdateMinimumAttendanceFailure).toPromise();
    }

    async getTenantAdminModuleStats(moduleId: string): Promise<TenantAdminModuleStats> {
        this._store.dispatch(new FetchTenantAdminModuleStats(moduleId));

        return race(
            this._actions$.pipe(
                ofActionDispatched(FetchTenantAdminModuleStatsSuccess),
                map((action: FetchTenantAdminModuleStatsSuccess) => action.payload)
            ),
            this._actions$.pipe(
                ofActionDispatched(FetchTenantAdminModuleStatsFailure),
                map((action: FetchTenantAdminModuleStatsFailure) => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    async getMinimumAttendance(moduleId: string): Promise<{ minimumAttendance: number }> {
        this._store.dispatch(new FetchMinimumAttendance(moduleId));

        return race(
            this._actions$.pipe(
                ofActionDispatched(FetchMinimumAttendanceSuccess),
                map((action: FetchMinimumAttendanceSuccess) => action.payload)
            ),
            this._actions$.pipe(
                ofActionDispatched(FetchMinimumAttendanceFailure),
                map((action: FetchMinimumAttendanceFailure) => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    async saveNotificationDefaultText(defaultText: string): Promise<boolean> {
        this._store.dispatch(new SaveNotificationDefaultText(defaultText));

        return await this._actionHelper.race(SaveNotificationDefaultTextSuccess, SaveNotificationDefaultTextFailure).toPromise();
    }
}
