<div class="actions-wrapper" [hidden]="hide" #card>
    <button type="button" (click)="choosePictureClicked.emit(true); toggle()">
        <mat-icon>edit</mat-icon>
        <span>{{ 'PAGE.USER_FORM.BUTTON.CHOOSE_PHOTO' | translate }}</span>
    </button>
    <button *ngIf="user.avatar?.src && !profilePictureSrc" (click)="removePictureClicked.emit(true); toggle()">
        <mat-icon>delete</mat-icon>
        <span>{{ 'GENERAL.BUTTON.REMOVE' | translate }}</span>
    </button>
</div>
