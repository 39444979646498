import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SnackBarTime } from 'app/projects/core/src/lib/constants/snack-bar';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import { finalize, tap } from 'rxjs/operators';
import {
    BulkUpdateCopyProtectionSuccess,
    CreateEmptyFileSuccess,
    CreateFolder,
    CreateFolderFailure,
    CreateFolderSuccess,
    DeleteContentNode,
    DeleteContentNodeFailure,
    DeleteContentNodeSuccess,
    DeletePersonalContentNode,
    FetchContentNode,
    FetchContentNodeFailure,
    FetchContentNodeSuccess,
    PublishContentNodes,
    PublishContentNodesFailure,
    PublishContentNodesSuccess,
    RenameContentNode,
    RenameContentNodeFailure,
    RenameContentNodeSuccess,
    RenamePersonalContentNode,
    SetContentNode,
    UnsetContentNode,
    UpdateContentNode,
    UpdateContentNodeFailure,
    UpdateContentNodeMetadata,
    UpdateContentNodeMetadataFailure,
    UpdateContentNodeMetadataSuccess,
    UpdateContentNodeSuccess,
    UpdatePersonalContentNode,
    UploadFileFailure,
    UploadFileSuccess,
    UploadFiles,
    UploadFilesFinished,
    UploadPersonalFiles,
    UploadZip,
    UploadZipFailure,
    UploadZipSuccess,
} from '../actions';
import { AddContentNodes } from '../actions/add-entities.action';
import { BulkUpdateCopyProtection, BulkUpdateCopyProtectionFailure, CreateEmptyFile, CreateEmptyFileFailure } from '../actions/index';
import { ContentNodeApiService } from '../services/content-node.api-service';
import { ContentNode } from './content-node.model';
import { ContentNodeStateModel } from './content-node.state-model';

@State<ContentNodeStateModel>({
    name: 'contentNode',
    defaults: new ContentNodeStateModel(),
    children: [],
})
@Injectable()
export class ContentNodeState extends EntityState<ContentNode> {
    @Selector()
    static getEntity(state: ContentNodeStateModel, id: string = state.item): ContentNode {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntities(state: ContentNodeStateModel): ContentNode[] {
        if (!state.map) {
            return null;
        }

        return Array.from(state.map.values());
    }

    constructor(private contentNodeApiService: ContentNodeApiService, private _translateService: TranslateService, private _matSnackBar: MatSnackBar) {
        super(null, 0);

        this._propstoSearch = ContentNode.props_to_search;
    }

    @Action(AddContentNodes)
    addEntities(ctx: StateContext<ContentNodeStateModel>, action: AddContentNodes): void {
        this._addEntities(ctx, action);
    }

    @Action(SetContentNode)
    setEntity(ctx: StateContext<ContentNodeStateModel>, action: SetContentNode): void {
        this._setEntity(ctx, action, AddContentNodes);
    }

    @Action(UnsetContentNode)
    unsetEntity(ctx: StateContext<ContentNodeStateModel>): void {
        this._unsetEntity(ctx);
    }

    @Action(CreateFolder)
    createFolder(ctx: StateContext<ContentNodeStateModel>, { payload }: CreateFolder): void {
        this.contentNodeApiService.createFolder(payload).subscribe(
            (contentNode) => {
                this._addEntities(ctx, { payload: [contentNode] });

                ctx.dispatch(new CreateFolderSuccess(contentNode));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new CreateFolderFailure({ response }));
            }
        );
    }

    @Action(UploadFiles)
    uploadFiles(ctx: StateContext<ContentNodeStateModel>, { payload }: UploadFiles): void {
        let successCounter = 0;
        let failureCounter = 0;

        payload.files.forEach((file) => {
            this.contentNodeApiService
                .uploadFile(payload.node, file)
                .pipe(
                    tap(
                        (contentNode) => {
                            this._addEntities(ctx, { payload: [contentNode] });

                            successCounter++;

                            ctx.dispatch(new UploadFileSuccess(contentNode));
                        },
                        (response: HttpErrorResponse) => {
                            failureCounter++;

                            ctx.dispatch(
                                new UploadFileFailure({
                                    node: payload.node,
                                    file: file.name,
                                    response,
                                })
                            );
                        }
                    ),
                    finalize(() => {
                        if (successCounter + failureCounter === payload.files.length) {
                            ctx.dispatch(new UploadFilesFinished({ count: successCounter, total: payload.files.length }));
                        }
                    })
                )
                .subscribe();
        });
    }

    @Action(UploadPersonalFiles)
    uploadPersonalFiles(ctx: StateContext<ContentNodeStateModel>, { payload }: UploadPersonalFiles): void {
        let successCounter = 0;
        let failureCounter = 0;

        payload.files.forEach((file) => {
            this.contentNodeApiService
                .uploadPersonalFile(payload.node, file)
                .pipe(
                    tap(
                        (contentNode) => {
                            this._addEntities(ctx, { payload: [contentNode] });

                            successCounter++;

                            ctx.dispatch(new UploadFileSuccess(contentNode));
                        },
                        (response: HttpErrorResponse) => {
                            failureCounter++;

                            ctx.dispatch(
                                new UploadFileFailure({
                                    node: payload.node,
                                    file: file.name,
                                    response,
                                })
                            );
                        }
                    ),
                    finalize(() => {
                        if (successCounter + failureCounter === payload.files.length) {
                            ctx.dispatch(new UploadFilesFinished({ count: successCounter, total: payload.files.length }));
                        }
                    })
                )
                .subscribe();
        });
    }

    @Action(UploadFilesFinished)
    uploadFilesFinished(ctx: StateContext<ContentNodeStateModel>, { payload }: UploadFilesFinished): void {
        const successMessage = this._translateService.instant('CONTENT.MESSAGE.FILES_UPLOADED', payload);

        // Show the success message
        this._matSnackBar.open(successMessage, 'OK', {
            verticalPosition: 'top',
            duration: SnackBarTime.medium,
        });
    }

    @Action(UploadZip)
    uploadZip(ctx: StateContext<ContentNodeStateModel>, { payload }: UploadZip): void {
        this.contentNodeApiService.uploadZip(payload.node, payload.file).subscribe(
            () => {
                ctx.dispatch(
                    new UploadZipSuccess({
                        node: payload.node,
                        file: payload.file.name,
                    })
                );
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(
                    new UploadZipFailure({
                        node: payload.node,
                        file: payload.file.name,
                    })
                );
            }
        );
    }

    @Action(UpdateContentNode)
    updateFile(ctx: StateContext<ContentNodeStateModel>, { payload }: UpdateContentNode): void {
        this.contentNodeApiService.update(payload).subscribe(
            (updatedContentNode) => {
                this._addEntities(ctx, { payload: [updatedContentNode] });

                ctx.dispatch(new UpdateContentNodeSuccess(updatedContentNode));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateContentNodeFailure({ entityId: payload.contentNode.id, response }));
            }
        );
    }

    @Action(UpdatePersonalContentNode)
    updatePersonalContentNode(ctx: StateContext<ContentNodeStateModel>, { payload }: UpdatePersonalContentNode): void {
        this.contentNodeApiService.updatePersonal(payload).subscribe(
            (updatedContentNode) => {
                this._addEntities(ctx, { payload: [updatedContentNode] });

                ctx.dispatch(new UpdateContentNodeSuccess(updatedContentNode));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateContentNodeFailure({ entityId: payload.contentNode.id, response }));
            }
        );
    }

    @Action(RenameContentNode)
    updateEntity(ctx: StateContext<ContentNodeStateModel>, action: RenameContentNode): void {
        this._updateEntity(ctx, action, RenameContentNodeSuccess, RenameContentNodeFailure, AddContentNodes, this.contentNodeApiService.rename(action.payload)).then(() => {});
    }

    @Action(RenamePersonalContentNode)
    renamePersonalContentNode(ctx: StateContext<ContentNodeStateModel>, action: RenamePersonalContentNode): void {
        this._updateEntity(ctx, action, RenameContentNodeSuccess, RenameContentNodeFailure, AddContentNodes, this.contentNodeApiService.renamePersonal(action.payload)).then(
            () => {}
        );
    }

    @Action(UpdateContentNodeMetadata)
    updateContentNodeMetadata(ctx: StateContext<ContentNodeStateModel>, action: UpdateContentNodeMetadata): void {
        this.contentNodeApiService.updateMetadata(action.payload.contentNodeId, action.payload.allowDistribution).subscribe(
            (entity) => {
                this._addEntities(ctx, { payload: [entity] });

                ctx.dispatch(new UpdateContentNodeMetadataSuccess(entity));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateContentNodeMetadataFailure({ entityId: action.payload.contentNodeId, response }));
            }
        );
    }

    @Action(DeleteContentNode)
    deleteEntity(ctx: StateContext<ContentNodeStateModel>, action: DeleteContentNode): void {
        this._deleteEntity(ctx, action, DeleteContentNodeSuccess, DeleteContentNodeFailure, this.contentNodeApiService.delete(action.payload)).then(() => {});
    }

    @Action(DeletePersonalContentNode)
    deletePersonalContentNode(ctx: StateContext<ContentNodeStateModel>, action: DeletePersonalContentNode): void {
        this.contentNodeApiService.deletePersonal(action.payload.nodeId).subscribe(
            () => {
                ctx.dispatch(new DeleteContentNodeSuccess(action.payload.nodeId));
            },
            () => {
                ctx.dispatch(new DeleteContentNodeFailure(action.payload.nodeId));
            }
        );
    }

    @Action(FetchContentNode)
    fetchEntity(ctx: StateContext<ContentNodeStateModel>, { payload }: FetchContentNode): void {
        this._fetchEntity(
            ctx,
            { payload: payload.contentNodeId },
            FetchContentNodeSuccess,
            FetchContentNodeFailure,
            AddContentNodes,
            payload.setEntity ? SetContentNode : null,
            this.contentNodeApiService.metadata(payload.contentNodeId)
        ).then(() => {});
    }

    @Action(FetchContentNodeSuccess)
    fetchEntitySuccess(ctx: StateContext<ContentNodeStateModel>, action: FetchContentNodeSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchContentNodeFailure)
    fetchEntityFailure(ctx: StateContext<ContentNodeStateModel>, action: FetchContentNodeFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(PublishContentNodes)
    publishContentNodes({ dispatch }: StateContext<ContentNodeStateModel>, { payload }: PublishContentNodes): void {
        this.contentNodeApiService.publish(payload).subscribe(
            (publishJob) => {
                dispatch(new PublishContentNodesSuccess({ jobId: publishJob.id, nodeId: payload.node }));

                const message = this._translateService.instant(`ERROR.RESPONSE.${publishJob.message}`);

                if (publishJob.message) {
                    this._matSnackBar.open(message, 'OK', {
                        verticalPosition: 'top',
                        duration: SnackBarTime.medium,
                    });
                }
            },
            (response: HttpErrorResponse) => {
                dispatch(new PublishContentNodesFailure({ response }));
            }
        );
    }

    @Action(CreateEmptyFile)
    createEmptyFile(ctx: StateContext<ContentNodeStateModel>, { payload }: CreateEmptyFile): void {
        this.contentNodeApiService.createEmptyFile(payload).subscribe(
            (contentNode) => {
                ctx.dispatch(new CreateEmptyFileSuccess(contentNode));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new CreateEmptyFileFailure(response.error));
            }
        );
    }

    @Action(BulkUpdateCopyProtection)
    bulkUpdateCopyProtection(ctx: StateContext<ContentNodeStateModel>, { payload }: BulkUpdateCopyProtection): void {
        this.contentNodeApiService.bulkUpdateCopyProtection(payload).subscribe(
            (contentNode) => {
                ctx.dispatch(new BulkUpdateCopyProtectionSuccess());
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new BulkUpdateCopyProtectionFailure());
            }
        );
    }
}
