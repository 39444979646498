import { UserAdditionalEmail } from '../models/user-additional-email';

export class FetchUserAdditionalEmailsSuccess {
    static readonly type = '[User] FetchUserAdditionalEmailsSuccess';

    constructor(public payload: UserAdditionalEmail[]) {}
}

export class FetchUserVerifiedAdditionalEmailsSuccess {
    static readonly type = '[User] FetchUserVerifiedAdditionalEmailsSuccess';

    constructor(public payload: UserAdditionalEmail[]) {}
}
