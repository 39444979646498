import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TenantDataService } from 'app/config/services/tenant-data.service';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import { SetUserInfo } from 'app/projects/user/src/lib/actions/set-user-info.action';
import { ConfigService } from '../../../config/services/config.service';
import { FetchFeatures, FetchFeaturesFailure, FetchFeaturesSuccess, SetFeatures } from '../actions';
import { FeatureApiService } from '../services/feature.api-service';
import { Feature } from './feature.model';
import { FeatureStateModel } from './feature.state-model';

@State<FeatureStateModel>({
    name: 'feature',
    defaults: new FeatureStateModel(),
})
@Injectable()
export class FeatureState extends EntityState<Feature> {
    static getEntity(state: FeatureStateModel, id: string): Feature {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntities(state: FeatureStateModel): Feature[] {
        if (!state.map) {
            return null;
        }

        return Array.from(state.map.values());
    }

    constructor(private _featureService: FeatureApiService, private _configService: ConfigService, private _tenantDataService: TenantDataService) {
        super(null, 0);

        this._propstoSearch = Feature.props_to_search;
    }

    @Action(SetFeatures)
    setEntities(ctx: StateContext<FeatureStateModel>, action: SetFeatures): void {
        this._setEntities(ctx, action);
    }

    @Action(SetUserInfo)
    @Action(FetchFeatures)
    async fetchEntities(ctx: StateContext<FeatureStateModel>): Promise<void> {
        if (!this._tenantDataService.tenantName) {
            await this._configService.fetchConfig(localStorage.getItem('accessToken'));
        }
        this._fetchEntities(ctx, FetchFeaturesSuccess, FetchFeaturesFailure, SetFeatures, this._featureService.getAll()).then(() => {});
    }

    @Action(FetchFeaturesSuccess)
    fetchEntitiesSuccess(ctx: StateContext<FeatureStateModel>): void {
        this._fetchEntitiesSuccess(ctx);
    }

    @Action(FetchFeaturesFailure)
    fetchEntitiesFailure(ctx: StateContext<FeatureStateModel>): void {
        this._fetchEntitiesFailure(ctx);
    }
}
