import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from 'app/projects/fuse/src/lib/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MaxBrainConfirmDialogComponent extends FuseConfirmDialogComponent {
    public confirmTitle: string;
    public entitiesLength: number;
    public confirmButtonColor = 'accent';
    public hideActionButton: boolean = false;
    public hideAdditionalActionButton: boolean = true;
    public actionButtonText: string = 'GENERAL.BUTTON.CONFIRM';
    public additionalActionButtonText: string = 'GENERAL.BUTTON.DISCARD';

    constructor(dialogRef: MatDialogRef<MaxBrainConfirmDialogComponent>) {
        super(dialogRef);
    }
}
