import { IMaxbrainImageQuery } from '../interfaces/maxbrain-image.interface';
import { MaxbrainImageDto } from '../interfaces/dto/maxbrain-image.dto.interface';

export class MaxbrainImage {
    id: string;
    unsplash: { unsplashId: string; authorName: string; authorProfileUrl: string; authorProfileImage: string } = {
        unsplashId: '',
        authorName: '',
        authorProfileImage: '',
        authorProfileUrl: '',
    };
    url: string;
    smallUrl: string;
    thumbUrl: string;
    largeUrl: string;

    constructor(imageQuery?: IMaxbrainImageQuery, imageDTO?: MaxbrainImageDto) {
        if (imageQuery) {
            this.id = imageQuery.id ? imageQuery.id.toString() : null;
            this.url = imageQuery.url;
            this.smallUrl = imageQuery.small_url;
            this.thumbUrl = imageQuery.thumb_url;
            this.largeUrl = imageQuery.large_url;
            if (imageQuery.unsplash) {
                this.unsplash.unsplashId = imageQuery.unsplash.unsplash_id.toString();
                this.unsplash.authorName = imageQuery.unsplash.author_name;
                this.unsplash.authorProfileUrl = imageQuery.unsplash.author_profile_url;
                this.unsplash.authorProfileImage = imageQuery.unsplash.author_profile_image;
            } else {
                this.unsplash = null;
            }
        } else if (imageDTO) {
            this.id = imageDTO.id.toString();
            this.url = imageDTO.url;
            this.smallUrl = imageDTO.smallUrl;
            this.thumbUrl = imageDTO.thumbUrl;
            this.largeUrl = imageDTO.largeUrl;
            if (imageDTO.unsplash) {
                this.unsplash.unsplashId = imageDTO.unsplash.unsplashId.toString();
                this.unsplash.authorName = imageDTO.unsplash.authorName;
                this.unsplash.authorProfileUrl = imageDTO.unsplash.authorProfileUrl;
                this.unsplash.authorProfileImage = imageDTO.unsplash.authorProfileImage;
            } else {
                this.unsplash = null;
            }
        }
    }
}
