import { IUserResetPasswordUrl } from '../interfaces/user-reset-password-url.interface';

export class UserResetPasswordUrl {
    userId: string;
    resetUrl: string;

    constructor(query: IUserResetPasswordUrl) {
        if (query) {
            this.userId = query.user_id.toString();
            this.resetUrl = query.reset_url;
        } else {
            this.userId = null;
            this.resetUrl = null;
        }
    }
}
