import { IBulkUpdateCopyProtectionQuery } from '../interfaces/bulk-update-protection.query.interface';
export class BulkUpdateCopyProtectionCommand {
    node_ids: string[];
    allow_distribution: boolean;

    constructor(query: IBulkUpdateCopyProtectionQuery) {
        this.node_ids = query.contentNodeIds;
        this.allow_distribution = query.allowDistribution;
    }
}
