import { ICourseStrcutureModuleBreadcrumbQuery } from '../interfaces/course-structure-module-breadcrumb.interface';

export class CourseStructureModuleBreadcrumb {
    id: string;
    name: string;
    type: string;

    constructor(tempModule: ICourseStrcutureModuleBreadcrumbQuery) {
        this.id = tempModule.id.toString();
        this.name = tempModule.name;
        this.type = tempModule.type;
    }
}
