export enum FeatureSwitchName {
    Gradebook = 'gradebook',
    Engage = 'engage',
    SelfRegistration = 'self_registration',
    Sso = 'sso',
    Zoom = 'zoom',
    Slack = 'slack',
    ModuleVisibility = 'module_visibility',
    CertificateOfAttendance = 'certificate_of_attendance',
    Community = 'community',
    SurveyIntegration = 'survey_integration',
    ELearning = 'e_learning',
    ClasstimeSections = 'classtime_sections',
    CourseSelfRegistration = 'course_self_registration',
    Reporting = 'reporting',
    BackgroundImage = 'background_image',
    CssSplitting = 'css_splitting',
    CustomDomain = 'custom_domain',
    CourseStructure = 'course_structure',
    Nuggets = 'nuggets',
    UserOnlineState = 'user_online_state',
    PersonalDocuments = 'personal_documents',
    Attendance = 'attendance',
    GrowSearch = 'grow_search',
    TenantDashboard = 'tenant_dashboard',
    Encryption = 'encryption',
    ExtendedAttributes = 'extended_attributes',
    AIImages = 'ai_images',
    Teams = 'teams',
    PasswordlessLogin = 'passwordless_login',
    CourseSelfCancellation = 'course_self_cancellation',
    ParticipantTab = 'participant_tab',
    ParticipantEmailVisibility = 'participant_email_visibility',
    Copyright = 'copyright',
    MultiEmails = 'multi_emails',
    GlobalCalendar = 'global_calendar',
    WelcomeTour = 'welcome_tour',
    Groups = 'groups',
    Chat = 'chat',
    MemberAreaTab = 'member_area_tab',
    ManagerFiles = 'manager_files',
    PersonalDocumentsCopyrightProtection = 'personal_documents_copyright_protection',
    PerformanceFeedbackForm = 'performance_feedback_form',
    CertificateManagement = 'certificate_management',
    RespeakIntegration = 'respeak_integration',
}
