import { IModuleEventAttendanceStatisticsQuery } from '../interfaces/module-event-attendance-statistics.query.interface';

export class ModuleEventAttendanceStatistics {
    status: number;
    count: number;

    constructor(moduleEvent: IModuleEventAttendanceStatisticsQuery) {
        this.status = moduleEvent.status;
        this.count = moduleEvent.count;
    }
}
