import { ModuleFeature } from './module-feature.model';

export class ModuleFeatureCommand {
    name: string;
    status: string;

    constructor(moduleFeature: ModuleFeature) {
        this.name = moduleFeature.id;
        this.status = moduleFeature.status;
    }
}
