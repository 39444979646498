import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IExperienceUserQuery } from 'app/library/experience-user/interfaces/experience-user.query.interface';
import { ExperienceUserExtended } from 'app/library/experience-user/models/experience-user.extended.model';
import { ExperienceUser } from 'app/library/experience-user/models/experience-user.model';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { IPaginatedResponse } from 'app/projects/subentity/src/lib/interfaces/paginated-response.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ExperienceUsersApiService {
    constructor(private http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) private environment: IEnvironment) {}

    getAll(params?: HttpParams): Observable<IPaginatedResponse<IExperienceUserQuery>> {
        return this.http.get<IPaginatedResponse<IExperienceUserQuery>>(`${this.environment.apiUrl}/engage/experiences-users`, { params });
    }

    getExperienceByUserId(userId: string): Observable<ExperienceUserExtended[]> {
        return this.http
            .get<IPaginatedResponse<IExperienceUserQuery>>(`${this.environment.apiUrl}/engage/experiences-users?user=${userId}`)
            .pipe(map((queries) => queries.data.map((query) => new ExperienceUserExtended(query))));
    }
}
