import { HttpErrorResponse } from '@angular/common/http';
import { IManageAudienceUsersActionPayload } from 'app/library/audience-users/interfaces/audience-users-action.action-payload.interface';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { IManageExperienceUsersActionPayload } from '../interfaces/experience-users-action.action-payload.interface';
import { ExperienceUserExtended } from '../models/experience-user.extended.model';
import { ExperienceUser } from '../models/experience-user.model';

export class SetExperienceUser {
    static readonly type = '[ExperienceUser] SetExperienceUser';

    constructor(public payload: ExperienceUser) {}
}

export class UnsetExperienceUser {
    static readonly type = '[ExperienceUser] UnsetExperienceUser';
}

export class FetchExperienceUser {
    static readonly type = '[ExperienceUser] FetchExperienceUser';

    constructor(public payload: string) {}
}

export class FetchExperienceUserSuccess {
    static readonly type = '[ExperienceUser] FetchExperienceUserSuccess';

    constructor(public payload: ExperienceUser) {}
}

export class FetchExperienceUserFailure {
    static readonly type = '[ExperienceUser] FetchExperienceUserFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class UpdateExperienceUser {
    static readonly type = '[ExperienceUser] UpdateExperienceUser';

    constructor(public payload: ExperienceUser) {}
}

export class UpdateExperienceUserSuccess {
    static readonly type = '[ExperienceUser] UpdateExperienceUserSuccess';

    constructor(public payload: ExperienceUser) {}
}

export class UpdateExperienceUserFailure {
    static readonly type = '[ExperienceUser] UpdateExperienceUserFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class AddUsersToExperiences {
    static readonly type = '[ExperienceUser] AddUsersToExperiences';

    constructor(public payload: IManageExperienceUsersActionPayload) {}
}

export class AddUsersToExperiencesSuccess {
    static readonly type = '[ExperienceUser] AddUsersToExperiencesSuccess';

    constructor(public payload: { newly_assigned: ExperienceUserExtended[]; already_assigned: ExperienceUserExtended[] }) {}
}

export class AddUsersToExperiencesFailure {
    static readonly type = '[ExperienceUser] AddUsersToExperiencesFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class AssignUserToExperience {
    static readonly type = '[ExperienceUser] AssignUserToExperience';

    constructor(public payload: IManageExperienceUsersActionPayload) {}
}

export class AssignUserToExperienceSuccess {
    static readonly type = '[ExperienceUser] AssignUserToExperienceSuccess';

    constructor(public payload: ExperienceUser) {}
}

export class AssignUserToExperienceFailure {
    static readonly type = '[ExperienceUser] AssignUserToExperienceFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class DeleteExperienceUsers {
    static readonly type = '[ExperienceUser] DeleteExperienceUsers';

    constructor(public payload: { experienceUsers: [{ id: string }] }) {}
}

export class DeleteExperienceUsersSuccess {
    static readonly type = '[ExperienceUser] DeleteExperienceUsersSuccess';

    constructor(public payload: string) {}
}

export class DeleteExperienceUsersFailure {
    static readonly type = '[ExperienceUser] DeleteExperienceUsersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class MoveExperienceUsers {
    static readonly type = '[ExperienceUser] MoveExperienceUser';

    constructor(public payload: IManageAudienceUsersActionPayload) {}
}

export class MoveExperienceUsersSuccess {
    static readonly type = '[ExperienceUser] MoveExperienceUsersSuccess';

    constructor(public payload: string) {}
}

export class MoveExperienceUsersFailure {
    static readonly type = '[ExperienceUser] MoveExperienceUsersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class AssignExperienceUsers {
    static readonly type = '[ExperienceUser] AssignExperienceUser';

    constructor(public payload: IManageAudienceUsersActionPayload) {}
}

export class AssignExperienceUsersSuccess {
    static readonly type = '[ExperienceUser] AssignExperienceUsersSuccess';

    constructor(public payload: string) {}
}

export class AssignExperienceUsersFailure {
    static readonly type = '[ExperienceUser] AssignExperienceUsersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}
