import { ModuleUserAttendance } from 'app/library/module-user/models/module-user-attendance.model';
import { ScheduledJob } from 'app/library/scheduler/models/scheduled-job.model';
import { MaxBrainTag } from 'app/library/tag/models/tag.model';
import { User } from 'app/projects/user/src/lib/models/user';
import * as momentImported from 'moment';
import { FinalGradeMetrics } from '../../final-grade/models/final-grade-metric.model';
import { ModuleKind } from '../enums/module-kind.enum';
import { SectionRule } from '../enums/sectionRule.enum';
import { IModuleDuration } from '../interfaces/module-duration.interface';
import { IModuleQuery } from '../interfaces/module.query.interface';
import { ModuleImage } from './module-image.model';
import { ModuleStatuses } from './module-statuses';
const moment = momentImported;

export class Module {
    static label_singular = 'module';
    static label_plural = 'modules';

    static props_to_search = ['name'];

    protected _type: string;

    get type(): string {
        return this._type;
    }

    id: string;
    isPrivate: boolean;
    name: string;
    description: string;
    startDate: momentImported.Moment;
    endDate: momentImported.Moment;
    numberOfParticipants: number;
    responsibleManagers: User[];
    status: string;
    tags: MaxBrainTag[];
    hasSections: boolean;
    sectionRule: SectionRule;
    duration: string;
    durationTime: string;
    rawDuration: number;
    digicompDuration: IModuleDuration;
    selfPaced: boolean;
    sectionCount: number;
    schedules: ScheduledJob[];
    // is logged in user manager on module
    isManager: boolean;

    slackChannel: string;

    contentNodeId: string;
    userContentNodeId: string;
    sharedContentNodeId: string;

    gradingSchemeId: string;

    metrics?: FinalGradeMetrics;

    image: ModuleImage;
    defaultPinned: boolean;
    kind: ModuleKind;
    publishSections: boolean;
    courseOffering: { id: string };
    alreadyBooked: boolean;
    completionRule: number;
    isSelected: boolean = false; // Frontend property
    attendance: ModuleUserAttendance; // Frontend property (Shows My Attendance column on Manage Modules page)
    version: string; // Frontend property for course execution

    constructor(moduleQuery?: IModuleQuery) {
        if (moduleQuery) {
            this.isPrivate = moduleQuery.private;
            this.name = moduleQuery.name;
            this.description = moduleQuery.description;
            this.id = moduleQuery.id.toString();
            this.startDate = moduleQuery.start_date ? moment(moduleQuery.start_date, 'YYYY-MM-DD') : null;
            this.endDate = moduleQuery.end_date ? moment(moduleQuery.end_date, 'YYYY-MM-DD') : null;
            this.numberOfParticipants = moduleQuery.participant_count;
            this.responsibleManagers = moduleQuery.responsible_managers.map((user) => new User(user));
            this.status = moduleQuery.status;
            this.tags = moduleQuery.tags ? moduleQuery.tags.map((tag) => new MaxBrainTag(tag)) : [];
            this.hasSections = moduleQuery.section;
            this.sectionRule = moduleQuery.section_rule;
            this.duration = this.hhmm(moduleQuery.expected_duration);
            this.durationTime = this.hhmm(moduleQuery.expected_duration, true);
            this.rawDuration = moduleQuery.expected_duration;
            this.image = moduleQuery.image ? new ModuleImage(moduleQuery.image) : null;
            this.defaultPinned = moduleQuery.default_pinned;
            this.selfPaced = moduleQuery.self_paced;
            this.kind = moduleQuery.kind;
            this.publishSections = moduleQuery.publish_sections;
            this.sectionCount = moduleQuery.section_count;
            this.courseOffering = moduleQuery.course_offering ? { id: moduleQuery.course_offering.id.toString() } : null;
            this.alreadyBooked = moduleQuery.already_booked;
            this.completionRule = moduleQuery.completion_rule;
            this.isManager = moduleQuery.is_manager;
            this.version = moduleQuery.version;

            for (const property in moduleQuery.duration) {
                if (moduleQuery.duration[property]) {
                    this.digicompDuration = moduleQuery.duration;
                }
            }
            if (moduleQuery.schedules?.length) {
                this.schedules = moduleQuery.schedules.map((schedule) => new ScheduledJob(schedule));
            }
        } else {
            this.isPrivate = false;
            this.name = null;
            this.description = null;
            this.id = null;
            this.startDate = null;
            this.endDate = null;
            this.numberOfParticipants = null;
            this.responsibleManagers = [];
            this.status = 'unpublished';
            this.tags = [];
            this.hasSections = false;
            this.sectionRule = SectionRule.Default;
            this.duration = null;
            this.durationTime = null;
            this.digicompDuration = null;
            this.defaultPinned = false;
            this.selfPaced = false;
            this.sectionCount = null;
            this.schedules = [];
            this.kind = null;
            this.publishSections = false;
            this.alreadyBooked = false;
            this.completionRule = null;
            this.version = null;
        }

        this.slackChannel = null;

        this.contentNodeId = null;
        this.userContentNodeId = null;
        this.sharedContentNodeId = null;

        this.gradingSchemeId = null;
    }

    get nextAvailableStatuses(): string[] {
        return ModuleStatuses.getNextAvailableStatuses(this.status).map((status) => status.name);
    }

    get hasTrackProgress(): boolean {
        const rulesAllowed = [ModuleStatuses.ruleOpts.trackProgress, ModuleStatuses.ruleOpts.sequential];

        return rulesAllowed.includes(this.sectionRule);
    }

    set hasTrackProgress(val) {}

    /**
     *    Module has status published, running, completed
     *    sequential is enabled
     */
    public canAddSectionWithoutConflict(hasSectionPublished = false): boolean {
        const statusConflict = ['published', 'running', 'completed'];

        /**
         * if sequential is not available
         */
        if (ModuleStatuses.ruleOpts.sequential === this.sectionRule) {
            if (hasSectionPublished) {
                return false;
            }

            /**
             * if the module is not publish running or completed
             */
            return false === statusConflict.includes(this.status);
        }

        return true;
    }

    set nextAvailableStatuses(param) {}

    hhmm(secs: number, returnOnlyTime = false): string {
        let minutes = Math.floor(secs / 60);
        const hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        return returnOnlyTime ? `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}` : `${hours}h ${minutes}min`;
    }
}
