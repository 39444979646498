import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { SnackBarTime } from 'app/projects/core/src/lib/constants/snack-bar';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { DeleteProfilePictureSuccess } from 'app/projects/user/src/lib/actions/delete-profile-picture-success.action';
import { DeleteProfilePicture } from 'app/projects/user/src/lib/actions/delete-profile-picture.action';
import { UpdateUserSuccess } from 'app/projects/user/src/lib/actions/update-entity-success.action';
import { UpdateUserProfilePicture } from 'app/projects/user/src/lib/actions/update-user-profile-picture.action';
import { User } from 'app/projects/user/src/lib/models/user';
import { take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-profile-photo-editor',
    templateUrl: './profile-photo-editor.component.html',
    styleUrls: ['./profile-photo-editor.component.scss'],
})
export class ProfilePhotoEditorComponent extends UnsubscribeOnDestroy {
    @Input()
    user: User;

    newProfilePictureFile: File;
    profilePictureSrc: string;

    constructor(private _matSnackBar: MatSnackBar, private _store: Store, private _action$: Actions, private _translateService: TranslateService) {
        super();
    }

    fileChange(event): void {
        if (event.target.files && event.target.files[0]) {
            this.newProfilePictureFile = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => (this.profilePictureSrc = (e.target as any).result);

            reader.readAsDataURL(this.newProfilePictureFile);

            this.updateProfilePicture();
        }
    }

    updateProfilePicture(): void {
        this._store.dispatch(
            new UpdateUserProfilePicture({
                entityId: this.user.id,
                file: this.newProfilePictureFile,
            })
        );
        this._action$.pipe(takeUntil(this._unsubscribeAll), ofActionDispatched(UpdateUserSuccess), take(1)).subscribe((action) => {
            this.newProfilePictureFile = null;
            this.profilePictureSrc = null;

            const successMessage = this._translateService.instant('USER.MESSAGE.PHOTO_UPDATED');

            this._matSnackBar.open(successMessage, 'OK', {
                verticalPosition: 'top',
                duration: SnackBarTime.medium,
            });
        });
    }

    removeProfilePicture(): void {
        this._store.dispatch(
            new DeleteProfilePicture({
                id: this.user.id,
            })
        );
        this._action$.pipe(takeUntil(this._unsubscribeAll), ofActionDispatched(DeleteProfilePictureSuccess), take(1)).subscribe(() => {
            const successMessage = this._translateService.instant('USER.MESSAGE.PHOTO_UPDATED');

            this._matSnackBar.open(successMessage, 'OK', {
                verticalPosition: 'top',
                duration: SnackBarTime.medium,
            });

            this.profilePictureSrc = '';
        });
    }

    toggleActiveClass(event: boolean): void {
        const el = document.querySelector('.avatar-wrapper');
        if (event) {
            el.classList.add('active');
        } else {
            el.classList.remove('active');
        }
    }
}
