import { HttpErrorResponse } from '@angular/common/http';

export class AddAdditionalEmailFailure {
    static readonly type = '[User] AddAdditionalEmailFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}
