import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { TenantDataService } from 'app/config/services/tenant-data.service';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { SetError } from 'app/projects/error/src/lib/actions/set-error.action';
import { IConfig } from '../interfaces/config.interface';

interface RGB {
    r: number;
    b: number;
    g: number;
}

@Injectable()
export class ConfigService {
    constructor(
        @Inject(MAXBRAIN_ENVIRONMENT) private environment: IEnvironment,
        private http: HttpClient,
        private store: Store,
        private titleService: Title,
        private tenantDataService: TenantDataService,
        @Inject(DOCUMENT) private _document: Document
    ) {}

    private _hexToRgb(hex: string): RGB {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.toLowerCase());

        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
    }

    private _hexColorDelta(hex1: string, hex2: string): number {
        // get red/green/blue int values of hex1
        const rgb1 = this._hexToRgb(hex1);
        // get red/green/blue int values of hex2
        const rgb2 = this._hexToRgb(hex2);
        // calculate differences between reds, greens and blues
        let r = 255 - Math.abs(rgb1.r - rgb2.r);
        let g = 255 - Math.abs(rgb1.g - rgb2.g);
        let b = 255 - Math.abs(rgb1.b - rgb2.b);
        // limit differences between 0 and 1
        r /= 255;
        g /= 255;
        b /= 255;
        // 0 means opposit colors, 1 means same colors
        return (r + g + b) / 3;
    }

    private _getForegroundColor(hex: string): string {
        const rgb = this._hexToRgb(hex);

        if (!rgb) {
            return null;
        }

        const sum = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);

        return sum > 128 ? 'rgba(0,0,0, 0.87)' : 'white';
    }

    private _setColorShadeToRootStyleProperty(key: string, obj: any): void {
        const root = document.documentElement;

        root.style.setProperty(`--maxbrain-${key}`, obj[key]);

        const foregroundValue = this._getForegroundColor(obj[key]);

        if (foregroundValue) {
            root.style.setProperty(`--maxbrain-${key}-text`, foregroundValue);
        } else {
            console.error(`Something went wrong with this value:`, obj[key], `\nThe following style property was not affected: --maxbrain-${key}-text`);
        }
    }

    async fetchConfig(token: string): Promise<any> {
        return this.http
            .get<IConfig>(`${this.environment.apiUrl}/v1/config`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .toPromise()
            .then((res) => {
                window.localStorage.setItem('application_id', res.application_id);
                window.localStorage.setItem('auth_connection', res.auth_connection);
                window.localStorage.setItem('subdomain', res.subdomain);
                if (res.tenant_favicon) {
                    this._document.getElementById('appFavicon').setAttribute('href', res.tenant_favicon);
                }

                this.titleService.setTitle(res.tenant_name);
                this.tenantDataService.setData(
                    res.tenant_logo,
                    res.tenant_name,
                    res.edman_url,
                    res.support_email,
                    res.ios_app_id,
                    res.android_app_id,
                    res.mobile_app_name,
                    res.mobile_app_logo,
                    res.can_create_private_module,
                    res.tenant_config,
                    res.subdomain,
                    res.default_language
                );

                if (res.tenant_config) {
                    Object.keys(res.tenant_config).forEach((configKey) => {
                        const tenantConfigValue = res.tenant_config[configKey];

                        if (!tenantConfigValue) {
                            return;
                        }

                        switch (configKey) {
                            case 'color_palette':
                                ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700'].forEach((shade) => {
                                    this._setColorShadeToRootStyleProperty(shade, tenantConfigValue);
                                });

                                const root = document.documentElement;
                                const delta = this._hexColorDelta(tenantConfigValue['700'], '#f44336');

                                if (delta > 0.75) {
                                    const rgb = this._hexToRgb('#f0c419');
                                    root.style.setProperty(`--maxbrain-warn`, '#f0c419');
                                    root.style.setProperty(`--maxbrain-warn-70-opacity`, `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.7)`);
                                    root.style.setProperty(`--maxbrain-warn-0-opacity`, `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`);
                                }
                                break;
                        }
                    });
                }
            })
            .catch((error: HttpErrorResponse) => {
                if (error.status === 503 && window.location.toString() !== `${MaxBrainUtils.baseUrl}/errors/${error.status}`) {
                    window.location.replace(`${MaxBrainUtils.baseUrl}/errors/${error.status}`);
                    return;
                }

                // @TODO ask Nikola what should we do if the error doesnt have any message
                if (error.error && error.error.message) {
                    window.localStorage.setItem('application_id', error.error.message);
                }

                this.store.dispatch(new SetError(error));
            });
    }
}
