import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEnGb from '@angular/common/locales/en-GB';
import localeFrFR from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { AppComponent } from 'app/app.component';
import { environment } from 'environments/environment';
import { NgChartsModule, ThemeService } from 'ng2-charts';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ConfigModule } from './config/config.module';
import { ConfigService } from './config/services/config.service';
import { MaintenanceMessageService } from './config/services/maintenance-message.service';
import { TenantDataService } from './config/services/tenant-data.service';
import { fuseConfig } from './fuse-config';
import { LayoutModule } from './layout/layout.module';
import { FeedApiService } from './library/feed/services/feed.api-service';
import { ModuleCreateFormDialogComponent } from './library/module/components/dialogs/create-form/create-form.component';
import { ChatDrawerModule } from './library/shared/chat-drawer/chat-drawer.module';
import { TagService } from './library/tag/services/tag.service';
import { TagModule } from './library/tag/tag.module';
import { UserCreateModule } from './library/user/user-create/user-create.module';
import { ModuleCreateFormDialogPageComponent } from './main/pages/dialogs/module-create-form-dialog-page/module-create-form-dialog-page.component';
import { UserCreateFormDialogPageComponent } from './main/pages/dialogs/user-create-form-dialog-page/user-create-form-dialog-page.component';
import { WelcomeTourDialogComponent } from './main/pages/dialogs/welcome-tour-dialog/welcome-tour-dialog.component';
import { FuseProgressBarModule } from './projects/fuse/src/lib/components/progress-bar/progress-bar.module';
import { FuseSidebarModule } from './projects/fuse/src/lib/components/sidebar/sidebar.module';
import { FuseThemeOptionsModule } from './projects/fuse/src/lib/components/theme-options/theme-options.module';
import { FuseModule } from './projects/fuse/src/lib/fuse.module';
import { FUSE_ASYNC_DRAWER } from './projects/fuse/src/lib/services/async-drawer.token';
import { FEED_SERVICE } from './projects/fuse/src/lib/services/feed-service.token';
import { FuseSharedModule } from './projects/fuse/src/lib/shared.module';
import { MAXBRAIN_LANGUAGE_SERVICE } from './projects/shared/src/lib/services/language.token';
import { MaxBrainSharedModule } from './projects/shared/src/lib/shared.module';
import { AsyncJobApiService } from './services/async-job.api-service';
import { MaxBrainLanguageService } from './services/language.service';
import { NewModuleGuard } from './services/new-module.guard';
import { SurveyMonkeyResultComponent } from './survey-monkey-result/survey-monkey-result.component';

registerLocaleData(localeEnGb);
registerLocaleData(localeDe);
registerLocaleData(localeFrFR);
registerLocaleData(localeIt);

const routes: Routes = [
    {
        path: 'new-module',
        children: [
            {
                path: ':type',
                component: ModuleCreateFormDialogPageComponent,
                canActivate: [NewModuleGuard],
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'choose',
            },
        ],
        outlet: 'modal',
    },
    {
        path: 'new-user',
        component: UserCreateFormDialogPageComponent,
        outlet: 'modal',
    },
    {
        path: 'errors',
        loadChildren: () => import('./main/pages/errors/errors.module').then((m) => m.ErrorsModule),
    },
    {
        path: 'surveymonkeyresult',
        component: SurveyMonkeyResultComponent,
    },
    {
        path: '',
        loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    },
];

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        ModuleCreateFormDialogPageComponent,
        UserCreateFormDialogPageComponent,
        ModuleCreateFormDialogComponent,
        SurveyMonkeyResultComponent,
        WelcomeTourDialogComponent,
    ],
    entryComponents: [UserCreateFormDialogPageComponent, ModuleCreateFormDialogComponent, WelcomeTourDialogComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgxCleaveDirectiveModule,

        RouterModule.forRoot(routes),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),

        MaxBrainSharedModule,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSelectModule,
        MatStepperModule,
        MatAutocompleteModule,
        MatListModule,
        MatCardModule,
        MatRadioModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatChipsModule,
        MatSnackBarModule,
        ReactiveFormsModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        UserCreateModule,
        TagModule,

        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsRouterPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot({
            disabled: true,
        }),
        NgxsModule.forRoot([], {
            // I commented this because the developpement mode slow down the application, Redux Dev Tool is enough to watch the state
            developmentMode: !environment.production,
        }),

        ChatDrawerModule.forRoot(),

        ConfigModule.forRoot(),
    ],
    providers: [
        TenantDataService,
        TagService,
        ConfigService,
        MaintenanceMessageService,
        {
            provide: MAXBRAIN_LANGUAGE_SERVICE,
            useValue: new MaxBrainLanguageService(),
        },
        NewModuleGuard,
        { provide: LOCALE_ID, useValue: 'en-GB' },
        {
            provide: FUSE_ASYNC_DRAWER,
            useClass: AsyncJobApiService,
        },
        {
            provide: FEED_SERVICE,
            useClass: FeedApiService,
        },
        ThemeService,
        NgChartsModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
