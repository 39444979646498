import { ModuleType } from '../enums/moduleType.enum';
import { IModuleQuery } from '../interfaces/module.query.interface';
import { BasicModule } from './basic-module.model';
import { ELearningModule } from './eLearning-module.model';
import { Module } from './module.model';

export function newModule(value: IModuleQuery | string): Module {
    if (typeof value === 'string') {
        switch (value) {
            case ModuleType.Basic:
                return new BasicModule();
            case ModuleType.ELearning:
                return new ELearningModule();
        }
    } else {
        switch (value.type) {
            case ModuleType.Basic:
                return new BasicModule(value);
            case ModuleType.ELearning:
                return new ELearningModule(value);
        }
    }
}
