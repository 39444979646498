<div *ngIf="!creating">
    <app-dialog-header [title]="'USER.BUTTON.ADD_NEW'" (onClick)="dialogRef.close(null)"></app-dialog-header>

    <div mat-dialog-content fxLayout="column" fxLayoutAlign="start" class="p-24">
        <form [formGroup]="detailsFormGroup" class="w-100-p">
            <div class="mb-24 relative user-header" fxLayout="row" fxLayoutAlign="start center">
                <input
                    type="file"
                    style="display: none"
                    (change)="fileChange($event)"
                    [placeholder]="'CONTENT.BUTTON.UPLOAD' | translate"
                    accept=".png, .jpg, .jpeg, .bmp, .gif"
                    #fileInput
                />
                <img [src]="profilePictureSrc" class="avatar user mr-24" />

                <app-flat-button [label]="'PAGE.USER_FORM.BUTTON.CHOOSE_PHOTO'" (onClick)="fileInput.click()"></app-flat-button>
            </div>

            <mat-form-field class="w-100-p" formGroupName="role">
                <mat-select formControlName="userRole" [placeholder]="'PAGE.USER_FORM.TAB.ACCOUNT.FIELD.ROLE' | translate">
                    <mat-option value="basic_role">
                        {{ 'USER.SINGULAR' | translate }}
                    </mat-option>
                    <mat-option value="tenant_management">
                        {{ 'GENERAL.ADMINISTRATOR' | translate }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="userRoleForm.controls['userRole'].errors && userRoleForm.controls['userRole'].errors['message']">
                    {{ 'ERROR.RESPONSE.' + userRoleForm.controls['userRole'].errors['message'] | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-100-p" formGroupName="account">
                <input matInput name="first-name" formControlName="firstName" [placeholder]="'GENERAL.PROPERTY.FIRST_NAME' | translate" />
                <mat-error *ngIf="userAccountForm.controls['firstName'].errors && userAccountForm.controls['firstName'].errors['message']">
                    {{ 'ERROR.RESPONSE.' + userAccountForm.controls['firstName'].errors['message'] | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-100-p" formGroupName="account">
                <input matInput name="last-name" formControlName="lastName" [placeholder]="'GENERAL.PROPERTY.LAST_NAME' | translate" />
                <mat-error *ngIf="userAccountForm.controls['lastName'].errors && userAccountForm.controls['lastName'].errors['message']">
                    {{ 'ERROR.RESPONSE.' + userAccountForm.controls['lastName'].errors['message'] | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-100-p" formGroupName="account">
                <input matInput name="email" formControlName="email" [placeholder]="'GENERAL.PROPERTY.EMAIL' | translate" />
                <mat-error *ngIf="userAccountForm.controls['email'].errors && userAccountForm.controls['email'].errors['message']">
                    {{ 'ERROR.RESPONSE.' + userAccountForm.controls['email'].errors['message'] | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-100-p" formGroupName="account">
                <mat-select name="language" formControlName="language" [placeholder]="'GENERAL.PROPERTY.LANGUAGE' | translate">
                    <mat-option *ngFor="let lang of languageService.languages | filterLanguages" [value]="lang.id">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <img class="flag mr-16" [src]="'assets/images/flags/' + lang.flag + '.svg'" />
                            <span class="iso" [translate]="lang.translate">{{ lang.title }}</span>
                        </span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="userAccountForm.controls['language'].errors && userAccountForm.controls['language'].errors['message']">
                    {{ 'ERROR.RESPONSE.' + userAccountForm.controls['language'].errors['message'] | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-100-p" formGroupName="account" *ngIf="isEngageFeatureEnabled">
                <mat-label>{{ 'ENGAGE.EXPERIENCE.DIALOGS.MOVE_USER.LABEL' | translate }}</mat-label>
                <mat-select formControlName="experienceId">
                    <mat-optgroup *ngFor="let experience of experiences; let j = index">
                        <mat-option [class.selected]="experience.open" [value]="experience.id">
                            {{ experience.name }}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions class="pt-0 action-buttons" fxLayout="row" fxLayoutAlign="end center">
        <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(null)"></app-white-button>

        <app-flat-button [label]="'GENERAL.BUTTON.CREATE'" (onClick)="create()" [disabled]="detailsFormGroup.invalid || detailsFormGroup.pristine"></app-flat-button>
    </div>
</div>
<div class="p-24" fxLayout="column" fxLayoutAlign="center center" *ngIf="creating">
    <div class="p-24">
        <mat-spinner></mat-spinner>
    </div>
    <p class="center">{{ 'USER.MESSAGE.CREATING' | translate }}.<br />{{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}.</p>
</div>
