import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxsActionHelper } from 'app/projects/core/src/lib/services/action.helper';
import { ILanguageService } from 'app/projects/shared/src/lib/interfaces/language.interface';
import { MAXBRAIN_LANGUAGE_SERVICE } from 'app/projects/shared/src/lib/services/language.token';
import {
    FetchModuleFeatures,
    FetchModuleFeaturesFailure,
    FetchModuleFeaturesSuccess,
    UpdateModuleFeature,
    UpdateModuleFeatureFailure,
    UpdateModuleFeatureSuccess,
} from '../actions';
import { ModuleFeature } from '../models/module-feature.model';
import { ModuleFeatureState } from '../models/module-feature.state';

@Injectable()
export class ModuleFeatureService {
    constructor(private _store: Store, private _actionHelper: NgxsActionHelper, @Inject(MAXBRAIN_LANGUAGE_SERVICE) public languageService: ILanguageService) {}

    async fetchModuleFeatures(moduleId: string): Promise<boolean> {
        this._store.dispatch(new FetchModuleFeatures(moduleId));

        return await this._actionHelper.race(FetchModuleFeaturesSuccess, FetchModuleFeaturesFailure).toPromise();
    }

    getModuleFeatures(): ModuleFeature[] {
        return this._store.selectSnapshot(ModuleFeatureState.getEntities);
    }

    async updateModuleFeatures(moduleId: string, moduleFeature: ModuleFeature) {
        this._store.dispatch(new UpdateModuleFeature({ moduleId, moduleFeature }));

        return await this._actionHelper.race(UpdateModuleFeatureSuccess, UpdateModuleFeatureFailure).toPromise();
    }

    checkModuleFeatureStatus(id: string, status: string = 'active'): boolean {
        const feature = ModuleFeatureState.getEntity(this._store.selectSnapshot(ModuleFeatureState), id);

        if (!feature) {
            return false;
        }

        return feature.status === status;
    }
}
