import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FetchExperiences } from 'app/library/experience/actions';
import { AddExperiences } from 'app/library/experience/actions/add-entities.action';
import { MaxBrainExperience } from 'app/library/experience/models/experience.model';
import { UnsetModuleParticipants } from 'app/library/module-participants/actions';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import { AddUsers } from 'app/projects/user/src/lib/actions/add-entities.action';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { UserApiService } from 'app/projects/user/src/lib/services/user.api-service';
import {
    AddUsersToExperiences,
    AddUsersToExperiencesFailure,
    AddUsersToExperiencesSuccess,
    AssignUserToExperience,
    AssignUserToExperienceFailure,
    AssignUserToExperienceSuccess,
    DeleteExperienceUsers,
    DeleteExperienceUsersFailure,
    DeleteExperienceUsersSuccess,
    FetchExperienceUser,
    FetchExperienceUserFailure,
    FetchExperienceUserSuccess,
    MoveExperienceUsers,
    MoveExperienceUsersFailure,
    MoveExperienceUsersSuccess,
    SetExperienceUser,
    UnsetExperienceUser,
    UpdateExperienceUser,
    UpdateExperienceUserFailure,
    UpdateExperienceUserSuccess,
} from '../actions';
import { AddExperienceUsers } from '../actions/add-entities.action';
import { ExperienceUserApiService } from '../services/experience-user.api-service';
import { ExperienceUser } from './experience-user.model';
import { ExperienceUserStateModel } from './experience-user.state-model';

// Note: THIS STATE DOES NOT HAVE TO HAVE A FETCH
// In this state we track userId, experienceId, and id (check ExperienceUser)
@State<ExperienceUserStateModel>({
    name: 'experienceUser',
    defaults: new ExperienceUserStateModel(),
})
@Injectable()
export class ExperienceUserState extends EntityState<ExperienceUser> {
    static sortProperties: string[] = ['id'];

    @Selector()
    static getEntity(state: ExperienceUserStateModel, id: string = state.item): ExperienceUser {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntitiesAsMap(state: ExperienceUserStateModel): Map<string, ExperienceUser> {
        if (!state.map) {
            return null;
        }
        return state.map;
    }

    @Selector()
    static getEntities(state: ExperienceUserStateModel): ExperienceUser[] {
        if (!state.map) {
            return null;
        }

        const toReturn = Array.from(state.map.values());

        toReturn.sort(MaxBrainUtils.sortCompareFn(ExperienceUserState.sortProperties));

        return toReturn;
    }

    constructor(private experienceUserApiService: ExperienceUserApiService, private _userApiService: UserApiService, private _store: Store) {
        super(null, 0);
    }

    @Action(SetExperienceUser)
    setEntity(ctx: StateContext<ExperienceUserStateModel>, action: SetExperienceUser): void {
        this._setEntity(ctx, action, AddExperienceUsers);
    }

    @Action(UnsetExperienceUser)
    unsetEntity(ctx: StateContext<ExperienceUserStateModel>): void {
        this._unsetEntity(ctx);

        ctx.dispatch(new UnsetModuleParticipants());
    }

    @Action(AddExperienceUsers)
    addEntities(ctx: StateContext<ExperienceUserStateModel>, action: AddExperienceUsers): void {
        const users: User[] = [];
        const experiences: MaxBrainExperience[] = [];

        action.payload = action.payload.map((moduleUserExtended) => {
            if (moduleUserExtended.user) {
                if (users.map((user) => user.id).indexOf(moduleUserExtended.user.id) === -1) {
                    if (moduleUserExtended.user.alreadyMember !== true && moduleUserExtended.user.alreadyMember !== false) {
                        // this IF is needed because the moduleUserExtended.user that comes from the API is missing these properties
                        const user = UserState.getEntity(this._store.selectSnapshot(UserState), moduleUserExtended.user.id);

                        users.push(user ? Object.assign(moduleUserExtended.user, { analytics: user.analytics, alreadyMember: user.alreadyMember }) : moduleUserExtended.user);
                    } else {
                        users.push(moduleUserExtended.user);
                    }
                }

                delete moduleUserExtended.user;
            }

            if (moduleUserExtended.experience) {
                if (experiences.map((module) => module.id).indexOf(moduleUserExtended.experience.id) === -1) {
                    experiences.push(moduleUserExtended.experience);
                }

                delete moduleUserExtended.experience;
            }

            return moduleUserExtended;
        });

        ctx.dispatch(new AddUsers(users));
        ctx.dispatch(new AddExperiences(experiences));

        this._addEntities(ctx, action);
    }

    @Action(UpdateExperienceUser)
    updateEntity(ctx: StateContext<ExperienceUserStateModel>, action: UpdateExperienceUser): void {
        this._updateEntity(ctx, action, UpdateExperienceUserSuccess, UpdateExperienceUserFailure, AddExperienceUsers, this.experienceUserApiService.update(action.payload));
    }

    @Action(UpdateExperienceUserSuccess)
    updateExperienceUserSuccess({ dispatch }: StateContext<ExperienceUserStateModel>, { payload }: UpdateExperienceUserSuccess): void {
        dispatch([new FetchExperiences(), new UpdateExperienceUser(payload)]);
    }

    @Action(FetchExperienceUser)
    fetchEntity(ctx: StateContext<ExperienceUserStateModel>, action: FetchExperienceUser): void {
        this._fetchEntity(
            ctx,
            action,
            FetchExperienceUserSuccess,
            FetchExperienceUserFailure,
            AddExperienceUsers,
            SetExperienceUser,
            this.experienceUserApiService.read(action.payload)
        );
    }

    @Action(FetchExperienceUserSuccess)
    fetchEntitySuccess(ctx: StateContext<ExperienceUserStateModel>, action: FetchExperienceUserSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchExperienceUserFailure)
    fetchEntityFailure(ctx: StateContext<ExperienceUserStateModel>, action: FetchExperienceUserFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(AddUsersToExperiences)
    addUsersToExperiences({ dispatch }: StateContext<ExperienceUserStateModel>, { payload }: AddUsersToExperiences): void {
        this.experienceUserApiService.addUsersToExperiences(payload).subscribe(
            (entities) => {
                dispatch([new AddExperienceUsers(entities.newly_assigned), new AddUsersToExperiencesSuccess(entities)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new AddUsersToExperiencesFailure({ response }));
            }
        );
    }

    @Action(AssignUserToExperience)
    asignUserToExperience({ dispatch }: StateContext<ExperienceUserStateModel>, { payload }: AssignUserToExperience): void {
        this.experienceUserApiService.assignUserToExperience(payload).subscribe(
            (entities) => {
                dispatch(new AssignUserToExperienceSuccess(entities));
            },
            (response: HttpErrorResponse) => {
                dispatch(new AssignUserToExperienceFailure({ response }));
            }
        );
    }

    @Action(MoveExperienceUsers)
    moveAudienceMembers({ dispatch }: StateContext<ExperienceUserStateModel>, { payload }: MoveExperienceUsers): void {
        this.experienceUserApiService.moveUsersFromExperiences(payload).subscribe(
            (entities) => {
                dispatch(new MoveExperienceUsersSuccess(''));
            },
            (response: HttpErrorResponse) => {
                dispatch(new MoveExperienceUsersFailure({ response }));
            }
        );
    }

    @Action(DeleteExperienceUsers)
    removeUsersFromExperiences({ dispatch }: StateContext<ExperienceUserStateModel>, { payload }: DeleteExperienceUsers): void {
        this.experienceUserApiService.removeUsersFromExperiences(payload).subscribe(
            (entities) => {
                dispatch(new DeleteExperienceUsersSuccess(''));
            },
            (response: HttpErrorResponse) => {
                dispatch(new DeleteExperienceUsersFailure({ response }));
            }
        );
    }
}
