import { ModuleUserProgressStatus } from '../enums/module-user-progress-status.enum';
import { IModuleUserProgressQuery } from '../interfaces/module-user-progress.query.interface';
import { ModuleUserNextSection } from './module-user-next-section.model';

export class ModuleUserProgress {
    id: string;
    status: ModuleUserProgressStatus;
    completedSectionsCount: number;
    nextSection: ModuleUserNextSection;
    sectionCompleteness: string;
    coursesBooked: number;
    coursesCompleted: number;
    programProgress: number;
    completed: number;
    total: number;

    constructor(moduleUserProgressQuery: IModuleUserProgressQuery) {
        if (moduleUserProgressQuery) {
            this.id = moduleUserProgressQuery.id ? moduleUserProgressQuery.id.toString() : null;
            this.status = moduleUserProgressQuery.status;
            this.completedSectionsCount = moduleUserProgressQuery.completed_sections_count;
            this.nextSection = moduleUserProgressQuery.next_section ? new ModuleUserNextSection(moduleUserProgressQuery.next_section) : null;
            this.sectionCompleteness = moduleUserProgressQuery.section_completeness;
            this.coursesBooked = moduleUserProgressQuery.courses_booked;
            this.coursesCompleted = moduleUserProgressQuery.courses_completed;
            this.programProgress = moduleUserProgressQuery.program_progress;
            this.completed = moduleUserProgressQuery.completed;
            this.total = moduleUserProgressQuery.total;
        } else {
            this.id = null;
            this.status = null;
            this.completedSectionsCount = null;
            this.nextSection = null;
            this.sectionCompleteness = null;
            this.coursesBooked = null;
            this.coursesCompleted = null;
            this.programProgress = null;
            this.completed = null;
            this.total = null;
        }
    }
}
