import { Injectable } from '@angular/core';
import { TagColorCardComponent } from '../components/tag-color-card/tag-color-card.component';

@Injectable()
export class TagColorCardService {
    activeTagColorCard: TagColorCardComponent;

    toggle(tagColorCard: TagColorCardComponent, event?: MouseEvent): void {
        if (!this.activeTagColorCard) {
            this.activeTagColorCard = tagColorCard;
        } else if (this.activeTagColorCard !== tagColorCard) {
            this.activeTagColorCard.hide = true;
            this.activeTagColorCard = tagColorCard;
        }

        this.activeTagColorCard.hide = !this.activeTagColorCard.hide;
    }
}
