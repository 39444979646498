import { NotifyType } from 'app/projects/shared/src/lib/enums/notify-type.enum';
import { Module } from '../models/module.model';
import { RelevantExecution } from 'app/library/program-item/models/relevant-execution.model';

export class UpdateModuleStatus {
    static readonly type = '[Module] UpdateModuleStatus';

    constructor(
        public payload: {
            module: Module | RelevantExecution;
            notify?: NotifyType;
        }
    ) {}
}
