import { IEntity } from 'app/projects/entity/src/lib/interfaces/entity.interface';
import { IMessageQuery } from '../interfaces/message.query.interface';

export class Message implements IEntity {
    static label_singular = 'message';
    static label_plural = 'messages';

    static props_to_search = ['name'];

    id: string;
    status: string;
    errorInfo: { extra: any; message: string };
    title: string;
    imageUrl: string;
    entityId: string;
    entityType: string;
    asyncJob: { id: string };

    constructor(query?: IMessageQuery) {
        if (query) {
            this.id = query.id.toString();
            this.status = query.status;
            this.errorInfo = query.error_info;
            this.title = query.title;
            this.imageUrl = query.image_url;
            this.entityId = query.entity_id.toString();
            this.entityType = query.entity_type;
            this.asyncJob = query.async_job;
        }
    }
}
