import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { TagEditDialogComponent } from './components/dialogs/tag-edit/tag-edit.component';
import { TagColorCardComponent } from './components/tag-color-card/tag-color-card.component';
import { TagColorCardService } from './services/tag-color-card.service';
import { TagApiService } from './services/tag.api-service';
import { TagService } from './services/tag.service';

@NgModule({
    imports: [MaxBrainSharedModule, MatChipsModule, MatIconModule, MatToolbarModule, MatFormFieldModule, MatCardModule, MatButtonModule, MatInputModule],
    providers: [TagApiService, TagColorCardService, TagService],
    entryComponents: [TagEditDialogComponent, TagColorCardComponent],
    declarations: [TagEditDialogComponent, TagColorCardComponent],
    exports: [TagColorCardComponent],
})
export class TagModule {
    static forRoot(): ModuleWithProviders<TagModule> {
        return {
            ngModule: TagModule,
            providers: [TagApiService],
        };
    }
}
