<app-dialog-header [title]="dialogTitle" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div class="p-24" mat-dialog-content fxLayout="column" fxLayoutAlign="start">
    <span class="block basic-text mb-32" *ngIf="description">{{ description | translate }}</span>
    <mat-form-field>
        <input matInput [placeholder]="'GENERAL.PROPERTY.SUBJECT' | translate" [(ngModel)]="subject" />
    </mat-form-field>
    <div *ngIf="tinymceIsLoading" class="p-24 white" fxLayout="column" fxLayoutAlign="center center">
        <div class="p-24">
            <p class="mb-8">{{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}</p>
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <label class="mt-52 mb-8 basic-text block">{{ 'FORM.SUBJECT_TEXT.FIELD.MESSAGE' | translate }}</label>
    <editor [init]="init" [(ngModel)]="message" (onInit)="handleEventInit($event)"></editor>
</div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>
    <app-flat-button [label]="saveButtonText" (onClick)="save()" [disabled]="!subject || !message"></app-flat-button>
</div>
