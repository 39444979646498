import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ChatPanelModule } from 'app/layout/components/chat-panel/chat-panel.module';
import { ProgressDrawerModule } from 'app/library/content-node/components/progress-drawer/progress-drawer.module';
import { AsyncJobDrawerModule } from 'app/library/shared/async-job-drawer/async-job-drawer.module';
import { ChatDrawerModule } from 'app/library/shared/chat-drawer/chat-drawer.module';
import { FuseSidebarModule } from 'app/projects/fuse/src/lib/components/sidebar/sidebar.module';
import { FuseThemeOptionsModule } from 'app/projects/fuse/src/lib/components/theme-options/theme-options.module';
import { FuseSharedModule } from 'app/projects/fuse/src/lib/shared.module';
import { ContentModule } from '../../components/content/content.module';
import { FooterModule } from '../../components/footer/footer.module';
import { NavbarModule } from '../../components/navbar/navbar.module';
import { QuickPanelModule } from '../../components/quick-panel/quick-panel.module';
import { ToolbarModule } from '../../components/toolbar/toolbar.module';
import { HorizontalLayout1Component } from './layout-1.component';

@NgModule({
    declarations: [HorizontalLayout1Component],
    imports: [
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        ContentModule,
        FooterModule,
        NavbarModule,
        QuickPanelModule,
        ToolbarModule,
        ChatPanelModule,
        ChatDrawerModule,
        ProgressDrawerModule,
        AsyncJobDrawerModule,
    ],
    exports: [HorizontalLayout1Component],
})
export class HorizontalLayout1Module {}
