import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
    CreateModuleEventAttendance,
    CreateModuleEventAttendanceFailure,
    CreateModuleEventAttendanceSuccess,
    FetchModuleEventAttendance,
    FetchModuleEventAttendanceFailure,
    FetchModuleEventAttendanceSuccess,
    SetModuleEventAttendance,
    UnsetModuleEventAttendance,
    UpdateModuleEventAttendance,
    UpdateModuleEventAttendanceFailure,
    UpdateModuleEventAttendanceSuccess,
    SetModuleEventAttendances,
    FetchModuleEventAttendanceStatistics,
    FetchModuleEventAttendanceStatisticsSuccess,
    FetchModuleEventAttendanceStatisticsFailure,
    FetchParticipantAttendancesSuccess,
    FetchParticipantAttendances,
    FetchParticipantAttendancesFailure,
    RefreshModuleEventAttendanceUsersOnlineState,
} from '../actions';
import { AddModuleEventAttendances } from '../actions/add-entities.action';
import { ModuleEventAttendanceApiService } from '../services/module-event-attendance.api-service';
import { ModuleEventAttendance } from './module-event-attendance.model';
import { ModuleEventAttendanceStateModel } from './module-event-attendance.state-model';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import { HttpErrorResponse } from '@angular/common/http';
import { UserApiService } from 'app/projects/user/src/lib/services/user.api-service';
import { GlobalParticipant } from 'app/projects/user/src/lib/models/participant.model';

@State<ModuleEventAttendanceStateModel>({
    name: 'moduleEventAttendance',
    defaults: new ModuleEventAttendanceStateModel(),
})
@Injectable()
export class ModuleEventAttendanceState extends EntityState<ModuleEventAttendance> {
    static sortProperties: string[] = ['name'];

    @Selector()
    static getEntity(state: ModuleEventAttendanceStateModel, id: string = state.item): ModuleEventAttendance {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntities(state: ModuleEventAttendanceStateModel): ModuleEventAttendance[] {
        if (!state.map) {
            return null;
        }

        const toReturn = Array.from(state.map.values());

        toReturn.sort(MaxBrainUtils.sortCompareFn(ModuleEventAttendanceState.sortProperties));

        return toReturn;
    }

    constructor(private _moduleEventAttendanceApiService: ModuleEventAttendanceApiService, private _userApiService: UserApiService) {
        super(_moduleEventAttendanceApiService, 0);
    }

    @Action(AddModuleEventAttendances)
    addModuleEventAttendanceEntities(ctx: StateContext<ModuleEventAttendanceStateModel>, action: AddModuleEventAttendances): void {
        this._addEntities(ctx, action);
    }

    @Action(SetModuleEventAttendance)
    setEntity(ctx: StateContext<ModuleEventAttendanceStateModel>, action: SetModuleEventAttendance): void {
        this._setEntity(ctx, action, AddModuleEventAttendances);
    }

    @Action(UnsetModuleEventAttendance)
    unsetEntity(ctx: StateContext<ModuleEventAttendanceStateModel>): void {
        this._unsetEntity(ctx);
    }

    @Action(CreateModuleEventAttendance)
    createEntity(ctx: StateContext<ModuleEventAttendanceStateModel>, action: CreateModuleEventAttendance): void {
        this._createEntity(ctx, action, CreateModuleEventAttendanceSuccess, CreateModuleEventAttendanceFailure, AddModuleEventAttendances).then(() => {});
    }

    @Action(FetchModuleEventAttendance)
    fetchEntity(ctx: StateContext<ModuleEventAttendanceStateModel>, action: FetchModuleEventAttendance): void {
        this._fetchEntity(ctx, action, FetchModuleEventAttendanceSuccess, FetchModuleEventAttendanceFailure, AddModuleEventAttendances, SetModuleEventAttendance).then(() => {});
    }

    @Action(FetchModuleEventAttendanceSuccess)
    fetchEntitySuccess(ctx: StateContext<ModuleEventAttendanceStateModel>, action: FetchModuleEventAttendanceSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchModuleEventAttendanceFailure)
    fetchEntityFailure(ctx: StateContext<ModuleEventAttendanceStateModel>, action: FetchModuleEventAttendanceFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(SetModuleEventAttendances)
    setModuleEventAttendanceEntities(ctx: StateContext<ModuleEventAttendanceStateModel>, action: SetModuleEventAttendances): void {
        this._setEntities(ctx, action);
    }

    @Action(FetchModuleEventAttendanceStatistics)
    fetchModuleEventAttendanceStatistics(ctx: StateContext<ModuleEventAttendanceStateModel>, { moduleId }: FetchModuleEventAttendanceStatistics): void {
        this._moduleEventAttendanceApiService.getModuleEventAttendanceStatistics(moduleId).subscribe(
            (entities) => {
                ctx.dispatch(new FetchModuleEventAttendanceStatisticsSuccess(entities));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new FetchModuleEventAttendanceStatisticsFailure());
            }
        );
    }

    @Action(UpdateModuleEventAttendance)
    updateEntity(ctx: StateContext<ModuleEventAttendanceStateModel>, action: UpdateModuleEventAttendance): void {
        this._moduleEventAttendanceApiService.updateAttendanceStatistic(action.payload.eventId, action.payload.moduleEventAttendance).subscribe(
            (entity) => {
                this._addEntities(ctx, { payload: [entity] });
                ctx.dispatch(new UpdateModuleEventAttendanceSuccess(entity));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateModuleEventAttendanceFailure(response.error));
            }
        );
    }

    @Action(FetchParticipantAttendances)
    fetchParticipantAttendances(ctx: StateContext<ModuleEventAttendanceStateModel>, { payload }: FetchParticipantAttendances): void {
        this._moduleEventAttendanceApiService.getParticipantAttendances(payload.moduleId, payload.participantId, payload.sortBy, payload.sortOrder).subscribe(
            (data) => {
                ctx.dispatch(new FetchParticipantAttendancesSuccess(data));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new FetchParticipantAttendancesFailure());
            }
        );
    }

    @Action(RefreshModuleEventAttendanceUsersOnlineState)
    async refreshModuleAttendanceUsersOnlineState(ctx: StateContext<ModuleEventAttendanceStateModel>, { payload }: RefreshModuleEventAttendanceUsersOnlineState): Promise<void> {
        const ids = payload.map((moduleEventAttendance) => moduleEventAttendance.participant.id);
        let onlineStateResponse = await this._userApiService.getUserOnlineState(ids);

        let attendances = payload.map((attendance) => {
            onlineStateResponse.onlineState.forEach((onlineState) => {
                if (attendance.participant.id === onlineState.id) {
                    attendance = Object.assign(new ModuleEventAttendance(), attendance, {
                        participant: Object.assign(new GlobalParticipant(), attendance.participant, {
                            isOnline: onlineState.isOnline,
                        }),
                    });
                }
            });

            return attendance;
        });

        this._addEntities(ctx, { payload: attendances });
    }
}
