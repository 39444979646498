import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { errorUrlExcpentions } from 'app/constants/error-url-exceptions';
import { navigation } from 'app/navigation/navigation';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { MAXBRAIN_ERROR_URL_EXCEPTIONS } from 'app/projects/error/src/lib/services/error-url-exceptions.token';
import { MAXBRAIN_NAVIGATION } from 'app/services/navigation.token';
import { environment } from 'environments/environment';

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
})
export class ConfigModule {
    static forRoot(): ModuleWithProviders<ConfigModule> {
        return {
            ngModule: ConfigModule,
            providers: [
                {
                    provide: MAXBRAIN_ENVIRONMENT,
                    useValue: environment,
                },
                {
                    provide: MAXBRAIN_ERROR_URL_EXCEPTIONS,
                    useValue: errorUrlExcpentions,
                },
                {
                    provide: MAXBRAIN_NAVIGATION,
                    useValue: navigation,
                },
            ],
        };
    }
}
