import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContentModule } from '../../components/content/content.module';
import { FooterModule } from '../../components/footer/footer.module';
import { NavbarModule } from '../../components/navbar/navbar.module';
import { QuickPanelModule } from '../../components/quick-panel/quick-panel.module';
import { ToolbarModule } from '../../components/toolbar/toolbar.module';
import { VerticalLayout1Component } from './layout-1.component';
import { ChatPanelModule } from 'app/layout/components/chat-panel/chat-panel.module';
import { FuseSidebarModule } from 'app/projects/fuse/src/lib/components/sidebar/sidebar.module';
import { FuseSharedModule } from 'app/projects/fuse/src/lib/shared.module';

@NgModule({
    declarations: [VerticalLayout1Component],
    imports: [RouterModule, FuseSharedModule, FuseSidebarModule, ContentModule, FooterModule, NavbarModule, QuickPanelModule, ToolbarModule, ChatPanelModule],
    exports: [VerticalLayout1Component],
})
export class VerticalLayout1Module {}
