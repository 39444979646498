<app-dialog-header [title]="'EVENT.ATTENDANCE.EVENT_ATTENDANCE'" (onClick)="dialogRef.close(null)"></app-dialog-header>

<div mat-dialog-content fxLayout="column" fxLayoutAlign="start" class="p-24 pt-8">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-4">
        <div fxLayout="row" fxLayoutAlign="start center">
            <span class="mr-12">{{ 'GENERAL.LABEL.FILTER_BY' | translate }}:</span>

            <app-white-button
                class="mr-8"
                *ngFor="let attendanceStatus of attendanceStatuses"
                [isActive]="attendanceStatus.isSelected"
                [label]="attendanceStatus.translationLabel"
                (onClick)="selectAttendanceStatus(attendanceStatus)"
            ></app-white-button>
        </div>

        <app-search-outline [searchInput]="searchInput"></app-search-outline>
    </div>

    <app-module-event-attendance-list
        [eventId]="eventId"
        [filteredModuleEventAttendances$]="filteredModuleEventAttendances$"
        [moduleEventAttendancesStateSearchTerm$]="moduleEventAttendancesStateSearchTerm$"
        [selectedAttendanceStatus$]="selectedAttendanceStatus$"
    ></app-module-event-attendance-list>
</div>
