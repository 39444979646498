import { Job } from './job.model';

export class JobCommand {
    id: number;
    type: string;
    communicated_status: string;
    is_read: boolean;
    closed: boolean;
    name: string;
    entity_id: number;
    entity_type: string;

    constructor(asyncJob: Job) {
        this.id = Number(asyncJob.id);
        this.type = asyncJob.type;
        this.communicated_status = asyncJob.communicatedStatus;
        this.is_read = asyncJob.isRead;
        this.name = asyncJob.name;
        this.entity_id = Number(asyncJob.entityId);
        this.entity_type = asyncJob.entityType;
    }
}
