import { IExtendedAttributeOptionQuery } from '../interfaces/extended-attribute-option.query.interface';

export class ExtendedAttributeOption {
    id: string = '0';
    valueNumber: number = null;
    valueText: string = '';
    sort: number = 0;
    translationKey: string = '';
    'translation_en-GB': string;
    'translation_de-DE': string;
    'translation_fr-FR': string;
    'translation_it-CH': string;
    missingTranslation: boolean = false;

    defaultTranslation: string;

    constructor(query?: IExtendedAttributeOptionQuery) {
        if (query) {
            this.id = query.id.toString();
            this.valueNumber = query.value_number;
            this.valueText = query.value_text;
            this.sort = query.sort;
            this.translationKey = query.translation_key;
            if (query.translations) {
                if (query.translations['translation_en-GB']) {
                    this['translation_en-GB'] = query.translations['translation_en-GB'];
                }
                if (query.translations['translation_de-DE']) {
                    this['translation_de-DE'] = query.translations['translation_de-DE'];
                }
                if (query.translations['translation_fr-FR']) {
                    this['translation_fr-FR'] = query.translations['translation_fr-FR'];
                }
                if (query.translations['translation_it-CH']) {
                    this['translation_it-CH'] = query.translations['translation_it-CH'];
                }
            }
            this.missingTranslation = query.missing_translation;
        }
    }
}
