<app-dialog-header [title]="dialogTitle" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div class="p-24" mat-dialog-content fxLayout="column" fxLayoutAlign="start">
    <span class="block basic-text mb-32" *ngIf="description">{{ description | translate }}</span>
    <div class="publish-options-wrapper" fxLayout="column" fxLayoutAlign="center start">
        <mat-radio-group fxLayout="row" fxLayoutAlign="space-between center" [formControl]="notifyOption" (change)="optionChange($event)" class="publish-options w-100-p mb-32">
            <div fxLayout="column" fxLayoutAlign="center" [class.narrow]="isUrgentOptionVisible && isScheduleOptionVisible" class="publish-option">
                <mat-radio-button [value]="'send'">
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <img src="/assets/icons/ic_send.svg" />
                        <span>{{ actionNowText | translate }}</span>
                    </div>
                </mat-radio-button>
            </div>
            <div fxLayout="column" fxLayoutAlign="center" [class.narrow]="isUrgentOptionVisible && isScheduleOptionVisible" class="publish-option" *ngIf="isScheduleOptionVisible">
                <mat-radio-button [value]="'schedule'">
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <img src="/assets/icons/ic_scheduled_module.svg" />
                        <span>{{ 'MODULE.SCHEDULER.SCHEDULE' | translate }}</span>
                    </div>
                </mat-radio-button>
            </div>
            <div fxLayout="column" fxLayoutAlign="center" [class.narrow]="isUrgentOptionVisible && isScheduleOptionVisible" class="publish-option" *ngIf="isUrgentOptionVisible">
                <mat-radio-button [value]="'urgent'">
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <img src="/assets/icons/ic_bolt.svg" />
                        <span>{{ urgentOptionText | translate }}</span>
                    </div>
                </mat-radio-button>
            </div>
        </mat-radio-group>
        <div class="schedule-time-wrapper" *ngIf="isScheduled" class="mb-24">
            <h3>{{ 'MODULE.SCHEDULER.SCHEDULE' | translate }}</h3>
            <form [formGroup]="scheduleForm" fxLayout="row" fxLayoutAlign="space-between" class="mt-8">
                <mat-form-field fxFlex="0 0 47%">
                    <input
                        matInput
                        name="scheduleDate"
                        [min]="minDate"
                        formControlName="scheduleDate"
                        [matDatepicker]="pickerScheduleDate"
                        [placeholder]="'GENERAL.PROPERTY.DATE' | translate"
                        (focus)="pickerScheduleDate.open()"
                    />
                    <mat-datepicker-toggle matSuffix [for]="pickerScheduleDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerScheduleDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field fxFlex="0 0 47%">
                    <input matInput [cleave]="{ time: true, timePattern: ['h', 'm'] }" formControlName="scheduleTime" [placeholder]="'GENERAL.PROPERTY.TIME' | translate" />
                    <mat-icon class="timepicker-icon">access_time</mat-icon>
                </mat-form-field>
            </form>
        </div>
    </div>
    <mat-form-field *ngIf="subject !== undefined" class="mt-8 mb-8">
        <input matInput [placeholder]="'GENERAL.PROPERTY.SUBJECT' | translate" [(ngModel)]="subjectValue" />
    </mat-form-field>
    <mat-checkbox *ngIf="isNotificationCheckboxVisible" [formControl]="sendNotification" (change)="notificationCheckboxChange($event)">
        {{ 'MODULE.SCHEDULER.SEND_NOTIFICATION' | translate }}
    </mat-checkbox>
    <ng-container *ngIf="keywords.length">
        <div>
            <h3 class="my-16">{{ 'GENERAL.LABEL.MESSAGE' | translate }}</h3>
            <span>{{ 'GENERAL.LABEL.KEYWORDS_YOU_CAN_USE_IN_YOUR_MESSAGE' | translate }}:</span>
            <div class="keywords-wrapper">
                <div class="keyword-tag pointer" *ngFor="let keyword of keywords" (click)="addKeywordToEditor(keyword)">
                    <span>{{ keyword }}</span>
                    <mat-icon svgIcon="keyword_tag"></mat-icon>
                </div>
            </div>
            <ng-container *ngIf="!keywords.length && !tinymceIsLoading">
                <div class="white" fxLayout="column" fxLayoutAlign="center center">
                    <div>
                        <mat-spinner diameter="24"></mat-spinner>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <div *ngIf="tinymceIsLoading" class="p-24 white" fxLayout="column" fxLayoutAlign="center center">
        <div class="p-24">
            <p class="mb-8">{{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}</p>
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div [class.mt-32]="dialogType === ScheduleDialogType.NotifyAll" *ngIf="(isNotificationCheckboxVisible && sendNotification.value) || !isNotificationCheckboxVisible">
        <div fxLayoutAlign="space-between center">
            <label class="mt-24 mb-8 basic-text block">{{ 'FORM.SUBJECT_TEXT.FIELD.MESSAGE' | translate }}</label>

            <app-white-button
                *ngIf="dialogType === ScheduleDialogType.NotifyAll && isUserTenantAdmin"
                [label]="'GENERAL.BUTTON.SAVE_AS_DEFAULT_TEXT'"
                (onClick)="saveAsDefaultText()"
            ></app-white-button>
        </div>
        <editor [init]="init" [(ngModel)]="message" (onInit)="handleEventInit($event)"></editor>
    </div>
</div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="flex-start center">
        <app-flat-button class="mr-8" [color]="'warn'" [label]="'MODULE.SCHEDULER.BUTTON.REMOVE_SCHEDULE'" (onClick)="removeSchedule()" *ngIf="scheduledJob"></app-flat-button>
        <app-flat-button [label]="'GENERAL.LABEL.SEND_IMMEDIATELY'" (onClick)="save(true)" *ngIf="!isScheduled && isSendUrgentButtonVisible"></app-flat-button>
    </div>
    <div fxLayout="row">
        <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>

        <app-flat-button
            [label]="isScheduled ? ('GENERAL.BUTTON.SAVE' | translate) : (saveButtonText | translate)"
            [disabled]="
                ((dialogType === ScheduleDialogType.SurveyReminder ||
                    dialogType === ScheduleDialogType.NotifyAll ||
                    dialogType === ScheduleDialogType.NotifySelected ||
                    dialogType === ScheduleDialogType.PasswordlessAccess) &&
                    (!subjectValue || !message)) ||
                !scheduleForm.valid
            "
            (onClick)="save()"
        ></app-flat-button>
    </div>
</div>
