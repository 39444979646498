import { IFinalGradeQuery } from 'app/library/final-grade/interfaces/final-grade.query.interface';
import { FinalGrade } from 'app/library/final-grade/models/final-grade.model';
import { Module } from 'app/library/module/models/module.model';
import { newModule } from 'app/library/module/models/newModule';
import { User } from 'app/projects/user/src/lib/models/user';
import { IModuleUserQuery } from '../interfaces/module-user.query.interface';
import { ModuleUser } from './module-user.model';

export class ModuleUserExtended extends ModuleUser {
    module: Module;
    user: User;

    constructor(moduleUser?: IModuleUserQuery, finalGrade?: IFinalGradeQuery) {
        super(moduleUser);

        if (moduleUser) {
            this.module = newModule(moduleUser.module);
            this.user = new User(moduleUser.user);
            this.certificates = moduleUser.certificates;
            this.userFinalGrade = new FinalGrade(finalGrade);
        } else {
            this.module = null;
            this.user = null;
            this.certificates = [];
            this.userFinalGrade = null;
        }
    }
}
