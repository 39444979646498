import { IHeaderStreamQuery } from 'app/library/grow/interfaces/header-stream.query.interface';

// @dynamic
export class HeaderStream {
    id: string;
    name: string;
    description: string;
    type: string;
    featuredStream: {
        id: string;
        maxVisibleLength: number;
    };

    constructor(query?: IHeaderStreamQuery) {
        if (query) {
            this.id = query.id.toString();
            this.name = query.name;
            this.description = query.description;
            this.type = query.type;
            if (query.featured_stream) {
                this.featuredStream = {
                    id: query.featured_stream.id.toString(),
                    maxVisibleLength: query.featured_stream.max_visible_length,
                };
            }
        }
    }
}
