import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseSharedModule } from 'app/projects/fuse/src/lib/shared.module';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { ProgressDrawerComponent } from './progress-drawer.component';

@NgModule({
    imports: [MaxBrainSharedModule, FuseSharedModule, MatExpansionModule, MatListModule, MatProgressSpinnerModule, MatIconModule],
    declarations: [ProgressDrawerComponent],
    exports: [ProgressDrawerComponent],
})
export class ProgressDrawerModule {}
