import { SectionType } from '../enums/section-type.enum';
import { ISectionQuery } from '../interfaces/section.query.interface';
import { Section } from './section.model';

export class ELearningSection extends Section {
    courseImportJobId: string;
    courseId: string;

    constructor(query?: ISectionQuery) {
        super(query);

        this._type = SectionType.ELearning;

        if (query) {
            this.courseImportJobId = query.course_import_job_id;
            this.courseId = query.course_id;
        }
    }
}
