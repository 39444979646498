export class ChangeEmailAddressSuccess {
    static readonly type = '[User] ChangeEmailAddressSuccess';

    constructor() {}
}

export class ChangeNotificationEmailAddressSuccess {
    static readonly type = '[User] ChangeNotificationEmailAddressSuccess';

    constructor() {}
}
