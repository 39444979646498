import { IEntity } from 'app/projects/entity/src/lib/interfaces/entity.interface';

export class SocialNetworkCommand {
    user: IEntity;
    constructor({ id }: IEntity, public value: string) {
        this.user = {
            id,
        };
    }
}
