import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IFinalGradeMetricsQuery } from 'app/library/final-grade/interfaces/final-grade-metric.query.interface';
import { FinalGradeMetrics } from 'app/library/final-grade/models/final-grade-metric.model';
import { PasswordlessModuleSettings } from 'app/library/module-passwordless-access/models/passwordless-module-settings.model';
import { IParticipantRegistrationMetricQuery } from 'app/library/participant-registration-metric/interfaces/participant-registration-metric.query.interface';
import { ParticipantRegistrationMetric } from 'app/library/participant-registration-metric/models/participant-registration-metric.model';
import { IPasswordlessModuleSettingsQuery } from 'app/library/passwordless-access/interfaces/passwordless-module-settings.query.interface';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { FileCommand } from 'app/projects/core/src/lib/models/file.command';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { IEntityApiService } from 'app/projects/entity/src/lib/interfaces/entity.service.interface';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { NotifyType } from 'app/projects/shared/src/lib/enums/notify-type.enum';
import { Observable, of, zip } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ModuleKind } from '../enums/module-kind.enum';
import { ICopyModuleToNewActionPayload } from '../interfaces/copy-module-to-new.action-payload.interface';
import { ICourseStrcutureModuleBreadcrumbQuery } from '../interfaces/course-structure-module-breadcrumb.interface';
import { IModuleQuery } from '../interfaces/module.query.interface';
import { INotifyAllParticipantsActionPayload } from '../interfaces/notfiy-all.action.interface';
import { INotifySelectedParticipantsActionPayload } from '../interfaces/notfiy-selected.action.interface';
import { ISlackQuery } from '../interfaces/slack.query.interface';
import { ITenantAdminModuleStatsQuery } from '../interfaces/tenant-admin-module-stats.query.interface';
import { CompletionRuleCommand } from '../models/completion-rule.command';
import { CopyModuleToNewCommand } from '../models/copy-module-to-new.command';
import { CourseStructureModuleBreadcrumb } from '../models/course-structure-module-breadcrumb.model';
import { ModuleStatusCommand } from '../models/module-status.command';
import { ModuleCommand } from '../models/module.command.model';
import { Module } from '../models/module.model';
import { newModule } from '../models/newModule';
import { SlackCommand } from '../models/slack.command.model';
import { TenantAdminModuleStats } from '../models/tenant-admin-module-stats.model';
import { RelevantExecution } from 'app/library/program-item/models/relevant-execution.model';

@Injectable()
export class ModuleApiService extends EntityService<IModuleQuery, ModuleCommand> implements IEntityApiService<Module> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'modules');
    }

    getAll(): Observable<Module[]> {
        return this._getAll().pipe(map((queries) => queries.map((query) => newModule(query))));
    }

    create(entity: Module): Observable<Module> {
        const moduleData = new ModuleCommand(entity);
        return this._create(moduleData).pipe(map((moduleQuery) => newModule(moduleQuery)));
    }

    getSlack(id: string): Observable<ISlackQuery> {
        return this.http.get<ISlackQuery>(`${this.apiUrl}/slack/channel/modules/${id}`).pipe(catchError((error) => of({ id: null, channel: '' } as ISlackQuery)));
    }

    async getOnlyModule(id: string): Promise<Module> {
        return zip(this._read(id))
            .pipe(map((value) => Object.assign<Module, Partial<Module>>(newModule(value[0]), {})))
            .toPromise();
    }

    read(id: string): Observable<Module> {
        return zip(this._read(id), this.getSlack(id)).pipe(map((value) => Object.assign<Module, Partial<Module>>(newModule(value[0]), { slackChannel: value[1].channel })));
    }

    setSlack(id: string, slackData: SlackCommand): Observable<ISlackQuery> {
        return this.http.post<ISlackQuery>(`${this.apiUrl}/slack/channel/modules/${id}`, slackData).pipe(catchError((error) => of({ id: null, channel: '' } as ISlackQuery)));
    }

    update(entity: Module): Observable<Module> {
        const moduleData = new ModuleCommand(entity);
        const slackData = new SlackCommand(entity);

        if (entity.kind !== ModuleKind.Template) {
            return zip(this._update(entity.id, moduleData), this.setSlack(entity.id, slackData)).pipe(
                map((value) => Object.assign<Module, Partial<Module>>(newModule(value[0]), { slackChannel: value[1].channel }))
            );
        } else {
            return zip(this._update(entity.id, moduleData)).pipe(map((value) => newModule(value[0])));
        }
    }

    updateStatus(entity: Module | RelevantExecution, notify?: NotifyType): Observable<Module> {
        const data = new ModuleStatusCommand(entity, notify);

        return this.http.patch<IModuleQuery>(`${this.apiUrl}/${this.entities}/${entity.id}/status`, data).pipe(map((query) => newModule(query)));
    }

    getMinimumAttendance(id: string): Observable<{ minimumAttendance: number }> {
        return this.http.get<{ minimum_attendance: number }>(`${this.apiUrl}/${this.entities}/${id}/minimum-attendance`).pipe(
            map((query) => {
                return { minimumAttendance: query.minimum_attendance };
            })
        );
    }

    getTenantAdminModuleStats(id: string): Observable<TenantAdminModuleStats> {
        return this.http.get<ITenantAdminModuleStatsQuery>(`${this.apiUrl}/${this.entities}/${id}/stats`).pipe(map((query) => new TenantAdminModuleStats(query)));
    }

    updateMinimumAttendance(id: string, minimumAttendance: number): Observable<Module> {
        const data = {
            minimum_attendance: minimumAttendance,
        };

        return this.http.patch<IModuleQuery>(`${this.apiUrl}/${this.entities}/${id}/minimum-attendance`, data).pipe(map((query) => newModule(query)));
    }

    updateCompletionRule(entity: Module): Observable<Module> {
        const data = new CompletionRuleCommand(entity);

        return this.http.put<IModuleQuery>(`${this.apiUrl}/${this.entities}/${entity.id}`, data).pipe(map((query) => newModule(query)));
    }

    updateContent(entity: Module): Observable<Module> {
        const data = {
            section: entity.hasSections,
        };

        return this.http.patch<IModuleQuery>(`${this.apiUrl}/${this.entities}/${entity.id}/section`, data).pipe(map((query) => newModule(query)));
    }

    delete(id: string): Observable<any> {
        return this._delete(id);
    }

    notifyAll(payload: INotifyAllParticipantsActionPayload, id: string): Observable<any> {
        return this.http.post<IModuleQuery>(`${this.apiUrl}/${this.entities}/${id}/notification`, payload);
    }

    notifySelected(payload: INotifySelectedParticipantsActionPayload, id: string): Observable<any> {
        return this.http.post<IModuleQuery>(`${this.apiUrl}/${this.entities}/${id}/notify-users`, payload);
    }

    fetchNotificationKeywords(): Observable<string[]> {
        return this.http.get<string[]>(`${this.apiUrl}/${this.entities}/notification/keywords`);
    }

    saveDefaultText(defaultText: string): Observable<{ default_text: string }> {
        return this.http.put<{ default_text: string }>(`${this.apiUrl}/${this.entities}/notification/default-text`, { default_text: defaultText });
    }

    export(id: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/export/module/${id}`, {}, { responseType: 'blob' }).pipe(tap((file) => MaxBrainUtils.downloadFile(file, 'import')));
    }

    import(payload: File): Observable<Module> {
        const data = new FileCommand('file', new File([payload], '', { type: 'text/plain' }), payload.name).formData;

        return this.http.post<IModuleQuery>(`${this.apiUrl}/import/module`, data).pipe(map((query) => newModule(query)));
    }

    metrics(id: string): Observable<FinalGradeMetrics> {
        return this.http.get<IFinalGradeMetricsQuery>(`${this.apiUrl}/${this.entities}/${id}/metrics`).pipe(map((query) => new FinalGradeMetrics(query)));
    }

    getTargetModules(module: Module): Observable<Module[]> {
        return this.http.get<IModuleQuery[]>(`${this.apiUrl}/${this.entities}/target?type=${module.type}`).pipe(map((queries) => queries.map((query) => newModule(query))));
    }

    copyModuleToExisting(source_module_id: number, target_module_id: number): Observable<{ id: string; async_job_id: string }> {
        const data = {
            source_module_id,
            target_module_id,
        };
        return this.http.post<{ id: string; async_job_id: string }>(`${this.apiUrl}/${this.entities}/copy-to-existing`, data);
    }

    convertModuleToExecution(sourceModuleId: string, courseOfferingId: string): Observable<Module> {
        const data = {
            course_offering: { id: Number(courseOfferingId) },
        };
        return this.http.post<IModuleQuery>(`${this.apiUrl}/course-structure/modules/${sourceModuleId}/convert-to-execution`, data).pipe(map((query) => newModule(query)));
    }

    convertExecutionToModule(sourceModuleId: string): Observable<Module> {
        return this.http.post<IModuleQuery>(`${this.apiUrl}/course-structure/executions/${sourceModuleId}/convert-to-module`, {}).pipe(map((query) => newModule(query)));
    }

    getCourseStructureModuleBreadcrumbs(moduleId: string): Observable<CourseStructureModuleBreadcrumb[]> {
        return this.http
            .get<ICourseStrcutureModuleBreadcrumbQuery[]>(`${this.apiUrl}/course-structure/modules/${moduleId}/breadcrumbs`)
            .pipe(map((queries) => queries.map((query) => new CourseStructureModuleBreadcrumb(query))));
    }

    copyModuleToNew(payload: ICopyModuleToNewActionPayload): Observable<{ id: string; async_job_id: string }> {
        const data = new CopyModuleToNewCommand(payload);
        return this.http.post<{ id: string; async_job_id: string }>(`${this.apiUrl}/${this.entities}/copy-to-new`, data);
    }

    copyELearningModuleToNew(payload: ICopyModuleToNewActionPayload): Observable<{ id: string; async_job_id: string }> {
        const data = new CopyModuleToNewCommand(payload);
        return this.http.post<{ id: string; async_job_id: string }>(`${this.apiUrl}/e-learning/${this.entities}/copy-to-new`, data);
    }

    createELearningModule(entity: Module): Observable<Module> {
        const moduleData = new ModuleCommand(entity);
        return this.http.post<IModuleQuery>(`${this.apiUrl}/e-learning/modules`, moduleData).pipe(map((query) => newModule(query)));
    }

    updateELearningModule(entity: Module): Observable<Module> {
        const moduleData = new ModuleCommand(entity);
        const slackData = new SlackCommand(entity);

        return zip(this.http.put<IModuleQuery>(`${this.apiUrl}/e-learning/modules/${entity.id}`, moduleData), this.setSlack(entity.id, slackData)).pipe(
            map((value) => Object.assign<Module, Partial<Module>>(newModule(value[0]), { slackChannel: value[1].channel }))
        );
    }

    getModuleLaunchCourseUrl(moduleId: string): Observable<{ launchLink: string }> {
        return this.http.get<any>(`${this.apiUrl}/e-learning/modules/${moduleId}/launch-link`);
    }

    getModuleParticipantRegistrationData(moduleId: string): Observable<ParticipantRegistrationMetric> {
        return this.http
            .get<IParticipantRegistrationMetricQuery>(`${this.apiUrl}/e-learning/modules/${moduleId}/registration-metrics/participant`)
            .pipe(map((query) => new ParticipantRegistrationMetric(query)));
    }

    removeModuleImage(moduleId: string, moduleType: string): Observable<Module> {
        const data = {
            image: null,
        };
        if (moduleType === 'e_learning') {
            return this.http.put<IModuleQuery>(`${this.apiUrl}/e-learning/modules/${moduleId}`, data).pipe(map((query) => newModule(query)));
        } else if (moduleType === 'basic') {
            return this.http.put<IModuleQuery>(`${this.apiUrl}/modules/${moduleId}`, data).pipe(map((query) => newModule(query)));
        }
    }

    patchSectionModule(moduleId: string, isEnable: boolean): Observable<Module> {
        return this.http.patch<IModuleQuery>(`${this.apiUrl}/modules/${moduleId}/section`, { section: isEnable }).pipe(map((query) => newModule(query)));
    }

    getCourseOfferingUrl(moduleId: string): Observable<{ url: string }> {
        return this.http.get<{ url: string }>(`${this.apiUrl}/csr/modules/${moduleId}/course-offering`);
    }

    downloadModuleParticipantList(module: Module): Observable<Blob> {
        return this.http
            .get(`${this.apiUrl}/${this.entities}/${module.id}/participant-list`, { responseType: 'blob' })
            .pipe(tap((file) => MaxBrainUtils.downloadFile(file, `${module.name}_participants.xlsx`)));
    }

    async getPasswordlessLoginSettings(moduleId: string): Promise<PasswordlessModuleSettings> {
        return this.http
            .get<IPasswordlessModuleSettingsQuery>(`${this.apiUrl}/modules/${moduleId}/passwordless-login-settings`)
            .pipe(map((query) => new PasswordlessModuleSettings(query)))
            .toPromise();
    }

    savePasswordlessLoginSettings(moduleId: string, daysBeforeStart: number, daysAfterEnd: number): Promise<PasswordlessModuleSettings> {
        return this.http
            .patch<IPasswordlessModuleSettingsQuery>(`${this.apiUrl}/modules/${moduleId}/passwordless-login-settings`, {
                days_before_start: daysBeforeStart,
                days_after_end: daysAfterEnd,
            })
            .pipe(map((query) => new PasswordlessModuleSettings(query)))
            .toPromise();
    }
}
