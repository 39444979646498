import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { IEntityApiService } from 'app/projects/entity/src/lib/interfaces/entity.service.interface';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IExperienceQuery } from '../interfaces/experience.query.interface';
import { ExperienceCommand } from '../models/experience.command.model';
import { MaxBrainExperience } from '../models/experience.model';

@Injectable()
export class ExperienceApiService extends EntityService<IExperienceQuery, ExperienceCommand> implements IEntityApiService<MaxBrainExperience> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'engage/experiences');
    }

    getAll(): Observable<MaxBrainExperience[]> {
        return this._getAll().pipe(map((queries) => queries.map((query) => new MaxBrainExperience(query))));
    }

    create(entity: MaxBrainExperience): Observable<MaxBrainExperience> {
        const data = new ExperienceCommand(entity);

        return this._create(data).pipe(map((query) => new MaxBrainExperience(query)));
    }

    read(id: string): Observable<MaxBrainExperience> {
        return this._read(id).pipe(map((query) => new MaxBrainExperience(query)));
    }

    update(entity: MaxBrainExperience): Observable<MaxBrainExperience> {
        const data = new ExperienceCommand(entity);
        return this.http.patch<IExperienceQuery>(`${this.apiUrl}/${this.entities}/${entity.id}`, data).pipe(map((query) => new MaxBrainExperience(query)));
    }

    delete(id: string): Observable<any> {
        return this._delete(id);
    }

    getGrowKeywords(): Observable<string[]> {
        return this.http.get<string[]>(`${this.apiUrl}/engage/keywords`);
    }
}
