import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-search-outline',
    templateUrl: './search-outline.component.html',
    styleUrls: ['./search-outline.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SearchOutlineComponent {
    @Input()
    searchInput: FormControl;

    @Input()
    label: string = '';

    constructor() {}
}
