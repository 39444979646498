import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { UnsetModuleParticipants } from 'app/library/module-participants/actions';
import { DeleteModulePersonalParticipants } from 'app/library/module-personal-participants/actions';
import { AddModules } from 'app/library/module/actions/add-entities.action';
import { UpdateModuleResponsibleManager } from 'app/library/module/actions/update-module-responsible-manager.action';
import { UpdateTemplateResponsibleManager } from 'app/library/module/actions/update-template-responsible-manager.action';
import { Module } from 'app/library/module/models/module.model';
import { FetchMyModules } from 'app/library/my-profile-modules/actions';
import { FetchTemplateResponsibleManagers } from 'app/library/template-managers/actions';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import { AddUsers } from 'app/projects/user/src/lib/actions/add-entities.action';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { UserApiService } from 'app/projects/user/src/lib/services/user.api-service';
import {
    AddSelfAsParticipant,
    AddUsersToCourseOfferings,
    AddUsersToCourseOfferingsFailure,
    AddUsersToCourseOfferingsSuccess,
    AddUsersToModulesAsManagers,
    AddUsersToModulesAsManagersFailure,
    AddUsersToModulesAsManagersSuccess,
    AddUsersToModulesAsParticipants,
    AddUsersToModulesAsParticipantsFailure,
    AddUsersToModulesAsParticipantsSuccess,
    AddUsersToProgramExecutionsAsParticipants,
    AddUsersToProgramExecutionsAsParticipantsFailure,
    AddUsersToProgramExecutionsAsParticipantsSuccess,
    AddUsersToTemplatesAsManagers,
    AddUsersToTemplatesAsManagersFailure,
    AddUsersToTemplatesAsManagersSuccess,
    FetchModuleUser,
    FetchModuleUserFailure,
    FetchModuleUserSuccess,
    FetchResponsibleRoles,
    FetchResponsibleRolesFailure,
    FetchResponsibleRolesSuccess,
    RefreshModuleUsersOnlineState,
    RemoveSelfAsParticipant,
    RemoveTemplateUserAsResponsible,
    RemoveTemplateUserAsResponsibleFailure,
    RemoveTemplateUserAsResponsibleSuccess,
    RemoveUsersFromModulesAsManagers,
    RemoveUsersFromModulesAsManagersFailure,
    RemoveUsersFromModulesAsManagersSuccess,
    RemoveUsersFromModulesAsParticipants,
    RemoveUsersFromModulesAsParticipantsFailure,
    RemoveUsersFromModulesAsParticipantsSuccess,
    RemoveUsersFromTemplatesAsManagers,
    RemoveUsersFromTemplatesAsManagersFailure,
    RemoveUsersFromTemplatesAsManagersSuccess,
    SetModuleUser,
    UnsetModuleUser,
    UpdateModuleManagerPermissions,
    UpdateModuleManagerPermissionsFailure,
    UpdateModuleManagerPermissionsSuccess,
    UpdateModuleSectionProgressStatus,
    UpdateModuleSectionProgressStatusFailure,
    UpdateModuleSectionProgressStatusSuccess,
    UpdateModuleUser,
    UpdateModuleUserFailure,
    UpdateModuleUserPinnedStatus,
    UpdateModuleUserPinnedStatusFailure,
    UpdateModuleUserPinnedStatusSuccess,
    UpdateModuleUserSuccess,
    UpdateTemplateUser,
    UpdateTemplateUserResponsible,
    UpdateTemplateUserResponsibleFailure,
    UpdateTemplateUserResponsibleSuccess,
    UpdateTemplateUserSuccess,
} from '../actions';
import { AddModuleUsers } from '../actions/add-entities.action';
import {
    RemoveUserAsResponsible,
    RemoveUserAsResponsibleFailure,
    RemoveUserAsResponsibleSuccess,
    UpdateModuleUserResponsible,
    UpdateModuleUserResponsibleFailure,
    UpdateModuleUserResponsibleSuccess,
} from '../actions/index';
import { ModuleUserApiService } from '../services/module-user.api-service';
import { ModuleUserExtended } from './module-user.extended.model';
import { ModuleUser } from './module-user.model';
import { ModuleUserStateModel } from './module-user.state-model';
import { FetchModuleResponsibles } from 'app/library/module-responsibles/actions';

@State<ModuleUserStateModel>({
    name: 'moduleUser',
    defaults: new ModuleUserStateModel(),
})
@Injectable()
export class ModuleUserState extends EntityState<ModuleUser> {
    static sortProperties: string[] = ['id'];

    @Selector()
    static getEntity(state: ModuleUserStateModel, id: string = state.item): ModuleUser {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntitiesAsMap(state: ModuleUserStateModel): Map<string, ModuleUser> {
        if (!state.map) {
            return null;
        }
        return state.map;
    }

    @Selector()
    static getEntities(state: ModuleUserStateModel): ModuleUser[] {
        if (!state.map) {
            return null;
        }

        const toReturn = Array.from(state.map.values());

        toReturn.sort(MaxBrainUtils.sortCompareFn(ModuleUserState.sortProperties));

        return toReturn;
    }

    constructor(private moduleUserApiService: ModuleUserApiService, private _userApiService: UserApiService, private _store: Store) {
        super(null, 30);
    }

    @Action(SetModuleUser)
    setEntity(ctx: StateContext<ModuleUserStateModel>, action: SetModuleUser): void {
        this._setEntity(ctx, action, AddModuleUsers);
    }

    @Action(UnsetModuleUser)
    unsetEntity(ctx: StateContext<ModuleUserStateModel>): void {
        this._unsetEntity(ctx);
        ctx.setState(new ModuleUserStateModel());

        ctx.dispatch(new UnsetModuleParticipants());
    }

    @Action(AddModuleUsers)
    addEntities(ctx: StateContext<ModuleUserStateModel>, action: AddModuleUsers): void {
        const users: User[] = [];
        const modules: Module[] = [];

        action.payload = action.payload.map((moduleUserExtended) => {
            if (moduleUserExtended.user) {
                if (users.map((user) => user.id).indexOf(moduleUserExtended.user.id) === -1) {
                    if (moduleUserExtended.user.alreadyMember !== true && moduleUserExtended.user.alreadyMember !== false) {
                        // this IF is needed because the moduleUserExtended.user that comes from the API is missing these properties
                        const user = UserState.getEntity(this._store.selectSnapshot(UserState), moduleUserExtended.user.id);

                        users.push(user ? Object.assign(moduleUserExtended.user, { analytics: user.analytics, alreadyMember: user.alreadyMember }) : moduleUserExtended.user);
                    } else {
                        users.push(moduleUserExtended.user);
                    }
                }

                // delete moduleUserExtended.user;
            }

            if (moduleUserExtended.module) {
                if (modules.map((module) => module.id).indexOf(moduleUserExtended.module.id) === -1) {
                    modules.push(moduleUserExtended.module);
                }

                // delete moduleUserExtended.module;
            }

            return moduleUserExtended;
        });

        ctx.dispatch(new AddUsers(users));
        ctx.dispatch(new AddModules(modules));

        this._addEntities(ctx, action);
    }

    @Action(UpdateModuleSectionProgressStatus)
    updateModuleSectionProgressStatus({ dispatch }: StateContext<ModuleUserStateModel>, action: UpdateModuleSectionProgressStatus): void {
        this.moduleUserApiService.updateModuleSectionProgressStatus(action.payload.moduleId, action.payload.action).subscribe(
            (entities) => {
                dispatch([new AddModuleUsers([entities]), new UpdateModuleSectionProgressStatusSuccess([entities])]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new UpdateModuleSectionProgressStatusFailure({ response }));
            }
        );
    }

    @Action(UpdateModuleUser)
    updateEntity({ dispatch }: StateContext<ModuleUserStateModel>, action: UpdateModuleUser): void {
        this.moduleUserApiService.update(action.payload).subscribe(
            (entities) => {
                dispatch([new AddModuleUsers(entities), new UpdateModuleUserSuccess(entities)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new UpdateModuleUserFailure({ response }));
            }
        );
    }

    @Action(UpdateModuleUserSuccess)
    updateModuleUserSuccess({ dispatch }: StateContext<ModuleUserStateModel>, { payload }: UpdateModuleUserSuccess): void {
        dispatch([new FetchMyModules(), new UpdateModuleResponsibleManager(payload)]);
    }

    @Action(UpdateTemplateUser)
    updateTemplateUser({ dispatch }: StateContext<ModuleUserStateModel>, action: UpdateModuleUser): void {
        this.moduleUserApiService.updateTemplateManager(action.payload).subscribe(
            (entities) => {
                dispatch([new AddModuleUsers(entities), new UpdateModuleUserSuccess(entities)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new UpdateModuleUserFailure({ response }));
            }
        );
    }

    @Action(UpdateModuleManagerPermissions)
    updateModuleManagerPermissions({ dispatch }: StateContext<ModuleUserStateModel>, action: UpdateModuleManagerPermissions): void {
        this.moduleUserApiService.updateModuleManagerPermissions(action.payload.moduleId, action.payload.userId, action.payload.permissions).subscribe(
            (entity) => {
                dispatch([new AddModuleUsers([entity]), new UpdateModuleManagerPermissionsSuccess(entity)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new UpdateModuleManagerPermissionsFailure({ response }));
            }
        );
    }

    @Action(UpdateTemplateUserSuccess)
    updateTemplateUserSuccess({ dispatch }: StateContext<ModuleUserStateModel>, { payload }: UpdateTemplateUserSuccess): void {
        dispatch([new FetchMyModules(), new UpdateTemplateResponsibleManager(payload)]);
    }

    @Action(UpdateModuleUserResponsible)
    updateModuleUserResponsible({ dispatch }: StateContext<ModuleUserStateModel>, action: UpdateModuleUserResponsible): void {
        this.moduleUserApiService.addUsersAsResponsible(action.payload).subscribe(
            (entities) => {
                dispatch([new AddModuleUsers(entities), new FetchModuleResponsibles(action.payload.moduleIds[0]), new UpdateModuleUserResponsibleSuccess(entities)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new UpdateModuleUserResponsibleFailure({ response }));
            }
        );
    }

    @Action(UpdateModuleUserResponsibleSuccess)
    updateModuleUserResponsibleSuccess({ dispatch }: StateContext<ModuleUserStateModel>, action: UpdateModuleUserResponsibleSuccess): void {
        dispatch([new UpdateTemplateResponsibleManager(action.payload)]);
    }

    @Action(UpdateTemplateUserResponsible)
    updateTemplateUserResponsible({ dispatch }: StateContext<ModuleUserStateModel>, action: UpdateTemplateUserResponsible): void {
        this.moduleUserApiService.addUsersAsResponsibleToTemplate(action.payload).subscribe(
            (entities) => {
                dispatch([new AddModuleUsers(entities), new FetchTemplateResponsibleManagers(action.payload.moduleId), new UpdateTemplateUserResponsibleSuccess(entities)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new UpdateTemplateUserResponsibleFailure({ response }));
            }
        );
    }

    @Action(UpdateTemplateUserResponsibleSuccess)
    updateTemplateUserResponsibleSuccess({ dispatch }: StateContext<ModuleUserStateModel>, action: UpdateTemplateUserResponsibleSuccess): void {
        dispatch([new UpdateTemplateResponsibleManager(action.payload)]);
    }

    @Action(RemoveUserAsResponsible)
    removeUserAsResponsible({ dispatch }: StateContext<ModuleUserStateModel>, action: RemoveUserAsResponsible): void {
        this.moduleUserApiService.removeUsersAsResponsible(action.payload).subscribe(
            () => {
                dispatch([new FetchModuleResponsibles(action.payload.moduleIds[0]), new RemoveUserAsResponsibleSuccess()]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new RemoveUserAsResponsibleFailure({ response }));
            }
        );
    }

    @Action(RemoveTemplateUserAsResponsible)
    removeTemplateUserAsResponsible({ dispatch }: StateContext<ModuleUserStateModel>, action: RemoveTemplateUserAsResponsible): void {
        this.moduleUserApiService.removeTemplateUsersAsResponsible(action.payload).subscribe(
            () => {
                dispatch([new FetchTemplateResponsibleManagers(action.payload.moduleId), new RemoveTemplateUserAsResponsibleSuccess()]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new RemoveTemplateUserAsResponsibleFailure({ response }));
            }
        );
    }

    @Action(FetchModuleUser)
    fetchEntity(ctx: StateContext<ModuleUserStateModel>, action: FetchModuleUser): void {
        this._fetchEntity(ctx, action, FetchModuleUserSuccess, FetchModuleUserFailure, AddModuleUsers, SetModuleUser, this.moduleUserApiService.read(action.payload));
    }

    @Action(FetchModuleUserSuccess)
    fetchEntitySuccess(ctx: StateContext<ModuleUserStateModel>, action: FetchModuleUserSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchModuleUserFailure)
    fetchEntityFailure(ctx: StateContext<ModuleUserStateModel>, action: FetchModuleUserFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(AddUsersToModulesAsParticipants)
    addUsersToModulesAsParticipants({ dispatch }: StateContext<ModuleUserStateModel>, { payload }: AddUsersToModulesAsParticipants): void {
        this.moduleUserApiService.addUsersToModulesAsParticipants(payload).subscribe(
            (entities) => {
                dispatch([new AddModuleUsers(entities), new AddUsersToModulesAsParticipantsSuccess(entities)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new AddUsersToModulesAsParticipantsFailure({ response }));
            }
        );
    }

    @Action(AddUsersToProgramExecutionsAsParticipants)
    addUsersToProgramExecutionsAsParticipants({ dispatch }: StateContext<ModuleUserStateModel>, { payload }: AddUsersToProgramExecutionsAsParticipants): void {
        this.moduleUserApiService.addUsersToProgramExecutionsAsParticipants(payload).subscribe(
            (entity) => {
                dispatch(new AddUsersToProgramExecutionsAsParticipantsSuccess({ asyncJobId: String(entity.id) }));
            },
            (response: HttpErrorResponse) => {
                dispatch(new AddUsersToProgramExecutionsAsParticipantsFailure({ response }));
            }
        );
    }

    @Action(AddUsersToCourseOfferings)
    addUsersToCourseOfferings({ dispatch }: StateContext<ModuleUserStateModel>, { payload }: AddUsersToCourseOfferings): void {
        this.moduleUserApiService.addUsersToCourseOfferings(payload).subscribe(
            (entity) => {
                dispatch(new AddUsersToCourseOfferingsSuccess({ asyncJobId: String(entity.id) }));
            },
            (response: HttpErrorResponse) => {
                dispatch(new AddUsersToCourseOfferingsFailure({ response }));
            }
        );
    }

    @Action(AddUsersToModulesAsManagers)
    addUsersToModulesAsManagers({ dispatch }: StateContext<ModuleUserStateModel>, { payload }: AddUsersToModulesAsManagers): void {
        this.moduleUserApiService.addUsersToModulesAsManagers(payload).subscribe(
            (entities) => {
                dispatch([new AddModuleUsers(entities), new AddUsersToModulesAsManagersSuccess(entities)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new AddUsersToModulesAsManagersFailure({ response }));
            }
        );
    }

    @Action(AddUsersToTemplatesAsManagers)
    addUsersToTemplatesAsManagers({ dispatch }: StateContext<ModuleUserStateModel>, { payload }: AddUsersToTemplatesAsManagers): void {
        this.moduleUserApiService.addUsersToTemplatesAsManagers(payload).subscribe(
            (entities) => {
                dispatch([new AddModuleUsers(entities), new AddUsersToTemplatesAsManagersSuccess(entities)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new AddUsersToTemplatesAsManagersFailure({ response }));
            }
        );
    }

    @Action(RemoveUsersFromModulesAsParticipants)
    removeUsersFromModulesAsParticipants(ctx: StateContext<ModuleUserStateModel>, { payload }: RemoveUsersFromModulesAsParticipants): void {
        this.moduleUserApiService.removeUsersFromModulesAsParticipants(payload).subscribe(
            (entities) => {
                const state = ctx.getState();
                const returnedEntityIds = entities.map((entity) => entity.id);
                const filteredModuleUserList = Array.from(state.map.values()).filter(
                    (moduleUser) => payload.userIds.includes(moduleUser.userId) && payload.moduleIds.includes(moduleUser.moduleId) && !returnedEntityIds.includes(moduleUser.id)
                );

                this._removeEntitiesFromState(
                    ctx,
                    filteredModuleUserList.map((moduleUser) => moduleUser.id)
                );

                const actions = [];

                actions.push(new DeleteModulePersonalParticipants(payload.userIds));

                actions.push(new AddModuleUsers(entities));

                actions.push(new RemoveUsersFromModulesAsParticipantsSuccess(entities));

                setTimeout(() => {
                    ctx.dispatch(actions);
                });
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new RemoveUsersFromModulesAsParticipantsFailure({ response }));
            }
        );
    }

    @Action(RemoveUsersFromModulesAsManagers)
    removeUsersFromModulesAsManagers(ctx: StateContext<ModuleUserStateModel>, { payload }: RemoveUsersFromModulesAsManagers): void {
        this.moduleUserApiService.removeUsersFromModulesAsManagers(payload).subscribe(
            (entities) => {
                const state = ctx.getState();
                const returnedEntityIds = entities.map((entity) => entity.id);
                const filteredModuleUserList = Array.from(state.map.values()).filter(
                    (moduleUser) => payload.userIds.includes(moduleUser.userId) && payload.moduleIds.includes(moduleUser.moduleId) && !returnedEntityIds.includes(moduleUser.id)
                );

                this._removeEntitiesFromState(
                    ctx,
                    filteredModuleUserList.map((moduleUser) => moduleUser.id)
                );

                const actions = [];

                actions.push(new AddModuleUsers(entities));

                actions.push(new RemoveUsersFromModulesAsManagersSuccess(entities));

                setTimeout(() => {
                    ctx.dispatch(actions);
                });
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new RemoveUsersFromModulesAsManagersFailure({ response }));
            }
        );
    }

    @Action(RemoveUsersFromTemplatesAsManagers)
    removeUsersFromTemplatesAsManagers(ctx: StateContext<ModuleUserStateModel>, { payload }: RemoveUsersFromTemplatesAsManagers): void {
        this.moduleUserApiService.removeUsersFromTemplatesAsManagers(payload).subscribe(
            () => {
                ctx.dispatch(new RemoveUsersFromTemplatesAsManagersSuccess());
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new RemoveUsersFromTemplatesAsManagersFailure({ response }));
            }
        );
    }

    @Action(AddSelfAsParticipant)
    addSelfAsParticipant({ dispatch }: StateContext<ModuleUserStateModel>, { payload }: AddSelfAsParticipant): void {
        this.moduleUserApiService.addSelfAsParticipant(payload).subscribe(
            (entity) => {
                dispatch([new AddModuleUsers([entity]), new AddUsersToModulesAsParticipantsSuccess([entity])]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new AddUsersToModulesAsParticipantsFailure({ response }));
            }
        );
    }

    @Action(RemoveSelfAsParticipant)
    removeSelfAsParticipant(ctx: StateContext<ModuleUserStateModel>, { payload }: RemoveSelfAsParticipant): void {
        this.moduleUserApiService.removeSelfAsParticipant(payload).subscribe(
            (entity) => {
                this._removeEntitiesFromState(ctx, [entity.id]);

                const actions = [];

                actions.push(new DeleteModulePersonalParticipants([entity.id]));

                actions.push(new AddModuleUsers([entity]));

                actions.push(new RemoveUsersFromModulesAsParticipantsSuccess([entity]));

                setTimeout(() => {
                    ctx.dispatch(actions);
                });
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new RemoveUsersFromModulesAsParticipantsFailure({ response }));
            }
        );
    }

    @Action(UpdateModuleUserPinnedStatus)
    updateModulePinnedStatus(ctx: StateContext<ModuleUserStateModel>, action: UpdateModuleUserPinnedStatus): void {
        this.moduleUserApiService.patchModulePinnedStatus(action.payload.moduleId, action.payload.isPinned).subscribe(
            (entity) => {
                this._store.dispatch(new UpdateModuleUserPinnedStatusSuccess({ moduleUserExtended: entity, isPinned: action.payload.isPinned, listType: action.payload.listType }));
            },
            () => {
                this._store.dispatch(new UpdateModuleUserPinnedStatusFailure());
            }
        );
    }

    @Action(FetchResponsibleRoles)
    fetchResponsibleRoles(ctx: StateContext<ModuleUserStateModel>, action: FetchResponsibleRoles): void {
        this.moduleUserApiService.getResponsibleRoles().subscribe(
            (entities) => {
                ctx.dispatch(new FetchResponsibleRolesSuccess(entities));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new FetchResponsibleRolesFailure());
            }
        );
    }

    @Action(RefreshModuleUsersOnlineState)
    async refreshModuleUsersOnlineState(ctx: StateContext<ModuleUserStateModel>, { payload }: RefreshModuleUsersOnlineState): Promise<void> {
        const ids = payload.map((moduleUser) => moduleUser.user.id);
        let onlineStateResponse = await this._userApiService.getUserOnlineState(ids);

        let moduleUsers = payload.map((moduleUser) => {
            onlineStateResponse.onlineState.forEach((onlineState) => {
                if (moduleUser.user.id === onlineState.id) {
                    moduleUser = Object.assign(new ModuleUserExtended(), moduleUser, {
                        user: Object.assign(new User(), moduleUser.user, {
                            isOnline: onlineState.isOnline,
                        }),
                    });
                }
            });

            return moduleUser;
        });

        this._addEntities(ctx, { payload: moduleUsers });
    }
}
