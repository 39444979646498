import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { MaxBrainAuthState } from 'app/projects/auth/src/lib/models/auth.state';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { IMaxBrainEnvironment } from 'app/projects/shared/src/lib/interfaces/environment.interface';
import { ILanguageService } from 'app/projects/shared/src/lib/interfaces/language.interface';
import { MAXBRAIN_LANGUAGE_SERVICE } from 'app/projects/shared/src/lib/services/language.token';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class TinymceService extends UnsubscribeOnDestroy {
    private _environment: IMaxBrainEnvironment;
    private readonly _jwtoken: string;
    private _selectedLanguage: string;
    private _languageMxToTinym: any = {
        'fr-FR': 'fr_FR',
        'de-DE': 'de',
        'en-GB': 'en_GB',
        'it-CH': 'it',
    };

    /*
     * CONFIGURATION TINY LIB
     */
    private _tinymceConf: object;

    public constructor(
        @Inject(MAXBRAIN_LANGUAGE_SERVICE) private _languageService: ILanguageService,
        private _store: Store,
        public translateService: TranslateService,
        @Inject(MAXBRAIN_ENVIRONMENT) environment: IMaxBrainEnvironment
    ) {
        super();

        this._jwtoken = this._store.selectSnapshot(MaxBrainAuthState.getAccessToken);
        this._selectedLanguage = this.getTinymceLanguage(this.translateService.currentLang);
        this._environment = environment;

        this._tinymceConf = {
            height: '500',
            base_url: '/assets/tinymce', // Root for resources
            suffix: '.min',

            // editimage_proxy: `${this._environment.tinymceUrl}/ephox-image-proxy/`,
            mediaembed_service_url: `${this._environment.tinymceUrl}/ephox-hyperlinking/`,
            linkchecker_service_url: `${this._environment.tinymceUrl}/ephox-hyperlinking/`,
            // mediaembed_max_width: 450,

            skin_url: MaxBrainUtils.baseUrl + '/assets/tinymce/skins/ui/material-classic',
            theme_url: MaxBrainUtils.baseUrl + '/assets/tinymce/themes/silver/theme.js',

            icons_url: MaxBrainUtils.baseUrl + '/assets/tinymce/icons/material/icons.js',
            icons: 'material',

            plugins: `image editimage media mediaembed
            export pagebreak advlist autolink lists link image charmap 
            anchor pagebreak searchreplace wordcount visualblocks visualchars 
            code fullscreen insertdatetime media nonbreaking save table powerpaste 
            directionality emoticons template textpattern table advtable
            media mediaembed formatpainter pageembed`,

            menubar: 'file edit view insert format tools table help',

            toolbar:
                'undo redo print formatpainter' +
                '| fontsize ' +
                '| bold italic forecolor backcolor  ' +
                '| link image pageembed ' +
                '| alignleft aligncenter alignright alignjustify ' +
                '| bullist numlist outdent indent ' +
                '| pagebreak  ',
            removed_menuitems: 'newdocument',

            powerpaste_allow_local_images: true,
            external_plugins: {
                powerpaste: MaxBrainUtils.baseUrl + '/assets/tinymce/plugins/powerpaste/plugin.js',
            },

            content_css: ['//fonts.googleapis.com/css?family=Roboto:300,400,500'],
            font_family_formats: 'Roboto=roboto;',
            paste_data_images: true,
            relative_urls: false,
            remove_script_host: false,
            body_class: 'mat-elevation-z0',
            min_height: 6 * 32 + 20,
            font_size_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt',
        };

        this.translateService.onLangChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ lang }) => {
            this._selectedLanguage = this.getTinymceLanguage(this._languageService.getLanguageById(lang).id);
        });
    }

    /**
     *
     * @param lang
     * @private
     */
    private getTinymceLanguage(lang: string): string {
        return this._languageMxToTinym[lang];
    }

    /**
     *
     */
    public getInitConfiguration(): Object {
        let optionsLang = {};
        if (this._selectedLanguage !== 'en_GB') {
            optionsLang = {
                language: this._selectedLanguage,
                language_url: MaxBrainUtils.baseUrl + `/assets/tinymce/langs/${this._selectedLanguage}.js`,
            };
        }

        return {
            ...this._tinymceConf,
            ...optionsLang,
            images_upload_handler: (blobInfo, progress) =>
                new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();

                    xhr.withCredentials = false;
                    xhr.open('POST', `${this._environment.apiUrl}/tinymce/images`);
                    xhr.setRequestHeader('Authorization', 'Bearer ' + this._jwtoken);

                    xhr.upload.onprogress = (e) => {
                        progress((e.loaded / e.total) * 100);
                    };

                    xhr.onload = () => {
                        if (xhr.status < 200 || xhr.status >= 300) {
                            reject('HTTP Error: ' + xhr.status);
                            return;
                        }

                        const json = JSON.parse(xhr.responseText);

                        if (!json || typeof json.location !== 'string') {
                            reject('Invalid JSON: ' + xhr.responseText);
                            return;
                        }

                        resolve(json.location);
                    };

                    xhr.onerror = () => {
                        reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                    };

                    const formData = new FormData();
                    formData.append('file', blobInfo.blob(), blobInfo.filename());

                    xhr.send(formData);
                }),
        };
    }
}
