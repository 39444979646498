import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search',
})
@Injectable()
export class SearchPipe implements PipeTransform {
    transform(value: any[], input: string): any[] {
        if (!value) {
            return [];
        }

        if (!input) {
            return value;
        }

        let values = [];

        if (input) {
            input = input.toLowerCase();

            return value.filter((el) => {
                values = Object.keys(el).map((key) => el[key]);

                if (values) {
                    for (const i in values) {
                        if (values[i] !== null && typeof values[i] === 'string' && values[i].toLowerCase().indexOf(input) > -1) {
                            return true;
                        }
                    }
                }

                return false;
            });
        }

        return value;
    }
}
