import { ITenantAdminModuleStatsQuery } from '../interfaces/tenant-admin-module-stats.query.interface';

export class TenantAdminModuleStats {
    showContentBox: boolean;
    sectionCount: number;
    showEventBox: boolean;
    eventCount: number;
    showGradeBox: boolean;
    gradedUnits: {
        name: string;
    }[];

    constructor(query: ITenantAdminModuleStatsQuery) {
        this.showContentBox = query.show_content_box;
        this.sectionCount = query.section_count;
        this.showEventBox = query.show_event_box;
        this.eventCount = query.event_count;
        this.showGradeBox = query.show_grade_box;
        this.gradedUnits = query.graded_units;
    }
}
