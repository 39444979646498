export class PatchProfileCommand {
    email_notification: boolean;
    hide_online_state: boolean;
    is_email_hidden: boolean;
    notification_email: string;

    constructor({
        email_notification,
        hide_online_state,
        is_email_hidden,
        notification_email,
    }: {
        email_notification?: boolean;
        hide_online_state?: boolean;
        is_email_hidden?: boolean;
        notification_email?: string;
    }) {
        this.email_notification = email_notification;
        this.notification_email = notification_email;
        this.hide_online_state = hide_online_state;
        this.is_email_hidden = is_email_hidden;
    }
}
