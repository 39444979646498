import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { IEntityState } from 'app/projects/entity/src/lib/interfaces/entity.state.interface';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import {
    CreateTag,
    CreateTagFailure,
    CreateTagSuccess,
    DeleteTag,
    DeleteTagFailure,
    DeleteTagSuccess,
    FetchTag,
    FetchTagFailure,
    FetchTags,
    FetchTagsFailure,
    FetchTagsSuccess,
    FetchTagSuccess,
    SetTag,
    SetTags,
    UnsetTag,
    UpdateTag,
    UpdateTagFailure,
    UpdateTagSuccess,
} from '../actions';
import { AddTags } from '../actions/add-entities.action';
import { TagApiService } from '../services/tag.api-service';
import { MaxBrainTag } from './tag.model';
import { TagStateModel } from './tag.state-model';

@State<TagStateModel>({
    name: 'tag',
    defaults: new TagStateModel(),
})
@Injectable()
export class TagState extends EntityState<MaxBrainTag> implements IEntityState<MaxBrainTag> {
    static sortProperties: string[] = ['name'];

    @Selector()
    static getEntity(state: TagStateModel, id: string = state.item): MaxBrainTag {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntities(state: TagStateModel): MaxBrainTag[] {
        if (!state.map) {
            return null;
        }

        const toReturn = Array.from(state.map.values());

        toReturn.sort(MaxBrainUtils.sortCompareFn(TagState.sortProperties));

        return toReturn;
    }

    @Selector()
    static getUnusedColors(state: TagStateModel): string[] {
        const stateList = TagState.getEntities(state);
        const usedColors = new Set(stateList.map((tag) => tag.color));

        return MaxBrainTag.colorsArray.filter((color) => {
            return usedColors.size === MaxBrainTag.colorsArray.length ? MaxBrainTag.colorsArray : !usedColors.has(color);
        });
    }

    constructor(tagService: TagApiService) {
        super(tagService, 30);

        this._propstoSearch = MaxBrainTag.props_to_search;
    }

    @Action(AddTags)
    addEntities(ctx: StateContext<TagStateModel>, action: AddTags): void {
        this._addEntities(ctx, action);
    }

    @Action(SetTag)
    setEntity(ctx: StateContext<TagStateModel>, action: SetTag): void {
        this._setEntity(ctx, action, AddTags);
    }

    @Action(UnsetTag)
    unsetEntity(ctx: StateContext<TagStateModel>): void {
        this._unsetEntity(ctx);
    }

    @Action(CreateTag)
    createEntity(ctx: StateContext<TagStateModel>, action: CreateTag): void {
        this._createEntity(ctx, action, CreateTagSuccess, CreateTagFailure, AddTags).then(() => {});
    }

    @Action(UpdateTag)
    updateEntity(ctx: StateContext<TagStateModel>, action: UpdateTag): void {
        this._updateEntity(ctx, action, UpdateTagSuccess, UpdateTagFailure, AddTags).then(() => {});
    }

    @Action(DeleteTag)
    deleteEntity(ctx: StateContext<TagStateModel>, action: DeleteTag): void {
        this._deleteEntity(ctx, action, DeleteTagSuccess, DeleteTagFailure).then(() => {});
    }

    @Action(FetchTag)
    fetchEntity(ctx: StateContext<TagStateModel>, action: FetchTag): void {
        this._fetchEntity(ctx, action, FetchTagSuccess, FetchTagFailure, AddTags, SetTag).then(() => {});
    }

    @Action(FetchTagSuccess)
    fetchEntitySuccess(ctx: StateContext<TagStateModel>, action: FetchTagSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchTagFailure)
    fetchEntityFailure(ctx: StateContext<TagStateModel>, action: FetchTagFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(SetTags)
    setEntities(ctx: StateContext<TagStateModel>, action: SetTags): void {
        this._setEntities(ctx, action);
    }

    @Action(FetchTags)
    fetchEntities(ctx: StateContext<TagStateModel>): void {
        this._fetchEntities(ctx, FetchTagsSuccess, FetchTagsFailure, SetTags).then(() => {});
    }

    @Action(FetchTagsSuccess)
    fetchEntitiesSuccess(ctx: StateContext<TagStateModel>): void {
        this._fetchEntitiesSuccess(ctx);
    }

    @Action(FetchTagsFailure)
    fetchEntitiesFailure(ctx: StateContext<TagStateModel>): void {
        this._fetchEntitiesFailure(ctx);
    }
}
