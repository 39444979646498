import { ModuleUserExtended } from 'app/library/module-user/models/module-user.extended.model';

export class SetTemplateManagers {
    static readonly type = '[TemplateManager] SetTemplateManagers';

    constructor(public payload: ModuleUserExtended[]) {}
}

export class UnsetTemplateManagers {
    static readonly type = '[TemplateManager] UnsetTemplateManagers';
}

export class FetchTemplateResponsibleManagers {
    static readonly type = '[TemplateManager] FetchTemplateResponsibleManagers';

    constructor(public payload: string) {}
}

export class FetchTemplateResponsibleManagersSuccess {
    static readonly type = '[TemplateManager] FetchTemplateResponsibleManagersSuccess';

    constructor(public payload: string) {}
}

export class FetchTemplateResponsibleManagersFailure {
    static readonly type = '[TemplateManager] FetchTemplateResponsibleManagersFailure';

    constructor(public payload: string) {}
}

export class FetchTemplateManagers {
    static readonly type = '[TemplateManager] FetchTemplateManagers';

    constructor(
        public payload?: {
            moduleId: string;
            search?: string;
            sortBy?: string;
            sortOrder?: string;
            pageSize?: number;
            pageNumber?: number;
        }
    ) {}
}

export class FetchTemplateManagersSuccess {
    static readonly type = '[TemplateManager] FetchTemplateManagersSuccess';
}

export class FetchTemplateManagersFailure {
    static readonly type = '[TemplateManager] FetchTemplateManagersFailure';
}

export class ToggleSelectedTemplateManager {
    static readonly type = '[TemplateManager] ToggleSelectedTemplateManager';

    constructor(public payload: string) {}
}

export class SelectFilteredTemplateManagers {
    static readonly type = '[TemplateManager] SelectFilteredTemplateManagers';
}

export class DeselectSelectedTemplateManagers {
    static readonly type = '[TemplateManager] DeselectSelectedTemplateManagers';
}

export class SearchTemplateManagers {
    static readonly type = '[TemplateManager] SearchTemplateManagers';

    constructor(public payload: string) {}
}
