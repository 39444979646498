import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarTime } from 'app/projects/core/src/lib/constants/snack-bar';

@Injectable()
export class HelperService {
    isKmpAppActive = false;

    constructor(private _translateService: TranslateService, private _matSnackBar: MatSnackBar) {
        const mobileAppValue = localStorage.getItem('kmpMobileApp');

        if (mobileAppValue === 'ios' || mobileAppValue === 'android') {
            this.isKmpAppActive = true;
        }
    }

    showSnackBarMessage(message: string, button = 'OK', duration = SnackBarTime.medium) {
        const messageTranslation = this._translateService.instant(message);

        this._matSnackBar.open(messageTranslation, button, {
            verticalPosition: this.isKmpAppActive ? 'bottom' : 'top',
            duration: duration,
        });
    }

    // Cleave directive which is used for time formatting sometimes format time incorrectly. Check MX-4191
    cleaveTimeFixer(time: string): string {
        if (!time) {
            return '';
        }

        let hours = time.split(':')[0];
        let minutes = time.split(':')[1];
        let hoursOffset: string;

        if (hours.length === 3) {
            hoursOffset = hours.substring(2, 3);
            hours = hours.substring(0, 2);

            if (parseInt(hoursOffset) <= 5) {
                minutes = hoursOffset + minutes.substring(0, 1);
            } else {
                minutes = 0 + hoursOffset;
            }
        }

        if (minutes.length === 3) {
            minutes = minutes.substring(0, 2);
        }

        return `${hours}:${minutes}`;
    }
}
