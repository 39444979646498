import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { MaxBrainEventModule } from 'app/library/event/event.module';
import { FuseSearchBarModule } from 'app/projects/fuse/src/lib/components/search-bar/search-bar.module';
import { FuseShortcutsModule } from 'app/projects/fuse/src/lib/components/shortcuts/shortcuts.module';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        RouterModule,
        MaxBrainSharedModule,
        MatMenuModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MatDialogModule,
        MatIconModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatButtonModule,
        MaxBrainEventModule,
    ],
    exports: [ToolbarComponent],
    providers: [],
})
export class ToolbarModule {}
