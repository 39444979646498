import { IContentNodeBreadcrumbQuery } from '../interfaces/content-node-breadcrumb.query.interface';

export class ContentNodeBreadcrumb {
    static label_singular = 'breadcrumb';
    static label_plural = 'breadcrumbs';

    static props_to_search = ['name'];

    id: string;
    name: string;

    constructor(query: IContentNodeBreadcrumbQuery) {
        this.id = query.id;
        this.name = query.name;
    }
}
