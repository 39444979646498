import { IModuleUserNextSectionQuery } from '../interfaces/module-user-next-section.query.interface';

export class ModuleUserNextSection {
    id: string;
    name: string;
    sort: number;
    type: string;

    constructor(moduleUserProgress: IModuleUserNextSectionQuery) {
        if (moduleUserProgress) {
            this.id = moduleUserProgress.id ? moduleUserProgress.id.toString() : null;
            this.name = moduleUserProgress.name;
            this.sort = moduleUserProgress.sort;
            this.type = moduleUserProgress.type;
        } else {
            this.id = null;
            this.name = null;
            this.sort = null;
            this.type = null;
        }
    }
}
