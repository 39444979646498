import { ISectionMetricsQuery } from '../interfaces/section-metrics.query.interface';

export class SectionMetric {
    passedPercentage: number;
    failedPercentage: number;

    constructor(query: ISectionMetricsQuery) {
        this.passedPercentage = query.passed_percentage;
        this.failedPercentage = query.failed_percentage;
    }
}
