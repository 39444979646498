import { StateContext } from '@ngxs/store';
import { IActionWithPayload } from 'app/projects/core/src/lib/interfaces/action_with_payload.interface';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { IEntity } from 'app/projects/entity/src/lib/interfaces/entity.interface';
import { Observable } from 'rxjs';
import { IAddSubentitiesToEntityActionPayload } from '../interfaces/add-subentities-to-entity.action-payload.interface';
import { IRemoveSubentityFromEntityActionPayload } from '../interfaces/remove-subentity-from-entity.action-payload.interface';
import { ISubentityService } from '../interfaces/subentity.service.interface';
import { SubentityStateModel } from './subentity.state-model';

export abstract class SubentityState<T extends IEntity> {
    private _expirationTime = {};
    protected _propstoSearch = null;

    constructor(protected _subentityService?: ISubentityService<T>, private _timeoutDuration: number = 0) {
        if (this._timeoutDuration < 0) {
            this._timeoutDuration = 0;
        }
    }

    /**
     *
     * @param key
     */
    private setExpirationTime(key): void {
        this._expirationTime[key] = this._timeoutDuration * 1000 + Date.now();
    }

    /**
     *
     * @param subentities
     * @param searchString
     */
    protected _filterSubentitiesByStringAndMapToIds(subentities: T[], searchString: string): string[] {
        return MaxBrainUtils.filterArrayByString(subentities, searchString, this._propstoSearch).map((subentity) => subentity.id);
    }

    /**
     *
     * @param getState
     * @param patchState
     * @param payload
     * @param subentities
     */
    protected _patchSubentities({ getState, patchState }: StateContext<SubentityStateModel>, { payload }: IActionWithPayload<{ subentityIds: string[] }>, subentities: T[]): void {
        const state = getState();
        const list = [...(state.list && state.list.length ? state.list : []), ...payload.subentityIds].filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        const filtered = this._filterSubentitiesByStringAndMapToIds(
            subentities.filter((subentity) => list.includes(subentity.id)),
            state.searchTerm
        );

        patchState({
            list,
            filtered,
        });
    }

    /**
     *
     * @param ctx
     * @param action
     * @param subentities
     * @param SuccessAction
     * @param FailureAction
     * @param serviceMethod
     */
    protected _addSubentities(
        ctx: StateContext<SubentityStateModel>,
        action: IActionWithPayload<IAddSubentitiesToEntityActionPayload>,
        subentities: T[],
        SuccessAction,
        FailureAction,
        serviceMethod: Observable<any> = this._subentityService ? this._subentityService.add(action.payload.entityId, action.payload.subentityIds) : null
    ): void {
        if (!serviceMethod) {
            return;
        }

        serviceMethod.subscribe(
            () => {
                this._patchSubentities(ctx, action, subentities);

                setTimeout(() => ctx.dispatch(new SuccessAction()));
            },
            (error) => {
                console.error(error);

                ctx.dispatch(new FailureAction());
            }
        );
    }

    /**
     *
     * @param getState
     * @param patchState
     * @param dispatch
     * @param payload
     * @param SuccessAction
     * @param FailureAction
     * @param serviceMethod
     */
    protected _removeSubentity(
        { getState, patchState, dispatch }: StateContext<SubentityStateModel>,
        { payload }: IActionWithPayload<IRemoveSubentityFromEntityActionPayload>,
        SuccessAction,
        FailureAction,
        serviceMethod: Observable<any> = this._subentityService ? this._subentityService.remove(payload.entityId, payload.subentityId) : null
    ): void {
        if (!serviceMethod) {
            return;
        }

        serviceMethod.subscribe(
            () => {
                const state = getState();

                patchState({
                    list: [...state.list.filter((subentityId) => subentityId !== payload.subentityId)],
                    selected: [...state.selected.filter((subentityId) => subentityId !== payload.subentityId)],
                    filtered: [...state.filtered.filter((subentityId) => subentityId !== payload.subentityId)],
                });

                setTimeout(() => dispatch(new SuccessAction(payload)));
            },
            (error) => {
                console.error(error);

                setTimeout(() => dispatch(new FailureAction(payload)));
            }
        );
    }

    /**
     *
     * @param getState
     * @param patchState
     * @param dispatch
     * @param payload
     * @param AddEntities
     */
    protected _setSubentities({ getState, patchState, dispatch }: StateContext<SubentityStateModel>, { payload }: IActionWithPayload<T[]>, AddEntities = null): void {
        const subentities = [...payload];

        if (AddEntities) {
            dispatch(new AddEntities(subentities));
        }

        const state = getState();
        const list = subentities.map((subentity) => subentity.id);
        const filtered = this._filterSubentitiesByStringAndMapToIds(subentities, state.searchTerm);

        patchState({
            list,
            filtered,
            selected: [],
        });
    }

    /**
     *
     * @param patchState
     */
    protected _unsetSubentities({ patchState }: StateContext<SubentityStateModel>): void {
        patchState({
            list: null,
            filtered: [],
            selected: [],
        });
    }

    /**
     *
     * @param getState
     * @param patchState
     * @param dispatch
     * @param payload
     * @param SetSubentitiesAction
     * @param SuccessAction
     * @param FailureAction
     * @param serviceMethod
     */
    protected _fetchSubentities(
        { getState, patchState, dispatch }: StateContext<SubentityStateModel>,
        { payload }: IActionWithPayload<string>,
        SetSubentitiesAction,
        SuccessAction,
        FailureAction,
        serviceMethod: Observable<T[]> = this._subentityService ? this._subentityService.getAll(payload) : null
    ): void {
        if (!serviceMethod) {
            return;
        }

        const state = getState();

        if (state.fetchingList) {
            return;
        }

        patchState({
            fetchingList: true,
        });

        /**
         * Skips fetching the subentities in case the timeoutDuration hasn't elapsed since the last fetch
         */
        if (this._expirationTime > Date.now() && state.list) {
            setTimeout(() => dispatch(new SuccessAction()));
            return;
        }

        serviceMethod.subscribe(
            (subentities) => {
                setTimeout(() => dispatch(new SetSubentitiesAction(subentities)));

                setTimeout(() => dispatch(new SuccessAction()));
            },
            (error) => {
                console.error(error);

                setTimeout(() => dispatch(new FailureAction()));
            },
            () => {
                this.setExpirationTime(payload);
            }
        );
    }

    /**
     *
     * @param getState
     * @param patchState
     * @param entityId
     */
    protected _removeSubentityFromState({ getState, patchState }: StateContext<SubentityStateModel>, entityId: string): void {
        const state = getState();
        const partialState: Partial<SubentityStateModel> = {};

        if (state.list) {
            partialState.list = state.list.filter((entity) => entity !== entityId);
        }

        patchState({
            ...partialState,
            selected: state.selected.filter((selectedEntityId) => selectedEntityId !== entityId),
            filtered: state.filtered.filter((filteredEntityId) => filteredEntityId !== entityId),
        });
    }

    /**
     *
     * @param patchState
     */
    protected _fetchSubentitiesSuccess({ patchState }: StateContext<SubentityStateModel>): void {
        patchState({
            fetchingList: false,
        });
    }

    /**
     *
     * @param patchState
     */
    protected _fetchSubentitiesFailure({ patchState }: StateContext<SubentityStateModel>): void {
        patchState({
            fetchingList: false,
        });
    }

    /**
     *
     * @param getState
     * @param patchState
     * @param payload
     */
    protected _toggleSelectedSubentity({ getState, patchState }: StateContext<SubentityStateModel>, { payload }: IActionWithPayload<string>): void {
        const state = getState();

        patchState({
            selected: state.selected.indexOf(payload) === -1 ? [...state.selected, payload] : [...state.selected.filter((subentityId) => subentityId !== payload)],
        });
    }

    /**
     *
     * @param getState
     * @param patchState
     */
    protected _selectFilteredSubentities({ getState, patchState }: StateContext<SubentityStateModel>): void {
        const state = getState();

        patchState({
            selected: state.filtered ? [...state.filtered] : null,
        });
    }

    /**
     *
     * @param patchState
     */
    protected _deselectSelectedSubentities({ patchState }: StateContext<SubentityStateModel>): void {
        patchState({
            selected: [],
        });
    }

    /**
     *
     * @param getState
     * @param patchState
     * @param payload
     * @param subentities
     */
    protected _searchSubentities({ getState, patchState }: StateContext<SubentityStateModel>, { payload }: IActionWithPayload<string>, subentities: T[]): void {
        const state = getState();
        const filtered = this._filterSubentitiesByStringAndMapToIds(
            subentities.filter((subentity) => state.list.indexOf(subentity.id) !== -1),
            payload
        );

        patchState({
            searchTerm: payload,
            filtered,
        });
    }
}
