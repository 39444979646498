import { IMaintenanceMessageQuery } from './maintenance-message.query.interface';

export class MaintenanceMessage {
    id: string;
    messageId: string;
    type: string;
    urgency: string;
    message: {
        de: string;
        en: string;
        fr: string;
        it: string;
    };
    messageToShow?: string;

    constructor(query: IMaintenanceMessageQuery) {
        if (query) {
            this.id = query.id;
            this.messageId = query.message_id.toString();
            this.type = query.type;
            this.urgency = query.urgency;
            this.message = query.message;
        } else {
            this.id = null;
            this.messageId = null;
            this.type = null;
            this.urgency = null;
            this.message = null;
            this.messageToShow = null;
        }
    }
}
