import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { NgxsActionHelper } from 'app/projects/core/src/lib/services/action.helper';
import { BehaviorSubject, race } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { FetchParticipantAttendances, FetchParticipantAttendancesFailure, FetchParticipantAttendancesSuccess } from '../actions';
import { ModuleEventAttendance } from '../models/module-event-attendance.model';

@Injectable()
export class ModuleEventAttendanceService {
    private _participantAttendancesSubject = new BehaviorSubject<ModuleEventAttendance[]>(null);
    public participantAttendancesSubject$ = this._participantAttendancesSubject.asObservable();

    constructor(private _store: Store, private _actionHelper: NgxsActionHelper, private _action$: Actions) {}

    async getParticipantAttendances(moduleId: string, participantId: string, sortBy?: string, sortOrder?: string): Promise<boolean> {
        this._store.dispatch(new FetchParticipantAttendances({ moduleId, participantId, sortBy, sortOrder }));

        return race(
            this._action$.pipe(
                filter((participantAttendances) => !!participantAttendances),
                ofActionDispatched(FetchParticipantAttendancesSuccess),
                map((action: FetchParticipantAttendancesSuccess) => {
                    this._participantAttendancesSubject.next(action.payload);
                    return true;
                })
            ),
            this._action$.pipe(
                ofActionDispatched(FetchParticipantAttendancesFailure),
                map((action: FetchParticipantAttendancesFailure) => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }
}
