import { IScormCourseQuery } from '../interfaces/scorm-course.query.interface';

export class ScormCourse {
    id: string;
    title: string;
    created: string;
    updated: string;
    version: number;

    constructor(query?: IScormCourseQuery) {
        if (query) {
            this.id = query.id;
            this.title = query.title;
            this.created = query.created;
            this.updated = query.updated;
            this.version = query.version;
        }
    }
}
