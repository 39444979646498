import { IGlobalParticipantQuery } from '../interfaces/participant.query.interface';
import { UserProfilePicture } from './user-profile-picture.model';

export class GlobalParticipant {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    profilePicture: UserProfilePicture;

    constructor(query?: IGlobalParticipantQuery) {
        if (query) {
            this.id = query.id.toString();
            this.email = query.email;
            this.firstName = query.first_name;
            this.lastName = query.last_name;
            this.fullName = query.first_name + ' ' + query.last_name;
            this.profilePicture = query.profile_picture ? new UserProfilePicture(query.profile_picture) : null;
        } else {
            this.id = null;
            this.email = null;
            this.firstName = null;
            this.lastName = null;
            this.fullName = null;
            this.profilePicture = null;
        }
    }
}
