import { ModuleUserExtended } from 'app/library/module-user/models/module-user.extended.model';

export class SetModuleManagers {
    static readonly type = '[ModuleManager] SetModuleManagers';

    constructor(public payload: ModuleUserExtended[]) {}
}

export class UnsetModuleManagers {
    static readonly type = '[ModuleManager] UnsetModuleManagers';
}

export class FetchModuleManagers {
    static readonly type = '[ModuleManager] FetchModuleManagers';

    constructor(public payload: string) {}
}

export class FetchModuleManagersSuccess {
    static readonly type = '[ModuleManager] FetchModuleManagersSuccess';
}

export class FetchModuleManagersFailure {
    static readonly type = '[ModuleManager] FetchModuleManagersFailure';
}

export class ToggleSelectedModuleManager {
    static readonly type = '[ModuleManager] ToggleSelectedModuleManager';

    constructor(public payload: string) {}
}

export class SelectFilteredModuleManagers {
    static readonly type = '[ModuleManager] SelectFilteredModuleManagers';
}

export class DeselectSelectedModuleManagers {
    static readonly type = '[ModuleManager] DeselectSelectedModuleManagers';
}

export class SearchModuleManagers {
    static readonly type = '[ModuleManager] SearchModuleManagers';

    constructor(public payload: string) {}
}
