import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FeatureService } from 'app/library/feature/services/feature.service';
import { AddUsersToModulesAsParticipantsSuccess, RefreshModuleUsersOnlineState, RemoveUsersFromModulesAsParticipantsSuccess } from 'app/library/module-user/actions';
import { AddModuleUsers } from 'app/library/module-user/actions/add-entities.action';
import { ModuleUser } from 'app/library/module-user/models/module-user.model';
import { ModuleUserState } from 'app/library/module-user/models/module-user.state';
import { ModuleState } from 'app/library/module/models/module.state';
import { UserService } from 'app/library/user/services/user.service';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { UtilsState } from 'app/projects/subentity/src/lib/models/utils.state';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { map } from 'rxjs/operators';
import { ModuleUserExtended } from '../../module-user/models/module-user.extended.model';
import { MyModulesStateModel } from '../../my-profile-modules/models/my-modules.state-model';
import {
    DeselectSelectedModuleParticipants,
    FetchModuleParticipants,
    FetchModuleParticipantsFailure,
    FetchModuleParticipantsSuccess,
    SearchModuleParticipants,
    SelectFilteredModuleParticipants,
    SetModuleParticipants,
    SortModuleParticipants,
    ToggleSelectedModuleParticipant,
    UnsetModuleParticipants,
} from '../actions';
import { ModuleParticipantsApiService } from '../services/module-participants.api-service';
import { ModuleParticipantsStateModel } from './module-participants.state-model';

@State<ModuleParticipantsStateModel>({
    name: 'participants',
    defaults: new ModuleParticipantsStateModel(),
})
@Injectable()
export class ModuleParticipantsState extends SubentityState<ModuleUser> {
    @Selector([ModuleUserState.getEntitiesAsMap])
    static getFilteredEntities(state: MyModulesStateModel, moduleUsers: Map<string, ModuleUser>): ModuleUser[] {
        return UtilsState.getFilteredEntities(state, moduleUsers);
    }

    @Selector([ModuleUserState.getEntitiesAsMap])
    static getEntities(state: MyModulesStateModel, moduleUsers: Map<string, ModuleUser>): ModuleUser[] {
        return UtilsState.getEntities(state, moduleUsers);
    }

    @Selector([ModuleUserState.getEntities, UserState.getEntities])
    static getEntitiesWithUser(state: ModuleParticipantsStateModel, moduleUsers: ModuleUser[], users: User[]): ModuleUserExtended[] {
        const toReturn = ModuleParticipantsState.getEntities(state, new Map(moduleUsers.map((moduleUser) => [moduleUser.id, moduleUser])));

        if (toReturn && users) {
            const usersContainer = {};

            users.forEach((user) => {
                usersContainer[user.id] = user;
            });

            return toReturn.map<ModuleUserExtended>((moduleUser) =>
                Object.assign<ModuleUserExtended, ModuleUser, Partial<ModuleUserExtended>>(new ModuleUserExtended(), moduleUser, {
                    user: usersContainer[moduleUser.userId] || null,
                })
            );
        }

        return null;
    }

    @Selector()
    static getSelectedEntityIds(state: ModuleParticipantsStateModel): string[] {
        return state.selected;
    }

    @Selector()
    static getFiltersList(state: ModuleParticipantsStateModel): string[] {
        return state.filtered;
    }

    @Selector()
    static getSearchTerm(state: ModuleParticipantsStateModel): string {
        return state.searchTerm;
    }

    @Selector()
    static getTotalCount(state: ModuleParticipantsStateModel): number {
        return state.totalCount;
    }

    @Selector()
    static getSortBy(state: ModuleParticipantsStateModel): string {
        return state.sortBy;
    }

    @Selector()
    static getSortOrder(state: ModuleParticipantsStateModel): SortDirection {
        return state.sortOrder;
    }

    @Selector()
    static getPageSize(state: ModuleParticipantsStateModel): number {
        return state.pageSize;
    }

    @Selector()
    static getPageNumber(state: ModuleParticipantsStateModel): number {
        return state.pageNumber || 1;
    }

    @Selector()
    static getPageIndex(state: ModuleParticipantsStateModel): number {
        return state.pageNumber ? state.pageNumber - 1 : 0;
    }

    @Selector()
    static isFetchingList(state: ModuleParticipantsStateModel): boolean {
        return state.fetchingList;
    }

    constructor(
        private _moduleParticipantsService: ModuleParticipantsApiService,
        private _userService: UserService,
        private store: Store,
        private _featureService: FeatureService
    ) {
        super(null, 0);

        this._propstoSearch = ['user', ...User.props_to_search];
    }

    @Action(SetModuleParticipants)
    setAll(ctx: StateContext<ModuleParticipantsStateModel>, action: SetModuleParticipants): void {
        this._setSubentities(ctx, action, AddModuleUsers);

        setTimeout(() => {
            const moduleParticipantsWithUser = this.store.selectSnapshot(ModuleParticipantsState.getEntitiesWithUser);

            if (moduleParticipantsWithUser && this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)) {
                this.store.dispatch(new RefreshModuleUsersOnlineState(moduleParticipantsWithUser));
                this._userService.setOnlineStateInterval(RefreshModuleUsersOnlineState, ModuleParticipantsState.getEntitiesWithUser);
            }
        });
    }

    @Action(UnsetModuleParticipants)
    unsetAll(ctx: StateContext<ModuleParticipantsStateModel>): void {
        this._unsetSubentities(ctx);
    }

    @Action(FetchModuleParticipants)
    fetchAll(ctx: StateContext<ModuleParticipantsStateModel>, action: FetchModuleParticipants): void {
        this._fetchSubentities(
            ctx,
            { payload: 'all' },
            SetModuleParticipants,
            FetchModuleParticipantsSuccess,
            FetchModuleParticipantsFailure,
            this._moduleParticipantsService
                .getAll(
                    action.payload.moduleId,
                    action.payload.search,
                    action.payload.sortBy,
                    action.payload.sortOrder,
                    action.payload.pageSize,
                    action.payload.pageNumber,
                    action.payload.withoutCertificate,
                    action.payload.selectedCertificateIds
                )
                .pipe(
                    map((res) => {
                        ctx.patchState({
                            totalCount: res.pagination.number_of_results,
                            pageNumber: res.pagination.number_of_pages,
                        });

                        return res.data.map((query) => new ModuleUserExtended(query));
                    })
                )
        );
    }

    @Action(FetchModuleParticipantsSuccess)
    fetchAllSuccess(ctx: StateContext<ModuleParticipantsStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchModuleParticipantsFailure)
    fetchAllFailure(ctx: StateContext<ModuleParticipantsStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(ToggleSelectedModuleParticipant)
    toggleSelect(ctx: StateContext<ModuleParticipantsStateModel>, action: ToggleSelectedModuleParticipant): void {
        this._toggleSelectedSubentity(ctx, action);
    }

    @Action(SelectFilteredModuleParticipants)
    selectAll(ctx: StateContext<ModuleParticipantsStateModel>): void {
        this._selectFilteredSubentities(ctx);
    }

    @Action(DeselectSelectedModuleParticipants)
    deselectAll(ctx: StateContext<ModuleParticipantsStateModel>): void {
        this._deselectSelectedSubentities(ctx);
    }

    @Action(AddUsersToModulesAsParticipantsSuccess)
    addUsersToModulesAsParticipantsSuccess(ctx: StateContext<ModuleParticipantsStateModel>): void {
        const moduleUsers = this.store.selectSnapshot(ModuleUserState.getEntities);
        const currentModule = this.store.selectSnapshot(ModuleState.getEntity);

        if (currentModule) {
            this._setSubentities(ctx, { payload: moduleUsers.filter((moduleUser) => moduleUser.isParticipant && moduleUser.moduleId === currentModule.id) }, AddModuleUsers);
        }
    }

    @Action(RemoveUsersFromModulesAsParticipantsSuccess)
    removeUsersFromModulesAsParticipants(ctx: StateContext<ModuleParticipantsStateModel>): void {
        const moduleUsers = this.store.selectSnapshot(ModuleUserState.getEntities);
        const currentModule = this.store.selectSnapshot(ModuleState.getEntity);

        if (currentModule) {
            this._setSubentities(ctx, { payload: moduleUsers.filter((moduleUser) => moduleUser.isParticipant && moduleUser.moduleId === currentModule.id) }, AddModuleUsers);
        }
    }

    @Action(SearchModuleParticipants)
    search(ctx: StateContext<ModuleParticipantsStateModel>, { payload }: SearchModuleParticipants): void {
        if (payload) {
            const { searchTerm, source } = payload;
            switch (source) {
                case 'manager':
                    UtilsState.searchManager(ctx, searchTerm, ModuleParticipantsStateModel.identifier);
                    break;
                default:
                    UtilsState.search(ctx, searchTerm, ModuleParticipantsStateModel.identifier);
                    break;
            }
        }
    }

    @Action(SortModuleParticipants)
    sort(stateContext: StateContext<ModuleParticipantsStateModel>, { payload }: SortModuleParticipants): void {
        UtilsState.sort(stateContext, payload, ModuleParticipantsStateModel.identifier);
    }
}
