import { IUserOnlineStateQueryInterface } from '../interfaces/user-online-state-query.interface';
import { OnlineState } from './online-state.model';

export class UserOnlineState {
    subdomain: string;
    onlineState: OnlineState[];

    constructor(query: IUserOnlineStateQueryInterface) {
        this.subdomain = query.subdomain;
        this.onlineState = query.online_state.map((state) => new OnlineState(state));
    }
}
