import * as momentImported from 'moment';
import { IScheduleInfoQuery } from '../interfaces/schedule-info.query.interface';
const moment = momentImported;

export class ScheduleInfo {
    status: string;
    execDate: Date;
    execTime: string;
    context: {
        subject: string;
        message: string;
        sendNotification: boolean;
    };

    constructor(query: IScheduleInfoQuery) {
        this.status = query.status;
        this.execDate = query.exec_time ? new Date(query.exec_time) : null;
        this.execTime = query.exec_time ? moment(query.exec_time).format('HH:mm') : '';
        if (query.context) {
            this.context = {
                subject: query.context.subject,
                message: query.context.message,
                sendNotification: query.context.sendNotification,
            };
        }
    }
}
