import { IHeaderBannerQuery } from 'app/library/grow/interfaces/header-banner.query.interface';

export class HeaderBanner {
    id: string;
    title: string;
    description: string;
    image: {
        id: string;
        unsplash?: {
            unsplashId: string;
            authorName: string;
            authorProfileUrl: string;
            authorProfileImage: string;
        };
        url: string;
        smallUrl: string;
        thumbUrl: string;
    };

    constructor(headerBannerQuery?: IHeaderBannerQuery) {
        if (headerBannerQuery) {
            if (headerBannerQuery.id) {
                this.id = headerBannerQuery.id.toString();
                this.title = headerBannerQuery.title;
                this.description = headerBannerQuery.description;
            }
            if (headerBannerQuery.image) {
                this.image = {
                    id: headerBannerQuery.image.id.toString(),
                    url: headerBannerQuery.image.url,
                    smallUrl: headerBannerQuery.image.small_url,
                    thumbUrl: headerBannerQuery.image.thumb_url,
                };

                if (headerBannerQuery.image.unsplash) {
                    this.image.unsplash = {
                        unsplashId: headerBannerQuery.image.unsplash.unsplash_id.toString(),
                        authorName: headerBannerQuery.image.unsplash.author_name,
                        authorProfileUrl: headerBannerQuery.image.unsplash.author_profile_url,
                        authorProfileImage: headerBannerQuery.image.unsplash.author_profile_image,
                    };
                }
            } else {
                this.image = null;
            }
        }
    }
}
