<ng-container *ngIf="!item.hidden">
    <!-- item.url -->
    <a
        class="nav-link px-16"
        [attr.test-id]="item.id"
        [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && !item.function"
        [routerLink]="item.url"
        [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <span class="badge-count alternative" *ngIf="item.id === 'feed' && (unreadFeedItemsCount$ | async)">{{
            (unreadFeedItemsCount$ | async) > 9 ? '9+' : (unreadFeedItemsCount$ | async)
        }}</span>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.action -->
    <a
        class="nav-link"
        [attr.test-id]="item.id"
        [ngClass]="item.classes"
        *ngIf="item.action && !item.externalUrl && !item.function"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <span class="badge-count" *ngIf="item.action && !isDrawerOpen && numberOfActiveAsyncJobs > 0">{{ numberOfActiveAsyncJobs }}</span>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a
        class="nav-link"
        [attr.test-id]="item.id"
        [ngClass]="item.classes"
        *ngIf="item.url && item.externalUrl && !item.function"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link" [attr.test-id]="item.id" [ngClass]="item.classes" *ngIf="!item.url && item.function" (click)="item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a
        class="nav-link"
        [attr.test-id]="item.id"
        [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && item.function"
        (click)="item.function()"
        [routerLink]="[item.url]"
        [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a
        class="nav-link"
        [attr.test-id]="item.id"
        [ngClass]="item.classes"
        *ngIf="item.url && item.externalUrl && item.function"
        (click)="item.function()"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>
        <mat-icon class="nav-link-icon" *ngIf="item.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
        <span class="nav-link-title text-sm" [translate]="item.translate"></span>
        <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate" [ngStyle]="{ 'background-color': item.badge.bg, color: item.badge.fg }">
            {{ item.badge.title }}
        </span>
    </ng-template>
</ng-container>
