import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { IEntityApiService } from 'app/projects/entity/src/lib/interfaces/entity.service.interface';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModuleEventAttendanceCommand } from '../models/module-event-attendance.command.model';
import { ModuleEventAttendance } from '../models/module-event-attendance.model';
import { IModuleEventAttendanceQuery } from '../interfaces/module-event-attendance.query.interface';
import { IModuleEventAttendanceStatisticsQuery } from '../interfaces/module-event-attendance-statistics.query.interface';
import { ModuleEventAttendanceStatistics } from '../models/module-event-attendance-statistics.model';

export interface IModuleEventAttendancesAndTotalCount {
    data: IModuleEventAttendanceQuery[];
    pagination: {
        number_of_pages: number;
        number_of_results: number;
    };
}

@Injectable()
export class ModuleEventAttendanceApiService extends EntityService<IModuleEventAttendanceQuery, ModuleEventAttendanceCommand> implements IEntityApiService<ModuleEventAttendance> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) private environment: IEnvironment) {
        super(http, environment.apiUrl, 'module-events');
    }

    getAll(): Observable<ModuleEventAttendance[]> {
        return this._getAll().pipe(map((queries) => queries.map((query) => new ModuleEventAttendance(query))));
    }

    create(entity: ModuleEventAttendance): Observable<ModuleEventAttendance> {
        const data = new ModuleEventAttendanceCommand(entity);

        return this._create(data).pipe(map((query) => new ModuleEventAttendance(query)));
    }

    read(id: string): Observable<ModuleEventAttendance> {
        return this._read(id).pipe(map((query) => new ModuleEventAttendance(query)));
    }

    update(entity: ModuleEventAttendance): Observable<ModuleEventAttendance> {
        const data = new ModuleEventAttendanceCommand(entity);

        return this._update(entity.id, data).pipe(map((query) => new ModuleEventAttendance(query)));
    }

    delete(id: string): Observable<any> {
        return this._delete(id);
    }

    getModuleEventAttendances(
        eventId: string,
        search = '',
        sortBy = '',
        sortOrder = '',
        pageSize = 10,
        pageIndex = 1,
        attendanceStatuses = []
    ): Observable<IModuleEventAttendancesAndTotalCount> {
        let params = new HttpParams().set('page', pageIndex.toString()).set('limit', pageSize.toString());

        if (search) {
            params = params.set('filter[query]', search);
        }

        if (sortBy && sortOrder) {
            params = params.set('sortBy', sortBy);
            params = params.set('sortOrder', sortOrder);
        }

        if (attendanceStatuses.length) {
            params = params.set('filter[attendance]', attendanceStatuses.toString());
        }

        return this.http.get<IModuleEventAttendancesAndTotalCount>(`${this.environment.apiUrl}/${this.entities}/${eventId}/attendances`, { params });
    }

    getModuleEventAttendanceStatistics(moduleId: string): Observable<ModuleEventAttendanceStatistics[]> {
        return this.http.get<IModuleEventAttendanceStatisticsQuery[]>(`${this.apiUrl}/module-events/${moduleId}/attendance-statistics`).pipe(
            map((queries) =>
                queries.map((query) => {
                    return new ModuleEventAttendanceStatistics(query);
                })
            )
        );
    }

    getParticipantAttendances(moduleId: string, participantId: string, sortBy = '', sortOrder = ''): Observable<ModuleEventAttendance[]> {
        let params = new HttpParams();

        if (sortBy && sortOrder) {
            params = params.set('sortBy', sortBy);
            params = params.set('sortOrder', sortOrder);
        }

        return this.http.get<IModuleEventAttendanceQuery[]>(`${this.apiUrl}/modules/${moduleId}/participants/${participantId}/attendances`, { params }).pipe(
            map((queries) =>
                queries.map((query) => {
                    return new ModuleEventAttendance(query);
                })
            )
        );
    }

    updateAttendanceStatistic(eventId: string, entity: ModuleEventAttendance): Observable<ModuleEventAttendance> {
        const data = new ModuleEventAttendanceCommand(entity);

        return this.http.patch<IModuleEventAttendanceQuery>(`${this.apiUrl}/event-attendances/${eventId}`, data).pipe(map((query) => new ModuleEventAttendance(query)));
    }
}
