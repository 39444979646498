import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { MaxBrainEvent } from 'app/library/event/models/event.model';
import { FetchLocations, SearchLocations, UnsetLocationAll } from 'app/library/location/actions';
import { ILocationQuery } from 'app/library/location/interfaces/location.query.interface';
import { MaxBrainLocation } from 'app/library/location/models/location.model';
import { LocationsState } from 'app/library/location/models/locations.state';
import { ModuleFeatureService } from 'app/library/module-feature/services/module-feature.service';
import { ModuleKind } from 'app/library/module/enums/module-kind.enum';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';
import { MaxBrainEditorDialogComponent } from 'app/projects/shared/src/lib/components/dialogs/editor/editor.component';
import { ModuleFeatureSwitchName } from 'app/projects/shared/src/lib/enums/module-feature-switch.enum';
import { HelperService } from 'app/projects/shared/src/lib/services/helper';
import { TinymceService } from 'app/services/tinymce.service';
import * as momentImported from 'moment';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
const moment = momentImported;

@Component({
    selector: 'app-event-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class EventFormComponent extends UnsubscribeOnDestroy implements OnInit {
    @Select(LocationsState.getFilteredEntities)
    location$: Observable<MaxBrainLocation[]>;
    locations: MaxBrainLocation[] = [];

    editorDialogRef: MatDialogRef<MaxBrainEditorDialogComponent>;

    @Input()
    title: string;

    // Public events has no Attendance Tracking
    @Input()
    isModuleEvent: boolean;

    @Input()
    isEditMode: boolean;

    @Input()
    moduleKind: ModuleKind;

    ModuleKind = ModuleKind;

    @Input()
    event: MaxBrainEvent;
    eventForm: FormGroup;

    isModuleFeatureAttendanceActive: boolean;
    selected: { startDate: Moment; endDate: Moment };

    constructor(
        public dialogRef: MatDialogRef<EventFormComponent>,
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private _store: Store,
        private _tinymceService: TinymceService,
        private _moduleFeatureService: ModuleFeatureService,
        private _helperService: HelperService
    ) {
        super();

        this._store.dispatch(new FetchLocations());
        this._store.dispatch(new SearchLocations(''));

        this.isModuleFeatureAttendanceActive = this._moduleFeatureService.checkModuleFeatureStatus(ModuleFeatureSwitchName.Attendance);
    }

    ngOnInit(): void {
        this.dialogRef.beforeClosed().subscribe(() => {
            this._store.dispatch([new UnsetLocationAll()]);
        });

        this.location$
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((locations) => !!locations)
            )
            .subscribe((locations) => (this.locations = locations));

        this.selected = {
            startDate: this.event.start ? moment(this.event.start) : null,
            endDate: this.event.end ? moment(this.event.end) : null,
        };

        this.eventForm = this._formBuilder.group({
            id: [this.event.id],
            name: [this.event.name, Validators.required],
            description: [this.event.description],
            startEndDate: [this.selected.startDate ? this.selected : '', Validators.required],
            startTime: [this.event.start ? moment(this.event.start).format('HH:mm') : '', Validators.required],
            endTime: [this.event.end ? moment(this.event.end).format('HH:mm') : '', Validators.required],
            location: [this.event.location ? this.event.location.name : ''],
            uniqueId: [this.event.uniqueId],
            trackAttendance: this.isEditMode ? this.event.trackAttendance : this.isModuleFeatureAttendanceActive,
        });

        this.eventForm.controls['location'].valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(300), distinctUntilChanged()).subscribe((searchTerm) => {
            this._store.dispatch(new SearchLocations(searchTerm));
        });
    }

    editDescription(): void {
        this.editorDialogRef = this._matDialog.open(MaxBrainEditorDialogComponent, {
            width: '1000px',
            disableClose: true,
        });

        const init: any = this._tinymceService.getInitConfiguration();
        init.plugins = `link`;

        // const maxNumberOfRows = Math.floor((window.innerHeight - 657) / 32);
        const minNumberOfRows = 6;

        init.min_height = minNumberOfRows * 32 + 20;

        this.editorDialogRef.componentInstance.dialogTitle = 'FORM.EVENT.FIELD.DESCRIPTION';
        this.editorDialogRef.componentInstance.data = this.eventForm.value.description;
        this.editorDialogRef.componentInstance.init = init;

        this.editorDialogRef
            .afterClosed()
            .pipe(filter((result) => !!result))
            .subscribe((result) => {
                if (result.data !== this.eventForm.value.description) {
                    this.eventForm.patchValue({
                        description: result.data,
                    });
                    this.eventForm.markAsDirty();
                }
            });
    }

    save(): void {
        const formData = this.eventForm.getRawValue();

        const locationQuery: ILocationQuery = {
            id: null,
            name: formData.location,
        };

        const filteredLocations = this.locations.filter((tempLocation) => tempLocation.name === formData.location);

        if (filteredLocations.length === 1) {
            const filteredLocation = filteredLocations[0];

            locationQuery.id = Number.parseInt(filteredLocation.id, 10);
            locationQuery.name = filteredLocation.name;
        } else if (filteredLocations.length > 1) {
            console.error('There are multiple locations with the same name');
        }

        const startDate = this.eventForm.get('startEndDate').value.startDate;
        const endDate = this.eventForm.get('startEndDate').value.endDate;
        const startTime = this._helperService.cleaveTimeFixer(this.eventForm.get('startTime').value);
        const endTime = this._helperService.cleaveTimeFixer(this.eventForm.get('endTime').value);

        if (startDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD') && startTime >= endTime) {
            this._helperService.showSnackBarMessage('FORM.EVENT.MESSAGE.END_DATE_MUST_BE_GT_START_DATE');

            return;
        }

        this.dialogRef.close({
            data: new MaxBrainEvent({
                id: formData.id ? Number.parseInt(formData.id, 10) : 0,
                name: formData.name,
                description: formData.description,
                start: formData.startEndDate.startDate ? new Date(`${formData.startEndDate.startDate.format('YYYY-MM-DD'.replace(/-/g, '/'))} ${startTime}`).toISOString() : null,
                end: formData.startEndDate.endDate ? new Date(`${formData.startEndDate.endDate.format('YYYY-MM-DD'.replace(/-/g, '/'))} ${endTime}`).toISOString() : null,
                is_all_day: false,
                location: locationQuery,
                module: formData.module,
                module_type: formData.moduletype,
                unique_id: formData.uniqueId,
                track_attendance: formData.trackAttendance,
            }),
        });
    }
}
