import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { CreateExperienceSuccess, DeleteExperience, SetExperiences } from 'app/library/experience/actions';
import { AddExperiences } from 'app/library/experience/actions/add-entities.action';
import { MaxBrainExperience } from 'app/library/experience/models/experience.model';
import { ExperienceState } from 'app/library/experience/models/experience.state';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { DeselectSelectedExperiences, SearchExperiences, SelectFilteredExperiences, ToggleSelectedExperience } from '../actions';
import { ExperiencesStateModel } from './experiences.state-model';

@State<ExperiencesStateModel>({
    name: 'experiences',
    defaults: new ExperiencesStateModel(),
})
@Injectable()
export class ExperiencesState extends SubentityState<MaxBrainExperience> {
    @Selector([ExperienceState.getEntities])
    static getFilteredEntities(state: ExperiencesStateModel, experiences: MaxBrainExperience[]): MaxBrainExperience[] {
        if (!experiences) {
            return null;
        }

        return experiences
            .filter((item) => state.filtered.indexOf(item.id) !== -1)
            .map((item) => {
                return [state.list.indexOf(item.id), item];
            })
            .sort((a: [number, MaxBrainExperience], b: [number, MaxBrainExperience]) => {
                return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
            })
            .map((x: [number, MaxBrainExperience]) => x[1]);
    }

    @Selector()
    static getSelectedEntityIds(state: ExperiencesStateModel): string[] {
        return state.selected;
    }

    @Selector()
    static getSearchTerm(state: ExperiencesStateModel): string {
        return state.searchTerm;
    }

    constructor(private store: Store) {
        super(null, 0);

        // this._propstoSearch = [...MaxBrainExperience.props_to_search];
    }

    @Action(AddExperiences)
    addEntities(ctx: StateContext<ExperiencesStateModel>, action: AddExperiences): void {
        const experiences = this.store.selectSnapshot(ExperienceState.getEntities) || [];

        this._patchSubentities(ctx, { payload: { subentityIds: action.payload.map((entity) => entity.id) } }, experiences);
    }

    @Action(CreateExperienceSuccess)
    createEntity(ctx: StateContext<ExperiencesStateModel>, action: CreateExperienceSuccess): void {
        const experiences = this.store.selectSnapshot(ExperienceState.getEntities) || [];

        this._patchSubentities(ctx, { payload: { subentityIds: [action.payload.id] } }, experiences);
    }

    @Action(DeleteExperience)
    deleteEntity({ getState, patchState }: StateContext<ExperiencesStateModel>, { payload }: DeleteExperience): void {
        const state = getState();

        patchState({
            list: [...state.list.filter((subentityId) => subentityId !== payload)],
            selected: [...state.selected.filter((subentityId) => subentityId !== payload)],
            filtered: [...state.filtered.filter((subentityId) => subentityId !== payload)],
        });
    }

    @Action(SetExperiences)
    setEntities(ctx: StateContext<ExperiencesStateModel>, action: SetExperiences): void {
        this._setSubentities(ctx, action, null);
    }

    @Action(ToggleSelectedExperience)
    toggleSelect(ctx: StateContext<ExperiencesStateModel>, action: ToggleSelectedExperience): void {
        this._toggleSelectedSubentity(ctx, action);
    }

    @Action(SelectFilteredExperiences)
    selectAll(ctx: StateContext<ExperiencesStateModel>): void {
        this._selectFilteredSubentities(ctx);
    }

    @Action(DeselectSelectedExperiences)
    deselectAll(ctx: StateContext<ExperiencesStateModel>): void {
        this._deselectSelectedSubentities(ctx);
    }

    @Action(SearchExperiences)
    search(ctx: StateContext<ExperiencesStateModel>, action: SearchExperiences): void {
        const experiences = this.store.selectSnapshot(ExperienceState.getEntities);

        this._searchSubentities(ctx, action, experiences);
    }
}
