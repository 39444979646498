import { IImportStatusQuery } from '../interfaces/import-status.query.interface';
import { ScormCourse } from './scorm-course.model';

export class ImportStatus {
    jobId: string;
    status: string;
    message: string;
    course: ScormCourse;

    constructor(query?: IImportStatusQuery) {
        if (query) {
            this.jobId = query.jobId;
            this.status = query.status;
            this.message = query.message;
            if (query.importResult) {
                this.course = new ScormCourse(query.importResult.course);
            }
        }
    }
}
