import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from '../enums/job-status.enum';

@Pipe({ name: 'getJobStatusIconColor' })
export class GetJobStatusIconColorPipe implements PipeTransform {
    transform(jobStatus: JobStatus, jobStats: any): any {
        switch (jobStatus) {
            case JobStatus.Created:
            case JobStatus.InProgress:
                return 'blue-icon';
            case JobStatus.TimedOut:
                return 'orange-icon';
            case JobStatus.Canceled:
                return 'red-icon';
            case JobStatus.Finished:
                return jobStats.failed > 0 ? 'red-icon' : 'green-icon';
            default:
                return 'gray-icon';
        }
    }
}
