<mat-expansion-panel class="mat-elevation-z2 progress-drawer mr-24" *ngIf="isPanelVisible" hideToggle="true" expanded="isExpanded" (expandedChange)="onExpandedChange($event)">
    <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!isAsyncJobVisable; else jobDetailsTitle">
            <div>
                <h4>
                    <mat-icon>menu_open</mat-icon>
                    <span>{{ 'ASYNC_DRAWER.TITLE' | translate }}</span>
                </h4>
                <h6>{{ asyncJobService.completedJobs }}/{{ asyncJobService.jobsPagination.numberOfResults }} {{ 'ASYNC_DRAWER.SUBTITLE.STATUS' | translate }}</h6>
            </div>
            <div class="actions-container">
                <mat-icon class="pointer mr-8 mb-4" *ngIf="isExpanded">minimize</mat-icon>
                <mat-icon (click)="$event.stopPropagation(); onCloseDrawer()">close</mat-icon>
            </div>
        </mat-panel-title>
        <ng-template #jobDetailsTitle>
            <mat-panel-title class="accordion-title" fxLayout="row" fxLayoutAlign="space-between center">
                <div *ngIf="jobStatus">
                    <h4>
                        <mat-icon class="mr-8" (click)="$event.stopPropagation(); onToggleAsyncJob()">keyboard_backspace </mat-icon>
                        <mat-icon>sticky_note_2</mat-icon>
                        <span> {{ 'ASYNC_DRAWER.JOB_TYPE.' + jobStatus.type | translate }}:</span>
                    </h4>
                    <h6>
                        {{ jobStatus.name }} <span *ngIf="jobStatus.additionalInfo">/ {{ jobStatus.additionalInfo }}</span>
                    </h6>
                </div>
                <div class="actions-container">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <mat-icon (click)="$event.stopPropagation(); onCloseDrawer()">close</mat-icon>
                </div>
            </mat-panel-title>
        </ng-template>
    </mat-expansion-panel-header>
    <ng-container *ngTemplateOutlet="matExpansionPanelContent"></ng-container>
</mat-expansion-panel>

<ng-template #matExpansionPanelContent>
    <mat-list class="mat-elevation-z2" role="list" *ngIf="!isAsyncJobVisable; else jobDetails">
        <mat-list-item *ngFor="let job of asyncJobs" class="jobs-container" role="listitem" [class.is-read]="!job.isRead" (click)="onGetJobStatus(job)">
            <div class="job full-width">
                <mat-icon class="certificate-icon">sticky_note_2</mat-icon>
                <div class="job-info full-width">
                    <h6>
                        {{ 'ASYNC_DRAWER.JOB_TYPE.' + job.type | translate }}
                        <mat-icon
                            (click)="$event.stopPropagation(); onCloseAsyncJob(job)"
                            *ngIf="job.communicatedStatus === JobStatus.Finished || job.communicatedStatus === JobStatus.TimedOut || job.communicatedStatus === JobStatus.Canceled"
                        >
                            close</mat-icon
                        >
                    </h6>
                    <div *ngIf="job.type !== 'copy_module'; else moduleName" class="text-truncate w-100-p file-name" [title]="job.name">
                        {{ job.name }}
                        <span *ngIf="job.additionalInfo">/ {{ job.additionalInfo }}</span>
                    </div>
                    <ng-template #moduleName
                        ><a class="text-truncate pointer" (click)="$event.stopPropagation(); navigateToModule(job.entityId)">{{ job.name }}</a></ng-template
                    >
                    <div class="job-stats-container">
                        <div class="job-stats">
                            <p *ngFor="let stat of job.stats | keyvalue">
                                <span>{{ stat.value }}</span> &nbsp;
                                {{ 'ASYNC_DRAWER.RESPONSE_STATS.' + stat.key | translate }}
                            </p>
                        </div>
                        <mat-icon> keyboard_arrow_right</mat-icon>
                    </div>
                    <div class="success-nodes-grade">
                        <mat-progress-bar
                            attr.aria-label="{{ 'GENERAL.LABEL.PROGRESS_BAR' | translate }}"
                            mode="determinate"
                            class="job-progress-bar"
                            [ngClass]="job.communicatedStatus | getProgressBarColor: job.stats"
                            [value]="job.progressPercentage"
                        ></mat-progress-bar>
                        <mat-icon [ngClass]="job.communicatedStatus | getJobStatusIconColor: job.stats" svgIcon="{{ job.communicatedStatus | getJobStatusIcon: job.stats }}">
                        </mat-icon>
                    </div>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
    <ng-template #jobDetails>
        <div class="job-details-container" *ngIf="jobStatus">
            <h3 class="mb-12">{{ 'ASYNC_DRAWER.RESPONSE_STATUS.' + jobStatus.communicatedStatus | translate }}</h3>
            <mat-progress-bar
                attr.aria-label="{{ 'GENERAL.LABEL.PROGRESS_BAR' | translate }}"
                mode="determinate"
                class="job-progress-bar"
                [ngClass]="jobStatus.communicatedStatus | getProgressBarColor: jobStatus.stats"
                [value]="jobStatus.progressPercentage"
            ></mat-progress-bar>
            <div class="cancel-action-container">
                <button mat-stroked-button class="full-width" *ngIf="jobStatus.communicatedStatus === JobStatus.InProgress" (click)="onCancelAsyncJob(jobStatus)">
                    {{ 'ASYNC_DRAWER.BUTTON.CANCEL_TASK' | translate }}
                </button>
            </div>
            <mat-list class="mat-elevation-z2" role="list">
                <mat-list-item *ngFor="let stat of jobStatus.stats | keyvalue" role="listitem" class="pointer" (click)="onGetJobMessages(jobStatus.id, 1, stat.key, stat.value)">
                    <div class="full-width">
                        <div class="status-title-container">
                            <span [style.color]="stat.key | getJobStatTextColor">{{ stat.value }}</span>
                            <h4 class="capitalize py-12">
                                {{ 'ASYNC_DRAWER.RESPONSE_STATS.' + stat.key | translate }}
                                <mat-icon [ngClass]="stat.key | getJobStatsIconColor" svgIcon="{{ stat.key | getJobStatIcon }}"></mat-icon>
                            </h4>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>

            <mat-list *ngIf="jobMessages && jobMessagesVisable" class="mat-elevation-z2" role="list" class="error-message-container">
                <mat-expansion-panel hideToggle [disabled]="message.status !== 'failed'" *ngFor="let message of jobMessages" role="listitem" class="error-message">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="error-title-container">
                            <div *ngIf="jobStatus.type === 'create_execution' || jobStatus.type === 'copy_module'; else showUserAvatar">
                                <mat-icon class="mr-8" svgIcon="course"> </mat-icon>
                            </div>
                            <ng-template #showUserAvatar>
                                <img class="avatar" *ngIf="profile.avatar.src; else initials" [alt]="profile.fullName" [src]="profile.avatar.src" />
                                <ng-template #initials>
                                    <div fxLayoutAlign="center center" class="avatar-initials avatar">
                                        {{ (jobStatus.type === 'publish_files' ? message.title : profile.fullName) | initials }}
                                    </div>
                                </ng-template>
                            </ng-template>
                            <h4 class="capitalize">
                                {{ message.title }}
                                <div class="icons-container">
                                    <mat-icon *ngIf="message.status === 'failed'">keyboard_arrow_down</mat-icon>
                                    <mat-icon svgIcon="{{ message.status | getJobStatIcon }}"></mat-icon>
                                </div>
                            </h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="center error-description" *ngIf="message.status === 'failed'">
                        {{ 'ERROR.RESPONSE.' + message.errorInfo.message | translate }}
                    </p>
                </mat-expansion-panel>
            </mat-list>
        </div>
    </ng-template>
</ng-template>
