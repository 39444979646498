export class FetchUserAdditionalEmails {
    static readonly type = '[User] FetchUserAdditionalEmails';

    constructor(public payload: { userId: string; isAdditional: boolean; isVerified: boolean }) {}
}

export class FetchUserVerifiedAdditionalEmails {
    static readonly type = '[User] FetchUserVerifiedAdditionalEmails';

    constructor(public payload: { userId: string; isAdditional: boolean; isVerified: boolean }) {}
}
