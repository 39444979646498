import { IUserProfilePicture } from '../interfaces/user-profile-picture.interface';

export class UserProfilePicture {
    url: string;
    smallUrl: string;
    thumbUrl: string;
    largeUrl: string;

    constructor(query: IUserProfilePicture) {
        this.url = query.url;
        this.smallUrl = query.small_url;
        this.thumbUrl = query.thumb_url;
        this.largeUrl = query.large_url;
    }
}
