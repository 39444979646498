import { ICreateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/create-entity-failure-payload.interface';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { TeamTree } from '../models/team-tree.model';
import { Team } from '../models/team.model';
import { IAddTeamMembersActionPayload } from '../models/add-team-members-action.action.payload';
import { User } from 'app/projects/user/src/lib/models/user';
import { HttpErrorResponse } from '@angular/common/http';
import { TeamMember } from '../models/team-member.model';
import { IAddTeamLeadersActionPayload } from '../models/add-team-leaders-action.action.payload';
import { IRemoveTeamLeadersActionPayload } from '../models/remove-team-leaders-action.action.payload';
import { IRemoveTeamWatchersActionPayload } from '../models/remove-team-watchers-action.action.payload';
import { IRemoveTeamMembersActionPayload } from '../models/remove-team-members-action.action.payload';
import { UserTeam } from '../models/user-team.model';
import { TeamBreadcrumb } from '../models/team-breadcrumb.model';
import { GlobalParticipant } from 'app/projects/user/src/lib/models/participant.model';

export class SetTeam {
    static readonly type = '[Team] SetTeam';

    constructor(public payload: Team) {}
}

export class UnsetTeam {
    static readonly type = '[Team] UnsetTeam';
}

export class FetchTeam {
    static readonly type = '[Team] FetchTeam';

    constructor(public payload: string) {}
}

export class FetchTeamSuccess {
    static readonly type = '[Team] FetchTeamSuccess';

    constructor(public payload: Team) {}
}

export class FetchTeamFailure {
    static readonly type = '[Team] FetchTeamFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class FetchUserTeams {
    static readonly type = '[Team] FetchUserTeams';

    constructor(public payload: { userId: string }) {}
}

export class FetchUserTeamsSuccess {
    static readonly type = '[Team] FetchUserTeamsSuccess';

    constructor(public payload: UserTeam[]) {}
}

export class FetchUserTeamsFailure {
    static readonly type = '[Team] FetchUserTeamsFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}
export class FetchTeamUsers {
    static readonly type = '[Team] FetchTeamUsers';

    constructor(public payload: { teamId: string; selectedTeamId: string; role: string }) {}
}

export class FetchTeamUsersSuccess {
    static readonly type = '[Team] FetchTeamUsersSuccess';

    constructor(public payload: User[]) {}
}

export class FetchTeamUsersFailure {
    static readonly type = '[Team] FetchTeamUsersFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class FetchTeamWithSubTeamsProgress {
    static readonly type = '[Team] FetchTeamWithSubTeamsProgress';

    constructor(public payload: { teamId: string; includeSubTeamsProgress: boolean }) {}
}

export class FetchTeamWithSubTeamsProgressSuccess {
    static readonly type = '[Team] FetchTeamWithSubTeamsProgressSuccess';

    constructor(public payload: Team) {}
}

export class FetchTeamWithSubTeamsProgressFailure {
    static readonly type = '[Team] FetchTeamWithSubTeamsProgressFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}
export class FetchTeamSubTeams {
    static readonly type = '[Team] FetchTeamSubTeams';

    constructor(public payload: { teamId: string }) {}
}

export class FetchTeamSubTeamsSuccess {
    static readonly type = '[Team] FetchTeamSubTeamsSuccess';

    constructor(public payload: Team[]) {}
}

export class FetchTeamSubTeamsFailure {
    static readonly type = '[Team] FetchTeamSubTeamsFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class FetchSubTeamWithSubteamsProgress {
    static readonly type = '[Team] FetchSubTeamWithSubteamsProgress';

    constructor(public payload: { teamId: string; includeSubTeamsProgress: boolean }) {}
}

export class FetchSubTeamWithSubteamsProgressSuccess {
    static readonly type = '[Team] FetchSubTeamWithSubteamsProgressSuccess';

    constructor(public payload: Team) {}
}

export class FetchSubTeamWithSubteamsProgressFailure {
    static readonly type = '[Team] FetchSubTeamWithSubteamsProgressFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class CreateTeam {
    static readonly type = '[Team] CreateTeam';

    constructor(public payload: Team) {}
}

export class CreateTeamSuccess {
    static readonly type = '[Team] CreateTeamSuccess';

    constructor(public payload: Team) {}
}

export class CreateTeamFailure {
    static readonly type = '[Team] CreateTeamFailure';

    constructor(public payload: ICreateEntityFailureActionPayload) {}
}

export class UpdateTeam {
    static readonly type = '[Team] UpdateTeam';

    constructor(public payload: Team) {}
}

export class UpdateTeamSuccess {
    static readonly type = '[Team] UpdateTeamSuccess';

    constructor(public payload: Team) {}
}

export class UpdateTeamFailure {
    static readonly type = '[Team] UpdateTeamFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}
export class DeleteTeam {
    static readonly type = '[Team] DeleteTeam';

    constructor(public payload: string) {}
}

export class DeleteTeamSuccess {
    static readonly type = '[Team] DeleteTeamSuccess';

    constructor(public payload: string) {}
}

export class DeleteTeamFailure {
    static readonly type = '[Team] DeleteTeamFailure';

    constructor(public payload: string) {}
}

export class SetTeams {
    static readonly type = '[Team] SetTeams';

    constructor(public payload: Team[]) {}
}

export class FetchTeams {
    static readonly type = '[Team] FetchTeams';
}

export class FetchTeamsSuccess {
    static readonly type = '[Team] FetchTeamsSuccess';
}

export class FetchTeamsFailure {
    static readonly type = '[Team] FetchTeamsFailure';
}

export class FetchTeamsUsers {
    static readonly type = '[Team] FetchTeamsUsers';

    constructor(
        public payload: {
            teamId: string;
            search: string;
            pageSize: number;
            pageNumber: number;
            role: string;
        }
    ) {}
}

export class FetchTeamsUsersSuccess {
    static readonly type = '[Team] FetchTeamsUsersSuccess';

    constructor(public payload: User[]) {}
}

export class FetchTeamsUsersFailure {
    static readonly type = '[Team] FetchTeamsUsersFailure';
}

export class SelectFilteredTeams {
    static readonly type = '[Team] SelectFilteredTeams';
}

export class SearchTeams {
    static readonly type = '[Team] SearchTeams';

    constructor(
        public payload: {
            search: string;
            pageSize: number;
            pageNumber: number;
        }
    ) {}
}

export class SearchTeamsSuccess {
    static readonly type = '[Team] SearchTeamsSuccess';

    constructor(public payload: TeamTree[]) {}
}

export class SearchTeamsFailure {
    static readonly type = '[Team] SearchTeamsFailure';
}

export class FetchUserProgress {
    static readonly type = '[Team] FetchUserProgress';

    constructor(public userId: string) {}
}

export class FetchUserProgressSuccess {
    static readonly type = '[Team] FetchUserProgressSuccess';

    constructor(public payload: TeamMember) {}
}

export class FetchUserProgressFailure {
    static readonly type = '[Team] FetchUserProgressFailure';
}

export class FetchUserOtherTeams {
    static readonly type = '[Team] FetchUserOtherTeams';

    constructor(public teamId: string, public userId: string) {}
}

export class FetchUserOtherTeamsSuccess {
    static readonly type = '[Team] FetchUserOtherTeamsSuccess';

    constructor(public payload: Team[]) {}
}

export class FetchUserOtherTeamsFailure {
    static readonly type = '[Team] FetchUserOtherTeamsFailure';
}

/* Team Members */

export class FetchTeamMembersUsers {
    static readonly type = '[Team] FetchTeamMembersUsers';

    constructor(public teamId: string) {}
}

export class FetchTeamMembersUsersSuccess {
    static readonly type = '[Team] FetchTeamMembersUsersSuccess';

    constructor(public payload: GlobalParticipant[]) {}
}

export class FetchTeamMembersUsersFailure {
    static readonly type = '[Team] FetchTeamMembersUsersFailure';
}

export class FetchTeamMembers {
    static readonly type = '[Team] FetchTeamMembers';

    constructor(public teamId: string) {}
}

export class FetchTeamMembersSuccess {
    static readonly type = '[Team] FetchTeamMembersSuccess';

    constructor(public payload: TeamMember[]) {}
}

export class FetchTeamMembersFailure {
    static readonly type = '[Team] FetchTeamMembersFailure';
}

export class AddUsersToTeamsAsMembers {
    static readonly type = '[Team] AddUsersToTeamsAsMembers';

    constructor(public payload: IAddTeamMembersActionPayload) {}
}

export class AddUsersToTeamsAsMembersSuccess {
    static readonly type = '[Team] AddUsersToTeamsAsMembersSuccess';

    constructor(public payload: TeamMember[]) {}
}

export class AddUsersToTeamsAsMembersFailure {
    static readonly type = '[Team] AddUsersToTeamsAsMembersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}
export class RemoveTeamMembers {
    static readonly type = '[Team] RemoveTeamMembers';

    constructor(public payload: IRemoveTeamMembersActionPayload) {}
}

export class RemoveTeamMembersSuccess {
    static readonly type = '[Team] RemoveTeamMembersSuccess';

    constructor() {}
}

export class RemoveTeamMembersFailure {
    static readonly type = '[Team] RemoveTeamMembersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

/* Team Leaders */

export class FetchTeamLeaders {
    static readonly type = '[Team] FetchTeamLeaders';

    constructor(public teamId: string) {}
}

export class FetchTeamLeadersSuccess {
    static readonly type = '[Team] FetchTeamLeadersSuccess';

    constructor(public payload: User[]) {}
}

export class FetchTeamLeadersFailure {
    static readonly type = '[Team] FetchTeamLeadersFailure';
}

export class FetchTeamLeaderTeams {
    static readonly type = '[Team] FetchTeamLeaderTeams';
}

export class FetchTeamLeaderTeamsSuccess {
    static readonly type = '[Team] FetchTeamLeaderTeamsSuccess';

    constructor(public payload: Team[]) {}
}

export class FetchTeamLeaderTeamsFailure {
    static readonly type = '[Team] FetchTeamLeaderTeamsFailure';
}

export class RemoveTeamLeaders {
    static readonly type = '[Team] RemoveTeamLeaders';

    constructor(public payload: IRemoveTeamLeadersActionPayload) {}
}

export class RemoveTeamLeadersSuccess {
    static readonly type = '[Team] RemoveTeamLeadersSuccess';

    constructor() {}
}

export class RemoveTeamLeadersFailure {
    static readonly type = '[Team] RemoveTeamLeadersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class AddUsersToTeamsAsLeaders {
    static readonly type = '[Team] AddUsersToTeamsAsLeaders';

    constructor(public payload: IAddTeamLeadersActionPayload) {}
}

export class AddUsersToTeamsAsLeadersSuccess {
    static readonly type = '[Team] AddUsersToTeamsAsLeadersSuccess';

    constructor(public payload: User[]) {}
}

export class AddUsersToTeamsAsLeadersFailure {
    static readonly type = '[Team] AddUsersToTeamsAsLeadersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

/* Team Watchers */

export class FetchTeamWatchers {
    static readonly type = '[Team] FetchTeamWatchers';

    constructor(public teamId: string) {}
}

export class FetchTeamWatchersSuccess {
    static readonly type = '[Team] FetchTeamWatchersSuccess';

    constructor(public payload: User[]) {}
}

export class FetchTeamWatchersFailure {
    static readonly type = '[Team] FetchTeamWatchersFailure';
}

export class RemoveTeamWatchers {
    static readonly type = '[Team] RemoveTeamWatchers';

    constructor(public payload: IRemoveTeamWatchersActionPayload) {}
}

export class RemoveTeamWatchersSuccess {
    static readonly type = '[Team] RemoveTeamWatchersSuccess';

    constructor() {}
}

export class RemoveTeamWatchersFailure {
    static readonly type = '[Team] RemoveTeamWatchersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class AddUsersToTeamsAsWatchers {
    static readonly type = '[Team] AddUsersToTeamsAsWatchers';

    constructor(public payload: IAddTeamLeadersActionPayload) {}
}

export class AddUsersToTeamsAsWatchersSuccess {
    static readonly type = '[Team] AddUsersToTeamsAsWatchersSuccess';

    constructor(public payload: User[]) {}
}

export class AddUsersToTeamsAsWatchersFailure {
    static readonly type = '[Team] AddUsersToTeamsAsWatchersFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class StopWatchingTeam {
    static readonly type = '[Team] StopWatchingTeam';

    constructor(public teamId: string) {}
}

export class StopWatchingTeamSuccess {
    static readonly type = '[Team] StopWatchingTeamSuccess';

    constructor() {}
}

export class StopWatchingTeamFailure {
    static readonly type = '[Team] StopWatchingTeamFailure';
}
