import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { ChatUsersState } from 'app/library/all-users/models/chat-users.state';
import { SelectGroupMembersDialogComponent } from 'app/library/group/components/dialogs/select-group-members-dialog/select-group-members-dialog.component';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { FuseSidebarComponent } from 'app/projects/fuse/src/lib/components/sidebar/sidebar.component';
import { FuseSidebarService } from 'app/projects/fuse/src/lib/components/sidebar/sidebar.service';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { IChatPanelConversationData, TalkService } from 'app/services/talk.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'chat-panel',
    templateUrl: './chat-panel.component.html',
    styleUrls: ['./chat-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChatPanelComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
    chatpanel: FuseSidebarComponent;

    @Select(ChatUsersState.getEntities)
    chatUsers$: Observable<User[]>;

    private _debounce: NodeJS.Timer;
    profile: User;

    @ViewChild('searchInput')
    searchInput: ElementRef;
    searchTerm = ''; // if adding the ability to persist searchTerm, use the persisted value here

    isThereMorePagesForCurrentSearchTerm = true;
    panelDetails: IChatPanelConversationData[];

    @ViewChild('loadMore', { read: ElementRef })
    loadMore: ElementRef;

    subscriptionToCancelWhenNoMoreConversationsCanBeLoaded: Subscription;

    constructor(public talkService: TalkService, private _fuseSidebarService: FuseSidebarService, private _matDialog: MatDialog, private _store: Store) {
        super();

        this.talkService.nextPageNumber.set('', 1);
        this.talkService.isThereMorePages.set('', this.isThereMorePagesForCurrentSearchTerm);
    }

    ngOnInit(): void {
        this.chatpanel = this._fuseSidebarService.getSidebar('chatPanel');

        this.chatpanel.unfoldedChanged.subscribe((value) => {
            if (value && this.searchInput) {
                this.searchInput.nativeElement.focus();
            }
        });

        this.profile = this._store.selectSnapshot(UserState.getMyInfo);

        combineLatest([this.talkService.panelConversationDetail$, this.talkService.userOnlineInfoDetails$])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([panelDetails, onlineDetails]) => {
                this.panelDetails = panelDetails.map((panel) => {
                    onlineDetails.forEach((header) => {
                        if (panel.id === header.id) {
                            panel.isOnline = header.isOnline;
                        }
                    });

                    return panel;
                });
            });
    }

    ngAfterViewInit(): void {
        // this is here in case we want to persist the searchTerm
        this.searchInput.nativeElement.value = this.searchTerm;
    }

    startConversation(conversationId: string): void {
        this.talkService.updateConversation(conversationId, { expanded: true, openedFrom: 'existing_sidebar' });
    }

    chooseUserToChatWith(): void {
        const dialogRef = this._matDialog.open(SelectGroupMembersDialogComponent, {
            width: '680px',
            id: 'select-users-dialog',
        });

        dialogRef.componentInstance.entitie$ = this.chatUsers$;
        dialogRef.componentInstance.title = 'USER.DIALOG.SELECT_AVAILABLE.TITLE';
        dialogRef.componentInstance.dataType = !this.profile.permissions.includes('tenant_management') ? 'chatUsers' : 'chatAdmins';
        dialogRef.componentInstance.isChat = true;

        this.talkService.foldSidebar();

        dialogRef.componentInstance.onConfirm.subscribe(async (user: User) => {
            if (user) {
                await this.talkService.startConversationByUser(user, 'new_sidebar');
                dialogRef.close();
            }
        });
    }

    async fetchMoreConversations(searchTerm: string = this.searchTerm, pageNumber: number = this.talkService.nextPageNumber.get(searchTerm) || 1): Promise<void> {
        this.searchTerm = searchTerm;

        if (pageNumber === 1) {
            this.talkService.isThereMorePages.set(searchTerm, true);
        }

        const options = await this.talkService.fetchMoreConversations({
            searchTerm,
        });

        if (!options) {
            return;
        }

        this.talkService.isThereMorePages.set(searchTerm, options.isThereMorePages);
        this.isThereMorePagesForCurrentSearchTerm = this.talkService.isThereMorePages.get(searchTerm);
    }

    trackByFn(index: number): number {
        return index;
    }

    search(searchTerm: string): void {
        if (this._debounce) {
            clearTimeout(this._debounce);
        }

        this._debounce = setTimeout(async () => {
            if (!this.talkService.nextPageNumber.has(searchTerm)) {
                await this.fetchMoreConversations(searchTerm, 1);
            } else {
                this.searchTerm = searchTerm;
                this.isThereMorePagesForCurrentSearchTerm = this.talkService.isThereMorePages.get(searchTerm);
            }
        }, 500);
    }
}
