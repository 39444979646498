import { MaxBrainLocation } from 'app/library/location/models/location.model';
import { EventModule } from 'app/library/module/models/event-module.model';
import { IEventQuery } from '../interfaces/event.query.interface';

export class MaxBrainEvent {
    static label_singular = 'event';
    static label_plural = 'events';

    static props_to_search = ['name', 'description', 'location'];

    id: string;
    name: string;
    description: string;
    start: Date;
    end: Date;
    isAllDay: boolean;
    location: MaxBrainLocation;
    module: EventModule;
    moduleType: string;
    type: string;
    uniqueId: string;
    trackAttendance: boolean;

    constructor(eventQuery?: IEventQuery) {
        if (eventQuery) {
            this.name = eventQuery.name;
            this.description = eventQuery.description;
            this.id = eventQuery.id.toString();
            this.start = new Date(eventQuery.start);
            this.end = new Date(eventQuery.end);
            this.isAllDay = eventQuery.is_all_day;
            this.location = eventQuery.location ? new MaxBrainLocation(eventQuery.location) : null;
            this.module = eventQuery.module ? new EventModule(eventQuery.module) : null;
            this.moduleType = eventQuery.module_type;
            this.type = eventQuery.type;
            this.uniqueId = eventQuery.unique_id;
            this.trackAttendance = eventQuery.track_attendance;
        } else {
            this.name = '';
            this.description = '';
            this.id = null;
            this.start = null;
            this.end = null;
            this.isAllDay = false;
            this.location = null;
            this.module = null;
            this.moduleType = '';
            this.type = '';
            this.uniqueId = null;
            this.trackAttendance = false;
        }
    }
}
