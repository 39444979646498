import { ExperienceUser } from './experience-user.model';

export class ExperienceUserCommand {
    user: { id: string };
    module: { id: string };

    constructor(experienceUser: ExperienceUser) {
        this.user = {
            id: experienceUser.userId,
        };
        this.module = {
            id: experienceUser.experienceId,
        };
    }
}
