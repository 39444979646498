import { HttpErrorResponse } from '@angular/common/http';

export class AddAdditionalEmailToUserFailure {
    static readonly type = '[User] AddAdditionalEmailToUserFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}
