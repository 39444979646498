import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { ExperienceUserExtended } from 'app/library/experience-user/models/experience-user.extended.model';
import { ExperienceUser } from 'app/library/experience-user/models/experience-user.model';
import { MaxBrainExperience } from 'app/library/experience/models/experience.model';

export class SetExperienceUsers {
    static readonly type = '[ExperienceUser] SetExperienceUsers';

    constructor(public payload: ExperienceUserExtended[]) {}
}

export class UnsetExperienceUsers {
    static readonly type = '[ExperienceUser] UnsetExperienceUsers';
}

export class FetchExperienceUsers {
    static readonly type = '[ExperienceUser] FetchModuleGroupMembers';

    constructor(
        public payload?: {
            experienceId: string;
            search?: string;
            sortBy?: string;
            sortOrder?: string;
            pageSize?: number;
            pageNumber?: number;
        }
    ) {}
}

export class FetchExperienceUsersSuccess {
    static readonly type = '[ExperienceUser] FetchExperienceUsersSuccess';
}

export class FetchExperienceUsersFailure {
    static readonly type = '[ExperienceUser] FetchExperienceUsersFailure';
}

export class ToggleSelectedExperienceUser {
    static readonly type = '[ExperienceUser] ToggleSelectedExperienceUser';

    constructor(public payload: string) {}
}

export class SelectFilteredExperienceUsers {
    static readonly type = '[ExperienceUser] SelectFilteredExperienceUsers';
}

export class DeselectSelectedExperienceUsers {
    static readonly type = '[ExperienceUser] DeselectSelectedExperienceUsers';
}

export class SearchExperienceUsers {
    static readonly type = '[ExperienceUser] SearchExperienceUsers';

    constructor(public payload: string) {}
}

export class SortExperienceUsers {
    static readonly type = '[ExperienceUser] SortExperienceUsers';

    constructor(public payload: Sort) {}
}

export class FetchExperienceByUserId {
    static readonly type = '[Experience] FetchExperienceByUserId';

    constructor(public payload: string) {}
}

export class FetchExperienceByUserIdSuccess {
    static readonly type = '[Experience] FetchExperienceByUserIdSuccess';

    constructor(public payload: ExperienceUserExtended) {}
}

export class FetchExperienceByUserIdFailure {
    static readonly type = '[Experience] FetchExperienceByUserIdFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}
