import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as momentImported from 'moment';
const moment = momentImported;

@Pipe({
    name: 'calculateDuration',
})
@Injectable()
export class CalculateDurationPipe implements PipeTransform {
    constructor(private _translateService: TranslateService) {}

    transform(startDate: Date, endDate: Date): string {
        if (!startDate || !endDate || !this._translateService) {
            return '';
        }

        const start_date = moment(startDate);
        const end_date = moment(endDate);
        end_date.add(1, 'days');

        const years = end_date.diff(start_date, 'year');
        start_date.add(years, 'years');

        const months = end_date.diff(start_date, 'months');
        start_date.add(months, 'months');

        const days = end_date.diff(start_date, 'days');

        return (
            (years
                ? years === 1
                    ? years + ' ' + this._translateService.instant('GENERAL.YEAR') + ' '
                    : years + ' ' + this._translateService.instant('GENERAL.YEARS') + ' '
                : '') +
            (months
                ? months === 1
                    ? months + ' ' + this._translateService.instant('GENERAL.MONTH') + ' '
                    : months + ' ' + this._translateService.instant('GENERAL.MONTHS') + ' '
                : '') +
            (days ? (days === 1 ? days + ' ' + this._translateService.instant('GENERAL.DAY') + ' ' : days + ' ' + this._translateService.instant('GENERAL.DAYS') + ' ') : '')
        );
    }
}
