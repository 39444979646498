import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { BasicUsersListDialogComponent } from 'app/projects/shared/src/lib/components/dialogs/basic-users-list/basic-users-list.component';
import { IFullConversationData, IUserOnlineInfo, TalkService } from 'app/services/talk.service';
import { takeUntil } from 'rxjs/operators';
import Talk from 'talkjs';

@Component({
    selector: 'app-chat-drawer',
    templateUrl: './chat-drawer.component.html',
    styleUrls: ['./chat-drawer.component.scss'],
})
export class ChatDrawerComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy, AfterViewChecked {
    private chatbox: Talk.Chatbox;
    private isMounted: boolean;

    @Input()
    conversationDetails: IFullConversationData;

    @ViewChild('talkjsContainer')
    talkjsContainer!: ElementRef;

    onlineStateInfo: IUserOnlineInfo;

    private async _mountChatbox(force: boolean = false): Promise<void> {
        if (!force && (!this.talkjsContainer || !this.talkjsContainer.nativeElement || this.isMounted)) {
            return;
        }

        if (!this.chatbox || force) {
            const session = await this._talkService.getSession();
            const conversation = session.getOrCreateConversation(this.conversationDetails.id);

            this.chatbox = session.createChatbox(conversation, {
                showChatHeader: false,
            });
        }

        this.chatbox.mount(this.talkjsContainer.nativeElement);
        this.isMounted = true;
    }

    constructor(private _talkService: TalkService, private _translateService: TranslateService, private _matDialog: MatDialog) {
        super();

        this._translateService.onLangChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(async () => {
            await this._mountChatbox(true);
        });
    }

    ngOnInit(): void {
        this._talkService.userOnlineInfoDetails$.pipe(takeUntil(this._unsubscribeAll)).subscribe((userOnlineInfoDetails) => {
            userOnlineInfoDetails.forEach((onlineInfo) => {
                if (onlineInfo.id === this.conversationDetails.id) {
                    this.onlineStateInfo = onlineInfo;
                }
            });
        });
    }

    async ngAfterViewChecked(): Promise<void> {
        if (this.conversationDetails.expanded) {
            this._mountChatbox();
        } else {
            this.isMounted = false;
            this.chatbox = null;
        }
    }

    ngOnDestroy(): void {
        if (this.chatbox) {
            this.chatbox.destroy();
        }
        super.ngOnDestroy();
    }

    async onExpandedChange(expanded: boolean): Promise<void> {
        if (this.conversationDetails.expanded !== expanded) {
            this._talkService.updateConversation(this.conversationDetails.id, { expanded, openedFrom: 'existing_collapsed' });
        }
    }

    closeConversation(): void {
        this._talkService.closeConversation(this.conversationDetails.id);
    }

    openMembersDialog(conversationDetails: IFullConversationData): void {
        const dialogRef = this._matDialog.open(BasicUsersListDialogComponent, {
            width: '400px',
        });

        dialogRef.componentInstance.dialogTitle = 'GROUPS.TAB.MEMBERS';
        dialogRef.componentInstance.list = conversationDetails;
    }
}
