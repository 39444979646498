import { IExperienceResourceQuery } from 'app/library/grow/interfaces/experience.query.interface';
import { HeaderBannerStream } from './header-banner-stream.model';
import { HeaderBanner } from './header-banner.model';
import { HeaderStream } from './header-stream.model';

export class MaxBrainExperience {
    id: string;
    name: string;
    label: string;
    headerBanner: HeaderBanner;
    headerBannerStream: HeaderBannerStream;
    headerStream: HeaderStream;
    memberCount: number;
    widgetCount: number;
    selected: boolean;

    constructor(query?: IExperienceResourceQuery) {
        if (query) {
            this.id = query.id.toString();
            this.name = query.name;
            this.label = query.label;
            this.headerBanner = new HeaderBanner(query.header_banner);
            this.headerBannerStream = new HeaderBannerStream(query.header_banner_stream);
            this.headerStream = new HeaderStream(query.header_stream);
            this.memberCount = query.member_count;
            this.widgetCount = query.widget_count;
        }
    }
}
