import { Injectable, Pipe, PipeTransform } from '@angular/core';

// TODO: for some reason we can not use MaxBrain tag model, fails on ng build shared
interface Tag {
    id: string;
    name: string;
    color: string;
    category: string;
}

@Pipe({
    name: 'filterTagsByCategory',
})
@Injectable()
export class FilterTagsByCategoryPipe implements PipeTransform {
    transform(tags: Tag[], tagCategory: string): Tag[] {
        // return empty array if array is falsy
        if (!tags) {
            return [];
        }

        // retrun the filtered array
        return tags.filter((tag) => tag.category === tagCategory);
    }
}
