export class UsersImportCommand {
    formData: FormData;

    constructor(
        formFileProperty: string,
        file: File | Blob,
        fileName: string = file instanceof File ? file.name : '',
        formModulesPropertyidsModules: string = '',
        idsModules?: string[] | null
    ) {
        this.formData = new FormData();
        this.formData.append(formFileProperty, file, fileName);

        if (formModulesPropertyidsModules && idsModules) {
            this.formData.append(formModulesPropertyidsModules, idsModules.map(Number).join(','));
        }
    }
}
