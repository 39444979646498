import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { TenantDataService } from 'app/config/services/tenant-data.service';
import { MaxBrainNavigationItem } from 'app/navigation/maxbrain-navigation';
import { navigation } from 'app/navigation/navigation';
import { NgxsActionHelper } from 'app/projects/core/src/lib/services/action.helper';
import { FuseNavigationService } from 'app/projects/fuse/src/lib/components/navigation/navigation.service';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { ILanguageService } from 'app/projects/shared/src/lib/interfaces/language.interface';
import { MAXBRAIN_LANGUAGE_SERVICE } from 'app/projects/shared/src/lib/services/language.token';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { take } from 'rxjs/operators';
import { FetchFeatures, FetchFeaturesFailure, FetchFeaturesSuccess } from '../actions';
import { Feature } from '../models/feature.model';
import { FeatureState } from '../models/feature.state';

@Injectable()
export class FeatureService {
    constructor(
        private _store: Store,
        private _actions: Actions,
        private _actionHelper: NgxsActionHelper,
        private _fuseNavigationService: FuseNavigationService,
        private _translateService: TranslateService,
        private _tenantDataService: TenantDataService,
        private _http: HttpClient,
        @Inject(MAXBRAIN_LANGUAGE_SERVICE) public languageService: ILanguageService
    ) {}

    /**
     *  loading features
     */
    initFeatureService(): void {
        this._actions.pipe(ofActionDispatched(FetchFeaturesSuccess), take(1)).subscribe(() => {
            const profile = this._store.selectSnapshot(UserState.getMyInfo);
            const hideNonDefaultNavItems = !profile.permissions.includes('tenant_management');

            this._fuseNavigationService.unregister('main');

            const isEngageHomeScreenFeatureActive = this._tenantDataService.isEngageHomeScreenFeatureActive;

            const tempNavigation = navigation
                .map((navItem) => {
                    switch (navItem.id) {
                        case 'new':
                            navItem.children = navItem.children.filter((childItem) => {
                                switch (childItem.id) {
                                    case 'new-eLearning-module':
                                        return this.checkFeatureStatus('e_learning');
                                    case 'new-private-module':
                                        return this.checkFeatureStatus('module_visibility');
                                }

                                return true;
                            });

                            break;
                        case 'community':
                            if (this.checkFeatureStatus(FeatureSwitchName.Community, 'inactive')) {
                                return {
                                    id: 'empty',
                                    title: 'Empty',
                                    translate: '',
                                    type: 'item',
                                } as MaxBrainNavigationItem;
                            }

                            break;
                        case 'grow':
                            if (this.checkFeatureStatus(FeatureSwitchName.Engage, 'inactive')) {
                                return {
                                    id: 'empty',
                                    title: 'Empty',
                                    translate: '',
                                    type: 'item',
                                } as MaxBrainNavigationItem;
                            }

                            break;
                        case 'groups':
                            if (this.checkFeatureStatus(FeatureSwitchName.Groups, 'inactive')) {
                                return {
                                    id: 'empty',
                                    title: 'Empty',
                                    translate: '',
                                    type: 'item',
                                } as MaxBrainNavigationItem;
                            }

                            break;
                    }

                    return navItem;
                })
                .filter((navItem) => {
                    switch (navItem.id) {
                        case 'community':
                            return this.checkFeatureStatus(FeatureSwitchName.Community);
                        case 'grow':
                            return this.checkFeatureStatus(FeatureSwitchName.Engage) && isEngageHomeScreenFeatureActive && profile.assignedToExperience;
                        case 'inbox':
                            return this.checkFeatureStatus(FeatureSwitchName.Chat);
                        case 'groups':
                            return this.checkFeatureStatus(FeatureSwitchName.Groups);
                        case 'member-area':
                            return this.checkFeatureStatus(FeatureSwitchName.MemberAreaTab);
                        case 'know-how':
                            return this.checkFeatureStatus(FeatureSwitchName.Nuggets);
                        case 'calendar':
                            return this.checkFeatureStatus(FeatureSwitchName.GlobalCalendar);
                        case 'teams':
                            return this.checkFeatureStatus(FeatureSwitchName.Teams) && profile.inTeam;
                        case 'manage-modules':
                            return profile.isManagerOnSomeModule;
                        default:
                            return !hideNonDefaultNavItems || navItem.default;
                    }
                });

            // fuseNavigationService.unregister('main');  already register before
            this._fuseNavigationService.register('main', tempNavigation);

            this._fuseNavigationService.setCurrentNavigation('main');

            this._fuseNavigationService.updateNavigationItem('know-how', {
                translate: localStorage.getItem('subdomain') === 'br-arnsberg' ? 'NAV.KNOW_HOW.BR_ARNSBERG_TITLE' : 'NAV.KNOW_HOW.TITLE',
            });
        });
    }

    async fetchFeatures(): Promise<boolean> {
        this._store.dispatch(new FetchFeatures());

        return await this._actionHelper.race(FetchFeaturesSuccess, FetchFeaturesFailure).toPromise();
    }

    getFeatures(): Feature[] {
        return this._store.selectSnapshot(FeatureState.getEntities);
    }

    checkFeatureStatus(id: string, status: string = 'active'): boolean {
        const feature = FeatureState.getEntity(this._store.selectSnapshot(FeatureState), id);

        if (!feature) {
            return false;
        }

        return feature.status === status;
    }
}
