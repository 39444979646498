import { Module } from 'app/library/module/models/module.model';
import { ScheduledJob } from 'app/library/scheduler/models/scheduled-job.model';
import { User } from 'app/projects/user/src/lib/models/user';
import { IPasswordlessAccessQuery } from '../interfaces/passwordless-access.query.interface';

export class PasswordlessAccess {
    id: string;
    user: User;
    start: Date;
    end: Date;
    accessCode: string;
    module: Module;
    state: string;
    url: string;
    canManage: boolean;
    createdBy: User;
    schedules: ScheduledJob[];

    constructor(query: IPasswordlessAccessQuery) {
        this.id = query.id.toString();
        this.user = new User(query.user);
        this.start = query.start;
        this.end = query.end;
        this.accessCode = query.access_code;
        this.module = new Module(query.module);
        this.state = query.state;
        this.url = query.url;
        this.canManage = query.can_manage;
        this.createdBy = new User(query.created_by);
        if (query.schedules?.length) {
            this.schedules = query.schedules.map((schedule) => new ScheduledJob(schedule));
        }
    }
}
