import { User } from '../models/user';
import { UserResetPasswordUrl } from '../models/user-reset-password-url.model';

export class ResetPasswordsSuccess {
    static readonly type = '[User] ResetPasswordsSuccess';

    constructor(public payload: User[]) {}
}

export class ResetPasswordUrlSuccess {
    static readonly type = '[User] ResetPasswordUrlSuccess';

    constructor(public payload: UserResetPasswordUrl[]) {}
}
