import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FeatureService } from 'app/library/feature/services/feature.service';
import { UserService } from 'app/library/user/services/user.service';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { AddUsers } from 'app/projects/user/src/lib/actions/add-entities.action';
import { RefreshUsersOnlineState } from 'app/projects/user/src/lib/actions/refresh-users-online-state.action';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { map } from 'rxjs/operators';
import {
    FetchAvailableChatUsers,
    FetchAvailableChatUsersFailure,
    FetchAvailableChatUsersSuccess,
    FetchChatUsers,
    FetchChatUsersFailure,
    FetchChatUsersSuccess,
    RemoveSearchChatUsers,
    SearchChatUsers,
    SetChatUsers,
    SortChatUsers,
    UnsetChatUsers,
} from '../actions';
import { AllUsersApiService } from '../services/all-users.api-service';
import { ChatUsersStateModel } from './chat-users.state-model';

@State<ChatUsersStateModel>({
    name: 'chatUsers',
    defaults: new ChatUsersStateModel(),
})
@Injectable()
export class ChatUsersState extends SubentityState<User> {
    @Selector([UserState.getEntities])
    static getEntities(state: ChatUsersStateModel, users: User[]): User[] {
        if (!users) {
            return null;
        }

        return users
            .filter((user) => state.list.indexOf(user.id) !== -1)
            .map((user) => {
                return [state.list.indexOf(user.id), user];
            })
            .sort((a: [number, User], b: [number, User]) => {
                return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
            })
            .map((x: [number, User]) => x[1]);
    }

    @Selector([UserState.getEntities])
    static getFilteredEntities(state: ChatUsersStateModel, users: User[]): User[] {
        if (!users) {
            return null;
        }

        return users
            .filter((user) => state.filtered.indexOf(user.id) !== -1)
            .map((user) => {
                return [state.list.indexOf(user.id), user];
            })
            .sort((a: [number, User], b: [number, User]) => {
                return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
            })
            .map((x: [number, User]) => x[1]);
    }

    @Selector()
    static getSelectedEntityIds(state: ChatUsersStateModel): string[] {
        return state.selected;
    }

    @Selector()
    static getFiltersList(state: ChatUsersStateModel): string[] {
        return state.filters.list;
    }

    @Selector()
    static getSearchTerm(state: ChatUsersStateModel): string {
        return state.searchTerm;
    }

    @Selector()
    static getTotalCount(state: ChatUsersStateModel): number {
        return state.totalCount;
    }

    @Selector()
    static getSortBy(state: ChatUsersStateModel): string {
        return state.sortBy;
    }

    @Selector()
    static getSortOrder(state: ChatUsersStateModel): SortDirection {
        return state.sortOrder;
    }

    @Selector()
    static getPageSize(state: ChatUsersStateModel): number {
        return state.pageSize;
    }

    @Selector()
    static getPageNumber(state: ChatUsersStateModel): number {
        return state.pageNumber || 1;
    }

    @Selector()
    static getPageIndex(state: ChatUsersStateModel): number {
        return state.pageNumber ? state.pageNumber - 1 : 0;
    }

    constructor(private _allUsersService: AllUsersApiService, private _userService: UserService, private store: Store, private _featureService: FeatureService) {
        super(null, 0);

        this._propstoSearch = ['user', ...User.props_to_search];
    }

    @Action(SetChatUsers)
    setAll(ctx: StateContext<ChatUsersStateModel>, action: SetChatUsers): void {
        this._setSubentities(ctx, action, AddUsers);

        if (this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)) {
            this.store.dispatch(new RefreshUsersOnlineState(action.payload));
            this._userService.setOnlineStateInterval(RefreshUsersOnlineState, ChatUsersState.getEntities);
        }
    }

    @Action(UnsetChatUsers)
    unsetAll(ctx: StateContext<ChatUsersStateModel>): void {
        this._unsetSubentities(ctx);
    }

    @Action(FetchChatUsers)
    fetchAll(ctx: StateContext<ChatUsersStateModel>, action: FetchChatUsers): void {
        if (action.payload) {
            ctx.patchState({
                pageSize: action.payload.pageSize,
                pageNumber: action.payload.pageNumber,
            });
        }

        this._fetchSubentities(
            ctx,
            { payload: 'all' },
            SetChatUsers,
            FetchChatUsersSuccess,
            FetchChatUsersFailure,
            action.payload
                ? this._allUsersService
                      .findUsers(
                          action.payload.moduleIds,
                          action.payload.entityId,
                          action.payload.search,
                          action.payload.sortBy,
                          action.payload.sortOrder,
                          action.payload.pageSize,
                          action.payload.pageNumber,
                          action.payload.isUnassignedSelected,
                          action.payload.role,
                          action.payload.includeAnalytics,
                          action.payload.experienceIds,
                          action.payload.audienceIds,
                          action.payload.teamIds,
                          action.payload.extendedAttributesFilters,
                          action.payload.isUnassignedSelectedExperiences,
                          action.payload.isUnassignedSelectedAudiences,
                          action.payload.isUnassignedSelectedTeams,
                          action.payload.columns,
                          action.payload.userRole
                      )
                      .pipe(
                          map((data) => {
                              ctx.patchState({
                                  totalCount: data.pagination.number_of_results,
                              });

                              return data.data.map((query) => new User(query));
                          })
                      )
                : this._allUsersService.getAll()
        );
    }

    @Action(FetchChatUsersSuccess)
    fetchAllSuccess(ctx: StateContext<ChatUsersStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchChatUsersFailure)
    fetchAllFailure(ctx: StateContext<ChatUsersStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(SearchChatUsers)
    search({ patchState }: StateContext<ChatUsersStateModel>, { payload }: SearchChatUsers): void {
        sessionStorage.setItem('chatUsersSearchTerm', payload);

        patchState({
            searchTerm: payload,
        });
    }

    @Action(RemoveSearchChatUsers)
    removeSearch({ patchState }: StateContext<ChatUsersStateModel>, { payload }: RemoveSearchChatUsers): void {
        sessionStorage.setItem('chatUsersSearchTerm', payload);

        patchState({
            searchTerm: payload,
        });
    }

    @Action(SortChatUsers)
    sort({ patchState }: StateContext<ChatUsersStateModel>, { payload }: SortChatUsers): void {
        sessionStorage.setItem('chatUsersSortBy', payload.active);
        sessionStorage.setItem('chatUsersSortOrder', payload.direction);

        patchState({
            sortBy: payload.active,
            sortOrder: payload.direction,
        });
    }

    @Action(FetchAvailableChatUsers)
    fetchAvailableUsers(ctx: StateContext<ChatUsersStateModel>, action: FetchAvailableChatUsers): void {
        this._allUsersService
            .getAvailableUsers(action.payload.groupId, action.payload.search, action.payload.sortBy, action.payload.sortOrder, action.payload.pageSize, action.payload.pageNumber)
            .pipe(
                map((data) => {
                    ctx.patchState({
                        totalCount: data.pagination.number_of_results,
                    });

                    return data.data.map((query) => new User(query));
                })
            )
            .subscribe(
                (entities) => {
                    this.store.dispatch([new FetchAvailableChatUsersSuccess(entities), new SetChatUsers(entities)]);
                },
                (error: HttpErrorResponse) => {
                    this.store.dispatch(new FetchAvailableChatUsersFailure());
                }
            );
    }
}
