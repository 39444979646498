import { Pipe, PipeTransform } from '@angular/core';
import { ScheduledJobType } from 'app/library/scheduler/enums/scheduledJob-type.enum';
import { ScheduledJob } from 'app/library/scheduler/models/scheduled-job.model';

@Pipe({ name: 'scheduleExecDate' })
export class ScheduleExecDatePipe implements PipeTransform {
    transform(schedules: ScheduledJob[]): Date {
        let date: Date;

        if (schedules && schedules.length) {
            schedules.forEach((schedule) => {
                if (schedule.type === ScheduledJobType.PublishSection || schedule.type === ScheduledJobType.NotifyAll) {
                    date = schedule.info.execDate;
                }
            });
        }

        return date;
    }
}
