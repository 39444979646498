<app-dialog-header [title]="confirmTitle" [additionalText]="entitiesLength ? '(' + entitiesLength + ')' : ''" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="p-24" [innerHTML]="confirmMessage | translate | safeHtml"></div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>
    <app-white-button
        class="mr-8"
        *ngIf="!hideAdditionalActionButton"
        [label]="additionalActionButtonText"
        (onClick)="dialogRef.close({ result: true, info: 'additionalActionButtonClicked' })"
    ></app-white-button>

    <app-flat-button
        [color]="confirmButtonColor"
        (keydown.enter)="dialogRef.close(true)"
        *ngIf="!hideActionButton"
        [label]="actionButtonText"
        (onClick)="dialogRef.close(true)"
        [autofocus]="true"
    ></app-flat-button>
</div>
