import { Component, ViewEncapsulation, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';

@Component({
    selector: 'app-autocomplete-dialog',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class AutocompleteDialogComponent {
    @Input()
    dialogTitle: string;
    @Input()
    placeholder: string;
    @Input()
    items: any[];
    @Input()
    key: string;
    @Input()
    value: string;

    data: any;

    constructor(public dialogRef: MatDialogRef<AutocompleteDialogComponent>) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Save selected entities
     */
    save(): void {
        if (this.data) {
            this.dialogRef.close({ data: this.value ? this.data[this.key] : this.data });
        }
    }
}
