import { HttpErrorResponse } from '@angular/common/http';
import { ModuleFeature } from '../models/module-feature.model';

export class SetModuleFeatures {
    static readonly type = '[ModuleFeature] SetModuleFeatures';

    constructor(public payload: ModuleFeature[]) {}
}

export class FetchModuleFeatures {
    static readonly type = '[ModuleFeature] FetchModuleFeatures';

    constructor(public payload: string) {}
}

export class FetchModuleFeaturesSuccess {
    static readonly type = '[ModuleFeature] FetchModuleFeaturesSuccess';
}

export class FetchModuleFeaturesFailure {
    static readonly type = '[ModuleFeature] FetchModuleFeaturesFailure';
}

export class UpdateModuleFeature {
    static readonly type = '[ModuleFeature] UpdateModuleFeature';

    constructor(public payload: { moduleId: string; moduleFeature: ModuleFeature }) {}
}

export class UpdateModuleFeatureSuccess {
    static readonly type = '[ModuleFeature] UpdateModuleFeatureSuccess';

    constructor(public payload: ModuleFeature) {}
}

export class UpdateModuleFeatureFailure {
    static readonly type = '[ModuleFeature] UpdateModuleFeatureFailure';

    constructor(public payload: HttpErrorResponse) {}
}
