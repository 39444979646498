import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';

@Component({
    selector: 'app-editor-dialog',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class MaxBrainEditorDialogComponent {
    @Input()
    dialogTitle: string;

    @Input()
    saveButtonColor = 'accent';

    @Input()
    init: any;

    @Input()
    data: string;

    @Input()
    saveButtonText: string;

    @Input()
    description: string;

    @Input()
    icCountingCharacters = false;

    @Input()
    characterLimit: number;

    tinymceIsLoading = true;

    constructor(public dialogRef: MatDialogRef<MaxBrainEditorDialogComponent>) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Save selected entities
     */
    save(): void {
        this.dialogRef.close({ data: this.data });
    }

    public handleEventInit(event: any): void {
        this.tinymceIsLoading = false;
    }
}
