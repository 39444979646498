import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderByBoolean' })
export class OrderByBooleanPipe implements PipeTransform {
    transform(list: any[], property: string = null): any {
        return list
            ? list
                  .slice()
                  .sort((a, b) => {
                      return a[property] - b[property];
                  })
                  .reverse()
            : [];
    }
}
