import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';
import { FuseConfirmDialogComponent } from 'app/projects/fuse/src/lib/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-checkbox.component.html',
    styleUrls: ['./confirm-checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class MaxBrainConfirmCheckboxDialogComponent extends FuseConfirmDialogComponent {
    public confirmTitle: string;
    public confirmButtonColor = 'accent';
    public actionButtonText = 'GENERAL.BUTTON.CONFIRM';
    public checkboxLabel: string;

    @Output()
    save = new EventEmitter<boolean>();

    checkboxValue: boolean;

    constructor(dialogRef: MatDialogRef<MaxBrainConfirmCheckboxDialogComponent>) {
        super(dialogRef);
    }
}
