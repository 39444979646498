import { HttpErrorResponse } from '@angular/common/http';

export class FetchUserAdditionalEmailsFailure {
    static readonly type = '[User] FetchUserAdditionalEmailsFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class FetchUserVerifiedAdditionalEmailsFailure {
    static readonly type = '[User] FetchUserVerifiedAdditionalEmailsFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}
