import { Component, Inject, OnInit } from '@angular/core';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { FuseConfigService } from 'app/projects/fuse/src/lib/services/config.service';
import { IMaxBrainEnvironment } from 'app/projects/shared/src/lib/interfaces/environment.interface';

@Component({
    selector: 'app-survey-monkey-result',
    templateUrl: './survey-monkey-result.component.html',
    styleUrls: ['./survey-monkey-result.component.scss'],
})
export class SurveyMonkeyResultComponent implements OnInit {
    constructor(private _fuseConfigService: FuseConfigService, @Inject(MAXBRAIN_ENVIRONMENT) private environment: IMaxBrainEnvironment) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                toolbar: {
                    hidden: true,
                },
                navbar: {
                    hidden: true,
                },
            },
        };

        window.top.postMessage('surveymonkeyresult', window.location.origin);
    }

    ngOnInit(): void {}
}
