import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { GetUserExtendedAttributeValuePipe } from 'app/projects/shared/src/lib/pipes/getUserExtendedAttributeValue.pipe';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-welcome-tour-dialog',
    templateUrl: './welcome-tour-dialog.component.html',
    styleUrls: ['./welcome-tour-dialog.component.scss'],
})
export class WelcomeTourDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    url: string;

    @Select(UserState.getMyInfo)
    profile$: Observable<User>;
    profile: User;

    keywordsType: {
        keyword: string;
        value: string;
    }[];

    safeUrl: SafeResourceUrl;

    constructor(
        public dialogRef: MatDialogRef<WelcomeTourDialogComponent>,
        private _getUserExtendedAttributeValuePipe: GetUserExtendedAttributeValuePipe,
        private _sanitizer: DomSanitizer
    ) {
        super();
    }

    ngOnInit(): void {
        this.profile$
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((profile) => !!profile)
            )
            .subscribe((profile) => {
                this.profile = profile;
            });

        this.keywordsType = [
            {
                keyword: '%first_name%',
                value: this.profile.firstName,
            },
            {
                keyword: '%last_name%',
                value: this.profile.lastName,
            },
            {
                keyword: '%email%',
                value: this.profile.email,
            },
            {
                keyword: '%user_id%',
                value: this.profile.id,
            },
            {
                keyword: '%user_language%',
                value: this.profile.language,
            },
            {
                keyword: '%ext_[A-Za-z0-9_]+%',
                value: '',
            },
        ];

        const userAttributes = this.profile.attributes;

        this.keywordsType.forEach((type) => {
            if (!type.keyword.includes('%ext_')) {
                this.url = this.url.split(type.keyword).join(type.value);
            } else {
                this.url = this.url.replace(new RegExp(type.keyword, 'g'), (match) => {
                    const attributeName = match.substring(5, match.length - 1);
                    const attribute = userAttributes.find((attr) => attr.attribute.name === attributeName);
                    const attributeValue = this._getUserExtendedAttributeValuePipe.transform(attribute?.attribute, userAttributes) as string;
                    return attributeValue || '-';
                });
            }
        });

        this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }

    dontShowDialogAgain(): void {
        this.dialogRef.close(true);
    }
}
