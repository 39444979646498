import { IEntity } from 'app/projects/entity/src/lib/interfaces/entity.interface';
import * as momentImported from 'moment';
import { IUserQuery } from '../interfaces/user.query.interface';
import { Analytics } from './analytics.model';
import { Avatar } from './avatar';
import { SocialNetwork } from './social-network.model';
import { UserExtendedAttributeWithValue } from './user-attribute-with-value';
const moment = momentImported;

export class User implements IEntity {
    static label_singular = 'user';
    static label_plural = 'users';

    static props_to_search = ['firstName', 'lastName', 'email', 'valueText', 'valueNumber', 'valueDate', 'value', 'attributes'];

    avatar: Avatar;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    status: string;
    permissions: string[];
    socialNetworks: SocialNetwork[];
    emailNotification: boolean;
    analytics?: Analytics;
    language: string;
    alreadyMember: boolean;
    resetPasswordLastSent: momentImported.Moment;
    isManagerOnSomeModule: boolean;
    assignedToExperience: boolean;
    roleLabel: string;
    hideOnlineState: boolean;
    isOnline: boolean;
    attributes: UserExtendedAttributeWithValue[];
    isEmailHidden: boolean;
    teamRoles: string[];
    inTeam: boolean;
    notificationEmail: string;

    constructor(userQuery?: IUserQuery) {
        if (userQuery) {
            this.avatar = new Avatar(userQuery.profile_picture);
            this.email = userQuery.email;
            this.firstName = userQuery.first_name;
            this.lastName = userQuery.last_name;
            this.id = userQuery.id.toString();
            this.status = userQuery.status;
            this.permissions = userQuery.permission ? userQuery.permission : [];
            this.isManagerOnSomeModule = userQuery.is_manager_on_some_module;
            this.assignedToExperience = userQuery.assigned_to_experience;
            this.socialNetworks = userQuery.social_networks ? userQuery.social_networks.map((query) => new SocialNetwork(query)) : [];
            this.emailNotification = userQuery.email_notification;
            this.analytics = new Analytics(userQuery.analytics);
            if (userQuery.reset_password_last_sent) {
                this.resetPasswordLastSent = moment(userQuery.reset_password_last_sent);
            }
            this.language = userQuery.language;
            if (userQuery.already_member !== null) {
                this.alreadyMember = userQuery.already_member;
            }
            this.roleLabel = userQuery.role_label;
            this.hideOnlineState = userQuery.hide_online_state;
            this.notificationEmail = userQuery.notification_email;
            this.isOnline = userQuery.is_online;
            this.attributes = userQuery.attributes ? userQuery.attributes.map((query) => new UserExtendedAttributeWithValue(query.attribute, query.value)) : [];
            this.isEmailHidden = userQuery.is_email_hidden;
            this.teamRoles = userQuery.team_roles;
            this.inTeam = userQuery.in_team;
        } else {
            this.avatar = new Avatar();
            this.email = '';
            this.firstName = '';
            this.lastName = '';
            this.id = null;
            this.status = '';
            this.permissions = [];
            this.socialNetworks = [];
            this.emailNotification = null;
            this.analytics = null;
            this.language = '';
            this.alreadyMember = false;
            this.resetPasswordLastSent = null;
            this.isManagerOnSomeModule = false;
            this.assignedToExperience = false;
            this.roleLabel = '';
            this.hideOnlineState = true;
            this.isOnline = false;
            this.attributes = [];
            this.isEmailHidden = false;
            this.teamRoles = [];
            this.inTeam = false;
        }
    }

    get initials(): string {
        return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`.toUpperCase();
    }

    get fullName(): string {
        if (!this.firstName) {
            return this.lastName || '';
        }

        if (!this.lastName) {
            return this.firstName;
        }

        return this.firstName + ' ' + this.lastName;
    }
}
