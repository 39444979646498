import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private _store: Store) {}
    interval: NodeJS.Timeout;

    getMyInfo(): User {
        return this._store.selectSnapshot(UserState.getMyInfo);
    }

    getUserById(id: string): User {
        return UserState.getEntity(this._store.selectSnapshot(UserState), id);
    }

    setOnlineStateInterval(action: any, list: any): void {
        clearInterval(this.interval);

        this.interval = setInterval(() => {
            const argument = this._store.selectSnapshot(list);
            this._store.dispatch(new action(argument));
        }, 30000);
    }
}
