import { SheduledJobEntityType } from '../enums/scheduledJob-entity-type.enum';
import { ScheduledJobId } from '../enums/scheduledJob-id.enum';
import { IScheduledJobQuery } from '../interfaces/scheduled-job.query.interface';
import { ScheduleInfo } from './schedule-info.model';

export class ScheduledJob {
    id: string;
    schedulerId: string;
    type: string;
    entityId: string;
    entityType: string;
    entityTypeShort: SheduledJobEntityType;
    job_id: ScheduledJobId;
    info: ScheduleInfo;

    constructor(query?: IScheduledJobQuery) {
        if (query) {
            this.id = query.id?.toString();
            this.schedulerId = query.scheduler_id;
            this.type = query.type;
            this.entityId = query.entity_id?.toString();
            this.entityType = query.entity_type;
            this.entityTypeShort = query.entity_type_short;
            this.job_id = query.job_id;
            this.info = new ScheduleInfo(query.info);
        } else {
            this.id = null;
            this.schedulerId = null;
            this.type = null;
            this.entityId = null;
            this.entityType = null;
            this.entityTypeShort = null;
            this.job_id = null;
            this.info = null;
        }
    }
}
