import { ICreateClasstimeFeedbackActionPayload } from '../interfaces/create-classtime-feedback.action-payload';

export class CreateClasstimeFeedbackCommand {
    retryable: boolean;
    classtime_question_set_id: string;
    question_set_title: string;

    constructor(entity: ICreateClasstimeFeedbackActionPayload) {
        if (!entity) {
            throw new Error('Can not create QuizSectionCommand when no input is provided!');
        }

        this.classtime_question_set_id = entity.classtimeQuestionSetId;
        this.question_set_title = entity.questionSetTitle;
        this.retryable = entity.retryable;
    }
}
