import { IUsersImportQuery } from '../interfaces/users-import.query.interface';
import { IModuleAssignmentQuery } from '../interfaces/module-assignment.query.interface';
import { User } from './user';
import { IImportUserDetailsModulesInterface } from '../interfaces/import-user-details-modules.interface';

export class UsersImport {
    private _users: User[];

    private _usersCreated: number[];
    private _usersDuplicated: number[];

    private _modules: IImportUserDetailsModulesInterface[];

    constructor(UsersImportQuery?: IUsersImportQuery) {
        this._users = UsersImportQuery.users.map((createdQuery) => new User(createdQuery));

        this._usersCreated = [...UsersImportQuery.import.created];
        this._usersDuplicated = [...UsersImportQuery.import.duplicates];

        this._modules = UsersImportQuery.module_assignment.map((module) => this.transformToImportUserDetailsModules(module));
    }

    protected reducerUser(reducer: string[], user: User): string[] {
        reducer.push(`${user.email}`);
        return reducer;
    }

    protected transformToImportUserDetailsModules(moduleAssignmentQuery: IModuleAssignmentQuery): IImportUserDetailsModulesInterface {
        return {
            moduleName: moduleAssignmentQuery.module_name,
            newlyAssigned: moduleAssignmentQuery.newly_assigned.length,
            assignmentFailed: moduleAssignmentQuery.assignment_failed.length,
            alreadyAssigned: moduleAssignmentQuery.already_assigned.length,
            users: this._users.reduce(this.reducerUser, []).join(', '),
            error: moduleAssignmentQuery.error ? moduleAssignmentQuery.error.message : '',
        };
    }

    /** Users **/

    public getUsersCreated(): User[] {
        return this._users.filter((user) => this._usersCreated.includes(+user.id));
    }

    public getUsersDuplicated(): User[] {
        return this._users.filter((user) => this._usersDuplicated.includes(+user.id));
    }

    /** Modules **/

    public getModules(): IImportUserDetailsModulesInterface[] {
        return [...this._modules];
    }
}
