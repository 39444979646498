import { IUserAdditionalEmailQuery } from '../interfaces/user-additional-emails.query.interface';

export class UserAdditionalEmail {
    email: string;
    isVerified: boolean;

    constructor(query: IUserAdditionalEmailQuery) {
        this.email = query.email;
        this.isVerified = query.is_verified;
    }
}
