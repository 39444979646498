import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaxBrainTag } from 'app/library/tag/models/tag.model';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';

@Component({
    selector: 'app-tag-edit-dialog',
    templateUrl: './tag-edit.component.html',
    styleUrls: ['./tag-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class TagEditDialogComponent implements OnInit {
    @Input()
    tag: MaxBrainTag;
    @Input()
    placeholder: string;

    colors: string[];
    tagForm: FormGroup;
    color: string;
    clicked: string;

    constructor(public dialogRef: MatDialogRef<TagEditDialogComponent>, private _formBuilder: FormBuilder) {
        this.colors = MaxBrainTag.colorsArray;
    }

    ngOnInit(): void {
        this.tagForm = this._formBuilder.group({
            id: [this.tag.id],
            name: [this.tag.name, Validators.required],
        });
        this.clicked = this.tag.color;
    }

    selectColor(color: string): void {
        this.color = color;
    }

    save(): void {
        const formData = this.tagForm.getRawValue();

        this.dialogRef.close({
            data: new MaxBrainTag({
                id: formData.id ? Number.parseInt(formData.id) : 0,
                name: formData.name,
                color: this.color,
                category: this.tag.category,
            }),
        });
    }
}
