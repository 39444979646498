import { ISurveyReportRowQuery } from '../interfaces/survey-report-row.query.interface';
import { SurveyReportChoice } from './survey-report-choice.model';

export class SurveyReportRow {
    text: string;
    choices: SurveyReportChoice[];

    constructor(query: ISurveyReportRowQuery) {
        this.text = query.text;
        this.choices = query.choices.map((choice) => new SurveyReportChoice(choice));
    }
}
