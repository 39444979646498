import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FeatureService } from 'app/library/feature/services/feature.service';
import { NgxsActionHelper } from 'app/projects/core/src/lib/services/action.helper';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { SelectGroupMembersDialogComponent } from './select-group-members-dialog.component';

@NgModule({
    imports: [
        MatIconModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MaxBrainSharedModule,
        MatButtonModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatChipsModule,
    ],
    declarations: [SelectGroupMembersDialogComponent],
    entryComponents: [SelectGroupMembersDialogComponent],
    exports: [SelectGroupMembersDialogComponent],
    providers: [NgxsActionHelper, FeatureService],
})
export class SelectGroupMembersDialogModule {}
