<app-dialog-header [title]="confirmTitle" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="p-24">
    <p [innerHTML]="confirmMessage | translate | safeHtml"></p>
    <mat-checkbox class="mt-16" [(ngModel)]="checkboxValue">{{ checkboxLabel }}</mat-checkbox>
</div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>

    <app-flat-button [color]="confirmButtonColor" [label]="actionButtonText" (onClick)="save.emit(checkboxValue); dialogRef.close(true)"></app-flat-button>
</div>
