import { MaxBrainEvent } from 'app/library/event/models/event.model';
import { GlobalParticipant } from 'app/projects/user/src/lib/models/participant.model';
import { AttendanceStatus } from '../enums/attendance-status.enum';
import { IModuleEventAttendanceQuery } from '../interfaces/module-event-attendance.query.interface';

export class ModuleEventAttendance {
    id: string;
    event: {
        id: string;
    };
    eventData: MaxBrainEvent;
    participant: GlobalParticipant;
    status: AttendanceStatus;
    comment: string;
    updatedBy: GlobalParticipant;

    constructor(query?: IModuleEventAttendanceQuery) {
        if (query) {
            this.id = query.id.toString();
            this.event = new MaxBrainEvent(query.event);
            this.participant = new GlobalParticipant(query.participant);
            this.status = query.status;
            this.comment = query.comment;
            this.updatedBy = new GlobalParticipant(query.updated_by);
        } else {
            this.id = null;
            this.event = null;
            this.participant = null;
            this.status = null;
            this.comment = null;
            this.updatedBy = null;
        }
    }
}
