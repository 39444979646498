import { IExtendedAttributeQuery } from 'app/library/extended-attributes/interfaces/extended-attribute.query.interface';
import { ExtendedAttribute } from 'app/library/extended-attributes/models/extended-attribute';
import { IUserExtendedAttributeValue } from '../interfaces/user-extended-attribute-value.interface';
import { UserExtendedAttributeValue } from './user-attribute-value';

export class UserExtendedAttributeWithValue {
    attribute: ExtendedAttribute;
    value: UserExtendedAttributeValue;

    constructor(attribute: IExtendedAttributeQuery, value: IUserExtendedAttributeValue) {
        this.attribute = new ExtendedAttribute(attribute);
        this.value = new UserExtendedAttributeValue(value || null);
    }
}
