import { IEntity } from 'app/projects/entity/src/lib/interfaces/entity.interface';
import { Section } from './section.model';

export class SectionCommand {
    name: string;
    module: IEntity;
    type: string;
    sort: number;

    constructor(entity: Section) {
        if (!entity) {
            throw new Error('Can not create SectionCommand when no input is provided!');
        }

        this.name = entity.name;
        this.module = {
            id: entity.moduleId,
        };
        this.type = entity.type;
        this.sort = entity.sort;
    }
}
