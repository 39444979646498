import { ILocationQuery } from '../interfaces/location.query.interface';

export class MaxBrainLocation {
    static label_singular = 'location';
    static label_plural = 'locations';

    static props_to_search = ['name'];

    id: string;
    name: string;

    constructor(location?: ILocationQuery) {
        if (location) {
            this.id = location.id ? location.id.toString() : null;
            this.name = location.name;
        } else {
            this.id = null;
            this.name = '';
        }
    }
}
