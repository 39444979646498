import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import {
    CreateModuleEventAttendanceSuccess,
    FetchModuleEventAttendances,
    FetchModuleEventAttendancesFailure,
    FetchModuleEventAttendancesSuccess,
    RefreshModuleEventAttendanceUsersOnlineState,
    SearchModuleEventAttendances,
    SetModuleEventAttendances,
    UnsetModuleEventAttendances,
} from '../actions';
import { AddModuleEventAttendances } from '../actions/add-entities.action';
import { ModuleEventAttendanceApiService } from '../services/module-event-attendance.api-service';
import { ModuleEventAttendancesStateModel } from './module-event-attendances.state-model';
import { ModuleEventAttendance } from './module-event-attendance.model';
import { ModuleEventAttendanceState } from './module-event-attendance.state';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { FeatureService } from 'app/library/feature/services/feature.service';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { UserService } from 'app/library/user/services/user.service';

@State<ModuleEventAttendancesStateModel>({
    name: 'moduleEventAttendances',
    defaults: new ModuleEventAttendancesStateModel(),
})
@Injectable()
export class ModuleEventAttendancesState extends SubentityState<ModuleEventAttendance> {
    @Selector([ModuleEventAttendanceState.getEntities])
    static getFilteredEntities(state: ModuleEventAttendancesStateModel, moduleEventAttendances: ModuleEventAttendance[]): ModuleEventAttendance[] {
        if (!moduleEventAttendances) {
            return null;
        }

        return moduleEventAttendances
            .filter((item) => state.filtered.indexOf(item.id) !== -1)
            .map((item) => {
                return [state.list.indexOf(item.id), item];
            })
            .sort((a: [number, ModuleEventAttendance], b: [number, ModuleEventAttendance]) => {
                return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
            })
            .map((x: [number, ModuleEventAttendance]) => x[1]);
    }

    @Selector()
    static getSelectedEntityIds(state: ModuleEventAttendancesStateModel): string[] {
        return state.selected;
    }

    @Selector()
    static getSearchTerm(state: ModuleEventAttendancesStateModel): string {
        return state.searchTerm;
    }

    @Selector()
    static getTotalCount(state: ModuleEventAttendancesStateModel): number {
        return state.totalCount;
    }

    @Selector()
    static getPageSize(state: ModuleEventAttendancesStateModel): number {
        return state.pageSize;
    }

    @Selector()
    static getPageNumber(state: ModuleEventAttendancesStateModel): number {
        return state.pageNumber;
    }

    @Selector()
    static isFetchingList(state: ModuleEventAttendancesStateModel): boolean {
        return state.fetchingList;
    }

    constructor(
        private _userService: UserService,
        private _featureService: FeatureService,
        private _moduleEventAttendanceApiService: ModuleEventAttendanceApiService,
        private _store: Store
    ) {
        super(null, 0);
    }

    @Action(UnsetModuleEventAttendances)
    unsetAll(ctx: StateContext<ModuleEventAttendancesStateModel>): void {
        this._unsetSubentities(ctx);
    }

    @Action(FetchModuleEventAttendances)
    fetchAll(ctx: StateContext<ModuleEventAttendancesStateModel>, action: FetchModuleEventAttendances): void {
        ctx.patchState({
            pageSize: action.payload.pageSize,
            pageNumber: action.payload.pageNumber,
        });

        // if user has selected filter, search term and is on first page -> patch state, otherwise add entities to list
        const stateAction =
            (action.payload.attendanceStatuses.length || action.payload.search || action.payload.sortChanged) && action.payload.pageNumber === 1
                ? SetModuleEventAttendances
                : AddModuleEventAttendances;

        this._fetchSubentities(
            ctx,
            { payload: 'all' },
            stateAction,
            FetchModuleEventAttendancesSuccess,
            FetchModuleEventAttendancesFailure,
            this._moduleEventAttendanceApiService
                .getModuleEventAttendances(
                    action.payload.eventId,
                    action.payload.search,
                    action.payload.sortBy,
                    action.payload.sortOrder,
                    action.payload.pageSize,
                    action.payload.pageNumber,
                    action.payload.attendanceStatuses
                )
                .pipe(
                    map((data) => {
                        ctx.patchState({
                            totalCount: data.pagination.number_of_pages,
                        });

                        return data.data.map((query) => new ModuleEventAttendance(query));
                    })
                )
        );
    }

    @Action(FetchModuleEventAttendancesSuccess)
    fetchAllSuccess(ctx: StateContext<ModuleEventAttendancesStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchModuleEventAttendancesFailure)
    fetchAllFailure(ctx: StateContext<ModuleEventAttendancesStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(AddModuleEventAttendances)
    addToAll({ getState, patchState }: StateContext<ModuleEventAttendancesStateModel>, { payload }: AddModuleEventAttendances): void {
        const state = getState();

        const subentities = payload.map((subentity) => subentity.id);
        const newList = Array.from(new Set<string>([...(state.list || []), ...subentities]));

        patchState({
            list: newList,
            filtered: [...newList], // filtered is same as list because of serverside pagination
            selected: [],
        });

        setTimeout(() => {
            const moduleEventAttendances = this._store.selectSnapshot(ModuleEventAttendanceState.getEntities);

            if (moduleEventAttendances && this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)) {
                this._store.dispatch(new RefreshModuleEventAttendanceUsersOnlineState(moduleEventAttendances));
                this._userService.setOnlineStateInterval(RefreshModuleEventAttendanceUsersOnlineState, ModuleEventAttendanceState.getEntities);
            }
        });
    }

    @Action(CreateModuleEventAttendanceSuccess)
    createEntity(ctx: StateContext<ModuleEventAttendancesStateModel>, action: CreateModuleEventAttendanceSuccess): void {
        const tags = this._store.selectSnapshot(ModuleEventAttendanceState.getEntities) || [];

        this._patchSubentities(ctx, { payload: { subentityIds: [action.payload.id] } }, tags);
    }

    @Action(SetModuleEventAttendances)
    setEntities(ctx: StateContext<ModuleEventAttendancesStateModel>, action: SetModuleEventAttendances): void {
        this._setSubentities(ctx, action, AddModuleEventAttendances);

        setTimeout(() => {
            const moduleEventAttendances = this._store.selectSnapshot(ModuleEventAttendanceState.getEntities);

            if (moduleEventAttendances && this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)) {
                this._store.dispatch(new RefreshModuleEventAttendanceUsersOnlineState(moduleEventAttendances));
                this._userService.setOnlineStateInterval(RefreshModuleEventAttendanceUsersOnlineState, ModuleEventAttendanceState.getEntities);
            }
        });
    }

    @Action(SearchModuleEventAttendances)
    search({ patchState }: StateContext<ModuleEventAttendancesStateModel>, { payload }: SearchModuleEventAttendances): void {
        patchState({
            searchTerm: payload,
        });
    }
}
