import { AttendanceStatus } from '../enums/attendance-status.enum';
import { IAttendanceStatus } from '../interfaces/attendance-status.interface';

export class AttendanceStatuses {
    static all: IAttendanceStatus[] = [
        {
            translationLabel: 'EVENT.ATTENDANCE.STATUS.ATTENDED',
            value: AttendanceStatus.ATTENDED,
            isSelected: false,
            btnColor: 'green',
            count: 0,
        },
        {
            translationLabel: 'EVENT.ATTENDANCE.STATUS.DIDNT_ATTEND',
            value: AttendanceStatus.NOT_ATTENDED,
            isSelected: false,
            btnColor: 'gray',
            count: 0,
        },
        {
            translationLabel: 'EVENT.ATTENDANCE.STATUS.EXCUSED',
            value: AttendanceStatus.EXCUSED,
            isSelected: false,
            btnColor: 'gray',
            count: 0,
        },
        {
            translationLabel: 'EVENT.ATTENDANCE.STATUS.UNMARKED',
            value: AttendanceStatus.UNMARKED,
            isSelected: false,
            btnColor: 'gray',
            count: 0,
        },
    ];
}
