import { IMaxBrainEnvironment } from 'app/projects/shared/src/lib/interfaces/environment.interface';
import { EnvServer } from './enums/env-server.enum';

export const environment: IMaxBrainEnvironment = {
    production: true,
    hmr: false,
    apiUrl: 'https://api.dev.maxbrain.io',
    tinymceUrl: 'https://api.dev.maxbrain.io',
    baseUrlSecondPart: 'dev.maxbrain.io',
    classtimeBaseUrl: 'https://beta.classtime.com',
    classtimeJsUrl: 'https://beta.classtime.com/release/Classtime.js',
    respeakIntegrationUrl: 'https://expert.respeak.io/widget/respeak-widget.umd.cjs',
    unsplashViewsBaseUrl: 'https://views.unsplash.com/v?app_id=mJyTgSjUq3cy1b16fS37zLjjwbaorhYg&photo_id=',
    version: require('../../package.json').version + '-dev',
    server: EnvServer.Dev,
    PSPDFKitLicenseKey:
        // tslint:disable-next-line:max-line-length
        'kiUq9OenAYZkcyT66GaLoHqqlGE3EtY0bOdQqoYpMG5EHuMOiOUXj06X5yU7h0mXkKIZUipemPHbRo44MzLtA4cYrMnz17El10XvSSObMdNxGvf1vvCaK38Q_YquwXRFh93dTd619h3guFxysKY_0R8NDuIslTt5ZidIgmRfvJMOrpIru8LbdkD2_bNS3tPK1P3lkJlweaa-J_gq-8cwza1TvxrqJ_6CHr5HCH35lb9QZ8Wqqb-CFMwtKUu0KyD4R3ajW9dg3Zf3hUNG8APkjH9EtxQJ2NnAxcBKsVzFjFkx-uUr_RP2bUoP5A678hjzBBIJoVX1kXrmuCEZaiZizqxqoGUaYSY03OrNn6KzB1RoRQkICd3Qq6S7tFtOZcsYsz3-a9UAA5KdijQpDz_ffIpYNu4Vm5ckKY9VWYD9krpQWh4Yuqg-YnNK73yGSizGndJLXDqBwCK43r3OuZ8Y5b4Tqbl_dUsIa6HhitS2izLcQbNdM9FU-DQX0NhgUMpa205TIlWgKc63Ykwlcmt43R67_phaDeDqSGg4Gd5j387ULk8SIcv0mMNx1KzBrBic',
};
