import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { FetchModuleEventAttendances, FetchModuleEventAttendancesSuccess } from '../../actions';
import { ModuleEventAttendance } from '../../models/module-event-attendance.model';

export class ModuleEventAttendancesDataSource implements DataSource<ModuleEventAttendance> {
    private _loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this._loadingSubject.asObservable();

    constructor(private _filteredUser$: Observable<ModuleEventAttendance[]>, private _store: Store, private _action$: Actions) {}

    connect(collectionViewer: CollectionViewer): Observable<ModuleEventAttendance[]> {
        return this._filteredUser$;
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this._loadingSubject.complete();
    }

    loadModuleEventAttendances(
        eventId: string,
        search?: string,
        sortBy?: string,
        sortOrder?: string,
        pageSize?: number,
        pageNumber?: number,
        attendanceStatuses?: number[],
        sortChanged?: boolean
    ): void {
        this._loadingSubject.next(true);

        this._store.dispatch(
            new FetchModuleEventAttendances({
                eventId,
                search,
                sortBy,
                sortOrder,
                pageSize,
                pageNumber: pageNumber,
                attendanceStatuses,
                sortChanged,
            })
        );

        this._action$
            .pipe(
                ofActionDispatched(FetchModuleEventAttendancesSuccess),
                finalize(() => this._loadingSubject.next(false)),
                take(1)
            )
            .subscribe();
    }
}
