import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IModuleUserQuery } from '../interfaces/module-user.query.interface';
import { IManageModuleUsersActionPayload } from '../interfaces/modules-users-action.action-payload.interface';
import { IManageModuleUsersResponsibleActionPayload } from '../interfaces/modules-users-responsible-action.action-payload.interface';
import { IResponsibleRoleQuery } from '../interfaces/responsible-role.query.interface';
import { IManageTemplateUsersActionPayload } from '../interfaces/templates-users-action.action-payload.interface';
import { IManageTemplateUsersResponsibleActionPayload } from '../interfaces/templates-users-responsible-action.action-payload.interface';
import { ModuleUserCommand } from '../models/module-user.command.model';
import { ModuleUserExtended } from '../models/module-user.extended.model';
import { ModuleUser } from '../models/module-user.model';
import { ResponsibleRole } from '../models/responsible-role.model';
import { IManageProgramExecutionUsersActionPayload } from '../interfaces/program-execution-users-action.action-payload.interface';

@Injectable()
export class ModuleUserApiService extends EntityService<IModuleUserQuery, ModuleUserCommand> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'modules-users');
    }

    getAll(): Observable<ModuleUser[]> {
        return this._getAll().pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    getAllByModuleId(moduleId: string): Observable<ModuleUser[]> {
        return this.http
            .get<IModuleUserQuery[]>(`${this.apiUrl}/${this.entities}?module=${moduleId}`)
            .pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    getAllByUserId(userId: string): Observable<ModuleUser[]> {
        return this.http.get<IModuleUserQuery[]>(`${this.apiUrl}/${this.entities}?user=${userId}`).pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    read(id: string): Observable<ModuleUser> {
        return this._read(id).pipe(map((query) => new ModuleUserExtended(query)));
    }

    getModuleUserByModuleId(moduleId: string, userId: string): Observable<ModuleUserExtended[]> {
        return this.http
            .get<IModuleUserQuery[]>(`${this.apiUrl}/${this.entities}?module=${moduleId}&user=${userId}`)
            .pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    update({ userIds, moduleIds, permissions, roleLabel, isResponsible }: IManageModuleUsersActionPayload): Observable<ModuleUserExtended[]> {
        const data = {
            user_ids: userIds,
            module_ids: moduleIds,
            role_label: roleLabel,
            is_responsible: isResponsible,
            permissions,
        };

        return this.http.put<IModuleUserQuery[]>(`${this.apiUrl}/modules-users/manager`, data).pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    updateModuleManagerPermissions(moduleId: string, userId: string, permissions: string[]): Observable<ModuleUserExtended> {
        const data = {
            permissions,
        };

        return this.http.put<IModuleUserQuery>(`${this.apiUrl}/modules/${moduleId}/managers/${userId}/permissions`, data).pipe(map((query) => new ModuleUserExtended(query)));
    }

    updateTemplateManager({ userIds, moduleIds, permissions, roleLabel, isResponsible }: IManageModuleUsersActionPayload): Observable<ModuleUserExtended[]> {
        const data = {
            user_ids: userIds,
            module_ids: moduleIds,
            role_label: roleLabel,
            is_responsible: isResponsible,
            permissions,
        };

        return this.http
            .put<IModuleUserQuery[]>(`${this.apiUrl}/course-structure/templates-users/manager`, data)
            .pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    addUsersAsResponsible({ userIds, moduleIds, roleLabel, roles }: IManageModuleUsersResponsibleActionPayload): Observable<ModuleUserExtended[]> {
        const data = {
            user_ids: userIds,
            module_ids: moduleIds,
            role_label: roleLabel,
            roles: roles,
        };

        return this.http.put<IModuleUserQuery[]>(`${this.apiUrl}/modules-users/responsible`, data).pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    removeUsersAsResponsible({ userIds, moduleIds }: IManageModuleUsersResponsibleActionPayload): Observable<void> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                user_ids: userIds,
                module_ids: moduleIds,
            },
        };

        return this.http.delete<void>(`${this.apiUrl}/modules-users/responsible`, options);
    }

    removeTemplateUsersAsResponsible({ userIds, moduleId }: IManageTemplateUsersResponsibleActionPayload): Observable<void> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                user_ids: userIds,
                module_id: moduleId,
            },
        };

        return this.http.delete<void>(`${this.apiUrl}/course-structure/templates-users/responsible`, options);
    }

    addUsersAsResponsibleToTemplate({ userIds, moduleId, roleLabel }: IManageTemplateUsersResponsibleActionPayload): Observable<ModuleUserExtended[]> {
        const data = {
            user_ids: userIds,
            module_id: moduleId,
            role_label: roleLabel,
        };

        return this.http
            .put<IModuleUserQuery[]>(`${this.apiUrl}/course-structure/templates-users/responsible`, data)
            .pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    removeUsersAsResponsibleFromTemplate({ userIds, moduleIds }: IManageModuleUsersResponsibleActionPayload): Observable<void> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                user_ids: userIds,
                module_ids: moduleIds,
            },
        };

        return this.http.delete<void>(`${this.apiUrl}/course-structure/templates-users/responsible`, options);
    }

    addUsersToModulesAsParticipants({ userIds, moduleIds, notify }: IManageModuleUsersActionPayload): Observable<ModuleUserExtended[]> {
        const data = {
            user_ids: userIds,
            module_ids: moduleIds,
            notify,
        };

        return this.http.put<IModuleUserQuery[]>(`${this.apiUrl}/modules-users/participant`, data).pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    addUsersToProgramExecutionsAsParticipants({
        bookings,
        programExecutionId,
        userIds,
        courseExecutionIds,
        courseOfferingId,
        bookingFilters,
    }: IManageProgramExecutionUsersActionPayload): Observable<{ id: number }> {
        let params = new HttpParams();

        if (bookingFilters) {
            if (bookingFilters.search) {
                params = params.set('filter[query]', bookingFilters.search);
            }

            if (bookingFilters.moduleIds.length) {
                params = params.append('filter[execution]', bookingFilters.moduleIds.join());
            }

            if (bookingFilters.certificateIds.length) {
                params = params.append('filter[certificate]', bookingFilters.certificateIds.join());
            }

            if (bookingFilters.noCertificate) {
                params = params.set('filter[withoutCertificate]', bookingFilters.noCertificate);
            }

            if (bookingFilters.completionStatusIds.length) {
                params = params.append('filter[completion]', bookingFilters.completionStatusIds.join());
            }
        }

        let data = {
            bookings: bookings,
            user_ids: userIds,
            course_execution_ids: courseExecutionIds,
            course_offering_id: courseOfferingId,
        };

        return this.http.post<{ id: number }>(`${this.apiUrl}/course-structure/program-executions/${programExecutionId}/book-participants/async`, data, { params });
    }

    addUsersToCourseOfferings({ bookings, userIds, courseExecutionIds, courseOfferingId, bookingFilters }: IManageProgramExecutionUsersActionPayload): Observable<{ id: number }> {
        let params = new HttpParams();

        if (bookingFilters) {
            if (bookingFilters.search) {
                params = params.set('filter[query]', bookingFilters.search);
            }

            if (bookingFilters.moduleIds.length) {
                params = params.append('filter[execution]', bookingFilters.moduleIds.join());
            }

            if (bookingFilters.certificateIds.length) {
                params = params.append('filter[certificate]', bookingFilters.certificateIds.join());
            }

            if (bookingFilters.noCertificate) {
                params = params.set('filter[withoutCertificate]', bookingFilters.noCertificate);
            }

            if (bookingFilters.completionStatusIds.length) {
                params = params.append('filter[completion]', bookingFilters.completionStatusIds.join());
            }
        }

        let data = {
            bookings: bookings,
            user_ids: userIds,
            course_execution_ids: courseExecutionIds,
            course_offering_id: courseOfferingId,
        };

        return this.http.post<{ id: number }>(`${this.apiUrl}/course-structure/course-offerings/${courseOfferingId}/book-participants/async`, data, { params });
    }

    addUsersToModulesAsManagers({ userIds, moduleIds, permissions }: IManageModuleUsersActionPayload): Observable<ModuleUserExtended[]> {
        const data = {
            user_ids: userIds,
            module_ids: moduleIds,
            permissions,
        };

        return this.http.put<IModuleUserQuery[]>(`${this.apiUrl}/modules-users/manager`, data).pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    addUsersToTemplatesAsManagers({ userIds, moduleId, permissions }: IManageTemplateUsersActionPayload): Observable<ModuleUserExtended[]> {
        const data = {
            user_ids: userIds,
            module_id: moduleId,
            permissions,
        };

        return this.http
            .put<IModuleUserQuery[]>(`${this.apiUrl}/course-structure/templates-users/manager`, data)
            .pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    removeUsersFromModulesAsParticipants({ userIds, moduleIds, notify }: IManageModuleUsersActionPayload): Observable<ModuleUserExtended[]> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                user_ids: userIds,
                module_ids: moduleIds,
                notify,
            },
        };

        return this.http
            .delete<IModuleUserQuery[]>(`${this.apiUrl}/modules-users/participant`, options)
            .pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    removeUsersFromModulesAsManagers({ userIds, moduleIds }: IManageModuleUsersActionPayload): Observable<ModuleUserExtended[]> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                user_ids: userIds,
                module_ids: moduleIds,
            },
        };

        return this.http.delete<IModuleUserQuery[]>(`${this.apiUrl}/modules-users/manager`, options).pipe(map((queries) => queries.map((query) => new ModuleUserExtended(query))));
    }

    removeUsersFromTemplatesAsManagers({ userIds, moduleId }: IManageTemplateUsersActionPayload): Observable<void> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                module_id: moduleId,
                user_ids: userIds,
            },
        };

        return this.http.delete<void>(`${this.apiUrl}/course-structure/templates-users/manager`, options);
    }

    addSelfAsParticipant(moduleId: string): Observable<ModuleUserExtended> {
        return this.http.put<IModuleUserQuery>(`${this.apiUrl}/modules/${moduleId}/participants/add-self`, {}).pipe(map((query) => new ModuleUserExtended(query)));
    }

    removeSelfAsParticipant(moduleId: string): Observable<ModuleUserExtended> {
        return this.http.delete<IModuleUserQuery>(`${this.apiUrl}/modules/${moduleId}/participants/remove-self`, {}).pipe(map((query) => new ModuleUserExtended(query)));
    }

    patchModulePinnedStatus(moduleId: string, isPinned: boolean): Observable<ModuleUserExtended> {
        return this.http.patch<IModuleUserQuery>(`${this.apiUrl}/modules/${moduleId}/is-pinned`, { is_pinned: isPinned }).pipe(map((query) => new ModuleUserExtended(query)));
    }

    updateModuleSectionProgressStatus(moduleId: string, action: string): Observable<ModuleUserExtended> {
        return this.http.post<IModuleUserQuery>(`${this.apiUrl}/modules/${moduleId}/${action}`, {}).pipe(map((query) => new ModuleUserExtended(query)));
    }

    getResponsibleRoles(): Observable<ResponsibleRole[]> {
        return this.http.get<IResponsibleRoleQuery[]>(`${this.apiUrl}/modules-users/reponsible-roles`).pipe(map((queries) => queries.map((query) => new ResponsibleRole(query))));
    }
}
