export class ResetPasswordSuccess {
    static readonly type = '[User] ResetPasswordSuccess';

    constructor() {}
}

export class ResetUserPasswordUrlSuccess {
    static readonly type = '[User] ResetUserPasswordUrlSuccess';

    constructor(public payload: { resetUrl: string }) {}
}
