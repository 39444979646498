import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { MaxBrainTag } from 'app/library/tag/models/tag.model';

@Pipe({
    name: 'filterTags',
})
@Injectable()
export class FilterTagsPipe implements PipeTransform {
    transform(value: MaxBrainTag[], content: HTMLElement, component): MaxBrainTag[] {
        if (!value) {
            return [];
        }
        if (!content) {
            return value;
        }

        let remainingWidth = content.offsetWidth - 20;

        const filteredTags = value.filter((tag, index) => {
            const tagsLeft = value.length - index - 1;

            // You have to add visualLenght method to component where you're using this filter
            const tagLength = component.visualLength(tagsLeft ? `${tag.name} + ${tagsLeft}` : tag.name);

            if (remainingWidth > tagLength) {
                remainingWidth -= tagLength;
                return true;
            }

            return false;
        });

        if (!filteredTags.length) {
            return [];
        }

        return filteredTags;
    }
}
