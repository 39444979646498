import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class EntityService<TQuery, TCommand = any> {
    constructor(protected http: HttpClient, protected apiUrl: string, protected entities: string) {}

    protected _getAll(): Observable<TQuery[]> {
        return this.http.get<TQuery[]>(`${this.apiUrl}/${this.entities}`);
    }

    protected _create(data: TCommand): Observable<TQuery> {
        return this.http.post<TQuery>(`${this.apiUrl}/${this.entities}`, data);
    }

    protected _read(id: string): Observable<TQuery> {
        return this.http.get<TQuery>(`${this.apiUrl}/${this.entities}/${id}`);
    }

    protected _update(id: string, data: TCommand): Observable<TQuery> {
        return this.http.put<TQuery>(`${this.apiUrl}/${this.entities}/${id}`, data);
    }

    protected _delete(id: string): Observable<string> {
        return this.http.delete(`${this.apiUrl}/${this.entities}/${id}`).pipe(map(() => id));
    }
}
