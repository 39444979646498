import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import {
    AddUsersToExperiences,
    AddUsersToExperiencesFailure,
    AddUsersToExperiencesSuccess,
    AssignUserToExperience,
    AssignUserToExperienceFailure,
    AssignUserToExperienceSuccess,
    DeleteExperienceUsers,
    DeleteExperienceUsersFailure,
    DeleteExperienceUsersSuccess,
    MoveExperienceUsers,
    MoveExperienceUsersFailure,
    MoveExperienceUsersSuccess,
} from 'app/library/experience-user/actions';
import { IManageExperienceUsersActionPayload } from 'app/library/experience-user/interfaces/experience-users-action.action-payload.interface';
import { ExperienceUserExtended } from 'app/library/experience-user/models/experience-user.extended.model';
import { ExperienceUser } from 'app/library/experience-user/models/experience-user.model';
import { BehaviorSubject, Observable, race } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FetchExperienceByUserId, FetchExperienceByUserIdFailure, FetchExperienceByUserIdSuccess } from '../actions';
import { ExperienceUsersState } from '../models/experience-users.state';

@Injectable()
export class ExperienceUsersService {
    @Select(ExperienceUsersState.getEntities)
    experienceUser$: Observable<ExperienceUser[]>;

    private _experienceUserExtendedSubject = new BehaviorSubject<ExperienceUserExtended>(null);
    public experienceUserExtendedSubject$ = this._experienceUserExtendedSubject.asObservable();

    constructor(private _store: Store, private _action$: Actions) {}

    getExperienceUsers(): ExperienceUser[] {
        return this._store.selectSnapshot(ExperienceUsersState.getEntities);
    }

    async fetchExperienceUserByExperienceId(experienceId: string): Promise<ExperienceUserExtended> {
        this._store.dispatch(new FetchExperienceByUserId(experienceId));

        return race(
            this._action$.pipe(
                ofActionDispatched(FetchExperienceByUserIdSuccess),
                map((action: FetchExperienceByUserIdSuccess) => {
                    this._experienceUserExtendedSubject.next(action.payload);
                    return action.payload;
                })
            ),
            this._action$.pipe(
                ofActionDispatched(FetchExperienceByUserIdFailure),
                map(() => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    addUsersToExperience(payload: IManageExperienceUsersActionPayload): Promise<{ newly_assigned: ExperienceUserExtended[]; already_assigned: ExperienceUserExtended[] }> {
        this._store.dispatch(new AddUsersToExperiences(payload));

        return race(
            this._action$.pipe(
                ofActionDispatched(AddUsersToExperiencesSuccess),
                map((action: AddUsersToExperiencesSuccess) => action.payload)
            ),
            this._action$.pipe(
                ofActionDispatched(AddUsersToExperiencesFailure),
                map(() => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    assignUserToExperience(payload: IManageExperienceUsersActionPayload): Promise<ExperienceUserExtended> {
        this._store.dispatch(new AssignUserToExperience(payload));

        return race(
            this._action$.pipe(
                ofActionDispatched(AssignUserToExperienceSuccess),
                map((action: AssignUserToExperienceSuccess) => {
                    return action.payload;
                })
            ),
            this._action$.pipe(
                ofActionDispatched(AssignUserToExperienceFailure),
                map(() => null)
            )
        )
            .pipe(take(1))
            .toPromise();
    }

    moveExperienceUsers(target: string, selectedUserIds: [{ id: string }]): Promise<boolean> {
        this._store.dispatch(new MoveExperienceUsers({ target, selectedUserIds }));

        return race(this._action$.pipe(ofActionDispatched(MoveExperienceUsersSuccess)), this._action$.pipe(ofActionDispatched(MoveExperienceUsersFailure)))
            .pipe(take(1))
            .toPromise();
    }

    assignExperienceUsers(target: string, selectedUserIds: [{ id: string }]): Promise<boolean> {
        this._store.dispatch(new MoveExperienceUsers({ target, selectedUserIds }));

        return race(this._action$.pipe(ofActionDispatched(MoveExperienceUsersSuccess)), this._action$.pipe(ofActionDispatched(MoveExperienceUsersFailure)))
            .pipe(take(1))
            .toPromise();
    }

    deleteExperienceUsers(experienceUsers: { experienceUsers: [{ id: string }] }): Promise<boolean> {
        this._store.dispatch(new DeleteExperienceUsers(experienceUsers));

        return race(this._action$.pipe(ofActionDispatched(DeleteExperienceUsersSuccess)), this._action$.pipe(ofActionDispatched(DeleteExperienceUsersFailure)))
            .pipe(take(1))
            .toPromise();
    }

    /* getUserSectionStatById(id: string): UserSectionStats {
        return UserSectionStatsState.getEntity(this._store.selectSnapshot(UserSectionStatsState), id);
    } */
}
