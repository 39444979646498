import { Pipe, PipeTransform } from '@angular/core';
import { JobStatus } from '../enums/job-status.enum';

@Pipe({ name: 'getProgressBarColor' })
export class GetProgressBarColorPipe implements PipeTransform {
    transform(jobStatus: JobStatus, jobStats: any): any {
        switch (jobStatus) {
            case JobStatus.Created:
            case JobStatus.Finished:
                return jobStats.failed > 0 ? 'red' : 'green';
            case JobStatus.InProgress:
                return 'blue';
            case JobStatus.TimedOut:
                return 'orange';
            case JobStatus.Canceled:
                return 'red';
            default:
                return 'gray';
        }
    }
}
