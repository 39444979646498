import { IModuleUserAttendanceQuery } from '../interfaces/module-user-attendance.query.interface';

export class ModuleUserAttendance {
    attended: number;
    percentage: number;
    total: number;

    constructor(query: IModuleUserAttendanceQuery) {
        this.attended = query.attended;
        this.percentage = query.percentage;
        this.total = query.total;
    }
}
