import { MaxBrainExperience } from 'app/library/experience/models/experience.model';
import { User } from 'app/projects/user/src/lib/models/user';
import { IExperienceUserQuery } from '../interfaces/experience-user.query.interface';
import { ExperienceUser } from './experience-user.model';

export class ExperienceUserExtended extends ExperienceUser {
    experience: MaxBrainExperience;
    user: User;

    constructor(experienceUser?: IExperienceUserQuery) {
        super(experienceUser);

        if (experienceUser) {
            this.experience = new MaxBrainExperience(experienceUser.experience);
            this.user = new User(experienceUser.user);
        } else {
            this.experience = null;
            this.user = null;
        }
    }
}
