<app-dialog-header [title]="confirmTitle" [additionalText]="entitiesLength ? '(' + entitiesLength + ')' : ''" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="p-24" [innerHTML]="confirmMessage | translate | safeHtml"></div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <mat-checkbox [(ngModel)]="isNotifyChecked" class="mr-24">
        {{ notifyText | translate }}
    </mat-checkbox>

    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="closeDialog(false)"></app-white-button>
    <app-white-button
        class="mr-8"
        *ngIf="!hideAdditionalActionButton"
        [label]="additionalActionButtonText"
        (onClick)="closeDialog(true, 'additionalActionButtonClicked')"
    ></app-white-button>

    <app-flat-button [autofocus]="true" [color]="confirmButtonColor" [label]="actionButtonText" (onClick)="closeDialog(true)" *ngIf="!hideActionButton"></app-flat-button>
</div>
