import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-flat-button',
    templateUrl: './flat-button.component.html',
    styleUrls: ['./flat-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FlatButtonComponent {
    @Input()
    label: string = '';

    @Input()
    color: string = '';

    @Input()
    icon: string = '';

    @Input()
    svgIcon: string = '';

    @Input()
    disabled: boolean = false;

    @Input()
    isLoading: boolean = false;

    @Input()
    showThreeDots: boolean = false;

    @Input()
    participantViewButton: boolean = false;

    @Input()
    autofocus: boolean = false;

    @Input()
    counter: number = 0;

    @Output()
    onClick = new EventEmitter<any>();

    constructor() {}

    onClickButton(event): void {
        this.onClick.emit(event);
    }
}
