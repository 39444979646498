<app-dialog-header [title]="dialogTitle" [additionalText]="'(' + list.chatParticipants.length + ')'" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="p-24" fxLayout="column" fxLayoutAlign="start">
    <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let item of list.chatParticipants">
            <div class="relative">
                <img class="avatar" *ngIf="item.imageUrl; else initials" [alt]="item.fullName" [src]="item.imageUrl" />
                <ng-template #initials>
                    <div fxLayoutAlign="center center" class="avatar-initials avatar">{{ item.fullName | initials }}</div>
                </ng-template>
                <ng-template [onlineStateIndicator]="item.isOnline"> </ng-template>
            </div>
            <p class="ml-8">{{ item.fullName }}</p>
        </mat-list-item>
    </mat-list>
</div>
