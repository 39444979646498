import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    transform(value: string, term: string): SafeHtml {
        return this.sanitized.bypassSecurityTrustHtml(value.replace(RegExp(term, 'gi'), '<span class="accent-50">$&</span>'));
    }
}
