import * as momentImported from 'moment';
import { ISurveyInfoQuery } from '../interfaces/survey-info.query-interface';
const moment = momentImported;

export class SurveyInfo {
    endDate: Date;
    endTime: string;
    status: string;
    surveyUrl: string;
    resultsUrl: string;
    completionText: string;
    reportGeneratedAt: string;

    constructor(query?: ISurveyInfoQuery) {
        if (query) {
            this.endDate = query.end_date ? new Date(query.end_date) : null;
            this.endTime = query.end_date ? moment(query.end_date).format('HH:mm') : '';
            this.status = query.status;
            this.surveyUrl = query.survey_url;
            this.resultsUrl = query.results_url;
            this.completionText = query.completion_text;
            this.reportGeneratedAt = query.report_generated_at;
        }
    }
}
