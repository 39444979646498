import { IModuleImageQuery } from '../interfaces/module-image.interface';

// @dynamic
/**
 * This class is deprecated use => MaxbrainImage
 */
export class ModuleImage {
    id: string;
    unsplash: { unsplashId: string; authorName: string; authorProfileUrl: string; authorProfileImage: string } = {
        unsplashId: '',
        authorName: '',
        authorProfileImage: '',
        authorProfileUrl: '',
    };
    url: string;
    smallUrl: string;
    thumbUrl: string;

    constructor(imageQuery?: IModuleImageQuery) {
        if (imageQuery) {
            this.id = imageQuery.id.toString();
            this.url = imageQuery.url;
            this.smallUrl = imageQuery.small_url;
            this.thumbUrl = imageQuery.thumb_url;
            if (imageQuery.unsplash) {
                this.unsplash.unsplashId = imageQuery.unsplash.unsplash_id.toString();
                this.unsplash.authorName = imageQuery.unsplash.author_name;
                this.unsplash.authorProfileUrl = imageQuery.unsplash.author_profile_url;
                this.unsplash.authorProfileImage = imageQuery.unsplash.author_profile_image;
            } else {
                this.unsplash = null;
            }
        }
    }
}
