import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatSort } from '@angular/material/sort';
import { Actions, Select, Store } from '@ngxs/store';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { UserStatuses } from 'app/projects/user/src/lib/models/user-statuses';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UpdateModuleEventAttendance } from '../../actions';
import { AttendanceStatus } from '../../enums/attendance-status.enum';
import { IAttendanceStatus } from '../../interfaces/attendance-status.interface';
import { AttendanceStatuses } from '../../models/attendance-status';
import { ModuleEventAttendance } from '../../models/module-event-attendance.model';
import { ModuleEventAttendancesState } from '../../models/module-event-attendances.state';
import { ModuleEventAttendancesDataSource } from './datasource';

@Component({
    selector: 'app-module-event-attendance-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModuleEventAttendanceListComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
    @Input()
    eventId: string;

    @Input()
    filteredModuleEventAttendances$: Observable<ModuleEventAttendance[]>;

    @Input()
    moduleEventAttendancesStateSearchTerm$: Observable<string>;
    moduleEventAttendancesStateSearchTerm: string;

    @Input()
    selectedAttendanceStatus$: Observable<number[]>;
    selectedAttendanceStatuses: number[];

    @Select(ModuleEventAttendancesState.isFetchingList)
    isFetchingModuleEventAttendances$: Observable<boolean>;

    userStatuses = UserStatuses;

    @ViewChild(MatSort)
    sort: MatSort;

    dataSource: ModuleEventAttendancesDataSource;
    displayedColumns = ['avatar', 'firstName', 'lastName', 'attendance', 'updatedBy'];

    attendanceStatuses = AttendanceStatuses.all;
    AttendanceStatus = AttendanceStatus;

    currentPage = 1;
    pageSize = 20;

    constructor(private _store: Store, private _action$: Actions, public matDialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.dataSource = new ModuleEventAttendancesDataSource(this.filteredModuleEventAttendances$, this._store, this._action$);

        setTimeout(() => {
            this.loadModuleEventAttendancesPage(false);

            this.sort.sortChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                this.loadModuleEventAttendancesPage(true, true);
            });
        });

        this.initInfiniteScroll();
    }

    ngAfterViewInit(): void {
        this.moduleEventAttendancesStateSearchTerm$
            .pipe(
                filter((searchTerm, index) => {
                    this.moduleEventAttendancesStateSearchTerm = searchTerm || '';
                    return index > 0;
                }),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.loadModuleEventAttendancesPage(true);
            });

        this.selectedAttendanceStatus$.pipe(takeUntil(this._unsubscribeAll)).subscribe((attendanceStatus: number[]) => {
            this.selectedAttendanceStatuses = attendanceStatus;
            this.loadModuleEventAttendancesPage(true);
        });
    }

    focusCommentInput(commentInput: MatFormField) {
        commentInput._elementRef.nativeElement.classList.add('ng-dirty');
    }

    onCommentSave(moduleEventAttendance: ModuleEventAttendance, commentInput: MatFormField) {
        commentInput._elementRef.nativeElement.classList.remove('ng-dirty');
        this.updateModuleEventAttendanceStatus(moduleEventAttendance);
    }

    onSelection(moduleEventAttendance: ModuleEventAttendance, attendanceStatus: IAttendanceStatus) {
        this.updateModuleEventAttendanceStatus(moduleEventAttendance, attendanceStatus);
    }

    updateModuleEventAttendanceStatus(moduleEventAttendance: ModuleEventAttendance, attendanceStatus?: IAttendanceStatus) {
        if (attendanceStatus) {
            moduleEventAttendance.status = attendanceStatus.value;
        }
        this._store.dispatch(new UpdateModuleEventAttendance({ eventId: moduleEventAttendance.id, moduleEventAttendance: moduleEventAttendance }));
    }

    initInfiniteScroll(): void {
        const scrollContainer = document.getElementById('scroll-container');
        scrollContainer.addEventListener('scroll', (e) => {
            const scrollTop = (e.target as HTMLElement).scrollTop;
            const clientHeight = (e.target as HTMLElement).clientHeight;
            const scrollHeight = (e.target as HTMLElement).scrollHeight;
            const hiddenHeight = scrollHeight - clientHeight - 60;

            const totalCount = this._store.selectSnapshot(ModuleEventAttendancesState.getTotalCount);
            const isFetchingList = this._store.selectSnapshot(ModuleEventAttendancesState.isFetchingList);

            if (!isFetchingList && scrollTop >= hiddenHeight && this.currentPage < totalCount) {
                this.currentPage = this.currentPage + 1;
                this.loadModuleEventAttendancesPage();
            }
        });
    }

    loadModuleEventAttendancesPage(resetPageIndex = false, sortChanged = false): void {
        if (resetPageIndex) {
            this.currentPage = 1;
        }

        this.dataSource.loadModuleEventAttendances(
            this.eventId,
            this.moduleEventAttendancesStateSearchTerm,
            this.sort.active,
            this.sort.direction,
            this.pageSize,
            this.currentPage,
            this.selectedAttendanceStatuses,
            sortChanged
        );
    }
}
