import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFeatureQuery } from '../interfaces/feature.query.interface';
import { Feature } from '../models/feature.model';

@Injectable()
export class FeatureApiService extends EntityService<IFeatureQuery> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'features');
    }

    getAll(): Observable<Feature[]> {
        return this._getAll().pipe(map((queries: IFeatureQuery[]) => queries.map((query) => new Feature(query))));
    }
}
