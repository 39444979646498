import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Select, Store, ofActionDispatched } from '@ngxs/store';
import { CalendarDialogComponent } from 'app/library/event/components/dialogs/calendar/calendar.component';
import { FeatureService } from 'app/library/feature/services/feature.service';
import { Logout } from 'app/projects/auth/src/lib/actions/logout.action';
import { MaxBrainAuthState } from 'app/projects/auth/src/lib/models/auth.state';
import { SnackBarTime } from 'app/projects/core/src/lib/constants/snack-bar';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { FuseSidebarService } from 'app/projects/fuse/src/lib/components/sidebar/sidebar.service';
import { FUSE_ASYNC_DRAWER } from 'app/projects/fuse/src/lib/services/async-drawer.token';
import { FuseConfigService } from 'app/projects/fuse/src/lib/services/config.service';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { ILanguage, ILanguageService } from 'app/projects/shared/src/lib/interfaces/language.interface';
import { MAXBRAIN_LANGUAGE_SERVICE } from 'app/projects/shared/src/lib/services/language.token';
import { PatchProfile } from 'app/projects/user/src/lib/actions/patch-profile.action';
import { ResetPasswordSuccess } from 'app/projects/user/src/lib/actions/reset-password-success.action';
import { ResetPassword } from 'app/projects/user/src/lib/actions/reset-password.action';
import { PatchProfileCommand } from 'app/projects/user/src/lib/models/patch-profile.command';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { AsyncJobApiService } from 'app/services/async-job.api-service';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TenantDataService } from '../../../config/services/tenant-data.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    selectedLanguage: ILanguage;
    userStatusOptions: any[];
    logoSrc: string;
    tenantName: string;
    supportEmail: string;
    iosUrl: string;
    androidUrl: string;
    isDrawerOpen = false;
    isSSOFeatureEnabled = false;
    isUserOnlineStateEnabled = false;
    isRespeakWidgetEnabled = false;
    isKmpAppActive = false;

    @Select(UserState.getMyInfo)
    profile$: Observable<User>;
    profile: User;

    @Select(MaxBrainAuthState.isCockpitUser)
    isCockpitUser$: Observable<boolean>;

    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(FUSE_ASYNC_DRAWER) public asyncJobApiService: AsyncJobApiService,
        @Inject(MAXBRAIN_ENVIRONMENT) private environment: IEnvironment,
        @Inject(MAXBRAIN_LANGUAGE_SERVICE) public languageService: ILanguageService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _store: Store,
        private _matDialog: MatDialog,
        private _tenantDataService: TenantDataService,
        private _http: HttpClient,
        private _action$: Actions,
        private _matSnackBar: MatSnackBar,
        private _featureService: FeatureService
    ) {
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50',
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107',
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336',
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD',
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161',
            },
        ];

        this.logoSrc = this._tenantDataService.logo;
        this.tenantName = this._tenantDataService.tenantName;
        this.supportEmail = this._tenantDataService.supportEmail;
        this.iosUrl = `https://itunes.apple.com/app/apple-store/id${this._tenantDataService.ios}?mt=8`;
        this.androidUrl = `https://play.google.com/store/apps/details?id=${this._tenantDataService.android}`;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.selectedLanguage = this._translateService.currentLang
            ? this.languageService.getLanguageById(this._translateService.currentLang)
            : this.languageService.getDefaultLanguage();
    }

    ngOnInit(): void {
        const mobileAppValue = localStorage.getItem('kmpMobileApp');

        if (mobileAppValue === 'ios' || mobileAppValue === 'android') {
            this.isKmpAppActive = true;
        }
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;

            this.horizontalNavbar = config.layout.navbar.position === 'top';
            this.rightNavbar = config.layout.navbar.position === 'right';
            this.hiddenNavbar = config.layout.navbar.hidden === true;
        });

        this.profile$.pipe(takeUntil(this._unsubscribeAll)).subscribe((profile) => {
            if (profile) {
                this.profile = profile;

                this.isSSOFeatureEnabled = this._featureService.checkFeatureStatus(FeatureSwitchName.Sso);
                this.isUserOnlineStateEnabled = this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState);
                this.isRespeakWidgetEnabled = this._featureService.checkFeatureStatus(FeatureSwitchName.RespeakIntegration);
            }
        });

        this.isDrawerOpen = Boolean(localStorage.getItem('isDrawerOpen'));
        this.asyncJobApiService.jobAdded.pipe(takeUntil(this._unsubscribeAll)).subscribe((job) => {
            if (job) {
                this.isDrawerOpen = Boolean(localStorage.getItem('isDrawerOpen'));
            }
        });
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    openRespeakWidget() {
        const onClose = () => console.log('Widget closed');
        (window as any).RespeakWidget.open(onClose);
    }

    async openDialog(): Promise<void> {
        /*
         * There is no access to the token interceptor from this component
         * Check comment from setLanguage method
         */
        const calendarSyncUrl = await this._http
            .get<{ url: string }>(`${this.environment.apiUrl}/ical-url`, {
                headers: {
                    Authorization: `Bearer ${this._store.selectSnapshot(MaxBrainAuthState.getAccessToken)}`,
                },
            })
            .toPromise();
        const confirmDialogRef = this._matDialog.open(CalendarDialogComponent, {
            width: '600px',
        });
        confirmDialogRef.componentInstance.calendarSyncUrl = calendarSyncUrl;
    }

    resetPassword(): void {
        this._store.dispatch(new ResetPassword(this.profile.email));
        this._action$.pipe(ofActionDispatched(ResetPasswordSuccess), take(1), takeUntil(this._unsubscribeAll)).subscribe(() => {
            const successMessage = this._translateService.instant('GENERAL.RESET_PASSWORD_EMAIL_SENT');

            this._matSnackBar.open(successMessage, 'OK', {
                verticalPosition: 'top',
                duration: SnackBarTime.medium,
            });
        });
    }

    updateOnlineState(toHideStatus: 'true' | 'false'): void {
        this._store.dispatch(
            new PatchProfile(
                new PatchProfileCommand({
                    hide_online_state: JSON.parse(toHideStatus),
                })
            )
        );
    }

    logout(): void {
        this._store.dispatch(new Logout());
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
