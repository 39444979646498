import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { NotifyType } from 'app/projects/shared/src/lib/enums/notify-type.enum';
import { GroupAvatar } from '../../group/models/group-avatar';
import { Group } from '../models/group';

export interface IUpdateFavouriteFlagQuery {
    groupId: string;
    isFavourite: boolean;
}

export class SetGroup {
    static readonly type = '[Group] SetGroup';

    constructor(public payload: Group) {}
}

export class UnsetGroup {
    static readonly type = '[Group] UnsetGroup';
}

export class AddGroups {
    static readonly type = '[Group] AddGroups';

    constructor(public payload: Group[]) {}
}

export class CreateGroup {
    static readonly type = '[Group] CreateGroup';

    constructor(public payload: Group) {}
}

export class CreateGroupSuccess {
    static readonly type = '[Group] CreateGroupSuccess';

    constructor(public payload: Group) {}
}

export class CreateGroupFailure {
    static readonly type = '[Group] CreateGroupFailure';
}

export class DeleteGroup {
    static readonly type = '[Group] DeleteGroup';

    constructor(public payload: { id: string; notify: NotifyType }) {}
}

export class DeleteGroupSuccess {
    static readonly type = '[Group] DeleteGroupSuccess';

    constructor(public payload: string) {}
}

export class DeleteGroupFailure {
    static readonly type = '[Group] DeleteGroupFailure';
}

export class SearchGroups {
    static readonly type = '[Group] SearchGroups';

    constructor(public payload: string) {}
}

export class FetchGroup {
    static readonly type = '[Group] FetchGroup';

    constructor(public payload: string) {}
}

export class FetchGroupSuccess {
    static readonly type = '[Group] FetchGroupSuccess';

    constructor(public payload: Group) {}
}

export class FetchGroupFailure {
    static readonly type = '[Group] FetchGroupFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class UpdateGroupFavourite {
    static readonly type = '[Group] UpdateGroupFavourite';

    constructor(public payload: IUpdateFavouriteFlagQuery) {}
}

export class UpdateGroupFavouriteSuccess {
    static readonly type = '[Group] UpdateGroupFavouriteSuccess';

    constructor(public payload: Group) {}
}

export class UpdateGroupFavouriteFailure {
    static readonly type = '[Group] UpdateGroupFavouriteFailure';
}

export class UpdateGroup {
    static readonly type = '[Group] UpdateGroup';

    constructor(public payload: Group) {}
}

export class UpdateGroupSuccess {
    static readonly type = '[Group] UpdateGroupSuccess';

    constructor(public payload: Group) {}
}

export class UpdateGroupFailure {
    static readonly type = '[Group] UpdateGroupFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class SetGroups {
    static readonly type = '[Group] SetGroups';

    constructor(public payload: Group[]) {}
}

export class FetchGroups {
    static readonly type = '[Group] FetchGroups';

    constructor(public payload: string) {}
}

export class FetchGroupsSuccess {
    static readonly type = '[Group] FetchGroupsSuccess';

    constructor(public payload: Group[]) {}
}

export class FetchGroupsFailure {
    static readonly type = '[Group] FetchGroupsFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class UploadGroupImage {
    static readonly type = '[Group] UploadGroupImage';

    constructor(public payload: File) {}
}

export class UploadGroupImageSuccess {
    static readonly type = '[Group] UploadGroupImageSuccess';

    constructor(public payload: GroupAvatar) {}
}

export class UploadGroupImageFailure {
    static readonly type = '[Group] UploadGroupImageFailure';
}

export class LeaveGroup {
    static readonly type = '[Group] LeaveGroup';

    constructor(public payload: string) {}
}

export class LeaveGroupSuccess {
    static readonly type = '[Group] LeaveGroupSuccess';

    constructor(public payload: string) {}
}

export class LeaveGroupFailure {
    static readonly type = '[Group] LeaveGroupFailure';
}
