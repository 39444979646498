import { Pipe, PipeTransform } from '@angular/core';
import { JobMessageStatus } from '../enums/job-message-status.enum';

@Pipe({ name: 'getJobStatsIconColor' })
export class GetJobStatsIconColorPipe implements PipeTransform {
    transform(jobStatskey: string): any {
        switch (jobStatskey) {
            case JobMessageStatus.Failed:
                return 'red-icon';
            case JobMessageStatus.Pending:
                return 'blue-icon';
            case JobMessageStatus.Succeeded:
                return 'green-icon';
            default:
                return 'gray-icon';
        }
    }
}
