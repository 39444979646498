import { Survey } from 'app/library/survey-section/models/survey.model';
import { SectionType } from '../enums/section-type.enum';
import { ISectionQuery } from '../interfaces/section.query.interface';
import { Section } from './section.model';

export class SurveySection extends Section {
    survey: Survey;

    constructor(query?: ISectionQuery, abc?: string) {
        super(query);

        this._type = SectionType.Survey;

        if (query?.survey) {
            this.survey = new Survey(query.survey);
        }
    }
}
