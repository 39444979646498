import { NotifyType } from 'app/projects/shared/src/lib/enums/notify-type.enum';
import { Module } from './module.model';
import { RelevantExecution } from 'app/library/program-item/models/relevant-execution.model';

export class ModuleStatusCommand {
    status: string;
    notify: NotifyType;

    constructor(tempModule: Module | RelevantExecution, notify?: NotifyType) {
        this.status = tempModule.status;
        this.notify = notify;
    }
}
