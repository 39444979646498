import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { MaxBrainAuthState } from 'app/projects/auth/src/lib/models/auth.state';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { JobStatus } from '../library/shared/async-job-drawer/enums/job-status.enum';
import { IJobQuery } from '../library/shared/async-job-drawer/interfaces/job.query.interface';
import { IJobQueryPaginated } from '../library/shared/async-job-drawer/interfaces/jobs-paginated.query.interface';
import { IMessagePaginated } from '../library/shared/async-job-drawer/interfaces/message-paginated.query.interface';
import { JobCommand } from '../library/shared/async-job-drawer/models/job.comand.model';
import { Job } from '../library/shared/async-job-drawer/models/job.model';
import { Message } from '../library/shared/async-job-drawer/models/message.model';

export interface Pagination {
    numberOfPages: number;
    numberOfResults: number;
}

@Injectable()
export class AsyncJobApiService extends EntityService<IJobQuery> {
    private _asyncJobs = new BehaviorSubject<Job[]>([]);
    public asyncJobs$ = this._asyncJobs.asObservable();

    jobAdded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    jobFinished = new BehaviorSubject<{ jobId: string; nodeId: string }>(null);
    jobsPaginationChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    jobsPage = 1;
    messagesPage = 1;
    numberOfActiveAsyncJobs = 0;

    get asyncJobs(): Job[] {
        return this._asyncJobs.getValue();
    }

    private _completedJobs: number = null;
    private _updated = false;

    private _jobsPagination: Pagination = { numberOfPages: 0, numberOfResults: 0 };
    private _messagesPagination: Pagination = { numberOfPages: 0, numberOfResults: 0 };

    get completedJobs(): number {
        return this._completedJobs;
    }
    set completedJobs(value: number) {
        this._completedJobs = value;
    }

    get updated(): boolean {
        return this._updated;
    }
    set updated(value: boolean) {
        this._updated = value;
    }

    get jobsPagination(): Pagination {
        return this._jobsPagination;
    }
    set jobsPagination(value: Pagination) {
        this._jobsPagination = value;
    }

    get messagesPagination(): Pagination {
        return this._messagesPagination;
    }
    set messagesPagination(value: Pagination) {
        this._messagesPagination = value;
    }

    accessToken: string;
    headers: {
        Authorization: string;
    };

    private async _getToken(): Promise<void> {
        this.accessToken = await this._store
            .select(MaxBrainAuthState.getAccessToken)
            .pipe(
                filter((x) => !!x),
                take(1)
            )
            .toPromise();
        this.headers = {
            Authorization: `Bearer ${this.accessToken}`,
        };
    }

    constructor(http: HttpClient, private _store: Store, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'certificate');
    }

    async getAllJobs(openDrawer = false, page = 1, checkActiveJobs = false): Promise<void> {
        await this._getToken();
        this.jobsPage = page;
        this.http.get<IJobQueryPaginated>(`${this.apiUrl}/async-job/jobs?page=${page}`, { headers: this.headers }).subscribe((queries) => {
            this.updated = queries.updated;
            this.completedJobs = queries.completed;
            this.jobsPagination = {
                numberOfPages: queries.pagination.number_of_pages,
                numberOfResults: queries.pagination.number_of_results,
            };
            this.jobsPaginationChanged.next(true);
            const asyncJobs = queries.data.map((query) => new Job(query));

            // Check if any of job is in progress or created after login
            let activeJob = false;
            if (checkActiveJobs) {
                activeJob = asyncJobs.some((job) => job.communicatedStatus === JobStatus.InProgress || job.communicatedStatus === JobStatus.Created);
            }
            if (openDrawer || activeJob || (checkActiveJobs && this.updated)) {
                localStorage.setItem('isDrawerOpen', 'true');
                if (this.updated && checkActiveJobs) {
                    this.numberOfActiveAsyncJobs = 0;
                }
            }
            this._asyncJobs.next(asyncJobs);
        });
    }

    async getJobStatus(jobId: string): Promise<Job> {
        await this._getToken();
        return this.http
            .get<IJobQuery>(`${this.apiUrl}/async-job/jobs/${jobId}/status`, { headers: this.headers })
            .pipe(map((query) => new Job(query)))
            .toPromise();
    }

    async getJobMessages(jobId: string, page = 1, messageStatus: string): Promise<Message[]> {
        await this._getToken();
        this.messagesPage = page;
        let status = '';
        if (messageStatus && messageStatus !== 'total') {
            status = '&status=' + messageStatus;
        }
        return this.http
            .get<IMessagePaginated>(`${this.apiUrl}/async-job/jobs/${jobId}/messages?page=${page}${status}`, { headers: this.headers })
            .pipe(
                map((queries) => {
                    this.messagesPagination = {
                        numberOfPages: queries.pagination.number_of_pages,
                        numberOfResults: queries.pagination.number_of_results,
                    };
                    return queries.data.map((query) => new Message(query));
                })
            )
            .toPromise();
    }

    async markJobAsRead(jobId: string): Promise<boolean> {
        try {
            await this._getToken();
            const data = { async_jobs: [{ id: jobId }] };
            await this.http.patch<IJobQuery>(`${this.apiUrl}/async-job/jobs/mark-as-read`, data, { headers: this.headers }).toPromise();
            return true;
        } catch (e) {
            console.warn(e);
        }
    }

    async cancelJob(entity: Job): Promise<Job> {
        try {
            await this._getToken();
            const data = new JobCommand(entity);
            return this.http
                .patch<IJobQuery>(`${this.apiUrl}/async-job/jobs/${entity.id}/cancel`, data, { headers: this.headers })
                .pipe(map((query) => new Job(query)))
                .toPromise();
        } catch (e) {
            console.warn(e);
        }
    }

    async closeJob(entity: Job): Promise<Job> {
        try {
            await this._getToken();
            const data = new JobCommand(entity);
            return this.http
                .patch<IJobQuery>(`${this.apiUrl}/async-job/jobs/${entity.id}/close`, data, { headers: this.headers })
                .pipe(map((query) => new Job(query)))
                .toPromise();
        } catch (e) {
            console.warn(e);
        }
    }
}
