import { Injectable } from '@angular/core';

import { Observable, race } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Actions, ofActionDispatched } from '@ngxs/store';

@Injectable()
export class NgxsActionHelper {
    constructor(private action$: Actions) {}

    public race(successAction: any, failureAction: any): Observable<boolean> {
        return race(
            this.action$.pipe(
                ofActionDispatched(successAction),
                map(() => true)
            ),
            this.action$.pipe(
                ofActionDispatched(failureAction),
                map(() => false)
            )
        ).pipe(take(1));
    }
}
