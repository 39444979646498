import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';

@Injectable()
export class EventService {
    constructor(private http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) private environment: IEnvironment) {}

    getIcalURL(): Promise<{ url: string }> {
        return this.http.get<{ url: string }>(`${this.environment.apiUrl}/ical-url`).toPromise();
    }
}
