import { User } from './user';

export class UserCommand {
    email: string;
    first_name: string;
    last_name: string;
    language: string;
    permission: string[];
    email_notification: boolean;
    is_email_hidden: boolean;

    constructor(user: User) {
        this.email = user.email;
        this.first_name = user.firstName;
        this.last_name = user.lastName;
        this.language = user.language;
        this.permission = user.permissions;
        this.email_notification = user.emailNotification;
        this.is_email_hidden = user.isEmailHidden;
    }
}
