import { SortDirection } from '@angular/material/sort';
import { SubentityStateModel } from 'app/projects/subentity/src/lib/models/subentity.state-model';

export class AllUsersStateModel extends SubentityStateModel {
    filters = {
        list: [],
    };
    totalCount: number;
    sortBy: string;
    sortOrder: SortDirection;
    pageSize: number;
    pageNumber: number;

    constructor() {
        super();

        this.searchTerm = sessionStorage.getItem('allUsersSearchTerm');
        this.sortBy = sessionStorage.getItem('allUsersSortBy');
        this.sortOrder = sessionStorage.getItem('allUsersSortOrder') as SortDirection;
    }
}
