import { IFeatureQuery } from '../interfaces/feature.query.interface';

export class Feature {
    static props_to_search = ['id'];

    id: string;
    status: string;

    constructor(feature: IFeatureQuery) {
        this.id = feature.name;
        this.status = feature.status;
    }
}
