import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ModuleKind } from 'app/library/module/enums/module-kind.enum';
import { ModuleType } from 'app/library/module/enums/moduleType.enum';
import { Module } from 'app/library/module/models/module.model';

@Pipe({
    name: 'getModuleIcon',
})
@Injectable()
export class GetModuleIconPipe implements PipeTransform {
    moduleKind = ModuleKind;

    transform(module: Module, currentColor = false): string {
        switch (module.type) {
            case ModuleType.Basic:
                if (module.kind === this.moduleKind.ProgramExecution || module.kind === this.moduleKind.ProgramTemplate) {
                    return currentColor ? 'program_current_color' : 'program';
                } else if (module.isPrivate) {
                    return currentColor ? 'course_private_current_color' : 'course_private';
                } else {
                    return currentColor ? 'course_current_color' : 'course';
                }
            case ModuleType.Private:
                return currentColor ? 'course_private_current_color' : 'course_private';
            case ModuleType.ELearning:
                return currentColor ? 'e_learning_current_color' : 'e_learning';
            default:
                return '';
        }
    }
}
