import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import {
    FetchModuleFeatures,
    FetchModuleFeaturesFailure,
    FetchModuleFeaturesSuccess,
    SetModuleFeatures,
    UpdateModuleFeature,
    UpdateModuleFeatureFailure,
    UpdateModuleFeatureSuccess,
} from '../actions';
import { ModuleFeatureApiService } from '../services/module-feature.api-service';
import { ModuleFeature } from './module-feature.model';
import { ModuleFeatureStateModel } from './module-feature.state-model';

@State<ModuleFeatureStateModel>({
    name: 'moduleFeature',
    defaults: new ModuleFeatureStateModel(),
})
@Injectable()
export class ModuleFeatureState extends EntityState<ModuleFeature> {
    static getEntity(state: ModuleFeatureStateModel, id: string): ModuleFeature {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntities(state: ModuleFeatureStateModel): ModuleFeature[] {
        if (!state.map) {
            return null;
        }

        return Array.from(state.map.values());
    }

    constructor(private moduleFeatureApiService: ModuleFeatureApiService) {
        super(null, 0);

        this._propstoSearch = ModuleFeature.props_to_search;
    }

    @Action(SetModuleFeatures)
    setEntities(ctx: StateContext<ModuleFeatureStateModel>, action: SetModuleFeatures): void {
        this._setEntities(ctx, action);
    }

    @Action(FetchModuleFeatures)
    fetchEntities(ctx: StateContext<ModuleFeatureStateModel>, action: FetchModuleFeatures): void {
        this._fetchEntities(ctx, FetchModuleFeaturesSuccess, FetchModuleFeaturesFailure, SetModuleFeatures, this.moduleFeatureApiService.getAll(action.payload)).then(() => {});
    }

    @Action(FetchModuleFeaturesSuccess)
    fetchEntitiesSuccess(ctx: StateContext<ModuleFeatureStateModel>): void {
        this._fetchEntitiesSuccess(ctx);
    }

    @Action(FetchModuleFeaturesFailure)
    fetchEntitiesFailure(ctx: StateContext<ModuleFeatureStateModel>): void {
        this._fetchEntitiesFailure(ctx);
    }

    @Action(UpdateModuleFeature)
    updateEntity(ctx: StateContext<ModuleFeatureStateModel>, action: UpdateModuleFeature): void {
        this.moduleFeatureApiService.update(action.payload.moduleId, action.payload.moduleFeature).subscribe(
            (entities) => {
                this._addEntities(ctx, { payload: [entities[0]] });
                ctx.dispatch(new UpdateModuleFeatureSuccess(entities[0]));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateModuleFeatureFailure(response));
            }
        );
    }
}
