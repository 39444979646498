import { COMMA } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatHorizontalStepper, MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, Select, Store, ofActionDispatched } from '@ngxs/store';
import { FetchAllUsers } from 'app/library/all-users/actions';
import { AllUserDataSource } from 'app/library/all-users/components/active-user-list/datasource';
import { Feature } from 'app/library/feature/models/feature.model';
import { AddUsersToModulesAsManagers } from 'app/library/module-user/actions';
import { ModuleUser } from 'app/library/module-user/models/module-user.model';
import {
    CreateELearningModule,
    CreateELearningModuleFailure,
    CreateELearningModuleSuccess,
    CreateModule,
    CreateModuleFailure,
    CreateModuleSuccess,
    UpdateModuleSuccess,
} from 'app/library/module/actions';
import { UpdateModuleContent } from 'app/library/module/actions/update-module-content.action';
import { Module } from 'app/library/module/models/module.model';
import { CreateTag, CreateTagFailure, CreateTagSuccess, SearchTags } from 'app/library/tag/actions';
import { MaxBrainTag } from 'app/library/tag/models/tag.model';
import { TagState } from 'app/library/tag/models/tag.state';
import { TagsState } from 'app/library/tag/models/tags.state';
import { TagService } from 'app/library/tag/services/tag.service';
import { SnackBarTime } from 'app/projects/core/src/lib/constants/snack-bar';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';
import { HelperService } from 'app/projects/shared/src/lib/services/helper';
import { User } from 'app/projects/user/src/lib/models/user';
import { UsersState } from 'app/projects/user/src/lib/models/users.state';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { SearchAllUsers, SortAllUsers } from '../../../../all-users/actions';
import { AllUsersState } from '../../../../all-users/models/all-users.state';
import { FeatureState } from '../../../../feature/models/feature.state';
import { ELearningModule } from '../../../models/eLearning-module.model';
import { ModuleStatuses } from '../../../models/module-statuses';

@Component({
    selector: 'app-module-create-form-dialog',
    templateUrl: './create-form.component.html',
    styleUrls: ['./create-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ModuleCreateFormDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    static readonly digicompDurationProperties = ['y', 'm', 'w', 'd', 'hh', 'mm'];
    @Select(UsersState.getFilteredEntities)
    user$: User[];
    separatorKeysCodes: number[] = [COMMA];

    @Select(FeatureState.getEntities)
    feature$: Observable<Feature[]>;

    @Select(AllUsersState.getEntities)
    entitie$: Observable<User[]>;

    @Select(TagState.getUnusedColors)
    unusedColor$: Observable<string[]>;
    unusedColors: string[];

    @Select(TagsState.getFilteredEntities)
    private _filteredTag$: Observable<MaxBrainTag[]>;
    tagTermExists: boolean;
    filteredTag$: Observable<MaxBrainTag[]>;

    @ViewChild('tagInput')
    tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto')
    matAutocomplete: MatAutocomplete;
    tagCtrl = new FormControl();

    @ViewChild(MatHorizontalStepper) stepper: MatHorizontalStepper;

    @Input()
    role: string;

    @Input()
    moduleId: string;

    @Input()
    entityId: string;

    moduleForm: FormGroup;

    minDate: Date;
    maxDate: Date;
    createAnotherChecked = false;

    list: User[] = [];
    searchInput = new FormControl();
    moduleType: string;
    isELearningVisible: boolean;
    isPrivateVisible: boolean;

    dataSource: AllUserDataSource;
    displayedColumns = [];
    removable = true;
    hideEmail: boolean;
    isStepOneActive = true;

    @Select(AllUsersState.getTotalCount)
    totalCount$: Observable<number>;

    @ViewChild(MatPaginator)
    paginator: MatPaginator;
    @ViewChild(MatSort)
    sort: MatSort;

    sortBy = this._store.selectSnapshot(AllUsersState.getSortBy);
    sortOrder = this._store.selectSnapshot(AllUsersState.getSortOrder);
    isCreatingTag = false;

    get moduleDetailsForm(): FormGroup {
        return this.moduleForm && this.moduleForm.controls.details ? (this.moduleForm.controls.details as FormGroup) : null;
    }

    get moduleContentForm(): FormGroup {
        return this.moduleForm && this.moduleForm.controls.content ? (this.moduleForm.controls.content as FormGroup) : null;
    }

    creating = false;
    ruleOpts: {
        dontTrackProgress: string;
        trackProgress: string;
        sequential: string;
    };

    constructor(
        public dialogRef: MatDialogRef<ModuleCreateFormDialogComponent>,
        public route: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private _store: Store,
        private _action$: Actions,
        private _matSnackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _router: Router,
        private _matDialog: MatDialog,
        private _tagService: TagService,
        private _helperService: HelperService
    ) {
        super();
        this.ruleOpts = ModuleStatuses.ruleOpts;

        const tags = this._tagService.getTags();
        if (!tags) {
            this._tagService.fetchTags();
        }
    }

    ngOnInit(): void {
        this._store.dispatch(
            new FetchAllUsers({
                pageSize: 5,
            })
        );

        this.filteredTag$ = this._filteredTag$.pipe(
            filter((tags) => !!tags),
            tap((tags) => {
                this.tagTermExists = tags.map((tag) => tag.name.toLowerCase()).indexOf(this.tagCtrl?.value?.toLowerCase()) !== -1;
            })
        );

        this.searchInput.valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(1000), distinctUntilChanged()).subscribe((searchText) => {
            this._store.dispatch(new SearchAllUsers(searchText));
            this.loadUsersPage(true);
        });

        this.route.children[1].children[0].params.pipe(filter(({ type }) => type !== 'choose')).subscribe(({ type }) => {
            this.moduleType = type;
            this.createFormGroup(type);

            if (this.moduleType === 'e-learning') {
                this.moduleDetailsForm.get('selfPaced').setValue(true);
            }
        });

        this.tagCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(300), distinctUntilChanged()).subscribe((searchTerm) => {
            this._store.dispatch(new SearchTags(searchTerm));
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result && result.createAnotherChecked) {
                this.openDialog();
                this._router.navigate([{ outlets: { modal: ['new-module', this.moduleType === 'basic' ? (result.newModule.isPrivate ? 'private' : 'basic') : this.moduleType] } }]);
            } else {
                this._router.navigate([{ outlets: { modal: null } }]);
            }
        });

        this.feature$
            .pipe(
                filter((features) => !!features),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((features) => {
                this.isPrivateVisible = features.filter((feature) => feature.id === 'module_visibility' && feature.status === 'active').length === 1;
                this.isELearningVisible = features.filter((feature) => feature.id === 'e_learning' && feature.status === 'active').length === 1;
            });

        this.searchInput.valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this._unsubscribeAll)).subscribe((searchText) => {
            this._store.dispatch(new SearchAllUsers(searchText));
            this.loadUsersPage(true);
        });

        this.dataSource = new AllUserDataSource(this.entitie$, this._store, this._action$);

        this.unusedColor$.pipe(takeUntil(this._unsubscribeAll)).subscribe((unusedColors) => {
            this.unusedColors = unusedColors;
        });
    }

    _initializeTableData(): void {
        setTimeout(() => {
            if (this.sortBy && this.sortOrder) {
                this.sort.sort({
                    id: this.sortBy || '',
                    start: this.sortOrder || 'asc',
                    disableClear: false,
                });
            }

            this.sort.sortChange.pipe(takeUntil(this._unsubscribeAll)).subscribe((sort) => {
                this._store.dispatch(new SortAllUsers(sort));

                this.loadUsersPage(true);
            });

            this.paginator.page.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                this.loadUsersPage(false);
            });

            this.loadUsersPage(false);

            if (this.hideEmail) {
                this.displayedColumns = ['avatar', 'firstName', 'lastName', 'buttons'];
            } else {
                this.displayedColumns = ['avatar', 'firstName', 'lastName', 'email', 'buttons'];
            }
        });
    }

    onStepChange(event: MatStepper): void {
        if (event.selectedIndex === 3 || (this.moduleType === 'e-learning' && event.selectedIndex === 2)) {
            this._initializeTableData();
        }

        // waits for animation to finish transition between steps
        setTimeout(() => {
            if (event.selectedIndex === 0) {
                this.isStepOneActive = true;
            } else {
                this.isStepOneActive = false;
            }
        }, 400);
    }

    private _resetTagInputValue(): void {
        this.tagInput.nativeElement.value = '';
        this.tagCtrl.setValue(null);
    }

    private _addTag(tag: MaxBrainTag): void {
        if (!this.isCreatingTag) {
            this.isCreatingTag = true;

            this._store.dispatch(new CreateTag(tag));

            const actionSuccessSubscription = this._action$.pipe(ofActionDispatched(CreateTagSuccess), takeUntil(this._unsubscribeAll)).subscribe((action: CreateTagSuccess) => {
                this.moduleDetailsForm.controls.tags.setValue([...this.moduleDetailsForm.value.tags, action.payload]);
                this.moduleDetailsForm.markAsDirty();

                this._resetTagInputValue();
                actionSuccessSubscription.unsubscribe();
                actionFailureSubscription.unsubscribe();
                this.isCreatingTag = false;
            });

            const actionFailureSubscription = this._action$.pipe(ofActionDispatched(CreateTagFailure), takeUntil(this._unsubscribeAll)).subscribe(() => {
                this._resetTagInputValue();
                actionSuccessSubscription.unsubscribe();
                actionFailureSubscription.unsubscribe();
                this.isCreatingTag = false;
            });
        }
    }

    addTag(event: MatChipInputEvent): void {
        // Add tag only when MatAutocomplete is not open
        // To make sure this does not conflict with OptionSelected Event
        if (!this.matAutocomplete.isOpen) {
            const value = event.value.trim();

            if (!value) {
                return;
            }

            const tagExists = this.moduleDetailsForm.value.tags.some((tag: MaxBrainTag) => tag.name === value);

            if (!tagExists) {
                this._addTag(
                    new MaxBrainTag({
                        id: 0,
                        name: value,
                        color: MaxBrainTag.getRandomColorFromList(this.unusedColors),
                        category: 'module',
                    })
                );
            }
        }
    }

    removeTag(tagToRemove: MaxBrainTag): void {
        this.moduleDetailsForm.controls.tags.setValue([...this.moduleDetailsForm.value.tags.filter((tag: MaxBrainTag) => tagToRemove.id !== tag.id)]);
        this.moduleDetailsForm.markAsDirty();
    }

    tagSelected(event: MatAutocompleteSelectedEvent): void {
        if (event.option.value instanceof MaxBrainTag) {
            this.moduleDetailsForm.controls.tags.setValue([...this.moduleDetailsForm.value.tags, event.option.value]);
            this.moduleDetailsForm.markAsDirty();

            this._resetTagInputValue();
        } else {
            this._addTag(
                new MaxBrainTag({
                    id: 0,
                    name: event.option.value,
                    color: MaxBrainTag.getRandomColorFromList(this.unusedColors),
                    category: 'module',
                })
            );
        }
    }

    tagUpdated(updatedTag: MaxBrainTag): void {
        this.moduleDetailsForm.patchValue({
            tags: this.moduleDetailsForm.controls.tags.value.map((tag) => {
                if (tag.id === updatedTag.id) {
                    return updatedTag;
                }
                return tag;
            }),
        });
    }

    openDialog(): void {
        this.dialogRef = this._matDialog.open(ModuleCreateFormDialogComponent, {
            panelClass: 'allow-tags-overflow-dialog',
            disableClose: true,
            width: '700px',
        });
    }

    createFormGroup(type: string): void {
        this.moduleForm = this._formBuilder.group({
            details: this._formBuilder.group({
                name: ['', Validators.required],
                startDate: ['', Validators.required],
                endDate: ['', Validators.required],
                selfPaced: [false, Validators.required],
                tags: [{ value: [], disabled: false }],
                duration: [''],
                digicompDuration: this._formBuilder.group(
                    ModuleCreateFormDialogComponent.digicompDurationProperties.reduce((accumulator, currentValue) => {
                        accumulator[currentValue] = ['', Validators.pattern(/^\d*(?:[.,]\d{1})?$/)];
                        return accumulator;
                    }, {})
                ),
            }),
            content: this._formBuilder.group({
                id: ['', Validators.required],
                structure: [true, Validators.required],
                rule: [{ value: this.ruleOpts.trackProgress, disabled: false }],
            }),
        });

        this.moduleDetailsForm
            .get('selfPaced')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value: boolean) => {
                if (value) {
                    this.moduleDetailsForm.get('startDate').clearValidators();
                    this.moduleDetailsForm.get('endDate').clearValidators();
                    this.moduleDetailsForm.get('startDate').setValue(null);
                    this.moduleDetailsForm.get('endDate').setValue(null);
                } else {
                    this.moduleDetailsForm.get('startDate').setValidators(Validators.required);
                    this.moduleDetailsForm.get('endDate').setValidators(Validators.required);
                }

                this.moduleDetailsForm.get('startDate').updateValueAndValidity();
                this.moduleDetailsForm.get('endDate').updateValueAndValidity();
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private _openSnackBar(message: string): MatSnackBarRef<SimpleSnackBar> {
        return this._matSnackBar.open(message, this._translateService.instant('GENERAL.BUTTON.EDIT'), {
            verticalPosition: 'top',
            duration: SnackBarTime.slow,
        });
    }

    private _convertDurationToSeconds(value: string): number {
        if (!value) {
            return null;
        }

        const durationArray = value.split(':');

        return parseInt(durationArray[0], 10) * 3600 + parseInt(durationArray[1], 10) * 60;
    }

    private _getFormData(type: string): any {
        const formData = this.moduleForm.getRawValue();

        switch (type) {
            case 'e-learning':
                formData.details.duration = this._convertDurationToSeconds(this._helperService.cleaveTimeFixer(formData.details.duration));
                break;
            default:
                formData.details = Object.assign({}, formData.details, {
                    hasSections: formData.content.structure,
                    sectionRule: formData.content.rule ? formData.content.rule : 'default',
                    isPrivate: type === 'private',
                });
                if (!formData.details.selfPaced) {
                    formData.details.digicompDuration = null;
                }
                break;
        }

        return formData;
    }

    /**
     *
     * @param newModule
     * @private
     */
    private _closeDialogOnSuccess(newModule: Module): void {
        this.dialogRef.close({ newModule, createAnotherChecked: this.createAnotherChecked });
        this.creating = true;

        const successMessage = this._translateService.instant('MODULE.MESSAGE.CREATED');

        this._openSnackBar(successMessage)
            .onAction()
            .subscribe(() => {
                this.goToModule(newModule);
            });

        return;
    }

    private _createSuccessCallback(failureSubscription: Subscription, newModule: Module, contentFormData: { structure: boolean } = null): void {
        failureSubscription.unsubscribe();

        const actions: any[] = [];

        if (contentFormData && this.moduleType !== 'e-learning') {
            actions.push(new UpdateModuleContent(Object.assign({}, newModule, { hasSections: contentFormData.structure })));
        }

        if (this.list.length) {
            actions.push(
                new AddUsersToModulesAsManagers({
                    userIds: this.list.map((user) => user.id),
                    moduleIds: [newModule.id],
                    permissions: ModuleUser.getAllPermissions(),
                })
            );
        }

        if (actions.length) {
            this._store.dispatch(actions);
        }

        if (newModule instanceof ELearningModule) {
            // if we create a E-learning module we can close the dialog
            this._closeDialogOnSuccess(newModule);
        }

        // UpdateModule need to be play before to redirect to edit module : section update
        this._action$.pipe(ofActionDispatched(UpdateModuleSuccess), take(1), takeUntil(this._unsubscribeAll)).subscribe(() => {
            this._closeDialogOnSuccess(newModule);
        });
    }

    private _createFailureCallback(successSubscription: Subscription, response: HttpErrorResponse): void {
        successSubscription.unsubscribe();

        this.dialogRef.updateSize('80%');
        this.creating = false;

        /**
         * setTimeout is needed because of this.creating is used for ngIf in template
         */
        setTimeout(() => {
            Object.keys(response.error.errors).forEach((errorfield) => {
                (this.moduleDetailsForm.controls[errorfield] as FormControl).setErrors(response.error.errors[errorfield]);
            });
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Go to module
     */
    goToModule(module: Module): void {
        this._store.dispatch(new Navigate([`/${Module.label_plural}/${module.id}/edit`]));
    }

    updateEndDate(): void {
        const startDate = this.moduleDetailsForm.get('startDate').value;

        this.minDate = startDate ? new Date(startDate) : null;
    }

    updateStartDate(): void {
        const endDate = this.moduleDetailsForm.get('endDate').value;

        this.maxDate = endDate ? new Date(endDate) : null;
    }

    goBack(stepper: MatStepper): void {
        stepper.previous();
    }

    goForward(stepper: MatStepper): void {
        stepper.next();
    }

    create(type: string): void {
        this.dialogRef.updateSize('300px');
        this.creating = true;

        const formData = this._getFormData(type);

        let CreateAction: any = CreateModule;
        let CreateActionSuccess: any = CreateModuleSuccess;
        let CreateActionFailure: any = CreateModuleFailure;

        switch (type) {
            case 'e-learning':
                CreateAction = CreateELearningModule;
                CreateActionSuccess = CreateELearningModuleSuccess;
                CreateActionFailure = CreateELearningModuleFailure;
                break;
        }

        this._store.dispatch(new CreateAction(formData.details));
        const successSubscription = this._action$.pipe(ofActionDispatched(CreateActionSuccess), take(1), takeUntil(this._unsubscribeAll)).subscribe((action) => {
            this._createSuccessCallback(failureSubscription, action.payload, formData.content);
        });
        const failureSubscription = this._action$.pipe(ofActionDispatched(CreateActionFailure), take(1), takeUntil(this._unsubscribeAll)).subscribe((action) => {
            this._createFailureCallback(successSubscription, action.payload.response);
        });
    }

    handleChange($event: MatRadioChange): void {
        if (!$event.value) {
            this.moduleContentForm.controls.rule.setValue(false);
            this.moduleContentForm.controls.rule.disable();
        } else {
            this.moduleContentForm.controls.rule.enable();
            this.moduleContentForm.controls.rule.setValue(this.ruleOpts.trackProgress);
        }
    }

    removeFromList(user: User, index: number): void {
        this.list = [...this.list.filter((item) => item.id !== user.id)];

        this.dataSource = new AllUserDataSource(
            this.entitie$.pipe(
                map((users) => {
                    return users.map((item) => {
                        if (item.id === user.id) {
                            item = Object.assign(new User(), item, {
                                selected: false,
                            });
                        } else if (this.list.find((item2) => item2.id === item.id)) {
                            item = Object.assign(new User(), item, {
                                selected: true,
                            });
                        }
                        return item;
                    });
                })
            ),
            this._store,
            this._action$
        );
    }

    addToList(user: User): void {
        this.list = [...this.list, user];
        this.dataSource = new AllUserDataSource(
            this.entitie$.pipe(
                map((users) => {
                    return users.map((item) => {
                        if (item.id === user.id || this.list.find((item2) => item2.id === item.id)) {
                            item = Object.assign(new User(), item, {
                                selected: true,
                            });
                        }
                        return item;
                    });
                })
            ),
            this._store,
            this._action$
        );
    }

    async loadUsersPage(resetPageIndex = false, isUnassignedSelected = false): Promise<void> {
        if (resetPageIndex) {
            this.paginator.pageIndex = 0;
        }

        if (this.dataSource) {
            this.dataSource.loadUsers(
                'users',
                [],
                this.entityId,
                this.searchInput.value,
                this.sort.active,
                this.sort.direction,
                this.paginator.pageSize,
                this.paginator.pageIndex + 1,
                isUnassignedSelected
            );
        }
    }
}
