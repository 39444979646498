import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModuleCreateFormDialogComponent } from 'app/library/module/components/dialogs/create-form/create-form.component';

@Component({
    selector: 'app-module-create-form-dialog-page',
    templateUrl: './module-create-form-dialog-page.component.html',
    styleUrls: ['./module-create-form-dialog-page.component.scss'],
})
export class ModuleCreateFormDialogPageComponent implements OnInit {
    matDialog: MatDialogRef<ModuleCreateFormDialogComponent, any>;

    constructor(private _matDialog: MatDialog, private _router: Router) {}

    ngOnInit(): void {
        this.openDialog();
    }

    openDialog(): void {
        this.matDialog = this._matDialog.open(ModuleCreateFormDialogComponent, {
            panelClass: 'allow-tags-overflow-dialog',
            disableClose: true,
            width: '700px',
        });
    }
}
