export const errorUrlExcpentions: RegExp[] = [
    new RegExp(`.*/slack/channel/modules/.*`),
    new RegExp(`.*/modules/.*/grading-scheme`),
    new RegExp(`.*/modules/.*/final-grade/participant`),
    new RegExp(`.*/e-learning/sections/.*/registration-metrics/participant`),
    new RegExp(`.*/e-learning/modules/.*/registration-metrics/participant`),
    new RegExp(`.*/scorm/courses/import-jobs/.*/status`),
    new RegExp(`.*/zoom/modules/.*/webinar`),
    new RegExp(`.*/zoom/modules/.*/meeting`),
    new RegExp(`.*/certificate/modules/.*/template`),
];
