import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'exclude' })
export class ExcludePipe implements PipeTransform {
    transform(list: any[], listToExclude: any[], property: string = null): any {
        return list
            ? list.filter((item) => listToExclude.map((itemToExclude) => (property ? itemToExclude[property] : itemToExclude)).indexOf(property ? item[property] : item) === -1)
            : [];
    }
}
