import { ITagQuery } from '../interfaces/tag.query.interface';

// @dynamic
export class MaxBrainTag {
    static label_singular = 'tag';
    static label_plural = 'tags';

    static props_to_search = ['name'];

    static colorsArray = [
        '#4FC3F7',
        '#64B5F6',
        '#03A9F4',
        '#1E88E5',
        '#26C6DA',
        '#00ACC1',
        '#4DB6AC',
        '#009688',
        '#81C784',
        '#4CAF50',
        '#9CCC65',
        '#7CB342',
        '#FBC02D',
        '#FFA000',
        '#FF7043',
        '#E15E62',
        '#D7777A',
        '#CE5558',
        '#EF5350',
        '#EC407A',
        '#BA68C8',
        '#9575CD',
        '#7E57C2',
        '#5C6BC0',
        '#A1887F',
        '#8D6E63',
        '#605A5A',
        '#78909C',
        '#546E7A',
        '#455A64',
    ];

    id: string;
    name: string;
    color: string;
    category: string;

    /**
     * Random color generator
     */
    static get getRandomColor(): string {
        return this.getRandomColorFromList(this.colorsArray);
    }

    /**
     * Random color generator from list
     */
    static getRandomColorFromList(list: string[]): string {
        const randomIndex = Math.floor(Math.random() * list.length);

        return list[randomIndex];
    }

    constructor(tagQuery?: ITagQuery) {
        if (tagQuery) {
            this.id = tagQuery.id.toString();
            this.name = tagQuery.name;
            this.color = tagQuery.color;
            this.category = tagQuery.category;
        } else {
            this.id = null;
            this.name = null;
            this.color = null;
            this.category = null;
        }
    }
}
