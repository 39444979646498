import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ScheduleRenewal } from 'app/projects/auth/src/lib/actions/schedule-renewal.action';
import { IEntityState } from 'app/projects/entity/src/lib/interfaces/entity.state.interface';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import { Observable, merge } from 'rxjs';
import { takeLast, tap } from 'rxjs/operators';
import { AddUsers } from '../actions/add-entities.action';
import { AddSocialNetworkSuccess } from '../actions/add-social-network-success.action';
import { AddSocialNetwork } from '../actions/add-social-network.action';
import { AddUserPermissionsFailure } from '../actions/add-user-permissions-failure.action';
import { AddUserPermissionsSuccess } from '../actions/add-user-permissions-success.action';
import { AddUserPermissions } from '../actions/add-user-permissions.action';
import { ChangeEmailAddressFailure, ChangeNotificationEmailAddressFailure } from '../actions/change-email-address-failure.action';
import { ChangeEmailAddressSuccess, ChangeNotificationEmailAddressSuccess } from '../actions/change-email-address-success.action';
import { ChangeEmailAddress, ChangeNotificationEmailAddress } from '../actions/change-email-address.action';
import { ClaimPermissionsFailure } from '../actions/claim-permissions-failure.action';
import { ClaimPermissionsSuccess } from '../actions/claim-permissions-success.action';
import { ClaimPermissions } from '../actions/claim-permissions.action';
import { CreateUserFailure } from '../actions/create-entity-failure.action';
import { CreateUserSuccess } from '../actions/create-entity-success.action';
import { CreateUser } from '../actions/create-entity.action';
import { DeleteUsersFailure } from '../actions/delete-entities-failure.action';
import { DeleteUsersSuccess } from '../actions/delete-entities-success.action';
import { DeleteUsers } from '../actions/delete-entities.action';
import { DeleteUserFailure } from '../actions/delete-entity-failure.action';
import { DeleteUserSuccess } from '../actions/delete-entity-success.action';
import { DeleteUser } from '../actions/delete-entity.action';
import { DeleteProfilePictureFailure } from '../actions/delete-profile-picture-failure.action';
import { DeleteProfilePictureSuccess } from '../actions/delete-profile-picture-success.action';
import { DeleteProfilePicture } from '../actions/delete-profile-picture.action';
import { DeleteSocialNetworkSuccess } from '../actions/delete-social-network-success.action';
import { DeleteSocialNetwork } from '../actions/delete-social-network.action';
import { FetchUsersFailure } from '../actions/fetch-entities-failure.action';
import { FetchUsersSuccess } from '../actions/fetch-entities-success.action';
import { FetchUsers } from '../actions/fetch-entities.action';
import { FetchUserFailure } from '../actions/fetch-entity-failure.action';
import { FetchUserSuccess } from '../actions/fetch-entity-success.action';
import { FetchUser } from '../actions/fetch-entity.action';
import { FetchUserInfoFailure } from '../actions/fetch-user-info-failure.action';
import { FetchUserInfoSuccess } from '../actions/fetch-user-info-success.action';
import { FetchUserInfo } from '../actions/fetch-user-info.action';
import { PatchProfileSuccess } from '../actions/patch-profile-success.action';
import { PatchProfile } from '../actions/patch-profile.action';
import { RefreshUsersOnlineState } from '../actions/refresh-users-online-state.action';
import { RemoveUserPermissionsFailure } from '../actions/remove-user-permissions-failure.action';
import { RemoveUserPermissionsSuccess } from '../actions/remove-user-permissions-success.action';
import { RemoveUserPermissions } from '../actions/remove-user-permissions.action';
import { ResetPasswordFailure, ResetUserPasswordUrlFailure } from '../actions/reset-password-failure.action';
import { ResetPasswordSuccess, ResetUserPasswordUrlSuccess } from '../actions/reset-password-success.action';
import { ResetPassword, ResetPasswordUrl, ResetUserPasswordUrl } from '../actions/reset-password.action';
import { ResetPasswordUrlFailure, ResetPasswordsFailure } from '../actions/reset-passwords-failure.action';
import { ResetPasswordUrlSuccess, ResetPasswordsSuccess } from '../actions/reset-passwords-success.action';
import { ResetPasswords } from '../actions/reset-passwords.action';
import { SetUsers } from '../actions/set-entities.action';
import { SetUser } from '../actions/set-entity.action';
import { SetUserInfo } from '../actions/set-user-info.action';
import { UnsetUser } from '../actions/unset-entity.action';
import { UpdateUserFailure } from '../actions/update-entity-failure.action';
import { UpdateUserSuccess } from '../actions/update-entity-success.action';
import { UpdateUser } from '../actions/update-entity.action';
import { UpdateLanguageFailure } from '../actions/update-language-failure.action';
import { UpdateLanguageSuccess } from '../actions/update-language-success.action';
import { UpdateLanguage } from '../actions/update-language.action';
import { UpdateUserProfilePicture } from '../actions/update-user-profile-picture.action';
import { UpdateUserStatusBulkSuccess } from '../actions/update-user-status-bulk-success.action';
import { UpdateUserStatusBulk } from '../actions/update-user-status-bulk.action';
import { UpdateUserStatus } from '../actions/update-user-status.action';
import { UploadUsersListFailure } from '../actions/upload-users-list-failure.action';
import { UploadUsersListSuccess } from '../actions/upload-users-list-success.action';
import { UploadUsersList } from '../actions/upload-users-list.action';
import { UserApiService } from '../services/user.api-service';
import { User } from './user';
import { UserStateModel } from './user.state-model';
import { UsersImport } from './users-import.model';
import { AddAdditionalEmail } from '../actions/add-additional-email.action';
import { AddAdditionalEmailSuccess } from '../actions/add-additional-email-success.action';
import { AddAdditionalEmailFailure } from '../actions/add-additional-email-failure.action';
import { AddAdditionalEmailToUser } from '../actions/add-additional-email-to-user.action';
import { AddAdditionalEmailToUserSuccess } from '../actions/add-additional-email-to-user-success.action';
import { AddAdditionalEmailToUserFailure } from '../actions/add-additional-email-to-user-failure.action';
import { FetchUserAdditionalEmails, FetchUserVerifiedAdditionalEmails } from '../actions/fetch-user-additional-emails.action';
import { FetchUserAdditionalEmailsSuccess, FetchUserVerifiedAdditionalEmailsSuccess } from '../actions/fetch-user-additional-emails-success.action';
import { DeleteUserAdditionalEmail } from '../actions/delete-user-additional-email.action';
import { DeleteUserAdditionalEmailSuccess } from '../actions/delete-user-additional-email-success.action';
import { FetchUserAdditionalEmailsFailure, FetchUserVerifiedAdditionalEmailsFailure } from '../actions/fetch-user-additional-emails-failure.action';

@State<UserStateModel>({
    name: 'user',
    defaults: new UserStateModel(),
})
@Injectable()
export class UserState extends EntityState<User> implements IEntityState<User> {
    @Selector()
    static getEntity(state: UserStateModel, id: string = state.item): User {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getMyInfo(state: UserStateModel): User {
        return UserState.getEntity(state, state.me);
    }

    @Selector()
    static getEntities(state: UserStateModel): User[] {
        if (!state.map) {
            return null;
        }

        return Array.from(state.map.values());
    }

    constructor(private _translateService: TranslateService, private _userApiService: UserApiService) {
        super(_userApiService, 30);

        this._propstoSearch = User.props_to_search;
    }

    @Action(SetUser)
    setEntity(ctx: StateContext<UserStateModel>, action: SetUser): void {
        this._setEntity(ctx, action, AddUsers);
    }

    @Action(UnsetUser)
    unsetEntity(ctx: StateContext<UserStateModel>): void {
        this._unsetEntity(ctx);
    }

    @Action(AddUsers)
    addEntities(ctx: StateContext<UserStateModel>, action: AddUsers): void {
        this._addEntities(ctx, action);
    }

    @Action(SetUserInfo)
    setUserInfo(ctx: StateContext<UserStateModel>, { payload }: SetUserInfo): void {
        this._addEntities(ctx, { payload: [payload] });

        ctx.patchState({
            me: payload.id,
        });
    }

    @Action(ScheduleRenewal)
    @Action(FetchUserInfo)
    getUserInfo({ dispatch }: StateContext<UserStateModel>): void {
        // Use access token to retrieve user's profile and set session
        this._userApiService
            .me()
            .pipe(tap((user) => this._translateService.use(user.language)))
            .subscribe(
                (user) => {
                    localStorage.setItem('userProfile', JSON.stringify(user));
                    dispatch([new SetUserInfo(user), new FetchUserInfoSuccess(user)]);
                },
                (error: HttpErrorResponse) => {
                    dispatch(
                        new FetchUserInfoFailure({
                            entityId: null,
                            response: error,
                        })
                    );
                }
            );
    }

    @Action(CreateUser)
    createEntity(ctx: StateContext<UserStateModel>, action: CreateUser): void {
        this._createEntity(ctx, action, CreateUserSuccess, CreateUserFailure, AddUsers).then(() => {});
    }

    @Action(UpdateUser)
    updateEntity(ctx: StateContext<UserStateModel>, action: UpdateUser): void {
        this._updateEntity(ctx, action, UpdateUserSuccess, UpdateUserFailure, AddUsers).then(() => {});
    }

    @Action(UpdateUserStatus)
    updateUserStatus(ctx: StateContext<UserStateModel>, { payload }: UpdateUserStatus): void {
        this._userApiService.updateStatus(payload).subscribe(
            (entity) => {
                this._addEntities(ctx, { payload: [entity] });

                ctx.dispatch(new UpdateUserSuccess(entity));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateUserFailure(payload.id));
            }
        );
    }

    @Action(UpdateUserStatusBulk)
    updateBulkUserStatus(ctx: StateContext<UserStateModel>, { payload }: UpdateUserStatusBulk): void {
        this._userApiService.updateStatusBulk(payload.entityIds, payload.status).subscribe(
            (entities) => {
                this._addEntities(ctx, { payload: entities });

                ctx.dispatch(new UpdateUserStatusBulkSuccess(entities));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateUserFailure(null));
            }
        );
    }

    @Action(AddUserPermissions)
    qddUserPermissions({ dispatch, getState, patchState }: StateContext<UserStateModel>, { payload }: AddUserPermissions): void {
        let observable: Observable<User>;
        const affectedUsers = [];

        payload.entityIds.forEach((entityId, index) => {
            observable =
                index === 0
                    ? this._userApiService.addPermission(payload.permissions, entityId)
                    : (observable = !observable
                          ? this._userApiService.addPermission(payload.permissions, entityId)
                          : merge(observable, this._userApiService.addPermission(payload.permissions, entityId)));
        });

        observable
            .pipe(
                tap((user) => {
                    affectedUsers.push(user);
                }),
                takeLast(1)
            )
            .subscribe(
                () => {
                    dispatch([new AddUsers(affectedUsers), new AddUserPermissionsSuccess(affectedUsers)]);
                },
                (error) => {
                    console.error(error);

                    setTimeout(() => dispatch(new AddUserPermissionsFailure()));
                }
            );
    }

    @Action(RemoveUserPermissions)
    removeUserPermissions(ctx: StateContext<UserStateModel>, { payload }: RemoveUserPermissions): void {
        this._userApiService.removePermission(payload.permissions, payload.entityId).subscribe(
            (entity) => {
                this._addEntities(ctx, { payload: [entity] });

                ctx.dispatch(new RemoveUserPermissionsSuccess({ entity, permission: payload.permissions[0] }));
            },
            (response: HttpErrorResponse) => {
                setTimeout(() => ctx.dispatch(new RemoveUserPermissionsFailure()));
            }
        );
    }

    @Action(ClaimPermissions)
    claimPermissions(ctx: StateContext<UserStateModel>, { payload }: ClaimPermissions): void {
        this._userApiService.claimPermission(payload).subscribe(
            (entity) => {
                ctx.dispatch([new SetUserInfo(entity), new ClaimPermissionsSuccess()]);
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new ClaimPermissionsFailure());
            }
        );
    }

    @Action(UpdateUserProfilePicture)
    updateProfilePicture(ctx: StateContext<UserStateModel>, { payload }: UpdateUserProfilePicture): void {
        this._userApiService.updateProfilePicture(payload.entityId, payload.file).subscribe(
            (entity) => {
                this.addEntities(ctx, { payload: [entity] });

                ctx.dispatch(new UpdateUserSuccess(entity));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new UpdateUserFailure(payload.entityId));
            }
        );
    }

    @Action(DeleteUser)
    deleteEntity(ctx: StateContext<UserStateModel>, action: DeleteUser): void {
        this._deleteEntity(ctx, action, DeleteUserSuccess, DeleteUserFailure).then(() => {});
    }

    @Action(FetchUser)
    fetchEntity(ctx: StateContext<UserStateModel>, action: FetchUser): void {
        this._fetchEntity(ctx, action, FetchUserSuccess, FetchUserFailure, AddUsers, SetUser).then(() => {});
    }

    @Action(FetchUserSuccess)
    fetchEntitySuccess(ctx: StateContext<UserStateModel>, action: FetchUserSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchUserFailure)
    fetchEntityFailure(ctx: StateContext<UserStateModel>, action: FetchUserFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(SetUsers)
    setEntities(ctx: StateContext<UserStateModel>, action: SetUsers): void {
        this._setEntities(ctx, action);
    }

    @Action(FetchUsers)
    fetchEntities(ctx: StateContext<UserStateModel>): void {
        this._fetchEntities(ctx, FetchUsersSuccess, FetchUsersFailure, SetUsers).then(() => {});
    }

    @Action(FetchUsersSuccess)
    fetchEntitiesSuccess(ctx: StateContext<UserStateModel>): void {
        this._fetchEntitiesSuccess(ctx);
    }

    @Action(FetchUsersFailure)
    fetchEntitiesFailure(ctx: StateContext<UserStateModel>): void {
        this._fetchEntitiesFailure(ctx);
    }

    @Action(AddSocialNetwork)
    addSocialNetwork(ctx: StateContext<UserStateModel>, { payload }: AddSocialNetwork): void {
        this._userApiService.addSocialNetwork(payload).subscribe((entity) => {
            const state = ctx.getState();

            if (!state.map) {
                throw new Error('Action DeleteSocialNetwork should not be dispatched if there is no state.map present. ');
            }

            const item = state.map.get(entity.user.id);
            const newEntity: User = Object.assign<User, User, Partial<User>>(new User(), item, {
                socialNetworks: [...item.socialNetworks, entity],
            });

            this._addEntities(ctx, { payload: [newEntity] });

            ctx.dispatch(new AddSocialNetworkSuccess(entity.id));
        });
    }

    @Action(ChangeNotificationEmailAddress)
    changeNotificationEmailAddress(ctx: StateContext<UserStateModel>, { payload }: ChangeNotificationEmailAddress): void {
        this._userApiService.changeNotificationEmailAddress(payload.id, payload.notificationEmail).subscribe(
            (entity) => {
                this._addEntities(ctx, { payload: [entity] });

                ctx.dispatch(new ChangeNotificationEmailAddressSuccess());
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new ChangeNotificationEmailAddressFailure({ response }));
            }
        );
    }

    @Action(FetchUserVerifiedAdditionalEmails)
    fetchUserVerifiedAdditionalEmails(ctx: StateContext<UserStateModel>, action: FetchUserVerifiedAdditionalEmails): void {
        this._userApiService.getUserAdditionalEmails(action.payload.userId, action.payload.isAdditional, action.payload.isVerified).subscribe(
            (entities) => {
                ctx.dispatch(new FetchUserVerifiedAdditionalEmailsSuccess(entities));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new FetchUserVerifiedAdditionalEmailsFailure({ response }));
            }
        );
    }

    @Action(FetchUserAdditionalEmails)
    fetchUserAdditionalEmails(ctx: StateContext<UserStateModel>, action: FetchUserAdditionalEmails): void {
        this._userApiService.getUserAdditionalEmails(action.payload.userId, action.payload.isAdditional, action.payload.isVerified).subscribe(
            (entities) => {
                ctx.dispatch(new FetchUserAdditionalEmailsSuccess(entities));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new FetchUserAdditionalEmailsFailure({ response }));
            }
        );
    }

    @Action(AddAdditionalEmailToUser)
    addAdditionalEmailToUser(ctx: StateContext<UserStateModel>, action: AddAdditionalEmailToUser): void {
        this._userApiService.addAdditionalEmailToUser(action.payload.email, action.payload.userId).subscribe(
            () => {
                ctx.dispatch(new AddAdditionalEmailToUserSuccess());
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new AddAdditionalEmailToUserFailure({ response }));
            }
        );
    }

    @Action(AddAdditionalEmail)
    addAdditionalEmail(ctx: StateContext<UserStateModel>, action: AddAdditionalEmail): void {
        this._userApiService.addAdditionalEmail(action.payload.email).subscribe(
            () => {
                ctx.dispatch(new AddAdditionalEmailSuccess());
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new AddAdditionalEmailFailure({ response }));
            }
        );
    }

    @Action(DeleteUserAdditionalEmail)
    deleteUserAdditionalEmail(ctx: StateContext<UserStateModel>, { payload }: DeleteUserAdditionalEmail): void {
        this._userApiService.deleteUserAdditionalEmail(payload).subscribe(() => {
            ctx.dispatch(new DeleteUserAdditionalEmailSuccess(payload));
        });
    }

    @Action(DeleteSocialNetwork)
    deleteSocialNetwork(ctx: StateContext<UserStateModel>, { payload }: DeleteSocialNetwork): void {
        this._userApiService.deleteSocialNetwork(payload.id).subscribe(() => {
            const state = ctx.getState();

            if (!state.map) {
                throw new Error('Action DeleteSocialNetwork should not be dispatched if there is no state.map present. ');
            }

            const item = state.map.get(payload.user.id);
            const newEntity: User = Object.assign<User, User, Partial<User>>(new User(), item, {
                socialNetworks: item.socialNetworks.filter((socialNetwork) => socialNetwork.id !== payload.id),
            });

            this._addEntities(ctx, { payload: [newEntity] });

            ctx.dispatch(new DeleteSocialNetworkSuccess());
        });
    }

    @Action(DeleteProfilePicture)
    deleteProfilePicture(ctx: StateContext<UserStateModel>, { payload }: DeleteProfilePicture): void {
        this._userApiService.deleteProfilePicture(payload.id).subscribe(
            () => {
                const state = ctx.getState();

                const item = state.map.get(payload.id);
                const newEntity: User = Object.assign<User, User, Partial<User>>(new User(), item, {
                    avatar: null,
                });

                this._addEntities(ctx, { payload: [newEntity] });

                ctx.dispatch(new DeleteProfilePictureSuccess());
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new DeleteProfilePictureFailure({ response }));
            }
        );
    }

    @Action(UploadUsersList)
    uploadUsersList({ dispatch }: StateContext<UserStateModel>, { file, idsModules }: UploadUsersList): void {
        this._userApiService.uploadUsersList(file, idsModules).subscribe(
            (usersImport: UsersImport) => {
                dispatch([new AddUsers(usersImport.getUsersCreated()), new UploadUsersListSuccess(usersImport)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(new UploadUsersListFailure({ response }));
            }
        );
    }

    @Action(DeleteUsers)
    deleteUsers(ctx: StateContext<UserStateModel>, { payload }: DeleteUsers): void {
        this._deleteEntities(ctx, payload.entityIds, DeleteUsersSuccess, DeleteUsersFailure).then(() => {});
    }

    @Action(PatchProfile)
    patchProfile(ctx: StateContext<UserStateModel>, { payload }: PatchProfile): void {
        this._userApiService.updateProfile(payload).subscribe((user) => {
            ctx.dispatch([new AddUsers([user]), new SetUserInfo(user), new PatchProfileSuccess(user)]);
        });
    }

    @Action(ResetUserPasswordUrl)
    getResetUserPasswordUrl(ctx: StateContext<UserStateModel>, { payload }: ResetUserPasswordUrl): void {
        this._userApiService.resetUserPasswordUrl(payload).subscribe(
            (entity) => {
                ctx.dispatch(new ResetUserPasswordUrlSuccess(entity));
            },
            (e) => {
                ctx.dispatch(new ResetUserPasswordUrlFailure());
            }
        );
    }

    @Action(ResetPasswordUrl)
    getPasswordResetUrl(ctx: StateContext<UserStateModel>, { payload }: ResetPasswordUrl): void {
        this._userApiService.resetPasswordUrl(payload).subscribe(
            (entity) => {
                ctx.dispatch(new ResetPasswordUrlSuccess(entity));
            },
            (e) => {
                ctx.dispatch(new ResetPasswordUrlFailure());
            }
        );
    }

    @Action(ResetPassword)
    restartPassword(ctx: StateContext<UserStateModel>, { payload }: ResetPassword): void {
        this._userApiService.resetPassword(payload).subscribe(
            () => {
                ctx.dispatch(new ResetPasswordSuccess());
            },
            (e) => {
                ctx.dispatch(new ResetPasswordFailure());
            }
        );
    }

    @Action(ResetPasswords)
    restartPasswords(ctx: StateContext<UserStateModel>, { payload }: ResetPasswords): void {
        this._userApiService.resetPasswords(payload).subscribe(
            (users) => {
                ctx.dispatch(new ResetPasswordsSuccess(users));
            },
            (e) => {
                ctx.dispatch(new ResetPasswordsFailure(payload));
            }
        );
    }

    @Action(ChangeEmailAddress)
    changeUserEmailAddress(ctx: StateContext<UserStateModel>, { payload }: ChangeEmailAddress): void {
        this._userApiService.changeUserEmailAddress(payload.id, payload.email).subscribe(
            () => {
                ctx.dispatch(new ChangeEmailAddressSuccess());
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new ChangeEmailAddressFailure({ response }));
            }
        );
    }

    @Action(UpdateLanguage)
    updateLanguage(ctx: StateContext<UserStateModel>, { payload }: UpdateLanguage): void {
        this._userApiService.updateLanguage(payload.ids, payload.language).subscribe(
            (users) => {
                ctx.dispatch(new UpdateLanguageSuccess(users));
            },
            (error) => {
                ctx.dispatch(new UpdateLanguageFailure());
            }
        );
    }

    @Action(RefreshUsersOnlineState)
    async refreshUsersOnlineState(ctx: StateContext<UserStateModel>, { payload }: RefreshUsersOnlineState): Promise<void> {
        const ids = payload.map((user) => user.id);
        let onlineStateResponse = await this._userApiService.getUserOnlineState(ids);

        const updatedUsers = payload.map((user) => {
            onlineStateResponse.onlineState.forEach((onlineState) => {
                if (user.id === onlineState.id) {
                    user = Object.assign(new User(), user, {
                        isOnline: onlineState.isOnline,
                    });
                }
            });

            return user;
        });

        this._addEntities(ctx, { payload: updatedUsers });
    }
}
