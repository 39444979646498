import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { SearchModuleEventAttendances } from 'app/library/module-event-attendance/actions';
import { IAttendanceStatus } from 'app/library/module-event-attendance/interfaces/attendance-status.interface';
import { AttendanceStatuses } from 'app/library/module-event-attendance/models/attendance-status';
import { ModuleEventAttendance } from 'app/library/module-event-attendance/models/module-event-attendance.model';
import { ModuleEventAttendancesState } from 'app/library/module-event-attendance/models/module-event-attendances.state';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-manage-attendances-dialog',
    templateUrl: './manage-attendances-dialog.component.html',
    styleUrls: ['./manage-attendances-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ManageAttendancesDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    moduleId: string;

    @Input()
    eventId: string;

    @Select(ModuleEventAttendancesState.getFilteredEntities)
    filteredModuleEventAttendances$: Observable<ModuleEventAttendance[]>;

    @Select(ModuleEventAttendancesState.getSearchTerm)
    moduleEventAttendancesStateSearchTerm$: Observable<string>;

    selectedAttendanceStatus$ = new BehaviorSubject<number[]>([]);
    selectedAttendanceStatuses: number[];

    attendanceStatuses = AttendanceStatuses.all;

    searchInput = new FormControl();

    constructor(public dialogRef: MatDialogRef<ManageAttendancesDialogComponent>, private _store: Store, public route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.searchInput.valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(300), distinctUntilChanged()).subscribe((searchTerm) => {
            this._store.dispatch(new SearchModuleEventAttendances(searchTerm));
        });
    }

    selectAttendanceStatus(attendanceStatus: IAttendanceStatus): void {
        attendanceStatus.isSelected = !attendanceStatus.isSelected;

        const selectedAttendanceStatuses = this.attendanceStatuses
            .filter((attendanceStatus) => attendanceStatus.isSelected)
            .map((selectedAttendanceStatus) => selectedAttendanceStatus.value);

        this.selectedAttendanceStatus$.next(selectedAttendanceStatuses);
    }
}
