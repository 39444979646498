import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { SnackBarTime } from 'app/projects/core/src/lib/constants/snack-bar';
import { NgxsActionHelper } from 'app/projects/core/src/lib/services/action.helper';
import { NameDialogComponent } from 'app/projects/shared/src/lib/components/dialogs/name/name.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import {
    BulkUpdateCopyProtection,
    BulkUpdateCopyProtectionFailure,
    BulkUpdateCopyProtectionSuccess,
    FetchContentNode,
    FetchContentNodeFailure,
    FetchContentNodeSuccess,
    UpdateContentNode,
    UpdateContentNodeFailure,
    UpdateContentNodeMetadata,
    UpdateContentNodeSuccess,
    UpdatePersonalContentNode,
    UploadFileFailure,
    UploadFileSuccess,
    UploadFiles,
    UploadZipFailure,
    UploadZipSuccess,
} from '../actions';
import { IBulkUpdateCopyProtectionQuery } from '../interfaces/bulk-update-protection.query.interface';
import { ContentNode } from '../models/content-node.model';

interface INodeInProgress {
    file: string;
    node: string;
    percentDone: number;
    success: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ContentNodeService {
    parentNode$ = new BehaviorSubject<ContentNode>(null);

    private _inProgressNode$ = new BehaviorSubject<INodeInProgress>(null);
    inProgressNode$ = this._inProgressNode$.asObservable().pipe(
        filter((progress) => !!progress),
        tap(({ file, node, percentDone, success }) => {
            if (!this.inProgressNodes[node]) {
                this.inProgressNodes[node] = {};
            }

            if (!Object.keys(this.inProgressNodes[node]).length) {
                this._visibility$.next({
                    node,
                    visible: true,
                });
            }

            if (percentDone === 100 && success !== null) {
                delete this.inProgressNodes[node][file];
            } else {
                this.inProgressNodes[node][file] = percentDone;
            }
        })
    );

    private _visibility$ = new BehaviorSubject<{ node: string; visible: boolean }>({ node: null, visible: null });
    visibility$ = this._visibility$.asObservable();

    successNodes: {
        parentNodeId: string;
        name: string;
    }[] = [];
    failureNodes: { node: string; file: string }[] = [];

    private _extendTracker: {
        [key: string]: boolean;
    } = {};

    inProgressNodes: {
        [key: string]: {
            [key: string]: number;
        };
    } = {};

    get supportedFileExtensions(): string[] {
        return [
            'pdf',
            'jpeg',
            'jpg',
            'png',
            'bmp',
            'svg',
            'ico',
            'gif',
            'mp4',
            'avi',
            'mov',
            'wmv',
            'ods',
            // 'xlsb',
            // 'xlsm',
            // 'xlsx',
            'odp',
            'ppsx',
            'pptx',
            'wopitest',
            'wopitestx',
            'docm',
            'docx',
            'odt',
            'csv',
            'xls',
            'one',
            'onetoc2',
            'pot',
            'potm',
            'potx',
            'pps',
            'ppsm',
            'ppt',
            'pptm',
            'doc',
            'docm',
            'docx',
            'dot',
            'rtf',
        ];
    }

    isExtended(nodeId: string): boolean {
        return this._extendTracker[nodeId];
    }

    updateIsExtended(nodeId: string, value: boolean): void {
        this._extendTracker[nodeId] = value;
    }

    inProgressNodeKeys(nodeId: string): string[] {
        if (!this.inProgressNodes[nodeId]) {
            return [];
        }

        return Object.keys(this.inProgressNodes[nodeId]);
    }

    filterSuccessNodes(nodeId: string): {
        parentNodeId: string;
        name: string;
    }[] {
        return this.successNodes.filter((node) => node.parentNodeId === nodeId);
    }

    filterFailureNodes(nodeId: string): { node: string; file: string }[] {
        return this.failureNodes.filter((node) => node.node === nodeId);
    }

    removeFinishedNodes(nodeId: string): void {
        this.successNodes = this.successNodes.filter((node) => node.parentNodeId !== nodeId);
        this.failureNodes = this.failureNodes.filter((node) => node.node !== nodeId);

        this._visibility$.next({
            node: nodeId,
            visible: this.inProgressNodeKeys(nodeId).length + this.filterFailureNodes(nodeId).length + this.filterSuccessNodes(nodeId).length !== 0,
        });
    }

    updateNodesInProgress(nodeInProgress: INodeInProgress): void {
        this._inProgressNode$.next(nodeInProgress);
    }

    constructor(
        private _store: Store,
        private _action$: Actions,
        private _translateService: TranslateService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _actionHelper: NgxsActionHelper
    ) {
        this._action$.pipe(ofActionDispatched(UploadFileFailure, UploadZipFailure)).subscribe((action: UploadFileFailure | UploadZipFailure) => {
            this.failureNodes.push(action.payload);

            this._inProgressNode$.next({
                ...action.payload,
                percentDone: 100,
                success: false,
            });
        });

        this._action$.pipe(ofActionDispatched(UploadFileSuccess, UploadZipSuccess)).subscribe((action: UploadFileSuccess | UploadZipSuccess) => {
            if (action instanceof UploadFileSuccess) {
                this.successNodes.push({ parentNodeId: action.payload.breadcrumbs[action.payload.breadcrumbs.length - 1].id, name: action.payload.name });

                this._inProgressNode$.next({
                    file: action.payload.name,
                    node: action.payload.breadcrumbs[action.payload.breadcrumbs.length - 1].id,
                    percentDone: 100,
                    success: true,
                });
            }

            if (action instanceof UploadZipSuccess) {
                this.successNodes.push({ parentNodeId: action.payload.node, name: action.payload.file });

                this._inProgressNode$.next({
                    ...action.payload,
                    percentDone: 100,
                    success: true,
                });
            }
        });
    }

    private _getExtension(contentNode: ContentNode): string {
        return contentNode.name.split('.').pop();
    }

    private _returnNameWithoutExtension(contentNode: ContentNode): string {
        return contentNode.name.split('.').slice(0, -1).join('.');
    }

    async bulkUpdateCopyProtection(payload: IBulkUpdateCopyProtectionQuery): Promise<boolean> {
        this._store.dispatch(new BulkUpdateCopyProtection(payload));

        return await this._actionHelper.race(BulkUpdateCopyProtectionSuccess, BulkUpdateCopyProtectionFailure).toPromise();
    }

    async fetchContentNodeById(contentNodeId: string): Promise<boolean> {
        if (!contentNodeId) {
            return new Promise<boolean>((resolve) => false);
        }
        this._store.dispatch(new FetchContentNode({ contentNodeId, setEntity: false }));

        return this._actionHelper.race(FetchContentNodeSuccess, FetchContentNodeFailure).toPromise();
    }

    async updateContentNode(contentNode: ContentNode, buffer: ArrayBuffer, isPersonalFile = false): Promise<ContentNode> {
        this._store.dispatch(
            isPersonalFile
                ? new UpdatePersonalContentNode({
                      contentNode,
                      buffer,
                  })
                : new UpdateContentNode({
                      contentNode,
                      buffer,
                  })
        );

        const updateContentNodeResponseAction = await this._action$
            .pipe(
                ofActionDispatched(UpdateContentNodeSuccess, UpdateContentNodeFailure),
                filter((action: UpdateContentNodeSuccess | UpdateContentNodeFailure) => {
                    if (action instanceof UpdateContentNodeSuccess) {
                        return action.payload.id === contentNode.id;
                    }

                    if (action instanceof UpdateContentNodeFailure) {
                        return action.payload.entityId === contentNode.id;
                    }
                }),
                take(1)
            )
            .toPromise();

        if (updateContentNodeResponseAction instanceof UpdateContentNodeSuccess) {
            const successMessage = this._translateService.instant('CONTENT.MESSAGE.FILE_UPDATED');

            // Show the success message
            this._matSnackBar.open(successMessage, 'OK', {
                verticalPosition: 'top',
                duration: SnackBarTime.medium,
            });

            return updateContentNodeResponseAction.payload;
        }

        if (updateContentNodeResponseAction instanceof UpdateContentNodeFailure) {
            const contentNodeSubject = new Subject<ContentNode>();
            const contentNodeSubject$ = contentNodeSubject.asObservable().pipe(take(1));

            const dialogRef = this._matDialog.open(NameDialogComponent, {
                disableClose: true,
                width: '482px',
            });

            dialogRef.componentInstance.dialogTitle = `GENERAL.LABEL.SAVE_CHANGES_AS_SEPARATE_FILE`;
            dialogRef.componentInstance.placeholder = 'GENERAL.PROPERTY.NAME';
            dialogRef.componentInstance.label = this._translateService.instant('GENERAL.LABEL.SAVE_SEPARATE_FILE');
            dialogRef.componentInstance.name = `${this._returnNameWithoutExtension(contentNode)}-copy.${this._getExtension(contentNode)}`;

            dialogRef.afterClosed().subscribe((newContentNode: ContentNode) => contentNodeSubject.next(newContentNode));

            dialogRef.componentInstance.save.subscribe((name: string) => {
                this._store.dispatch(
                    new UploadFiles({
                        node: contentNode.breadcrumbs[contentNode.breadcrumbs.length - 1].id,
                        files: [new File([new Uint8Array(buffer, 0)], name)],
                    })
                );

                this._action$
                    .pipe(takeUntil(contentNodeSubject$), ofActionDispatched(UploadFileSuccess, UploadFileFailure))
                    .subscribe((action: UploadFileSuccess | UploadFileFailure) => {
                        if (action instanceof UploadFileSuccess) {
                            if (!contentNode.allowDistribution) {
                                this._store.dispatch(new UpdateContentNodeMetadata({ contentNodeId: action.payload.id, allowDistribution: false }));
                            }
                            dialogRef.close(action.payload);
                        }

                        if (action instanceof UploadFileFailure) {
                            dialogRef.componentInstance.errors.next(action.payload.response.error);
                        }
                    });
            });

            return contentNodeSubject$.toPromise();
        }
    }
}
