import { MaxBrainLocation } from 'app/library/location/models/location.model';
import { ICreateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/create-entity-failure-payload.interface';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';

export class SetLocation {
    static readonly type = '[Location] SetLocation';

    constructor(public payload: MaxBrainLocation) {}
}

export class UnsetLocation {
    static readonly type = '[Location] UnsetLocation';
}

export class FetchLocation {
    static readonly type = '[Location] FetchLocation';

    constructor(public payload: string) {}
}

export class FetchLocationSuccess {
    static readonly type = '[Location] FetchLocationSuccess';

    constructor(public payload: MaxBrainLocation) {}
}

export class FetchLocationFailure {
    static readonly type = '[Location] FetchLocationFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class AddLocations {
    static readonly type = '[Location] AddLocations';

    constructor(public payload: MaxBrainLocation[]) {}
}

export class CreateLocation {
    static readonly type = '[Location] CreateLocation';

    constructor(public payload: MaxBrainLocation) {}
}

export class CreateLocationSuccess {
    static readonly type = '[Location] CreateLocationSuccess';

    constructor(public payload: MaxBrainLocation) {}
}

export class CreateLocationFailure {
    static readonly type = '[Location] CreateLocationFailure';

    constructor(public payload: ICreateEntityFailureActionPayload) {}
}

export class UpdateLocation {
    static readonly type = '[Location] UpdateLocation';

    constructor(public payload: MaxBrainLocation) {}
}

export class UpdateLocationSuccess {
    static readonly type = '[Location] UpdateLocationSuccess';

    constructor(public payload: MaxBrainLocation) {}
}

export class UpdateLocationFailure {
    static readonly type = '[Location] UpdateLocationFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class DeleteLocation {
    static readonly type = '[Location] DeleteLocation';

    constructor(public payload: string) {}
}

export class DeleteLocationSuccess {
    static readonly type = '[Location] DeleteLocationSuccess';

    constructor(public payload: string) {}
}

export class DeleteLocationFailure {
    static readonly type = '[Location] DeleteLocationFailure';

    constructor(public payload: string) {}
}

export class SetLocations {
    static readonly type = '[Location] SetLocations';

    constructor(public payload: MaxBrainLocation[]) {}
}

export class FetchLocations {
    static readonly type = '[Location] FetchLocations';
}

export class FetchLocationsSuccess {
    static readonly type = '[Location] FetchLocationsSuccess';
}

export class FetchLocationsFailure {
    static readonly type = '[Location] FetchLocationsFailure';
}

export class ToggleSelectedLocation {
    static readonly type = '[Location] ToggleSelectedLocation';

    constructor(public payload: string) {}
}

export class SelectFilteredLocations {
    static readonly type = '[Location] SelectFilteredLocations';
}

export class DeselectSelectedLocations {
    static readonly type = '[Location] DeselectSelectedLocations';
}

export class SearchLocations {
    static readonly type = '[Location] SearchLocations';

    constructor(public payload: string) {}
}

export class DeleteSelectedLocations {
    static readonly type = '[Location] DeleteSelectedLocations';
}

export class UnsetLocationAll {
    static readonly type = '[Location] UnsetLocationAll';
}
