import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-white-button',
    templateUrl: './white-button.component.html',
    styleUrls: ['./white-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WhiteButtonComponent {
    @Input()
    label: string = '';

    @Input()
    icon: string = '';

    @Input()
    svgIcon: string = '';

    @Input()
    disabled: boolean = false;

    @Input()
    isActive: boolean = false;

    @Input()
    showThreeDots: boolean = false;

    @Input()
    counter: number = 0;

    @Input()
    isRounded: boolean = false;

    @Input()
    showArrowRight: boolean = false;

    @Output()
    onClick = new EventEmitter<any>();

    constructor() {}

    onClickButton(event): void {
        this.onClick.emit(event);
    }
}
