import { ISurveyTemplateQuery } from '../interfaces/survey-template.query.interface';
export class SurveyTemplate {
    id: string;
    name: string;
    previewLink: string;
    createdAt: Date;
    isHidden: boolean;

    constructor(query: ISurveyTemplateQuery) {
        this.id = query.id.toString();
        this.name = query.name;
        this.previewLink = query.preview_url;
        this.createdAt = query.created_at;
        this.isHidden = query.is_hidden;
    }
}
