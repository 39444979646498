import { ModulePersonalParticipant } from '../models/module-personal-participant';

export class SetPersonalParticipants {
    static readonly type = '[PersonalParticipant] SetPersonalParticipants';

    constructor(public payload: ModulePersonalParticipant[]) {}
}

export class UnsetPersonalParticipants {
    static readonly type = '[PersonalParticipant] UnsetPersonalParticipants';
}

export class FetchPersonalParticipants {
    static readonly type = '[PersonalParticipant] FetchPersonalParticipants';

    constructor(
        public payload?: {
            moduleId: string;
            search?: string;
            pageSize?: number;
            pageNumber?: number;
        }
    ) {}
}

export class FetchPersonalParticipantsSuccess {
    static readonly type = '[PersonalParticipant] FetchPersonalParticipantsSuccess';
}

export class FetchPersonalParticipantsFailure {
    static readonly type = '[PersonalParticipant] FetchPersonalParticipantsFailure';
}

export class SearchPersonalParticipants {
    static readonly type = '[PersonalParticipant] SearchPersonalParticipants';

    constructor(
        public payload: {
            searchTerm: string;
        }
    ) {}
}

export class AddModulePersonalParticipants {
    static readonly type = '[PersonalParticipant] AddModulePersonalParticipants';

    constructor(public payload: ModulePersonalParticipant[]) {}
}
export class SetModulePersonalParticipant {
    static readonly type = '[PersonalParticipant] SetModulePersonalParticipant';

    constructor(public payload: ModulePersonalParticipant) {}
}

export class DeleteModulePersonalParticipants {
    static readonly type = '[PersonalParticipant] DeleteModulePersonalParticipants';

    constructor(public payload: string[]) {}
}

export class RefreshModulePersonalParticipantOnlineState {
    static readonly type = '[PersonalParticipant] RefreshModulePersonalParticipantOnlineState';

    constructor(public payload?: ModulePersonalParticipant[]) {}
}
