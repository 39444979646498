import { IFinalGradeMetricsQuery } from '../interfaces/final-grade-metric.query.interface';

export class FinalGradeMetrics {
    average: number;
    lowest: number;
    highest: number;
    belowPassing: number;
    participantsCount: number;

    constructor(query: IFinalGradeMetricsQuery) {
        if (!query) {
            throw new Error(`Unable to create new FinalGradeMetric since no values were passed to the constructor: ${JSON.stringify(query)}`);
        }

        this.average = query.average;
        this.lowest = query.lowest;
        this.highest = query.highest;
        this.belowPassing = query.below_passing;
        this.participantsCount = query.participants_count;
    }
}
