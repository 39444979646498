import { SectionType } from '../enums/section-type.enum';
import { ISectionQuery } from '../interfaces/section.query.interface';
import { Section } from './section.model';

export class QuizSection extends Section {
    classtimeQuestionSetId: string = null;
    qlFolderId: string = null;
    isAssessmentLinkHidden: boolean = null;
    shuffleQuestions: boolean = null;
    shuffleQuestionOptions: boolean = null;
    questionSetTitle: string = null;
    passingPercentage: number = null;
    markCompletedOnFail: boolean = null;
    retryable: boolean = null;
    showScoreToParticipants: boolean = null;
    hideFeedbackMode: boolean = null;
    reportGeneratedAt?: string;

    constructor(query?: ISectionQuery) {
        super(query);

        this._type = SectionType.Quiz;

        if (query) {
            this.classtimeQuestionSetId = query.classtime_question_set_id;
            this.qlFolderId = query.ql_folder_id;
            this.isAssessmentLinkHidden = query.assessment_link_hidden;
            this.shuffleQuestions = query.shuffle_questions;
            this.shuffleQuestionOptions = query.shuffle_question_options;
            this.passingPercentage = query.passing_percentage;
            this.markCompletedOnFail = query.mark_completed_on_fail;
            this.retryable = query.retryable;
            this.questionSetTitle = query.question_set_title;
            this.hideFeedbackMode = query.hide_feedback_mode;
            this.reportGeneratedAt = query.report_generated_at;
            this.showScoreToParticipants = query.show_score_to_participants;
        }
    }
}
