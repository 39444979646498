import * as momentImported from 'moment';
import { IAnalyticsQuery } from '../interfaces/analytics.query.interface';
const moment = momentImported;

export class Analytics {
    lastLogin: momentImported.Moment;
    loginCount: number;

    constructor(analyticsQuery?: IAnalyticsQuery) {
        if (analyticsQuery) {
            this.lastLogin = moment(analyticsQuery.last_login);
            this.loginCount = analyticsQuery.login_count;
        } else {
            this.lastLogin = null;
            this.loginCount = null;
        }
    }
}
