import { MaxBrainExperience } from './experience.model';

export class ExperienceCommand {
    name?: string;
    header_banner: {
        title?: string;
        description?: string;
        image: { id: string };
    };

    constructor(tempExperience: MaxBrainExperience) {
        this.name = tempExperience.name;
        this.header_banner = {
            title: tempExperience.headerBanner.title,
            description: tempExperience.headerBanner.description,
            image: { id: tempExperience.headerBanner.image.id },
        };
    }
}
