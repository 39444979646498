export class ChangeEmailAddress {
    static readonly type = '[User] ChangeEmailAddress';

    constructor(public payload: { id: string; email: string }) {}
}

export class ChangeNotificationEmailAddress {
    static readonly type = '[User] ChangeNotificationEmailAddress';

    constructor(public payload: { id: string; notificationEmail: string }) {}
}
