export class SubentityStateModel {
    fetchingList: boolean;
    filtered: string[];
    list: string[];
    searchTerm: string;
    selected: string[];
    totalCount: number;

    constructor() {
        this.fetchingList = false;
        this.filtered = [];
        this.list = null;
        this.searchTerm = null;
        this.selected = [];
    }
}
