import { ModuleUserExtended } from 'app/library/module-user/models/module-user.extended.model';

export class SetModuleResponsibles {
    static readonly type = '[ModuleResponsible] SetModuleResponsibles';

    constructor(public payload: ModuleUserExtended[]) {}
}

export class UnsetModuleResponsibles {
    static readonly type = '[ModuleResponsible] UnsetModuleResponsibles';
}

export class FetchModuleResponsibles {
    static readonly type = '[ModuleResponsible] FetchModuleResponsibles';

    constructor(public payload: string) {}
}

export class FetchModuleResponsiblesSuccess {
    static readonly type = '[ModuleResponsible] FetchModuleResponsiblesSuccess';
}

export class FetchModuleResponsiblesFailure {
    static readonly type = '[ModuleResponsible] FetchModuleResponsiblesFailure';
}

export class ToggleSelectedModuleResponsible {
    static readonly type = '[ModuleResponsible] ToggleSelectedModuleResponsible';

    constructor(public payload: string) {}
}

export class SelectFilteredModuleResponsibles {
    static readonly type = '[ModuleResponsible] SelectFilteredModuleResponsibles';
}

export class DeselectSelectedModuleResponsibles {
    static readonly type = '[ModuleResponsible] DeselectSelectedModuleResponsibles';
}

export class SearchModuleResponsibles {
    static readonly type = '[ModuleResponsible] SearchModuleResponsibles';

    constructor(public payload: string) {}
}
