import { IStatus } from 'app/projects/shared/src/lib/interfaces/status.interface';

// @dynamic
export class UserStatuses {
    static all: IStatus[] = [
        {
            name: 'inactive',
            translationLabel: 'USER.STATUS.INACTIVE',
            possiblePreviousStatusNames: ['active'],
            primaryColor: '#ADAFB4',
            secundaryColor: '#A8AAAD',
        },
        {
            name: 'active',
            translationLabel: 'USER.STATUS.ACTIVE',
            possiblePreviousStatusNames: ['inactive'],
            primaryColor: '#61B3F2',
            secundaryColor: '#60ADE8',
        },
        {
            name: 'approved',
            translationLabel: 'USER.STATUS.APPROVED',
            possiblePreviousStatusNames: ['approved'],
            primaryColor: '#ADAFB4',
            secundaryColor: '#A8AAAD',
        },
        {
            name: 'canceled',
            translationLabel: 'USER.STATUS.CANCELED',
            possiblePreviousStatusNames: ['canceled'],
            primaryColor: '#ADAFB4',
            secundaryColor: '#A8AAAD',
        },
        {
            name: 'declined',
            translationLabel: 'USER.STATUS.DECLINED',
            possiblePreviousStatusNames: ['declined'],
            primaryColor: '#ADAFB4',
            secundaryColor: '#A8AAAD',
        },
        {
            name: 'blocked',
            translationLabel: 'USER.STATUS.BLOCKED',
            possiblePreviousStatusNames: ['blocked'],
            primaryColor: '#ADAFB4',
            secundaryColor: '#A8AAAD',
        },
        {
            name: 'unblocked',
            translationLabel: 'USER.STATUS.UNBLOCKED',
            possiblePreviousStatusNames: ['unblocked'],
            primaryColor: '#ADAFB4',
            secundaryColor: '#A8AAAD',
        },
    ];

    static getNextAvailableStatuses(userStatus): IStatus[] {
        return this.all.filter((status) => status.possiblePreviousStatusNames.includes(userStatus));
    }
}
