import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Pagination {
    numberOfPages: number;
    numberOfResults: number;
}

export interface IAsyncJobsApiService {
    getAllJobs: (openDrawer: boolean) => Promise<any>;
    numberOfActiveAsyncJobs: number;
    jobAdded: BehaviorSubject<boolean>;
    jobsPaginationChanged: BehaviorSubject<boolean>;
    jobsPagination: Pagination;
}

export const FUSE_ASYNC_DRAWER = new InjectionToken<IAsyncJobsApiService>('FUSE_ASYNC_DRAWER');
