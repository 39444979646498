import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

const MIN_HOURS = 0;
const MAX_HOURS = 23;
const MIN_MINUTES = 0;
const MAX_MINUTES = 59;
const reg = /^\d+$/;

class LibTime {
    constructor(public hours: string, public minutes: string) {}
}

@Component({
    selector: 'lib-time-input',
    templateUrl: './time-input.component.html',
    styleUrls: ['./time-input.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: TimeInputComponent }],
    host: {
        '[class.time-input-floating]': 'shouldLabelFloat',
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
    },
})
export class TimeInputComponent extends UnsubscribeOnDestroy implements ControlValueAccessor, MatFormFieldControl<string>, OnInit, OnDestroy {
    static nextId = 0;

    @ViewChild('hoursInput', { static: true })
    hoursInput: ElementRef<HTMLInputElement>;
    @ViewChild('minutesInput', { static: true })
    minutesInput: ElementRef<HTMLInputElement>;

    parts: FormGroup;
    stateChanges = new Subject<void>();
    focused = false;
    errorState = false;
    controlType = 'time-input';
    id = `time-input-${TimeInputComponent.nextId++}`;
    describedBy = '';

    get empty(): boolean {
        const {
            value: { hours, minutes },
        } = this.parts;

        return !hours && !minutes;
    }

    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.parts.disable() : this.parts.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input()
    get value(): string {
        const {
            value: { hours, minutes },
        } = this.parts;
        if (hours.length === 2 && minutes.length === 2) {
            return `${hours}:${minutes}`;
        }
        return '';
    }
    set value(time: string | null) {
        const timeArray = (time || '').split(':');
        const { hours, minutes } = new LibTime(timeArray[0] || '', timeArray[1] || '');
        this.parts.setValue({ hours, minutes });
        this.stateChanges.next();
    }

    saveValueChanges = true;
    history: { changedAt: Date; state: LibTime }[];

    constructor(formBuilder: FormBuilder, private _focusMonitor: FocusMonitor, private _elementRef: ElementRef<HTMLElement>, @Optional() @Self() public ngControl: NgControl) {
        super();

        this.parts = formBuilder.group({
            hours: '',
            minutes: '',
        });

        _focusMonitor
            .monitor(_elementRef, true)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((origin) => {
                if (this.focused && !origin) {
                    this.onTouched();
                }
                this.focused = !!origin;
                this.stateChanges.next();
            });

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {
        this.history = [{ changedAt: new Date(), state: this.parts.getRawValue() }];
        this.parts.valueChanges
            .pipe(
                filter(() => this.saveValueChanges),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((values: LibTime) => this.history.push({ changedAt: new Date(), state: values }));
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    onChange = (_: any) => {};

    onTouched = () => {};

    onFocus(e): void {
        e.preventDefault();

        setTimeout(() => {
            (e.target as HTMLInputElement).select();
        });
    }

    preventDefault(e): void {
        e.preventDefault();
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.focusHoursElement();
        }
    }

    writeValue(time: string | null): void {
        this.value = time;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    focusHoursElement(): void {
        this.hoursInput.nativeElement.focus();
    }

    focusMinutesElement(): void {
        this.minutesInput.nativeElement.focus();
    }

    undo(): void {
        this.saveValueChanges = false;
        if (this.history.length > 1) {
            this.history.pop();
        }
        const oldState = this.history[this.history.length - 1];
        this.parts.setValue(oldState.state);
        this.saveValueChanges = true;
    }

    increaseHours(e): void {
        this.preventDefault(e);

        const {
            value: { hours, minutes },
        } = this.parts;

        const hoursNumber = Number.parseInt(hours, 10);

        if (hoursNumber < MAX_HOURS) {
            let hourString = (hoursNumber + 1).toString();

            if (hourString.length === 1) {
                hourString = `0${hourString}`;
            }

            this.value = `${hourString}:${minutes}`;
            this.onChange(this.value);
        }
    }

    decreaseHours(e): void {
        this.preventDefault(e);

        const {
            value: { hours, minutes },
        } = this.parts;

        const hoursNumber = Number.parseInt(hours, 10);

        if (hoursNumber > MIN_HOURS) {
            let hourString = (hoursNumber - 1).toString();

            if (hourString.length === 1) {
                hourString = `0${hourString}`;
            }

            this.value = `${hourString}:${minutes}`;
            this.onChange(this.value);
        }
    }

    areHoursValid(hours: string | number): boolean {
        if (typeof hours === 'string') {
            hours = Number.parseInt(hours, 10);
        }

        return !(hours < MIN_HOURS || hours > MAX_HOURS);
    }

    handleHoursInput(): void {
        const {
            value: { hours, minutes },
        } = this.parts;

        if (!reg.test(hours.slice(-1))) {
            this.undo();
            return;
        }

        let hoursNumber = Number.parseInt(hours, 10);
        let newValue: string;

        if (hours.length > 2) {
            hoursNumber = Number.parseInt(hours.slice(-2), 10);

            if (!this.areHoursValid(hoursNumber)) {
                hoursNumber = Number.parseInt(hours.slice(-1), 10);
            }

            newValue = `${(hoursNumber < 10 ? '0' : '') + hoursNumber}:${minutes}`;
        } else if (!this.areHoursValid(hoursNumber)) {
            return;
        }

        if (hours.length === 1) {
            if (hoursNumber > 2) {
                this.focusMinutesElement();
            }

            newValue = `0${hours}:${minutes}`;
        } else {
            this.focusMinutesElement();
        }

        if (newValue) {
            this.value = newValue;
            this.onChange(this.value);
        }
    }

    increaseMinutes(e): void {
        this.preventDefault(e);

        const {
            value: { hours, minutes },
        } = this.parts;

        const minutesNumber = Number.parseInt(minutes, 10);

        if (minutesNumber < MAX_MINUTES) {
            let minuteString = (minutesNumber + 1).toString();

            if (minuteString.length === 1) {
                minuteString = `0${minuteString}`;
            }

            this.value = `${hours}:${minuteString}`;
            this.onChange(this.value);
        } else if (minutesNumber === MAX_MINUTES && Number.parseInt(hours, 10) < MAX_HOURS) {
            this.value = `${hours}:00`;
            this.increaseHours(e);
        }
    }

    decreaseMinutes(e): void {
        this.preventDefault(e);

        const {
            value: { hours, minutes },
        } = this.parts;

        const minutesNumber = Number.parseInt(minutes, 10);

        if (minutesNumber > MIN_MINUTES) {
            let minuteString = (minutesNumber - 1).toString();

            if (minuteString.length === 1) {
                minuteString = `0${minuteString}`;
            }

            this.value = `${hours}:${minuteString}`;
            this.onChange(this.value);
        } else if (minutesNumber === MIN_MINUTES && Number.parseInt(hours, 10) > MIN_HOURS) {
            this.value = `${hours}:${MAX_MINUTES}`;
            this.decreaseHours(e);
        }
    }

    areMinutesValid(minutes: string | number): boolean {
        if (typeof minutes === 'string') {
            minutes = Number.parseInt(minutes, 10);
        }

        return !(minutes < MIN_MINUTES || minutes > MAX_MINUTES);
    }

    handleMinutesInput(): void {
        const {
            value: { hours, minutes },
        } = this.parts;

        if (!reg.test(minutes.slice(-1))) {
            this.undo();
            return;
        }

        let minutesNumber = Number.parseInt(minutes, 10);
        let newValue: string;

        if (minutes.length > 2) {
            minutesNumber = Number.parseInt(minutes.slice(-2), 10);

            if (!this.areMinutesValid(minutesNumber)) {
                minutesNumber = Number.parseInt(minutes.slice(-1), 10);
            }

            newValue = `${hours}:${(minutesNumber < 10 ? '0' : '') + minutesNumber}`;
        } else if (minutes.length === 1) {
            newValue = `${hours}:0${minutes}`;
        }

        if (newValue) {
            this.value = newValue;
            this.onChange(this.value);
        }
    }
}
