export class ResetPasswordFailure {
    static readonly type = '[User] ResetPasswordFailure';

    constructor() {}
}

export class ResetUserPasswordUrlFailure {
    static readonly type = '[User] ResetUserPasswordUrlFailure';

    constructor() {}
}
