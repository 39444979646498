import { ModuleWithProviders, NgModule } from '@angular/core';
import { UserApiService } from './services/user.api-service';

@NgModule({
    providers: [UserApiService],
})
export class MaxBrainUserModule {
    static forRoot(): ModuleWithProviders<MaxBrainUserModule> {
        return {
            ngModule: MaxBrainUserModule,
            providers: [UserApiService],
        };
    }
}
