import { ILanguage, ILanguageService } from 'app/projects/shared/src/lib/interfaces/language.interface';
import * as _ from 'lodash';

export class MaxBrainLanguageService implements ILanguageService {
    static languagesOptions: ILanguage[] = [
        {
            id: 'en-GB',
            title: 'English',
            translate: 'LANGUAGE.ENGLISH.TITLE',
            flag: 'gb',
            dateId: 'en-GB',
        },
        {
            id: 'de-DE',
            title: 'Deutsch',
            translate: 'LANGUAGE.GERMAN.TITLE',
            flag: 'de',
            dateId: 'de',
        },
        {
            id: 'fr-FR',
            title: 'Français',
            translate: 'LANGUAGE.FRENCH.TITLE',
            flag: 'fr',
            dateId: 'fr',
        },
        {
            id: 'it-CH',
            title: 'Italian',
            translate: 'LANGUAGE.ITALIAN.TITLE',
            flag: 'it',
            dateId: 'it',
        },
    ];

    languages: ILanguage[] = null;

    constructor() {
        this.languages = MaxBrainLanguageService.languagesOptions;
    }

    /**
     *
     */
    getDefaultLanguage(): ILanguage {
        let languageId = navigator.language;

        if (!languageId || this.languages.map((language) => language.id).indexOf(languageId) === -1) {
            languageId = this.languages[0].id;
        }

        return this.languages.filter((language) => language.id === languageId)[0] || null;
    }

    /**
     *
     * @param id
     */
    getLanguageById(id: string): ILanguage {
        return _.find(this.languages, { id });
    }

    getCurrentLanguage(): ILanguage {
        let languageId = navigator.language;
        if (!languageId || this.languages.map((language) => language.id).indexOf(languageId) === -1) {
            languageId = this.languages[0].id;
        }
        return this.languages.filter((language) => language.id === languageId)[0] || null;
    }
}
