import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { IEntityState } from 'app/projects/entity/src/lib/interfaces/entity.state.interface';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import {
    CreateExperience,
    CreateExperienceFailure,
    CreateExperienceSuccess,
    DeleteExperience,
    DeleteExperienceFailure,
    DeleteExperienceSuccess,
    FetchExperience,
    FetchExperienceFailure,
    FetchExperiences,
    FetchExperiencesFailure,
    FetchExperiencesSuccess,
    FetchExperienceSuccess,
    FetchGrowKeywords,
    FetchGrowKeywordsFailure,
    FetchGrowKeywordsSuccess,
    SetExperience,
    SetExperiences,
    UnsetExperience,
    UpdateExperience,
    UpdateExperienceFailure,
    UpdateExperienceSuccess,
} from '../actions';
import { AddExperiences } from '../actions/add-entities.action';
import { ExperienceApiService } from '../services/experience.api-service';
import { MaxBrainExperience } from './experience.model';
import { ExperienceStateModel } from './experience.state-model';
import { HttpErrorResponse } from '@angular/common/http';

@State<ExperienceStateModel>({
    name: 'experience',
    defaults: new ExperienceStateModel(),
})
@Injectable()
export class ExperienceState extends EntityState<MaxBrainExperience> implements IEntityState<MaxBrainExperience> {
    static sortProperties: string[] = ['name'];

    @Selector()
    static getEntity(state: ExperienceStateModel, id: string = state.item): MaxBrainExperience {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntities(state: ExperienceStateModel): MaxBrainExperience[] {
        if (!state.map) {
            return null;
        }

        const toReturn = Array.from(state.map.values());

        toReturn.sort(MaxBrainUtils.sortCompareFn(ExperienceState.sortProperties));

        return toReturn;
    }

    constructor(public experienceService: ExperienceApiService) {
        super(experienceService, 0);

        //this._propstoSearch = MaxBrainExperience.props_to_search;
    }

    @Action(AddExperiences)
    addEntities(ctx: StateContext<ExperienceStateModel>, action: AddExperiences): void {
        this._addEntities(ctx, action);
    }

    @Action(SetExperience)
    setEntity(ctx: StateContext<ExperienceStateModel>, action: SetExperience): void {
        this._setEntity(ctx, action, AddExperiences);
    }

    @Action(UnsetExperience)
    unsetEntity(ctx: StateContext<ExperienceStateModel>): void {
        this._unsetEntity(ctx);
    }

    @Action(CreateExperience)
    createEntity(ctx: StateContext<ExperienceStateModel>, action: CreateExperience): void {
        this._createEntity(ctx, action, CreateExperienceSuccess, CreateExperienceFailure, AddExperiences).then(() => {});
    }

    @Action(UpdateExperience)
    updateEntity(ctx: StateContext<ExperienceStateModel>, action: UpdateExperience): void {
        this._updateEntity(ctx, action, UpdateExperienceSuccess, UpdateExperienceFailure, AddExperiences, this.experienceService.update(action.payload));
    }

    @Action(DeleteExperience)
    deleteEntity(ctx: StateContext<ExperienceStateModel>, action: DeleteExperience): void {
        this._deleteEntity(ctx, action, DeleteExperienceSuccess, DeleteExperienceFailure).then(() => {});
    }

    @Action(FetchExperience)
    fetchEntity(ctx: StateContext<ExperienceStateModel>, action: FetchExperience): void {
        this._fetchEntity(ctx, action, FetchExperienceSuccess, FetchExperienceFailure, AddExperiences, SetExperience).then(() => {});
    }

    @Action(FetchExperienceSuccess)
    fetchEntitySuccess(ctx: StateContext<ExperienceStateModel>, action: FetchExperienceSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchExperienceFailure)
    fetchEntityFailure(ctx: StateContext<ExperienceStateModel>, action: FetchExperienceFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(SetExperiences)
    setEntities(ctx: StateContext<ExperienceStateModel>, action: SetExperiences): void {
        this._setEntities(ctx, action);
    }

    @Action(FetchExperiences)
    fetchEntities(ctx: StateContext<ExperienceStateModel>): void {
        this._fetchEntities(ctx, FetchExperiencesSuccess, FetchExperiencesFailure, SetExperiences).then(() => {});
    }

    @Action(FetchExperiencesSuccess)
    fetchEntitiesSuccess(ctx: StateContext<ExperienceStateModel>): void {
        this._fetchEntitiesSuccess(ctx);
    }

    @Action(FetchExperiencesFailure)
    fetchEntitiesFailure(ctx: StateContext<ExperienceStateModel>): void {
        this._fetchEntitiesFailure(ctx);
    }

    @Action(FetchGrowKeywords)
    fetchGrowKeywords(ctx: StateContext<ExperienceStateModel>, action: FetchGrowKeywords): void {
        this.experienceService.getGrowKeywords().subscribe(
            (entity) => {
                ctx.dispatch(new FetchGrowKeywordsSuccess(entity));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new FetchGrowKeywordsFailure(response.error));
            }
        );
    }
}
