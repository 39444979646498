import { ChangeDetectorRef, Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Select, ofActionDispatched } from '@ngxs/store';
import { PublishContentNodesSuccess } from 'app/library/content-node/actions';
import { ContentNodeService } from 'app/library/content-node/services/content-node.service';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { FUSE_ASYNC_DRAWER } from 'app/projects/fuse/src/lib/services/async-drawer.token';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { User } from 'talkjs/all';
import { AsyncJobApiService } from '../../../services/async-job.api-service';
import { JobMessageStatus } from './enums/job-message-status.enum';
import { JobStatus } from './enums/job-status.enum';
import { Job } from './models/job.model';
import { Message } from './models/message.model';

@Component({
    selector: 'app-async-job-drawer',
    templateUrl: './async-job-drawer.component.html',
    styleUrls: ['./async-job-drawer.component.scss'],
})
export class AsyncJobDrawerComponent extends UnsubscribeOnDestroy implements OnInit, OnDestroy {
    @HostBinding('class.no-jobs')
    isHidden = true;

    @Select(UserState.getMyInfo)
    profile$: Observable<User>;
    profile: User;

    isAsyncJobVisable = false;
    asyncJobs: Job[] = [];
    jobStatus: Job = null;
    jobMessages: Message[];
    jobMessageStatus: string = null;
    jobMessagesVisable = true;
    JobStatus = JobStatus;
    isExpanded = true;

    get isPanelVisible(): boolean {
        const value = this.asyncJobs.length;

        this.isHidden = !value;

        return !this.isHidden;
    }

    constructor(
        public contentNodeService: ContentNodeService,
        @Inject(FUSE_ASYNC_DRAWER) public asyncJobService: AsyncJobApiService,
        private _action$: Actions,
        private _changeDetection: ChangeDetectorRef,
        public router: Router
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this._action$.pipe(ofActionDispatched(PublishContentNodesSuccess), takeUntil(this._unsubscribeAll)).subscribe(async (action: PublishContentNodesSuccess) => {
            const { jobId, nodeId } = action.payload;
            if (jobId) {
                this.asyncJobService.getAllJobs(Boolean(localStorage.getItem('isDrawerOpen')));
                this.asyncJobService.jobAdded.next(true);

                // increases number of active async jobs on My backgrounds tasks button
                this.asyncJobService.numberOfActiveAsyncJobs++;

                let jobStatus = await this.asyncJobService.getJobStatus(jobId);
                const foundIndex = this.asyncJobs.findIndex((x) => x.id === jobStatus.id);
                this.asyncJobs[foundIndex] = jobStatus;

                if (jobStatus.communicatedStatus === JobStatus.Finished || jobStatus.communicatedStatus === JobStatus.TimedOut) {
                    this.asyncJobService.completedJobs++;
                    this.asyncJobService.jobFinished.next({ jobId, nodeId });
                } else {
                    const intervalId = setInterval(async () => {
                        jobStatus = await this.asyncJobService.getJobStatus(jobId);

                        const foundIndex = this.asyncJobs.findIndex((x) => x.id === jobStatus.id);
                        this.asyncJobs[foundIndex] = jobStatus;

                        if (
                            jobStatus.communicatedStatus === JobStatus.Finished ||
                            jobStatus.communicatedStatus === JobStatus.TimedOut ||
                            jobStatus.communicatedStatus === JobStatus.Canceled
                        ) {
                            this.asyncJobService.completedJobs++;
                            this.asyncJobService.jobFinished.next({ jobId, nodeId });
                            clearInterval(intervalId);
                        }
                    }, 3000);
                }
            }
        });

        this.asyncJobService.asyncJobs$.pipe(takeUntil(this._unsubscribeAll)).subscribe((jobs: Job[]) => {
            if (jobs.length > 0) {
                if (this.asyncJobService.jobsPage > 1) {
                    this.asyncJobs = this.asyncJobs.concat(jobs);
                } else {
                    this.asyncJobs = jobs;
                }

                const isDrawerOpen: boolean = Boolean(localStorage.getItem('isDrawerOpen'));

                // Initialize infinite scroll only once after results
                if (this.asyncJobService.jobsPage === 1 && isDrawerOpen) {
                    setTimeout(() => {
                        this.initInfiniteScroll();
                    });
                }

                // if there are no changes, clean asyncJobs to hide drawer
                if (!isDrawerOpen) {
                    localStorage.removeItem('isDrawerOpen');
                    this.asyncJobs = [];
                }
                this._changeDetection.detectChanges();
            }
        });

        this.profile$
            .pipe(
                filter((profile) => !!profile),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((profile) => {
                this.profile = profile;
            });
    }

    navigateToModule(moduleId: string) {
        this.router.navigate([`modules/${moduleId}/overview`]);
    }

    private initInfiniteScroll(): void {
        const expansionPanel = document.getElementsByClassName('mat-expansion-panel-content')[0];
        expansionPanel.addEventListener('scroll', (e) => {
            const scrollTop = (e.target as HTMLElement).scrollTop;
            const clientHeight = (e.target as HTMLElement).clientHeight;
            const scrollHeight = (e.target as HTMLElement).scrollHeight;
            const hiddenHeight = scrollHeight - clientHeight;

            if (scrollTop === hiddenHeight && this.asyncJobService.jobsPage < this.asyncJobService.jobsPagination.numberOfPages) {
                if (!this.isAsyncJobVisable) {
                    this.asyncJobService.getAllJobs(true, ++this.asyncJobService.jobsPage);
                }
            }

            if (scrollTop === hiddenHeight && this.asyncJobService.messagesPage < this.asyncJobService.messagesPagination.numberOfPages) {
                if (this.isAsyncJobVisable) {
                    this.onGetJobMessages(this.jobStatus.id, ++this.asyncJobService.messagesPage, this.jobMessageStatus);
                }
            }
        });
    }

    async onGetJobStatus(job: Job): Promise<void> {
        this.isAsyncJobVisable = !this.isAsyncJobVisable;
        this.jobStatus = await this.asyncJobService.getJobStatus(job.id);
        if (this.jobStatus) {
            if (!this.jobStatus.isRead) {
                const isRead = await this.asyncJobService.markJobAsRead(job.id);
                if (isRead) {
                    job.isRead = true;
                }
            }
            this.onGetJobMessages(job.id, 1);
            if (this.jobStatus.communicatedStatus === JobStatus.InProgress || this.jobStatus.communicatedStatus === JobStatus.Created) {
                const intervalId = setInterval(async () => {
                    this.jobStatus = await this.asyncJobService.getJobStatus(job.id);
                    this.onGetJobMessages(job.id);
                    if (
                        this.jobStatus.communicatedStatus === JobStatus.Finished ||
                        this.jobStatus.communicatedStatus === JobStatus.TimedOut ||
                        this.jobStatus.communicatedStatus === JobStatus.Canceled
                    ) {
                        clearInterval(intervalId);
                    }
                }, 3000);
            }
        }
    }

    async onGetJobMessages(jobId: string, page = 1, messageStatus?: string, numberOfMessages?: number): Promise<void> {
        if (messageStatus) {
            if (numberOfMessages > 0) {
                if (messageStatus !== this.jobMessageStatus) {
                    await this.onGetJobMessagesByStatus(jobId, page, messageStatus);
                } else if (messageStatus === this.jobMessageStatus) {
                    this.jobMessagesVisable = !this.jobMessagesVisable;
                }
            } else if (page > 1) {
                await this.onGetJobMessagesByStatus(jobId, page, messageStatus);
            } else {
                this.jobMessages = [];
                this.jobMessageStatus = null;
            }
        } else {
            if (this.jobStatus.stats.failed > 0) {
                await this.onGetJobMessagesByStatus(jobId, page, JobMessageStatus.Failed);
            } else if (this.jobStatus.stats.pending > 0) {
                await this.onGetJobMessagesByStatus(jobId, page, JobMessageStatus.Pending);
            } else {
                this.jobMessageStatus = null;
                this.jobMessages = [];
            }
        }
    }

    async onGetJobMessagesByStatus(jobId: string, page: number, messageStatus: string): Promise<void> {
        this.jobMessagesVisable = true;
        const jobMessages = await this.asyncJobService.getJobMessages(jobId, page, messageStatus);
        if (jobMessages) {
            this.jobMessageStatus = messageStatus;
            if (page > 1) {
                this.jobMessages = this.jobMessages.concat(jobMessages);
            } else {
                this.jobMessages = jobMessages;
            }
        }
    }

    onToggleAsyncJob(): void {
        this.jobMessages = [];
        this.jobMessageStatus = null;
        this.isAsyncJobVisable = !this.isAsyncJobVisable;
    }

    async onCancelAsyncJob(job: Job): Promise<void> {
        const jobCanceled = await this.asyncJobService.cancelJob(job);
        if (jobCanceled) {
            const foundIndex = this.asyncJobs.findIndex((x) => x.id === jobCanceled.id);
            this.asyncJobs[foundIndex] = jobCanceled;
        }
    }

    async onCloseAsyncJob(job: Job): Promise<void> {
        const jobClosed = await this.asyncJobService.closeJob(job);
        if (jobClosed) {
            this.asyncJobs = this.asyncJobs.filter((asyncJob) => asyncJob.id !== jobClosed.id);
            this.asyncJobService.completedJobs--;
            this.asyncJobService.jobsPagination.numberOfResults--;
            this.asyncJobService.jobsPaginationChanged.next(true);
            if (this.asyncJobs.length === 0) {
                localStorage.removeItem('isDrawerOpen');
            }
        }
    }

    onCloseDrawer(): void {
        localStorage.removeItem('isDrawerOpen');
        this.asyncJobs = [];
        this._changeDetection.detectChanges();
    }

    onExpandedChange(value: boolean): void {
        this.isExpanded = !this.isExpanded;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
