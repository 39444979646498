import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { SnackBarTime } from 'app/projects/core/src/lib/constants/snack-bar';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';
import { FuseCopierService } from 'app/projects/fuse/src/lib/services/copier.service';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CalendarDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    @Select(UserState.getMyInfo)
    profile$: Observable<User>;
    profile: User;

    @Input()
    calendarSyncUrl: { url: string };

    constructor(
        public dialogRef: MatDialogRef<CalendarDialogComponent>,
        @Inject(MAXBRAIN_ENVIRONMENT) private environment: IEnvironment,
        private _matSnackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _copierService: FuseCopierService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.profile$.pipe(takeUntil(this._unsubscribeAll)).subscribe((profile) => {
            this.profile = profile;
        });
    }

    /**
     * Copy URL to Clipboard
     *
     */
    copyToClipboard(): void {
        this._copierService.copyText(this.calendarSyncUrl.url);

        const successMessage = this._translateService.instant('FORM.EXPORT_CALENDAR.MESSAGE.COPY_SUCCESS');

        this._matSnackBar.open(successMessage, 'OK', {
            verticalPosition: 'top',
            duration: SnackBarTime.medium,
        });
    }
}
