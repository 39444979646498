<button
    fxLayout="row"
    fxLayoutAlign="center center"
    class="w-100-p"
    mat-flat-button
    [color]="color ? color : 'accent'"
    (click)="onClickButton($event)"
    [disabled]="disabled"
    [loading]="isLoading"
    #btnFocus
    [autofocus]="autofocus ? btnFocus.focus() : null"
    [class.icon-button]="!label"
    [class.participant-view-btn]="participantViewButton"
>
    <mat-icon class="mr-8" *ngIf="icon">{{ icon }}</mat-icon>
    <mat-icon class="mr-8" *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>

    <span class="text-sm">{{ label | translate }}<span *ngIf="showThreeDots">...</span></span>
    <span class="text-sm" *ngIf="counter"> &nbsp;({{ counter }})</span>
</button>
