import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { IManageExperienceUsersActionPayload } from 'app/library/experience-user/interfaces/experience-users-action.action-payload.interface';
import { ExperienceUsersService } from 'app/library/experience-users/services/experience-users.service';
import { SnackBarTime } from 'app/projects/core/src/lib/constants/snack-bar';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { MaxBrainUtils } from 'app/projects/core/src/lib/utils';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { ILanguageService } from 'app/projects/shared/src/lib/interfaces/language.interface';
import { FeatureSwitchPipe } from 'app/projects/shared/src/lib/pipes/feature-switch.pipe';
import { MAXBRAIN_LANGUAGE_SERVICE } from 'app/projects/shared/src/lib/services/language.token';
import { AddUserPermissionsSuccess } from 'app/projects/user/src/lib/actions/add-user-permissions-success.action';
import { AddUserPermissions } from 'app/projects/user/src/lib/actions/add-user-permissions.action';
import { CreateUserFailure } from 'app/projects/user/src/lib/actions/create-entity-failure.action';
import { CreateUserSuccess } from 'app/projects/user/src/lib/actions/create-entity-success.action';
import { CreateUser } from 'app/projects/user/src/lib/actions/create-entity.action';
import { UpdateUserSuccess } from 'app/projects/user/src/lib/actions/update-entity-success.action';
import { UpdateUserProfilePicture } from 'app/projects/user/src/lib/actions/update-user-profile-picture.action';
import { User } from 'app/projects/user/src/lib/models/user';
import { take, takeUntil } from 'rxjs/operators';
import { ExperienceService } from '../../../experience/services/experience.service';

@Component({
    selector: 'app-user-create-form-dialog',
    templateUrl: './user-create-form-dialog.component.html',
    styleUrls: ['./user-create-form-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class UserCreateFormDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    detailsFormGroup: FormGroup;

    newProfilePictureFile: File;
    profilePictureSrc = `${MaxBrainUtils.baseUrl}/assets/images/avatars/profile.jpg`;

    creating = false;
    // @Todo, This path cannot work online, fix with relative path ?
    // experiences: import("d:/Q-software/maxbrain/maxbrain_web/src/app/library/experience/models/experience.model").MaxBrainExperience[];
    experiences: any;
    isEngageFeatureEnabled: boolean;

    get userAccountForm(): FormGroup {
        return this.detailsFormGroup.controls.account as FormGroup;
    }

    get userRoleForm(): FormGroup {
        return this.detailsFormGroup.controls.role as FormGroup;
    }

    constructor(
        public dialogRef: MatDialogRef<UserCreateFormDialogComponent>,
        private _formBuilder: FormBuilder,
        private _store: Store,
        private _action$: Actions,
        private _translateService: TranslateService,
        private _matSnackBar: MatSnackBar,
        private _experienceService: ExperienceService,
        private _experienceUsersService: ExperienceUsersService,
        private _featureSwitchPipe: FeatureSwitchPipe,
        @Inject(MAXBRAIN_LANGUAGE_SERVICE) public languageService: ILanguageService
    ) {
        super();

        this.detailsFormGroup = this._formBuilder.group({
            account: this._formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', Validators.required],
                language: ['de-DE', Validators.required],
                photo: [null] as Blob[],
            }),
            role: this._formBuilder.group({
                userRole: ['basic_role', Validators.required],
            }),
        });

        this.isEngageFeatureEnabled = this._featureSwitchPipe.transform(FeatureSwitchName.Engage);
    }

    async ngOnInit(): Promise<void> {
        if (this.isEngageFeatureEnabled) {
            this.userAccountForm.addControl('experienceId', new FormControl(''));
            this.experiences = this._experienceService.getExperiences();
            if (!this.experiences) {
                await this._experienceService.fetchExperiences();
                this.experiences = this._experienceService.getExperiences();
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private _openSnackBar(message: string): MatSnackBarRef<SimpleSnackBar> {
        return this._matSnackBar.open(message, this._translateService.instant('GENERAL.BUTTON.EDIT'), {
            verticalPosition: 'top',
            duration: SnackBarTime.slow,
        });
    }

    private _createUserSuccess(user: User): void {
        this.dialogRef.close(user);
        this.creating = true;

        const successMessage = this._translateService.instant('USER.MESSAGE.CREATED');

        this._openSnackBar(successMessage)
            .onAction()
            .subscribe(() => {
                this.goToUser(user.id);
            });
    }

    private _updateRole(newUser: User): void {
        const roleData = this.userRoleForm.getRawValue();

        if (roleData.userRole === 'basic_role') {
            this._createUserSuccess(newUser);
            return;
        }

        this._store.dispatch(
            new AddUserPermissions({
                entityIds: [newUser.id],
                permissions: ['tenant_management'],
            })
        );
        this._action$.pipe(ofActionDispatched(AddUserPermissionsSuccess), take(1), takeUntil(this._unsubscribeAll)).subscribe(() => {
            this._createUserSuccess(newUser);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Go to module
     */
    goToUser(userId: string): void {
        // this._moduleGlobalService.setParentUrl(window.location.pathname);
        this._store.dispatch(new Navigate([`/${User.label_plural}/${userId}/edit`]));
    }

    /**
     * File change
     */
    fileChange(event): void {
        if (event.target.files && event.target.files[0]) {
            this.userAccountForm.patchValue({
                photo: event.target.files[0],
            });

            const reader = new FileReader();

            reader.onload = (e) => (this.profilePictureSrc = (e.target as any).result);

            reader.readAsDataURL(this.userAccountForm.value.photo);
        }
    }

    create(): void {
        this.dialogRef.updateSize('300px');
        this.creating = true;

        const accountData = this.userAccountForm.getRawValue();

        this._store.dispatch(new CreateUser(accountData));
        const successSubscription = this._action$
            .pipe(ofActionDispatched(CreateUserSuccess), take(1), takeUntil(this._unsubscribeAll))
            .subscribe(async (action: CreateUserSuccess) => {
                failureSubscription.unsubscribe();

                if (this.userAccountForm.get('experienceId') && !!this.userAccountForm.get('experienceId').value) {
                    const user = action.payload;

                    const payload: IManageExperienceUsersActionPayload = {
                        users: [{ id: user.id }],
                        experience: { id: this.userAccountForm.get('experienceId').value },
                    };
                    const responsePayload = await this._experienceUsersService.addUsersToExperience(payload);
                    if (responsePayload) {
                        const successMessage = this._translateService.instant('ENGAGE.ALL_USERS_TAB.DIALOGS.ASSIGN_EXPERIENCE.MESSAGE.ASSIGN_USER_SUCCESS');
                        this._matSnackBar.open(successMessage, 'OK', {
                            verticalPosition: 'top',
                            duration: SnackBarTime.slow,
                        });
                    }
                }

                if (!this.userAccountForm.value.photo) {
                    this._updateRole(action.payload);
                    return;
                }

                this._store.dispatch(
                    new UpdateUserProfilePicture({
                        entityId: action.payload.id,
                        file: this.userAccountForm.value.photo,
                    })
                );
                this._action$.pipe(ofActionDispatched(UpdateUserSuccess), take(1), takeUntil(this._unsubscribeAll)).subscribe(() => {
                    this._updateRole(action.payload);
                });
            });
        const failureSubscription = this._action$.pipe(ofActionDispatched(CreateUserFailure), take(1)).subscribe((action: CreateUserFailure) => {
            successSubscription.unsubscribe();

            this.dialogRef.updateSize('80%');
            this.creating = false;

            /**
             * setTimeout is needed because of this.creating is used for ngIf in template
             */
            setTimeout(() => {
                Object.keys(action.payload.response.error.errors).forEach((errorfield) => {
                    let form: FormGroup;

                    switch (errorfield) {
                        case 'userRole':
                            form = this.userRoleForm;
                            break;
                        default:
                            form = this.userAccountForm;
                            break;
                    }

                    (form.controls[errorfield] as FormControl).setErrors(action.payload.response.error.errors[errorfield]);
                });
            });
        });
    }
}
