import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventFormComponent } from './components/dialogs/form/form.component';
import { CalendarDialogComponent } from './components/dialogs/calendar/calendar.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { TinymceService } from 'app/services/tinymce.service';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { FuseSharedModule } from 'app/projects/fuse/src/lib/shared.module';
import { FuseConfirmDialogModule } from 'app/projects/fuse/src/lib/components/confirm-dialog/confirm-dialog.module';
import { EventService } from './services/event.service';
import { ManageAttendancesDialogComponent } from './components/dialogs/manage-attendances-dialog/manage-attendances-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ModuleEventAttendanceModule } from '../module-event-attendance/module-event-attendance.module';
import { ManageParticipantAttendancesDialogComponent } from './components/dialogs/manage-participant-attendance-dialog/manage-participant-attendance-dialog.component';

@NgModule({
    imports: [
        MaxBrainSharedModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSnackBarModule,
        MatIconModule,
        MatToolbarModule,
        MatAutocompleteModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        ModuleEventAttendanceModule,

        FuseSharedModule,
        FuseConfirmDialogModule,
        NgxDaterangepickerMd.forRoot({
            format: 'DD/MM/YYYY',
            applyLabel: 'OK',
        }),
        NgxCleaveDirectiveModule,
    ],
    declarations: [EventFormComponent, CalendarDialogComponent, ManageAttendancesDialogComponent, ManageParticipantAttendancesDialogComponent],
    entryComponents: [EventFormComponent, CalendarDialogComponent],
    exports: [EventFormComponent, CalendarDialogComponent],
})
export class MaxBrainEventModule {
    static forRoot(): ModuleWithProviders<MaxBrainEventModule> {
        return {
            ngModule: MaxBrainEventModule,
            providers: [TinymceService, EventService],
        };
    }
}
