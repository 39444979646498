<div id="smart-banner" *ngIf="isVisible && href">
    <div id="smart-banner-close">
        <mat-icon (click)="hide.next()">add-circle</mat-icon>
    </div>

    <div id="smart-banner-button">
        <a mat-raised-button color="white" [hidden]="!href" [href]="href | safeUrl">View</a>
    </div>

    <div id="smart-banner-content">
        <img [src]="src" [alt]="title" />
        <div>
            <span class="title" [title]="title">{{ title }}</span>
            <span>MaxBrain {{ isIOS ? 'AG' : '' }}</span>
            <span *ngIf="isIOS" [title]="'SMART_BANNER.GET_IT_ON_APP_STORE' | translate">
                {{ 'SMART_BANNER.GET_IT_ON_APP_STORE' | translate }}
            </span>
            <span *ngIf="isAndroid" [title]="'SMART_BANNER.GET_IT_ON_GOOGLE_PLAY' | translate">
                {{ 'SMART_BANNER.GET_IT_ON_GOOGLE_PLAY' | translate }}
            </span>
        </div>
    </div>
</div>
