import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Team } from 'app/library/team/models/team.model';

import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MaxBrainAudience } from 'app/library/audience/models/audience.model';
import { CourseStructureCategory } from 'app/library/course-offerings-category/models/course-structure-category.model';
import { IEntityNode } from '../../../interfaces/entity-node.interface';

@Component({
    selector: 'app-move-to-dialog',
    templateUrl: './move-to-dialog.component.html',
    styleUrls: ['./move-to-dialog.component.scss'],
})
export class MoveToDialogComponent implements OnInit {
    @Input()
    listTree: IEntityNode[];

    @Input()
    entity: Team | MaxBrainAudience | CourseStructureCategory;

    @Input()
    title: string;

    treeControl = new NestedTreeControl<IEntityNode>((node) => node.children);
    dataSource = new MatTreeNestedDataSource<IEntityNode>();
    selectedNode: IEntityNode;

    constructor(public dialogRef: MatDialogRef<MoveToDialogComponent>) {}

    ngOnInit(): void {
        this.dataSource.data = this.listTree;
    }

    hasChild = (_: number, node: IEntityNode) => !!node.children && node.children.length > 0;

    onSelect(node: IEntityNode): void {
        node.isSelected = true;
        this.selectedNode = node;
    }

    onDeselect(node: IEntityNode): void {
        node.isSelected = false;
        this.selectedNode = null;
    }

    onClearSelection(): void {
        this.onDeselect(this.selectedNode);
    }

    onSave(): void {
        this.dialogRef.close(this.selectedNode);
    }
}
