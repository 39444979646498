import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFeedItemQuery } from '../interfaces/feed-item.query.interface';

export interface IFeedItemsAndTotalCount {
    data: IFeedItemQuery[];
    pagination: {
        number_of_pages: number;
        number_of_results: number;
    };
}

@Injectable()
export class FeedApiService {
    private _unreadCountSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(private _http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) private _environment: IEnvironment) {}

    getUnreadCountObservable(): Observable<number> {
        return this._unreadCountSubject$.asObservable();
    }

    getFeedItems(pageIndex = 1, pageSize = 10): Observable<IFeedItemsAndTotalCount> {
        let params = new HttpParams().set('page', pageIndex.toString()).set('limit', pageSize.toString());

        return this._http.get<IFeedItemsAndTotalCount>(`${this._environment.apiUrl}/feed-items`, { params });
    }

    getNotificationUnreadCount(): Observable<number> {
        return this._http
            .get<{ count: number }>(`${this._environment.apiUrl}/new-feed-count`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            })
            .pipe(
                map((response) => {
                    const unreadCount = response.count;
                    this._unreadCountSubject$.next(unreadCount);
                    return unreadCount;
                })
            );
    }

    markFeedItemsAsRead(
        ids: string[],
        state: number
    ): Promise<{
        notification_id: number;
        state: number;
    }> {
        return this._http.post<{ notification_id: number; state: number }>(`${this._environment.apiUrl}/notifications/mark`, { ids, state }).toPromise();
    }
}
