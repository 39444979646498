import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FuseSearchBarComponent } from './search-bar.component';

@NgModule({
    declarations: [FuseSearchBarComponent],
    imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule],
    exports: [FuseSearchBarComponent],
})
export class FuseSearchBarModule {}
