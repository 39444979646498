import { Pipe, PipeTransform } from '@angular/core';
import { JobMessageStatus } from '../enums/job-message-status.enum';

@Pipe({ name: 'getJobStatTextColor' })
export class GetJobStatTextColorPipe implements PipeTransform {
    transform(jobMessageStatus: JobMessageStatus): any {
        switch (jobMessageStatus) {
            case JobMessageStatus.Pending:
                return '#61b3f1';
            case JobMessageStatus.Succeeded:
                return '#a2c617';
            case JobMessageStatus.Failed:
                return '#f50808';
            default:
                return '#666666';
        }
    }
}
