import { IHeaderBannerStreamQuery } from 'app/library/grow/interfaces/header-banner-stream.query.interface';

// @dynamic
export class HeaderBannerStream {
    id: string;
    name: string;
    description: string;
    type: string;

    constructor(query?: IHeaderBannerStreamQuery) {
        if (query) {
            this.id = query.id.toString();
            this.name = query.name;
            this.description = query.description;
            this.type = query.type;
        }
    }
}
