import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';
import { IFullConversationData } from 'app/services/talk.service';

@Component({
    selector: 'app-basic-list-dialog',
    templateUrl: './basic-users-list.component.html',
    styleUrls: ['./basic-users-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class BasicUsersListDialogComponent {
    @Input()
    dialogTitle: string;

    @Input()
    list: IFullConversationData;

    constructor(public dialogRef: MatDialogRef<BasicUsersListDialogComponent>) {}
}
