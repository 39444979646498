import { Sort } from '@angular/material/sort';
import { ModuleUser } from '../../module-user/models/module-user.model';

export class SetMyModules {
    static readonly type = '[MyModules] SetMyModules';

    constructor(public payload: ModuleUser[]) {}
}

export class UnsetMyModules {
    static readonly type = '[MyModules] UnsetMyModules';
}

export class AddMyModules {
    static readonly type = '[MyModules] AddMyModules';

    constructor(public payload: ModuleUser[]) {}
}

export class FetchMyModule {
    static readonly type = '[MyModules] FetchMyModule';

    constructor(public payload: string) {}
}
export class FetchParticipantModule {
    static readonly type = '[MyModules] FetchParticipantModule';

    constructor(public payload: { moduleId: string; userId: string }) {}
}

export class FetchParticipantModuleSuccess {
    static readonly type = '[MyModules] FetchParticipantModuleSuccess';

    constructor(public payload: ModuleUser[]) {}
}

export class FetchParticipantModuleFailure {
    static readonly type = '[MyModules] FetchParticipantModuleFailure';
}

export class FetchMyModules {
    static readonly type = '[MyModules] FetchMyModules';

    constructor(
        public payload?: {
            search?: string;
            sortBy?: string;
            sortOrder?: string;
            pageSize?: number;
            pageNumber?: number;
            participantId?: string;
            tag?: number[];
        }
    ) {}
}

export class FetchMyModulesSuccess {
    static readonly type = '[MyModules] FetchMyModulesSuccess';
}

export class FetchMyModulesFailure {
    static readonly type = '[MyModules] FetchMyModulesFailure';
}

export class ToggleSelectedModule {
    static readonly type = '[MyModules] ToggleSelectedModule';

    constructor(public payload: string) {}
}

export class SelectFilteredModules {
    static readonly type = '[MyModules] SelectFilteredModules';
}

export class DeselectSelectedModules {
    static readonly type = '[MyModules] DeselectSelectedModules';
}

export class SearchMyModules {
    static readonly type = '[MyModules] SearchMyModules';

    constructor(public payload: string) {}
}

export class SortMyModules {
    static readonly type = '[MyModules] SetMyModulesSort';

    constructor(public payload: Sort) {}
}
