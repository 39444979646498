import { IExperienceUserQuery } from '../interfaces/experience-user.query.interface';

export class ExperienceUser {
    static label_singular = 'experienceUser';
    static label_plural = 'experienceUsers';

    static props_to_search = [];

    id: string;
    experienceId: string;
    userId: string;

    constructor(experienceUser?: IExperienceUserQuery) {
        if (experienceUser) {
            this.experienceId = experienceUser.experience.id.toString();
            this.userId = experienceUser.user.id.toString();
            this.id = experienceUser.id.toString();
        } else {
            this.experienceId = null;
            this.userId = null;
            this.id = null;
        }
    }
}
