<mat-expansion-panel class="mat-elevation-z2 progress-drawer mr-24" *ngIf="isVisible" [expanded]="expanded" hideToggle="true" (expandedChange)="onExpandedChange($event)">
    <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title" fxLayout="row" fxLayoutAlign="space-between center">
            <h4>
                <mat-progress-spinner diameter="20" class="mr-12" color="accent" mode="indeterminate" *ngIf="inProgressNodeKeys.length"></mat-progress-spinner>
                <span *ngIf="inProgressNodeKeys.length">{{ 'CONTENT.MESSAGE.UPLOADING' | translate }}</span>
                <span *ngIf="!inProgressNodeKeys.length && (failureNodes.length || successNodes.length)">{{ 'CONTENT.LABEL.UPLOADS' | translate }}</span>
            </h4>
            <div>
                <mat-icon class="pointer mr-8 mb-4" *ngIf="expanded">minimize</mat-icon>
                <mat-icon *ngIf="successNodes.length || failureNodes.length" (click)="$event.stopPropagation(); removeFiles()">close</mat-icon>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngTemplateOutlet="matExpansionPanelContent"></ng-container>
</mat-expansion-panel>

<ng-template #matExpansionPanelContent>
    <mat-list class="mat-elevation-z2" role="list">
        <mat-list-item *ngFor="let fileName of inProgressNodeKeys; let last = last" [class.last]="last" role="listitem">
            <div class="full-width">
                <div class="text-truncate w-100-p file-name" [title]="fileName">{{ fileName }}</div>
                <div class="mw-200 text-right">
                    <span class="ml-24 mr-12">{{ 'CONTENT.MESSAGE.UPLOADING' | translate }}... {{ contentNodeService.inProgressNodes[parentNode.id][fileName] }}%</span>
                    <mat-progress-spinner diameter="20" color="accent" mode="determinate" [value]="contentNodeService.inProgressNodes[parentNode.id][fileName]">
                    </mat-progress-spinner>
                </div>
            </div>
        </mat-list-item>
        <mat-list-item *ngFor="let node of failureNodes.slice().reverse()" class="failure-nodes" role="listitem">
            <div class="full-width">
                <div class="text-truncate w-100-p file-name" [title]="node.file">{{ node.file }}</div>
                <div class="mw-200 text-right">
                    <span class="ml-24 mr-12">{{ 'CONTENT.MESSAGE.UPLOAD_FAILED' | translate }}</span>
                    <mat-icon color="warn">add_circle_outline</mat-icon>
                </div>
            </div>
        </mat-list-item>
        <mat-list-item *ngFor="let node of successNodes.slice().reverse()" class="success-nodes-container" role="listitem">
            <div class="full-width">
                <div class="text-truncate w-100-p file-name" [title]="node.name">{{ node.name }}</div>
                <div class="mw-200 text-right">
                    <span class="ml-24 mr-12">{{ 'CONTENT.MESSAGE.UPLOAD_COMPLETED' | translate }}</span>
                    <mat-icon color="accent">check_circle_outline</mat-icon>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</ng-template>
