import { Component, HostBinding, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'fuse-nav-horizontal-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
})
export class FuseNavHorizontalItemComponent {
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: any;

    @Input()
    numberOfActiveAsyncJobs: number;

    @Input()
    isDrawerOpen: number;

    @Input()
    unreadFeedItemsCount$: BehaviorSubject<number>;

    /**
     * Constructor
     */
    constructor() {}
}
