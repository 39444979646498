import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaxBrainSharedModule } from 'app/projects/shared/src/lib/shared.module';
import { MaxBrainUserModule } from 'app/projects/user/src/lib/user.module';
import { TalkService } from 'app/services/talk.service';
import { ChatDrawerComponent } from './chat-drawer.component';

@NgModule({
    declarations: [ChatDrawerComponent],
    imports: [MaxBrainSharedModule, MatExpansionModule, MatProgressSpinnerModule, MatIconModule, MaxBrainUserModule],
    exports: [ChatDrawerComponent],
})
export class ChatDrawerModule {
    static forRoot(): ModuleWithProviders<ChatDrawerModule> {
        return {
            ngModule: ChatDrawerModule,
            providers: [TalkService],
        };
    }
}
