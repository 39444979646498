import { Sort } from '@angular/material/sort';
import { ModuleUserExtended } from 'app/library/module-user/models/module-user.extended.model';
import { ModuleUser } from 'app/library/module-user/models/module-user.model';

export class SetModuleParticipants {
    static readonly type = '[ModuleParticipant] SetModuleParticipants';

    constructor(public payload: ModuleUserExtended[]) {}
}

export class UnsetModuleParticipants {
    static readonly type = '[ModuleParticipant] UnsetModuleParticipants';
}

export class FetchModuleParticipants {
    static readonly type = '[ModuleParticipant] FetchModuleParticipants';

    constructor(
        public payload?: {
            moduleId: string;
            search?: string;
            sortBy?: string;
            sortOrder?: string;
            pageSize?: number;
            pageNumber?: number;
            withoutCertificate?: boolean;
            selectedCertificateIds?: string[];
            fetchAll?: boolean; // TODO: remove it when implement pagination for e-learning sections and modules
        }
    ) {}
}

export class FetchModuleParticipantsSuccess {
    static readonly type = '[ModuleParticipant] FetchModuleParticipantsSuccess';
}

export class FetchModuleParticipantsFailure {
    static readonly type = '[ModuleParticipant] FetchModuleParticipantsFailure';
}

export class ToggleSelectedModuleParticipant {
    static readonly type = '[ModuleParticipant] ToggleSelectedModuleParticipant';

    constructor(public payload: string) {}
}

export class SelectFilteredModuleParticipants {
    static readonly type = '[ModuleParticipant] SelectFilteredModuleParticipants';
}

export class DeselectSelectedModuleParticipants {
    static readonly type = '[ModuleParticipant] DeselectSelectedModuleParticipants';
}

export class SearchModuleParticipants {
    static readonly type = '[ModuleParticipant] SearchModuleParticipants';

    constructor(
        public payload: {
            searchTerm: string;
            source?: string;
        }
    ) {}
}

export class SortModuleParticipants {
    static readonly type = '[ModuleParticipant] SortModuleParticipants';

    constructor(public payload: Sort) {}
}
