<div class="relative" fxLayout="row" fxLayoutAlign="start center" #profile>
    <input type="file" style="display: none" (change)="fileChange($event)" [placeholder]="'CONTENT.BUTTON.UPLOAD' | translate" accept=".png, .jpg, .jpeg, .bmp, .gif" #fileInput />
    <div class="avatar-wrapper" *ngIf="profilePictureSrc || user.avatar?.src; else initials">
        <img [src]="profilePictureSrc || user.avatar.src" class="avatar" />
    </div>
    <ng-template #initials>
        <div class="avatar-wrapper">
            <div fxLayoutAlign="center center" class="avatar-initials avatar">{{ user.fullName | initials }}</div>
        </div>
    </ng-template>
    <app-profile-photo-action-wrapper
        [user]="user"
        [target]="profile"
        [profilePictureSrc]="profilePictureSrc"
        (choosePictureClicked)="fileInput.click()"
        (removePictureClicked)="removeProfilePicture()"
        (cursorOnCard)="toggleActiveClass($event)"
    ></app-profile-photo-action-wrapper>
</div>
