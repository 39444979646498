import { AttendanceStatus } from '../enums/attendance-status.enum';
import { ModuleEventAttendance } from './module-event-attendance.model';

export class ModuleEventAttendanceCommand {
    status: AttendanceStatus;
    comment: string;

    constructor(tempTap: ModuleEventAttendance) {
        this.status = tempTap.status;
        this.comment = tempTap.comment;
    }
}
