import { Pipe, PipeTransform } from '@angular/core';
import { ScheduledJobStatus } from 'app/library/scheduler/enums/scheduledJob-status.enum';
import { ScheduledJobType } from 'app/library/scheduler/enums/scheduledJob-type.enum';
import { ScheduledJob } from 'app/library/scheduler/models/scheduled-job.model';

@Pipe({ name: 'scheduleIcon' })
export class ScheduleIconPipe implements PipeTransform {
    transform(schedules: ScheduledJob[]): string {
        let icon: string;

        if (schedules && schedules.length) {
            schedules.forEach((schedule) => {
                if (schedule.type === ScheduledJobType.PublishSection || schedule.type === ScheduledJobType.NotifyAll) {
                    switch (schedule.info.status) {
                        case ScheduledJobStatus.Pending:
                            icon = 'schedule';
                            break;
                        case ScheduledJobStatus.Error:
                            icon = 'schedule_error';
                            break;
                        default:
                            icon = null;
                            break;
                    }
                }
            });
        }

        return icon;
    }
}
