import { Injectable } from '@angular/core';
import { ProfilePhotoActionWrapperComponent } from './profile-photo-action-wrapper.component';

@Injectable()
export class ProfilePhotoActionWrapperService {
    activeProfilePhotoEditorComponent: ProfilePhotoActionWrapperComponent;

    toggle(profilePhotoEditorComponent: ProfilePhotoActionWrapperComponent): void {
        if (!this.activeProfilePhotoEditorComponent) {
            this.activeProfilePhotoEditorComponent = profilePhotoEditorComponent;
        } else if (this.activeProfilePhotoEditorComponent !== profilePhotoEditorComponent) {
            this.activeProfilePhotoEditorComponent.hide = true;
            this.activeProfilePhotoEditorComponent = profilePhotoEditorComponent;
        }

        this.activeProfilePhotoEditorComponent.hide = !this.activeProfilePhotoEditorComponent.hide;
    }
}
