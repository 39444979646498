import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { IActionWithPayload } from 'app/projects/core/src/lib/interfaces/action_with_payload.interface';
import { IAddSubentitiesToEntityActionPayload } from 'app/projects/subentity/src/lib/interfaces/add-subentities-to-entity.action-payload.interface';
import { IRemoveSubentityFromEntityActionPayload } from 'app/projects/subentity/src/lib/interfaces/remove-subentity-from-entity.action-payload.interface';
import { ISubentityState } from 'app/projects/subentity/src/lib/interfaces/subentity.state.interface';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { AddUsers } from '../actions/add-entities.action';
import { CreateUserSuccess } from '../actions/create-entity-success.action';
import { DeleteUsersSuccess } from '../actions/delete-entities-success.action';
import { DeleteUser } from '../actions/delete-entity.action';
import { DeselectSelectedUsers } from '../actions/deselect-selected-entities.action';
import { SearchUsers } from '../actions/search-entities.action';
import { SelectFilteredUsers } from '../actions/select-filtered-entities.action';
import { SetUsers } from '../actions/set-entities.action';
import { ToggleSelectedUser } from '../actions/toggle-selected-entity.action';
import { User } from './user';
import { UserState } from './user.state';
import { UsersStateModel } from './users.state-model';

@State<UsersStateModel>({
    name: 'users',
    defaults: new UsersStateModel(),
})
@Injectable()
export class UsersState extends SubentityState<User> implements ISubentityState<User> {
    @Selector([UserState.getEntities])
    static getFilteredEntities(state: UsersStateModel, users: User[]): User[] {
        if (!users) {
            return null;
        }

        return users
            .filter((item) => state.filtered.indexOf(item.id) !== -1)
            .map((item) => {
                return [state.list.indexOf(item.id), item];
            })
            .sort((a: [number, User], b: [number, User]) => {
                return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
            })
            .map((x: [number, User]) => x[1]);
    }

    @Selector()
    static getSelectedEntityIds(state: UsersStateModel): string[] {
        return state.selected;
    }

    @Selector()
    static getSearchTerm(state: UsersStateModel): string {
        return state.searchTerm;
    }

    constructor(private store: Store) {
        super(null, 0);

        this._propstoSearch = [...User.props_to_search];
    }

    @Action(AddUsers)
    addEntities(ctx: StateContext<UsersStateModel>, action: AddUsers): void {
        const users = this.store.selectSnapshot(UserState.getEntities) || [];

        this._patchSubentities(ctx, { payload: { subentityIds: action.payload.map((entity) => entity.id) } }, users);
    }

    @Action(CreateUserSuccess)
    createEntity(ctx: StateContext<UsersStateModel>, action: CreateUserSuccess): void {
        const users = this.store.selectSnapshot(UserState.getEntities) || [];

        this._patchSubentities(ctx, { payload: { subentityIds: [action.payload.id] } }, users);
    }

    @Action(DeleteUser)
    deleteEntity({ getState, patchState }: StateContext<UsersStateModel>, { payload }: DeleteUser): void {
        const state = getState();

        patchState({
            list: [...state.list.filter((subentityId) => subentityId !== payload)],
            selected: [...state.selected.filter((subentityId) => subentityId !== payload)],
            filtered: [...state.filtered.filter((subentityId) => subentityId !== payload)],
        });
    }

    @Action(DeleteUsersSuccess)
    deleteEntitiesSuccess({ getState, patchState }: StateContext<UsersStateModel>, { payload }: DeleteUsersSuccess): void {
        const state = getState();

        patchState({
            list: [...state.list.filter((subentityId) => !payload.entityIds.includes(subentityId))],
            selected: [...state.selected.filter((subentityId) => !payload.entityIds.includes(subentityId))],
            filtered: [...state.filtered.filter((subentityId) => !payload.entityIds.includes(subentityId))],
        });
    }

    @Action(SetUsers)
    setEntities(ctx: StateContext<UsersStateModel>, action: SetUsers): void {
        this._setSubentities(ctx, action);
    }

    @Action(ToggleSelectedUser)
    toggleSelect(ctx: StateContext<UsersStateModel>, action: ToggleSelectedUser): void {
        this._toggleSelectedSubentity(ctx, action);
    }

    @Action(SelectFilteredUsers)
    selectAll(ctx: StateContext<UsersStateModel>): void {
        this._selectFilteredSubentities(ctx);
    }

    @Action(DeselectSelectedUsers)
    deselectAll(ctx: StateContext<UsersStateModel>): void {
        this._deselectSelectedSubentities(ctx);
    }

    @Action(SearchUsers)
    search(ctx: StateContext<UsersStateModel>, action: SearchUsers): void {
        const users = this.store.selectSnapshot(UserState.getEntities);

        this._searchSubentities(ctx, action, users);
    }

    addMultiple(state: StateContext<UsersStateModel>, action: IActionWithPayload<IAddSubentitiesToEntityActionPayload>, SuccessAction: any, FailureAction: any): void {
        throw new Error('Method not implemented.');
    }

    removeSingle(state: StateContext<UsersStateModel>, action: IActionWithPayload<IRemoveSubentityFromEntityActionPayload>, SuccessAction: any, FailureAction: any): void {
        throw new Error('Method not implemented.');
    }

    setAll(state: StateContext<UsersStateModel>, action: IActionWithPayload<User[]>): void {
        throw new Error('Method not implemented.');
    }

    fetchAll(state: StateContext<UsersStateModel>, SetEntityAction: any, SuccessAction: any, FailureAction: any): void {
        throw new Error('Method not implemented.');
    }

    fetchAllSuccess(state: StateContext<UsersStateModel>): void {
        throw new Error('Method not implemented.');
    }

    fetchAllFailure(state: StateContext<UsersStateModel>): void {
        throw new Error('Method not implemented.');
    }
}
