import { HttpErrorResponse } from '@angular/common/http';
import { ContentNode } from 'app/library/content-node/models/content-node.model';
import { ISearchModuleContentNodeActionPayload as ISearchModuleContentNodesActionPayload } from '../interfaces/search.action-payload.interface';
import { IUpdateSortFieldsActionPayload } from '../interfaces/update-sort-fields.action.interface';

export class SetModuleContentNode {
    static readonly type = '[ModuleContentNode] SetModuleContentNode';

    constructor(public payload: ContentNode) {}
}

export class UnsetModuleContentNode {
    static readonly type = '[ModuleContentNode] UnsetModuleContentNode';
}

export class RefreshModuleContentNode {
    static readonly type = '[ModuleContentNode] RefreshModuleContentNode';

    constructor(public payload: string) {}
}

export class RefreshModuleContentNodeSuccess {
    static readonly type = '[ModuleContentNode] RefreshModuleContentNodeSuccess';

    constructor(public payload: ContentNode) {}
}

export class RefreshModuleContentNodeFailure {
    static readonly type = '[ModuleContentNode] RefreshModuleContentNodeFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
            entityId: string;
        }
    ) {}
}

export class FetchModuleContentNode {
    static readonly type = '[ModuleContentNode] FetchModuleContentNode';

    constructor(public payload: string) {}
}

export class FetchModuleContentNodeSuccess {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodeSuccess';

    constructor(public payload: ContentNode) {}
}

export class FetchPrivateContentNodeSuccess {
    static readonly type = '[ModuleContentNode] FetchPrivateContentNodeSuccess';

    constructor(public payload: ContentNode) {}
}

export class FetchSharedContentNodeSuccess {
    static readonly type = '[ModuleContentNode] FetchSharedContentNodeSuccess';

    constructor(public payload: ContentNode) {}
}

export class FetchModuleContentNodeFailure {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodeFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
            entityId: string;
        }
    ) {}
}

export class SetModuleContentNodes {
    static readonly type = '[ModuleContentNode] SetModuleContentNodes';

    constructor(public payload: ContentNode[]) {}
}

export class UnsetModuleContentNodes {
    static readonly type = '[ModuleContentNode] UnsetModuleContentNodes';
}

export class FetchPrivateContentNodesByModuleId {
    static readonly type = '[ModuleContentNode] FetchPrivateContentNodesByModuleId';

    constructor(public payload: string) {}
}

export class FetchPrivateContentNodesByModuleIdSuccess {
    static readonly type = '[ModuleContentNode] FetchPrivateContentNodesByModuleIdSuccess';

    constructor(public payload: ContentNode) {}
}

export class FetchPrivateContentNodesByModuleIdFailure {
    static readonly type = '[ModuleContentNode] FetchPrivateContentNodesByModuleIdFailure';

    constructor(public payload: string) {}
}

export class FetchPrivateSectionContentNodesByModuleId {
    static readonly type = '[ModuleContentNode] FetchPrivateSectionContentNodesByModuleId';

    constructor(public payload: string) {}
}

export class FetchPrivateSectionContentNodesByModuleIdSuccess {
    static readonly type = '[ModuleContentNode] FetchPrivateSectionContentNodesByModuleIdSuccess';

    constructor(public payload: ContentNode) {}
}

export class FetchPrivateSectionContentNodesByModuleIdFailure {
    static readonly type = '[ModuleContentNode] FetchPrivateSectionContentNodesByModuleIdFailure';

    constructor(public payload: string) {}
}

export class FetchSharedContentNodesByModuleId {
    static readonly type = '[ModuleContentNode] FetchSharedContentNodesByModuleId';

    constructor(public payload: string) {}
}

export class FetchSharedContentNodesByModuleIdSuccess {
    static readonly type = '[ModuleContentNode] FetchSharedContentNodesByModuleIdSuccess';

    constructor(public payload: ContentNode) {}
}

export class FetchSharedContentNodesByModuleIdFailure {
    static readonly type = '[ModuleContentNode] FetchSharedContentNodesByModuleIdFailure';

    constructor(public payload: string) {}
}

export class FetchModuleGroupsContentNodes {
    static readonly type = '[ModuleContentNode] FetchModuleGroupsContentNodes';

    constructor(public payload: string) {}
}

export class FetchModuleGroupsContentNodesSuccess {
    static readonly type = '[ModuleContentNode] FetchModuleGroupsContentNodesSuccess';

    constructor(public payload: ContentNode) {}
}

export class FetchModuleGroupsContentNodesIdFailure {
    static readonly type = '[ModuleContentNode] FetchModuleGroupsContentNodesFailure';

    constructor(public payload: string) {}
}

export class FetchGroupsContentNodes {
    static readonly type = '[ModuleContentNode] FetchGroupsContentNodes';

    constructor(public payload: string) {}
}

export class FetchGroupsContentNodesSuccess {
    static readonly type = '[ModuleContentNode] FetchGroupsContentNodesSuccess';

    constructor(public payload: ContentNode) {}
}

export class FetchGroupsContentNodesIdFailure {
    static readonly type = '[ModuleContentNode] FetchGroupsContentNodesFailure';

    constructor(public payload: string) {}
}

export class FetchModuleContentNodesByModuleId {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodesByModuleId';

    constructor(public payload: string) {}
}

export class FetchModuleContentNodesByModuleIdSuccess {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodesByModuleIdSuccess';

    constructor(public payload: ContentNode) {}
}

export class FetchModuleContentNodesByModuleIdFailure {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodesByModuleIdFailure';
}

export class FetchModuleContentNodesBySectionId {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodesBySectionId';

    constructor(public payload: string) {}
}

export class FetchModuleContentNodesBySectionIdSuccess {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodesBySectionIdSuccess';
}

export class FetchModuleContentNodesBySectionIdFailure {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodesBySectionIdFailure';
}

export class FetchModuleSectionContentNodesByModuleId {
    static readonly type = '[ModuleContentNode] FetchModuleSectionContentNodesByModuleId';

    constructor(public payload: string) {}
}

export class FetchModuleSectionContentNodesByModuleIdSuccess {
    static readonly type = '[ModuleContentNode] FetchModuleSectionContentNodesByModuleIdSuccess';
}

export class FetchModuleSectionContentNodesByModuleIdFailure {
    static readonly type = '[ModuleContentNode] FetchModuleSectionContentNodesByModuleIdFailure';
}

export class FetchModuleContentNodesByParentId {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodesByParentId';

    constructor(public payload: string) {}
}

export class FetchModuleContentNodesByParentIdSuccess {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodesByParentIdSuccess';
}

export class FetchModuleContentNodesByParentIdFailure {
    static readonly type = '[ModuleContentNode] FetchModuleContentNodesByParentIdFailure';
}

export class FetchPersonalContentNodesByModuleId {
    static readonly type = '[ModuleContentNode] FetchPersonalContentNodesByModuleId';

    constructor(
        public payload: {
            moduleId: string;
            userId: string;
        }
    ) {}
}

export class FetchPersonalContentNodesByModuleIdSuccess {
    static readonly type = '[ModuleContentNode] FetchPersonalContentNodesByModuleIdSuccess';
}

export class FetchPersonalContentNodesByModuleIdFailure {
    static readonly type = '[ModuleContentNode] FetchPersonalContentNodesByModuleIdFailure';
}

export class FetchPersonalManagersContentNodesByModuleId {
    static readonly type = '[ModuleContentNode] FetchPersonalManagersContentNodesByModuleId';

    constructor(
        public payload: {
            moduleId: string;
        }
    ) {}
}

export class FetchPersonalManagersContentNodesByModuleIdSuccess {
    static readonly type = '[ModuleContentNode] FetchPersonalManagersContentNodesByModuleIdSuccess';
}

export class FetchPersonalManagersContentNodesByModuleIdFailure {
    static readonly type = '[ModuleContentNode] FetchPersonalManagersContentNodesByModuleIdFailure';
}

export class ToggleSelectedModuleContentNode {
    static readonly type = '[ModuleContentNode] ToggleSelectedModuleContentNode';

    constructor(public payload: string) {}
}

export class SelectFilteredModuleContentNodes {
    static readonly type = '[ModuleContentNode] SelectFilteredModuleContentNodes';
}

export class DeselectSelectedModuleContentNodes {
    static readonly type = '[ModuleContentNode] DeselectSelectedModuleContentNodes';
}

export class SearchModuleContentNodes {
    static readonly type = '[ModuleContentNode] SearchModuleContentNodes';

    constructor(public payload: ISearchModuleContentNodesActionPayload) {}
}

export class SearchPersonalContentNodes {
    static readonly type = '[ModuleContentNode] SearchPersonalContentNodes';

    constructor(
        public payload: {
            moduleId: string;
            userId: string;
            contentNodeId?: string;
            searchTerm?: string;
        }
    ) {}
}

export class DeleteSelectedModuleContentNodes {
    static readonly type = '[ModuleContentNode] DeleteSelectedModuleContentNodes';
}

export class UpdateSortFields {
    static readonly type = '[ModuleContentNode] UpdateSortFields';

    constructor(public payload: IUpdateSortFieldsActionPayload) {}
}
