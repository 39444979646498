import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'app-inline-rename',
    templateUrl: './inline-rename.component.html',
    styleUrls: ['./inline-rename.component.scss'],
})
export class InlineRenameComponent implements ControlValueAccessor {
    @Input()
    value: string = '';

    @Input()
    inputPlaceholder: string = '';

    @Input()
    defaultFormStyle: boolean;

    @Input()
    isValueRemovable: boolean;

    isTooltipVisible = false;

    @Output()
    update = new EventEmitter<string>();

    @ViewChild('input')
    input: ElementRef<HTMLInputElement>;

    inputFocus = false;
    previousValue: string;

    constructor() {}

    ngOnInit() {
        setTimeout(() => {
            this.input.nativeElement.onfocus = () => {
                setTimeout(() => {
                    this.isTooltipVisible = false;
                    this.inputFocus = true;
                    this.previousValue = this.input.nativeElement.value;
                }, 200);
            };

            // input loses focus before calling methods rename/cancel
            this.input.nativeElement.onblur = () => {
                setTimeout(() => {
                    this.inputFocus = false;
                    this.saveChanges();
                }, 200);
            };
        });
    }

    editInputValue() {
        this.inputFocus = !this.inputFocus;
    }

    saveChanges() {
        if (this.previousValue !== this.input.nativeElement.value) {
            this.value = this.input.nativeElement.value;
            this.inputFocus = false;

            if ((this.input.nativeElement.value && !this.isValueRemovable) || this.isValueRemovable) {
                this.update.emit(this.input.nativeElement.value);
            }
        }
        // show warn tooltip for title if empty and not for form input fields
        if (!this.input.nativeElement.value && !this.defaultFormStyle) {
            this.isTooltipVisible = true;
        }
    }

    cancelChanges() {
        this.inputFocus = false;
        this.input.nativeElement.value = this.previousValue;
    }

    writeValue(obj: any): void {
        this.input.nativeElement.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onChange() {}

    onTouched() {}
}
