export enum QuestionKind {
    TrueOrFalse = 0,
    Categorizer = 1,
    CategorizerChechkboxes = 2,
    HighlightText = 3,
    FillInTheGap = 4,
    HotSpot = 5,
    CheckBoxes = 6,
    MultipleChoice = 7,
    Sorter = 8,
    FreeText = 9,
}
