import { Module } from './module.model';
import { IModuleQuery } from '../interfaces/module.query.interface';

export class BasicModule extends Module {
    constructor(moduleQuery?: IModuleQuery) {
        super(moduleQuery);

        this._type = 'basic';
    }
}
