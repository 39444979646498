import { Component, Input, OnInit, ViewChild, Output, EventEmitter, ElementRef, Renderer2 } from '@angular/core';
import { Store, Actions, ofActionDispatched } from '@ngxs/store';
import { FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { TagColorCardService } from '../../services/tag-color-card.service';
import { MaxBrainTag } from '../../models/tag.model';
import { UpdateTag, UpdateTagSuccess } from '../../actions';

@Component({
    selector: 'app-tag-color-card',
    templateUrl: './tag-color-card.component.html',
    styleUrls: ['./tag-color-card.component.scss'],
})
export class TagColorCardComponent implements OnInit {
    @Input()
    tag: MaxBrainTag;
    @Input()
    target: HTMLElement;
    @Input()
    eventName: string;
    @Input()
    set name(value: string) {
        this.nameCtrl.setValue(value);
    }

    @Output()
    updated = new EventEmitter<MaxBrainTag>();

    @ViewChild('card')
    card: ElementRef;

    hide: boolean;
    colors: string[];
    placeholder = 'TAG.NAME';
    clicked: string;
    nameCtrl = new FormControl();

    constructor(private _renderer: Renderer2, private _service: TagColorCardService, private _store: Store, private _action$: Actions) {
        this.hide = true;
        this.eventName = this.eventName || 'click';
        this.colors = MaxBrainTag.colorsArray;

        switch (this.eventName) {
            case 'mouseenter':
                this._renderer.listen(this.card, this.eventName, (event) => {
                    this.toggle(event);
                });
                break;
            default:
                break;
        }
    }

    ngOnInit(): void {
        this._renderer.listen(this.target, this.eventName, (event) => {
            this.toggle(event);
        });
        this.clicked = this.tag.color;
    }

    toggle(event: MouseEvent): void {
        this._service.toggle(this, event);
    }

    editTag(name: string, color?: string, category?: string): void {
        this._store.dispatch(
            new UpdateTag({
                id: this.tag.id,
                name: name,
                color: color || this.tag.color,
                category: category,
            })
        );
        this._action$.pipe(ofActionDispatched(UpdateTagSuccess), take(1)).subscribe((action) => {
            this.updated.emit(action.payload);
        });
    }
}
