<ng-container *ngIf="!item.hidden">
    <!-- normal collapse -->
    <a class="nav-link" [attr.test-id]="item.id" [ngClass]="item.classes" *ngIf="!item.url && !item.function">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.url -->
    <a
        class="nav-link"
        [attr.test-id]="item.id"
        [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && !item.function"
        [routerLink]="[item.url]"
        [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a
        class="nav-link"
        [attr.test-id]="item.id"
        [ngClass]="item.classes"
        *ngIf="item.url && item.externalUrl && !item.function"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link" [attr.test-id]="item.id" [ngClass]="item.classes" *ngIf="!item.url && item.function" (click)="item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a
        class="nav-link"
        [attr.test-id]="item.id"
        [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && item.function"
        (click)="item.function()"
        [routerLink]="[item.url]"
        [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a
        class="nav-link"
        [attr.test-id]="item.id"
        [ngClass]="item.classes"
        *ngIf="item.url && item.externalUrl && item.function"
        (click)="item.function()"
        [href]="item.url"
        [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>
        <span class="nav-link-title text-sm" [translate]="item.translate"></span>
        <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate" [ngStyle]="{ 'background-color': item.badge.bg, color: item.badge.fg }">
            {{ item.badge.title }}
        </span>
        <mat-icon class="collapsable-arrow">keyboard_arrow_right</mat-icon>
    </ng-template>

    <div class="children" [ngClass]="{ open: isOpen }">
        <div class="{{ fuseConfig.layout.navbar.primaryBackground }}">
            <ng-container *ngFor="let item of item.children">
                <fuse-nav-horizontal-item *ngIf="item.type == 'item'" [item]="item"></fuse-nav-horizontal-item>
                <fuse-nav-horizontal-collapsable *ngIf="item.type == 'collapsable'" [item]="item"></fuse-nav-horizontal-collapsable>
                <fuse-nav-horizontal-collapsable *ngIf="item.type == 'group'" [item]="item"></fuse-nav-horizontal-collapsable>
            </ng-container>
        </div>
    </div>
</ng-container>
