import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ModuleEventAttendance } from 'app/library/module-event-attendance/models/module-event-attendance.model';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';

@Component({
    selector: 'app-manage-participant-attendance-dialog',
    templateUrl: './manage-participant-attendance-dialog.component.html',
    styleUrls: ['./manage-participant-attendance-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ManageParticipantAttendancesDialogComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input()
    moduleId: string;

    @Input()
    participantId: string;

    @Input()
    participantAttendances: ModuleEventAttendance[];

    constructor(public dialogRef: MatDialogRef<ManageParticipantAttendancesDialogComponent>, public route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {}
}
