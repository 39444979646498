import { Pipe, PipeTransform } from '@angular/core';
import { JobMessageStatus } from '../enums/job-message-status.enum';

@Pipe({ name: 'getJobStatIcon' })
export class GetJobStatIconPipe implements PipeTransform {
    transform(jobMessageStatus: JobMessageStatus): any {
        switch (jobMessageStatus) {
            case JobMessageStatus.Pending:
                return 'status_in_progress';
            case JobMessageStatus.Succeeded:
                return 'status_succeeded';
            case JobMessageStatus.Failed:
                return 'status_error';
            default:
                return 'status_sum';
        }
    }
}
