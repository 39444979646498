import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NgxsActionHelper } from 'app/projects/core/src/lib/services/action.helper';
import { Observable } from 'rxjs';
import { FetchTags, FetchTagsFailure, FetchTagsSuccess } from '../actions/index';
import { MaxBrainTag } from '../models/tag.model';
import { TagState } from '../models/tag.state';
import { TagsState } from '../models/tags.state';

@Injectable()
export class TagService {
    @Select(TagState.getEntities)
    tag$: Observable<MaxBrainTag[]>;

    @Select(TagsState.getFilteredEntities)
    filteredTag$: Observable<MaxBrainTag[]>;

    constructor(private _store: Store, private _actionHelper: NgxsActionHelper) {}

    getTags(): MaxBrainTag[] {
        return this._store.selectSnapshot(TagsState.getFilteredEntities);
    }

    async fetchTags(): Promise<boolean> {
        this._store.dispatch(new FetchTags());

        return await this._actionHelper.race(FetchTagsSuccess, FetchTagsFailure).toPromise();
    }
}
