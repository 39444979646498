import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureSwitchName } from '../enums/feature-switch.enum';
import { FeatureSwitchPipe } from '../pipes/feature-switch.pipe';

@Directive({
    selector: '[onlineStateIndicator]',
})
export class OnlineStateIndicatorDirective {
    constructor(private _template: TemplateRef<any>, private _viewContainerRef: ViewContainerRef, private _renderer: Renderer2, private _featureSwitchPipe: FeatureSwitchPipe) {}

    @Input('onlineStateIndicator') set onlineStateIndicator(isOnline: boolean) {
        this._viewContainerRef.createEmbeddedView(this._template);

        if (this._featureSwitchPipe.transform(FeatureSwitchName.UserOnlineState)) {
            let newElement = this._renderer.createElement('div');
            this._renderer.addClass(newElement, 'online-state-indicator');

            if (isOnline) {
                this._renderer.addClass(newElement, 'online');
            } else {
                this._renderer.addClass(newElement, 'offline');
            }

            this._renderer.appendChild(this._template.elementRef.nativeElement.parentElement, newElement);
        }
    }
}
