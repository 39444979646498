import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPasswordlessAccessAndTotalCount } from '../../interfaces/passwordless-access-pagination.query';
import { IPasswordlessAccessQuery } from '../../interfaces/passwordless-access.query.interface';
import { CreatePasswordlessAccessModel } from '../../models/create-passwordless-access';
import { EditPasswordlessAccessModel } from '../../models/edit-passwordless-access';
import { PasswordlessAccessCommand } from '../../models/passwordless-access.command.model';
import { PasswordlessAccess } from '../../models/passwordless-access.model';

@Injectable()
export class PasswordlessAccessApiService {
    entities = 'passwordless-accesses';

    constructor(private _http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) private _environment: IEnvironment) {}

    getAll(pageSize = 10, pageIndex = 1, search = ''): Observable<IPasswordlessAccessAndTotalCount> {
        let params = new HttpParams().set('page', pageIndex.toString()).set('limit', pageSize.toString());

        if (search) {
            params = params.set('filter[query]', search);
        }

        return this._http.get<IPasswordlessAccessAndTotalCount>(`${this._environment.apiUrl}/${this.entities}`, { params });
    }

    create(entity: CreatePasswordlessAccessModel): Observable<PasswordlessAccess[]> {
        const data = new PasswordlessAccessCommand(entity);

        return this._http
            .post<IPasswordlessAccessQuery[]>(`${this._environment.apiUrl}/${this.entities}/bulk`, data)
            .pipe(map((queries) => queries.map((query) => new PasswordlessAccess(query))));
    }

    update(entity: EditPasswordlessAccessModel): Observable<PasswordlessAccess[]> {
        const data = new PasswordlessAccessCommand(entity);

        return this._http
            .patch<IPasswordlessAccessQuery[]>(`${this._environment.apiUrl}/${this.entities}/bulk`, data)
            .pipe(map((queries) => queries.map((query) => new PasswordlessAccess(query))));
    }

    delete(ids: string[]): Observable<void> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                access_ids: ids,
            },
        };

        return this._http.delete<any>(`${this._environment.apiUrl}/${this.entities}/bulk`, options);
    }

    sendPasswordlessAccess(accessIds: string[], subject: string, message: string): Observable<any> {
        const data = {
            access_ids: accessIds,
            subject,
            message,
        };

        return this._http
            .post<IPasswordlessAccessQuery[]>(`${this._environment.apiUrl}/${this.entities}/send`, data)
            .pipe(map((queries) => queries.map((query) => new PasswordlessAccess(query))));
    }

    fetchPasswordlessKeywords(): Observable<string[]> {
        return this._http.get<string[]>(`${this._environment.apiUrl}/${this.entities}/email-keywords`);
    }

    generatePdf(accessIds: string[]): Observable<Blob> {
        return this._http
            .post<Blob>(
                `${this._environment.apiUrl}/${this.entities}/generate-pdf`,
                { access_ids: accessIds },
                {
                    responseType: 'blob' as 'json',
                }
            )
            .pipe(map((response) => new Blob([response], { type: 'application/pdf' })));
    }

    async fetchQrCode(id: string): Promise<Blob> {
        return this._http
            .get<Blob>(`${this._environment.apiUrl}/${this.entities}/${id}/qr-code`, {
                responseType: 'blob' as 'json',
            })
            .pipe(map((response) => new Blob([response], { type: 'image/png' })))
            .toPromise();
    }
}
