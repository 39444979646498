export enum ModuleFeatureSwitchName {
    Attendance = 'attendance',
    ParticipantTab = 'participant_tab',
    ScheduleTab = 'schedule_tab',
    CourseSelfCancellation = 'course_self_cancellation',
    PersonalDocuments = 'personal_documents',
    CollaborationTab = 'collaboration_tab',
    PasswordlessLogin = 'passwordless_login',
    ContentTab = 'content_tab',
    ManagerFiles = 'manager_files',
    PersonalDocumentsCopyrightProtection = 'personal_documents_copyright_protection',
}
