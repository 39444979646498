import { SortDirection } from '@angular/material/sort';
import { SubentityStateModel } from './subentity.state-model';

export abstract class PaginatedSubentityStateModel extends SubentityStateModel {
    searchTerm: string;
    managerSearchTerm: string;

    sortBy: string;
    sortOrder: SortDirection;

    pageSize: number;
    pageNumber: number;

    totalCount: number;

    language: string;

    constructor(identifier: string) {
        super();

        if (identifier) {
            this.searchTerm = sessionStorage.getItem(`${identifier}SearchTerm`);
            this.managerSearchTerm = sessionStorage.getItem(`${identifier}ManagerSearchTerm`);

            this.sortBy = sessionStorage.getItem(`${identifier}SortBy`);
            this.sortOrder = sessionStorage.getItem(`${identifier}SortOrder`) as SortDirection;
        }
    }
}
