import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserCreateFormDialogComponent } from '../../../../library/user/user-create/user-create-form-dialog/user-create-form-dialog.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-create-form-dialog-page',
    templateUrl: './user-create-form-dialog-page.component.html',
    styleUrls: ['./user-create-form-dialog-page.component.scss'],
})
export class UserCreateFormDialogPageComponent implements OnInit {
    constructor(private _matDialog: MatDialog, private _router: Router) {}

    ngOnInit(): void {
        const matDialog = this._matDialog.open(UserCreateFormDialogComponent, {
            disableClose: false,
            width: '680px',
        });

        matDialog.afterClosed().subscribe(() => {
            this._router.navigate([{ outlets: { modal: null } }]);
        });
    }
}
