import { MaxBrainLocation } from 'app/library/location/models/location.model';
import { INextEventQuery } from '../interfaces/next-event.query.interface';

export class NextEvent {
    id: string;
    name: string;
    start: Date;
    end: Date;
    location: MaxBrainLocation;

    constructor(nextEventQuery?: INextEventQuery) {
        if (nextEventQuery) {
            this.name = nextEventQuery.name;
            this.id = nextEventQuery.id.toString();
            this.start = new Date(nextEventQuery.start);
            this.location = nextEventQuery.location ? new MaxBrainLocation(nextEventQuery.location) : null;
        } else {
            this.id = null;
            this.name = '';
            this.start = null;
            this.end = null;
            this.location = null;
        }
    }
}
