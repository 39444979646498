import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IImportCourseQuery } from '../interfaces/import-course.action.interface';
import { IImportStatusQuery } from '../interfaces/import-status.query.interface';
import { IScormCourseQuery } from '../interfaces/scorm-course.query.interface';
import { ImportStatus } from '../models/import-status.model';
import { ScormCourse } from '../models/scorm-course.model';

@Injectable()
export class ScormService extends EntityService<any> {
    private _course$ = new BehaviorSubject<ScormCourse>(null);
    public course$ = this._course$.asObservable();

    private _courseStatus$ = new BehaviorSubject<ImportStatus>(null);
    public courseStatus$ = this._courseStatus$.asObservable();

    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'scorm');
    }

    getImportStatus(jobId: string): Observable<ImportStatus> {
        return this.http.get<IImportStatusQuery>(`${this.apiUrl}/${this.entities}/courses/import-jobs/${jobId}/status`).pipe(
            map((query) => new ImportStatus(query)),
            tap((course) => this._courseStatus$.next(course))
        );
    }

    getCourseById(courseId: string): Observable<ScormCourse> {
        return this.http.get<IScormCourseQuery>(`${this.apiUrl}/${this.entities}/courses/${courseId}`).pipe(
            map((query) => new ScormCourse(query)),
            tap((course) => this._course$.next(course))
        );
    }

    fetchAndImportCourse(payload: IImportCourseQuery): Observable<string> {
        return this.http.post<string>(`${this.apiUrl}/${this.entities}/courses/fetch-and-import`, payload);
    }

    uploadAndImportCourse(payload: IImportCourseQuery): Observable<string> {
        return this.http.post<string>(`${this.apiUrl}/${this.entities}/courses/upload-and-import`, payload);
    }

    deletePackage(uploadKey: string): Observable<string> {
        return this.http.delete<string>(`${this.apiUrl}/tus/${uploadKey}`);
    }

    removePackageFromState(): void {
        this._course$.next(null);
    }
}
