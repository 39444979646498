import { IPasswordlessModuleSettingsQuery } from 'app/library/passwordless-access/interfaces/passwordless-module-settings.query.interface';

export class PasswordlessModuleSettings {
    daysBeforeStart: number;
    daysAfterEnd: number;
    earliestStart: string;
    latestEnd: string;

    constructor(query: IPasswordlessModuleSettingsQuery) {
        this.daysBeforeStart = query.days_before_start;
        this.daysAfterEnd = query.days_after_end;
        this.earliestStart = query.earliest_start;
        this.latestEnd = query.latest_end;
    }
}
