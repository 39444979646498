export class ResetPasswordsFailure {
    static readonly type = '[User] ResetPasswordsFailure';

    constructor(public payload: string[]) {}
}

export class ResetPasswordUrlFailure {
    static readonly type = '[User] ResetPasswordUrlFailure';

    constructor() {}
}
