import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { CreateTagSuccess, DeleteTag, SetTags } from 'app/library/tag/actions';
import { AddTags } from 'app/library/tag/actions/add-entities.action';
import { MaxBrainTag } from 'app/library/tag/models/tag.model';
import { TagState } from 'app/library/tag/models/tag.state';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { DeselectSelectedTags, SearchTags, SelectFilteredTags, ToggleSelectedTag } from '../actions';
import { TagsStateModel } from './tags.state-model';

@State<TagsStateModel>({
    name: 'tags',
    defaults: new TagsStateModel(),
})
@Injectable()
export class TagsState extends SubentityState<MaxBrainTag> {
    @Selector([TagState.getEntities])
    static getFilteredEntities(state: TagsStateModel, tags: MaxBrainTag[]): MaxBrainTag[] {
        if (!tags) {
            return null;
        }

        return tags
            .filter((item) => state.filtered.indexOf(item.id) !== -1)
            .map((item) => {
                return [state.list.indexOf(item.id), item];
            })
            .sort((a: [number, MaxBrainTag], b: [number, MaxBrainTag]) => {
                return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
            })
            .map((x: [number, MaxBrainTag]) => x[1]);
    }

    @Selector()
    static getSelectedEntityIds(state: TagsStateModel): string[] {
        return state.selected;
    }

    @Selector()
    static getSearchTerm(state: TagsStateModel): string {
        return state.searchTerm;
    }

    constructor(private store: Store) {
        super(null, 0);

        this._propstoSearch = [...MaxBrainTag.props_to_search];
    }

    @Action(AddTags)
    addEntities(ctx: StateContext<TagsStateModel>, action: AddTags): void {
        const tags = this.store.selectSnapshot(TagState.getEntities) || [];

        this._patchSubentities(ctx, { payload: { subentityIds: action.payload.map((entity) => entity.id) } }, tags);
    }

    @Action(CreateTagSuccess)
    createEntity(ctx: StateContext<TagsStateModel>, action: CreateTagSuccess): void {
        const tags = this.store.selectSnapshot(TagState.getEntities) || [];

        this._patchSubentities(ctx, { payload: { subentityIds: [action.payload.id] } }, tags);
    }

    @Action(DeleteTag)
    deleteEntity({ getState, patchState }: StateContext<TagsStateModel>, { payload }: DeleteTag): void {
        const state = getState();

        patchState({
            list: [...state.list.filter((subentityId) => subentityId !== payload)],
            selected: [...state.selected.filter((subentityId) => subentityId !== payload)],
            filtered: [...state.filtered.filter((subentityId) => subentityId !== payload)],
        });
    }

    @Action(SetTags)
    setEntities(ctx: StateContext<TagsStateModel>, action: SetTags): void {
        this._setSubentities(ctx, action, null);
    }

    @Action(ToggleSelectedTag)
    toggleSelect(ctx: StateContext<TagsStateModel>, action: ToggleSelectedTag): void {
        this._toggleSelectedSubentity(ctx, action);
    }

    @Action(SelectFilteredTags)
    selectAll(ctx: StateContext<TagsStateModel>): void {
        this._selectFilteredSubentities(ctx);
    }

    @Action(DeselectSelectedTags)
    deselectAll(ctx: StateContext<TagsStateModel>): void {
        this._deselectSelectedSubentities(ctx);
    }

    @Action(SearchTags)
    search(ctx: StateContext<TagsStateModel>, action: SearchTags): void {
        const tags = this.store.selectSnapshot(TagState.getEntities);

        this._searchSubentities(ctx, action, tags);
    }
}
