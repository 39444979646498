import { ModuleUserExtended } from '../models/module-user.extended.model';

export class AddModuleUsers {
    static readonly type = '[ModuleUser] AddModuleUsers';

    constructor(public payload: ModuleUserExtended[]) {}
}
export class AddPinnedModuleUsers {
    static readonly type = '[ModuleUser] AddPinnedModuleUsers';

    constructor(public payload: ModuleUserExtended[]) {}
}
