import { Module } from 'app/library/module/models/module.model';
import { MaxBrainNavigation } from './maxbrain-navigation';

export const navigation: MaxBrainNavigation[] = [
    {
        id: 'home',
        title: 'Administration',
        translate: 'NAV.HOME.TITLE',
        default: false,
        type: 'item',
        svgIcon: 'dashboard_icon',
        classes: 'header-home',
        url: `/admin`,
    },
    {
        id: 'feed',
        title: 'Feed',
        translate: 'NAV.FEED.TITLE',
        default: true,
        type: 'item',
        svgIcon: 'newspaper',
        classes: 'feed',
        url: `/feed`,
    },
    {
        id: 'manage-modules',
        title: 'Manage modules',
        translate: 'NAV.MANAGE_MODULES.TITLE',
        type: 'item',
        svgIcon: 'groups',
        url: `/manage-modules`,
    },
    {
        id: 'grow',
        title: 'Home',
        translate: 'NAV.ENGAGE_HOMESCREEN.TITLE',
        default: false,
        type: 'item',
        svgIcon: 'dashboard_icon',
        url: `/grow`,
    },
    {
        id: 'my-modules',
        title: 'My learnings',
        translate: 'NAV.MY_LEARNINGS.TITLE',
        default: true,
        type: 'item',
        icon: 'school',
        classes: 'header-my-learnings',
        url: `/${Module.label_plural}`,
    },
    {
        id: 'calendar',
        title: 'Calendar',
        translate: 'NAV.CALENDAR.TITLE',
        default: true,
        type: 'item',
        icon: 'calendar_today',
        classes: 'header-calendar',
        url: `/calendar`,
    },
    {
        id: 'inbox',
        title: 'Inbox',
        translate: 'NAV.INBOX.TITLE',
        type: 'item',
        icon: 'inbox',
        url: `/inbox`,
    },
    {
        id: 'groups',
        title: 'Groups',
        translate: 'NAV.GROUPS.TITLE',
        type: 'item',
        svgIcon: 'groups',
        url: `/groups`,
    },
    {
        id: 'member-area',
        title: 'Member area',
        translate: 'NAV.MEMBER_AREA.TITLE',
        type: 'item',
        svgIcon: 'groups',
        url: `/member-area`,
    },
    {
        id: 'know-how',
        title: 'Know How',
        translate: 'NAV.KNOW_HOW.TITLE',
        type: 'item',
        icon: 'import_contacts',
        url: `/know-how`,
    },
    {
        id: 'teams',
        title: 'My Teams',
        translate: 'NAV.MY_TEAMS.TITLE',
        type: 'item',
        svgIcon: 'team',
        url: `/my-teams`,
    },
    {
        id: 'empty',
        title: 'Empty',
        translate: '',
        default: true,
        type: 'item',
    },
    {
        id: 'background-tasks',
        title: 'Monitor progress',
        translate: 'NAV.MY_BACKGROUND_TASKS.TITLE',
        type: 'item',
        action: 'openAsyncDrawer',
        default: false,
        icon: 'menu_open',
    },
    {
        id: 'new',
        title: 'New',
        translate: 'NAV.NEW.TITLE',
        type: 'group',
        icon: 'add_circle',
        classes: 'left header-new-dropdown',
        children: [
            {
                id: 'new-module',
                title: 'New Module',
                translate: 'NAV.NEW.MODULE.TITLE',
                type: 'item',
                svgIcon: 'course',
                classes: 'header-new-module',
                url: { outlets: { modal: 'new-module/basic' } } as any,
            },
            {
                id: 'new-private-module',
                title: 'New Private Module',
                translate: 'NAV.NEW.PRIVATE_MODULE.TITLE',
                type: 'item',
                svgIcon: 'course_private',
                classes: 'header-new-private module',
                url: { outlets: { modal: 'new-module/private' } } as any,
            },
            {
                id: 'new-eLearning-module',
                title: 'New E-Learning Module',
                translate: 'NAV.NEW.ELEARNING_MODULE.TITLE',
                type: 'item',
                svgIcon: 'e_learning',
                classes: 'header-new-elearning',
                url: { outlets: { modal: 'new-module/e-learning' } } as any,
            },
            {
                id: 'new-user',
                title: 'New User',
                translate: 'NAV.NEW.USER.TITLE',
                type: 'item',
                icon: 'person_add',
                classes: 'header-new-user',
                url: { outlets: { modal: 'new-user' } } as any,
            },
            //  @TODO : we didn't use the modal routing to display new import user
            //          because of depedency conflit with the service, modules configurations see with @Nikola or @Math for more details
            //          Look for another way to display the dialog import new user
            // {
            //     id: 'new-import-user',
            //     title: 'New Import User',
            //     translate: 'NAV.NEW.IMPORT.USER.TITLE',
            //     type: 'item',
            //     icon: 'cloud_upload',
            //     classes: 'header-new-import-user',
            //     url: { outlets: { modal: 'new-import-user' } } as any
            // }
        ],
    },
    {
        id: 'nussbaum-menu',
        title: 'Nussbaum menu',
        translate: 'NAV.NUSSBAUM.MAIN_MENU',
        type: 'group',
        svgIcon: 'nussbaum_menu',
        classes: 'nussbaum-menu',
        children: [
            {
                id: 'online-shop',
                title: 'Online shop',
                translate: 'NAV.NUSSBAUM.ONLINE_SHOP',
                type: 'item',
                svgIcon: 'nussbaum_shop',
                url: 'https://www.nussbaum.ch/de/online-shop.html',
                externalUrl: true,
                openInNewTab: true,
            },
            {
                id: 'konfigurator',
                title: 'Konfigurator',
                translate: 'NAV.NUSSBAUM.KONFIGURATOR',
                type: 'item',
                svgIcon: 'nussbaum_konfigurator',
                url: 'https://www.nussbaum.ch/de/configurator.html',
                externalUrl: true,
                openInNewTab: true,
            },
            {
                id: 'prüfdatenbank',
                title: 'Prüfdatenbank',
                translate: 'NAV.NUSSBAUM.PRUFDATENBANK',
                type: 'item',
                svgIcon: 'nussbaum_prufdatenbank',
                url: 'https://pruefdaten.nussbaum.ch/de',
                externalUrl: true,
                openInNewTab: true,
            },
        ],
    },
];
