import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
    transform(list: any[], property: string = null): any {
        return list
            ? list.slice().sort((a, b) => {
                  return property ? (a[property] > b[property] ? 1 : b[property] > a[property] ? -1 : 0) : a > b ? 1 : b > a ? -1 : 0;
              })
            : [];
    }
}
