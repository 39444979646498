import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { EntityService } from 'app/projects/entity/src/lib/services/entity.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IExperienceUserQuery } from '../interfaces/experience-user.query.interface';
import { IManageExperienceUsersActionPayload } from '../interfaces/experience-users-action.action-payload.interface';
import { IManagePutExperienceUsersActionPayload } from '../interfaces/experience-users-put-action.interface';
import { ExperienceUserCommand } from '../models/experience-user.command.model';
import { ExperienceUserExtended } from '../models/experience-user.extended.model';
import { ExperienceUser } from '../models/experience-user.model';

@Injectable()
export class ExperienceUserApiService extends EntityService<IExperienceUserQuery, ExperienceUserCommand> {
    constructor(http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) environment: IEnvironment) {
        super(http, environment.apiUrl, 'experiences-users');
    }

    getAll(): Observable<ExperienceUser[]> {
        return this._getAll().pipe(map((queries) => queries.map((query) => new ExperienceUserExtended(query))));
    }

    read(id: string): Observable<ExperienceUser> {
        return this._read(id).pipe(map((query) => new ExperienceUserExtended(query)));
    }

    update(entity: ExperienceUser): Observable<ExperienceUser> {
        const data = new ExperienceUserCommand(entity);

        return this._update(entity.id, data).pipe(map((query) => new ExperienceUserExtended(query)));
    }

    getAllByExperienceId(moduleId: string): Observable<ExperienceUser[]> {
        return this.http
            .get<IExperienceUserQuery[]>(`${this.apiUrl}/${this.entities}?experience=${moduleId}`)
            .pipe(map((queries) => queries.map((query) => new ExperienceUserExtended(query))));
    }

    getAllByUserId(userId: string): Observable<ExperienceUser[]> {
        return this.http
            .get<IExperienceUserQuery[]>(`${this.apiUrl}/${this.entities}?user=${userId}`)
            .pipe(map((queries) => queries.map((query) => new ExperienceUserExtended(query))));
    }

    addUsersToExperiences({
        experience,
        users,
    }: IManageExperienceUsersActionPayload): Observable<{ newly_assigned: ExperienceUserExtended[]; already_assigned: ExperienceUserExtended[] }> {
        const data = {
            experience: experience,
            users: users,
        };

        return this.http.put<IManagePutExperienceUsersActionPayload>(`${this.apiUrl}/engage/experiences-users`, data).pipe(
            map((queries) => {
                return {
                    newly_assigned: queries.newly_assigned.map((query) => new ExperienceUserExtended(query)),
                    already_assigned: queries.already_assigned.map((query) => new ExperienceUserExtended(query)),
                };
            })
        );
    }

    assignUserToExperience({ experience, users }: IManageExperienceUsersActionPayload): Observable<ExperienceUserExtended> {
        const data = {
            experience: experience,
            users: users,
        };

        return this.http
            .put<IManagePutExperienceUsersActionPayload>(`${this.apiUrl}/engage/experiences-users`, data)
            .pipe(map((query) => new ExperienceUserExtended(query.newly_assigned[0])));
    }

    removeUsersFromExperiences({ experienceUsers }: { experienceUsers: [{ id: string }] }): Observable<void> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: {
                experience_users: experienceUsers,
            },
        };

        return this.http.delete<void>(`${this.apiUrl}/engage/experiences-users`, options);
    }

    moveUsersFromExperiences({ target, selectedUserIds }: any): Observable<void> {
        const data = {
            target_experience: target,
            experience_users: selectedUserIds,
        };

        return this.http.put<void>(`${this.apiUrl}/engage/experience-users/move`, data);
    }
}
