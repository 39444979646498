import { HttpErrorResponse } from '@angular/common/http';
import { ICreateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/create-entity-failure-payload.interface';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { IBulkUpdateCopyProtectionQuery } from '../interfaces/bulk-update-protection.query.interface';
import { ICreateFileQuery } from '../interfaces/create-file.interface';
import { ICreateFolderActionPayload } from '../interfaces/create-folder.action-payload.interface';
import { IPublishContentNodesActionPayload } from '../interfaces/publish-content-nodes.action-payload.interface';
import { IUpdateFileActionPayload } from '../interfaces/update-file.action-payload.interface';
import { IUploadFilesActionPayload } from '../interfaces/upload-files.action-payload.interface';
import { IUploadZipActionPayload } from '../interfaces/upload-zip.action-payload.interface';
import { ContentNode } from '../models/content-node.model';

export class SetContentNode {
    static readonly type = '[ContentNode] SetContentNode';

    constructor(public payload: ContentNode) {}
}

export class UnsetContentNode {
    static readonly type = '[ContentNode] UnsetContentNode';
}

export class FetchContentNode {
    static readonly type = '[ContentNode] FetchContentNode';

    constructor(public payload: { contentNodeId: string; setEntity?: boolean }) {
        if (this.payload.setEntity !== false) {
            this.payload.setEntity = true;
        }
    }
}

export class FetchContentNodeSuccess {
    static readonly type = '[ContentNode] FetchContentNodeSuccess';

    constructor(public payload: ContentNode) {}
}

export class FetchContentNodeFailure {
    static readonly type = '[ContentNode] FetchContentNodeFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class UploadFiles {
    static readonly type = '[ContentNode] UploadFiles';

    constructor(public payload: IUploadFilesActionPayload) {}
}

export class UploadFileSuccess {
    static readonly type = '[ContentNode] UploadFileSuccess';

    constructor(public payload: ContentNode) {}
}

export class UploadFileFailure {
    static readonly type = '[ContentNode] UploadFileFailure';

    constructor(public payload: { node: string; file: string; response: HttpErrorResponse }) {}
}

export class UploadPersonalFiles {
    static readonly type = '[ContentNode] UploadPersonalFiles';

    constructor(public payload: IUploadFilesActionPayload) {}
}

export class UploadFilesFinished {
    static readonly type = '[ContentNode] UploadFilesFinished';

    constructor(
        public payload: {
            count: number;
            total: number;
        }
    ) {}
}

export class UploadFilesFailure {
    static readonly type = '[ContentNode] UploadFilesFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class UploadZip {
    static readonly type = '[ContentNode] UploadZip';

    constructor(public payload: IUploadZipActionPayload) {}
}

export class UploadZipSuccess {
    static readonly type = '[ContentNode] UploadZipSuccess';

    constructor(public payload: { node: string; file: string }) {}
}

export class UploadZipFailure {
    static readonly type = '[ContentNode] UploadZipFailure';

    constructor(public payload: { node: string; file: string }) {}
}

export class PublishContentNodes {
    static readonly type = '[ContentNode] PublishContentNodes';

    constructor(public payload: IPublishContentNodesActionPayload) {}
}

export class PublishContentNodesSuccess {
    static readonly type = '[ContentNode] PublishContentNodesSuccess';

    constructor(public payload: { jobId: string; nodeId: string }) {}
}

export class PublishContentNodesFailure {
    static readonly type = '[ContentNode] PublishContentNodesFailure';

    constructor(
        public payload: {
            response: HttpErrorResponse;
        }
    ) {}
}

export class CreateFolder {
    static readonly type = '[ContentNode] CreateFolder';

    constructor(public payload: ICreateFolderActionPayload) {}
}

export class CreateFolderSuccess {
    static readonly type = '[ContentNode] CreateFolderSuccess';

    constructor(public payload: ContentNode) {}
}

export class CreateFolderFailure {
    static readonly type = '[ContentNode] CreateFolderFailure';

    constructor(public payload: ICreateEntityFailureActionPayload) {}
}

export class RenameContentNode {
    static readonly type = '[ContentNode] RenameContentNode';

    constructor(public payload: ContentNode) {}
}

export class RenamePersonalContentNode {
    static readonly type = '[ContentNode] RenamePersonalContentNode';

    constructor(public payload: ContentNode) {}
}

export class RenameContentNodeSuccess {
    static readonly type = '[ContentNode] RenameContentNodeSuccess';

    constructor(public payload: ContentNode) {}
}

export class RenameContentNodeFailure {
    static readonly type = '[ContentNode] RenameContentNodeFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class UpdateContentNodeMetadata {
    static readonly type = '[ContentNode] UpdateContentNodeMetadata';

    constructor(
        public payload: {
            contentNodeId: string;
            allowDistribution: boolean;
        }
    ) {}
}

export class UpdateContentNodeMetadataSuccess {
    static readonly type = '[ContentNode] UpdateContentNodeMetadataSuccess';

    constructor(public payload: ContentNode) {}
}

export class UpdateContentNodeMetadataFailure {
    static readonly type = '[ContentNode] UpdateContentNodeMetadataFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class UpdateContentNode {
    static readonly type = '[ContentNode] UpdateContentNode';

    constructor(public payload: IUpdateFileActionPayload) {}
}

export class UpdateContentNodeSuccess {
    static readonly type = '[ContentNode] UpdateContentNodeSuccess';

    constructor(public payload: ContentNode) {}
}

export class UpdateContentNodeFailure {
    static readonly type = '[ContentNode] UpdateContentNodeFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class UpdatePersonalContentNode {
    static readonly type = '[ContentNode] UpdatePersonalContentNode';

    constructor(public payload: IUpdateFileActionPayload) {}
}

export class DeleteContentNode {
    static readonly type = '[ContentNode] DeleteContentNode';

    constructor(public payload: string) {}
}

export class DeletePersonalContentNode {
    static readonly type = '[ContentNode] DeletePersonalContentNode';

    constructor(
        public payload: {
            nodeId: string;
        }
    ) {}
}

export class DeleteContentNodeSuccess {
    static readonly type = '[ContentNode] DeleteContentNodeSuccess';

    constructor(public payload: string) {}
}

export class DeleteContentNodeFailure {
    static readonly type = '[ContentNode] DeleteContentNodeFailure';

    constructor(public payload: string) {}
}

export class SetContentNodes {
    static readonly type = '[ContentNode] SetContentNodes';

    constructor(public payload: ContentNode[]) {}
}

export class FetchContentNodes {
    static readonly type = '[ContentNode] FetchContentNodes';
}

export class FetchContentNodesSuccess {
    static readonly type = '[ContentNode] FetchContentNodesSuccess';
}

export class FetchContentNodesFailure {
    static readonly type = '[ContentNode] FetchContentNodesFailure';
}

export class ToggleSelectedContentNode {
    static readonly type = '[ContentNode] ToggleSelectedContentNode';

    constructor(public payload: string) {}
}

export class SelectFilteredContentNodes {
    static readonly type = '[ContentNode] SelectFilteredContentNodes';
}

export class DeselectSelectedContentNodes {
    static readonly type = '[ContentNode] DeselectSelectedContentNodes';
}

export class SearchContentNodes {
    static readonly type = '[ContentNode] SearchContentNodes';

    constructor(public payload: string) {}
}

export class DeleteSelectedContentNodes {
    static readonly type = '[ContentNode] DeleteSelectedContentNodes';
}

export class CreateEmptyFile {
    static readonly type = '[ContentNode] CreateEmptyFile';

    constructor(public payload: ICreateFileQuery) {}
}

export class CreateEmptyFileSuccess {
    static readonly type = '[ContentNode] CreateEmptyFileSuccess';

    constructor(public payload: ContentNode) {}
}

export class CreateEmptyFileFailure {
    static readonly type = '[ContentNode] CreateEmptyFileFailure';

    constructor(public payload: ICreateEntityFailureActionPayload) {}
}

export class BulkUpdateCopyProtection {
    static readonly type = '[ContentNode] BulkUpdateCopyProtection';

    constructor(public payload: IBulkUpdateCopyProtectionQuery) {}
}

export class BulkUpdateCopyProtectionSuccess {
    static readonly type = '[ContentNode] BulkUpdateCopyProtectionSuccess';
}

export class BulkUpdateCopyProtectionFailure {
    static readonly type = '[ContentNode] BulkUpdateCopyProtectionFailure';
}
