export class ResetPassword {
    static readonly type = '[User] ResetPassword';

    constructor(public payload: string) {}
}
export class ResetUserPasswordUrl {
    static readonly type = '[User] ResetUserPasswordUrl';

    constructor(public payload: string) {}
}

export class ResetPasswordUrl {
    static readonly type = '[User] ResetPasswordUrl';

    constructor(public payload: string[]) {}
}
