<app-dialog-header [title]="title" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content class="p-24" fxLayout="column" fxLayoutAlign="start">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree mat-elevation-z2" *ngIf="listTree.length; else noTeams">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
          This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p p-16 pr-24">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <button class="mr-8" mat-icon-button>
                        <mat-icon class="circle">circle</mat-icon>
                    </button>
                    <div class="custom-avatar-icon mr-8" fxLayoutAlign="center center">
                        <mat-icon svgIcon="team"></mat-icon>
                    </div>
                    <h3>{{ node.name }}</h3>
                </div>

                <app-flat-button
                    [label]="'GENERAL.BUTTON.SELECT'"
                    (onClick)="onSelect(node)"
                    [disabled]="selectedNode && selectedNode.id !== node.id"
                    *ngIf="!node.isSelected; else unselect"
                ></app-flat-button>

                <ng-template #unselect>
                    <app-white-button [label]="'GENERAL.BUTTON.UNSELECT'" (onClick)="onDeselect(node)"></app-white-button>
                </ng-template>
            </div>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-tree-node p-16 pr-24">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <button mat-icon-button class="mr-8" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                    </button>
                    <div class="custom-avatar-icon mr-8" fxLayoutAlign="center center">
                        <mat-icon svgIcon="team"></mat-icon>
                    </div>
                    <h3>{{ node.name }}</h3>
                </div>

                <app-flat-button
                    [label]="'GENERAL.BUTTON.SELECT'"
                    (onClick)="onSelect(node)"
                    [disabled]="selectedNode && selectedNode.id !== node.id"
                    *ngIf="!node.isSelected; else unselect"
                ></app-flat-button>

                <ng-template #unselect>
                    <app-white-button [label]="'GENERAL.BUTTON.UNSELECT'" (onClick)="onDeselect(node)"></app-white-button>
                </ng-template>
            </div>
            <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
            <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                <ng-container matTreeNodeOutlet></ng-container>
            </div>
        </mat-nested-tree-node>
    </mat-tree>

    <ng-template #noTeams>
        <div fxLayout="column" fxLayoutAlign="center center" class="p-32">
            <mat-icon class="participant-view-illustration" svgIcon="team_illustration"></mat-icon>
            <h3 class="mt-8 mb-16">
                {{ 'TEAMS.DIALOG.MOVE_TO.NO_TEAMS' | translate }}
            </h3>
        </div>
    </ng-template>
</div>
<div class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button [disabled]="!selectedNode" [label]="'GENERAL.LABEL.CLEAR_SELECTION'" (onClick)="onClearSelection()"></app-white-button>

    <div class="example-spacer"></div>
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>
    <app-flat-button [label]="'GENERAL.BUTTON.SAVE'" (onClick)="onSave()" [disabled]="!selectedNode"></app-flat-button>
</div>
