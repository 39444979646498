import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Actions, Selector, State, StateContext, ofActionDispatched } from '@ngxs/store';
import {
    CreateFolder,
    CreateFolderSuccess,
    DeleteContentNodeSuccess,
    UploadFileFailure,
    UploadFileSuccess,
    UploadFiles,
    UploadPersonalFiles,
} from 'app/library/content-node/actions';
import { AddContentNodes } from 'app/library/content-node/actions/add-entities.action';
import { ContentNode } from 'app/library/content-node/models/content-node.model';
import { ContentNodeState } from 'app/library/content-node/models/content-node.state';
import { ContentNodeApiService } from 'app/library/content-node/services/content-node.api-service';
import { SectionApiService } from 'app/library/section/services/section-api.service';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { map, take, tap } from 'rxjs/operators';
import {
    DeselectSelectedModuleContentNodes,
    FetchGroupsContentNodes,
    FetchGroupsContentNodesSuccess,
    FetchModuleContentNode,
    FetchModuleContentNodeFailure,
    FetchModuleContentNodeSuccess,
    FetchModuleContentNodesByModuleId,
    FetchModuleContentNodesByModuleIdFailure,
    FetchModuleContentNodesByModuleIdSuccess,
    FetchModuleContentNodesByParentId,
    FetchModuleContentNodesByParentIdFailure,
    FetchModuleContentNodesByParentIdSuccess,
    FetchModuleContentNodesBySectionId,
    FetchModuleContentNodesBySectionIdFailure,
    FetchModuleContentNodesBySectionIdSuccess,
    FetchModuleGroupsContentNodes,
    FetchModuleGroupsContentNodesSuccess,
    FetchModuleSectionContentNodesByModuleId,
    FetchModuleSectionContentNodesByModuleIdFailure,
    FetchModuleSectionContentNodesByModuleIdSuccess,
    FetchPersonalContentNodesByModuleId,
    FetchPersonalContentNodesByModuleIdFailure,
    FetchPersonalContentNodesByModuleIdSuccess,
    FetchPersonalManagersContentNodesByModuleId,
    FetchPersonalManagersContentNodesByModuleIdFailure,
    FetchPersonalManagersContentNodesByModuleIdSuccess,
    FetchPrivateContentNodeSuccess,
    FetchPrivateContentNodesByModuleId,
    FetchPrivateContentNodesByModuleIdFailure,
    FetchPrivateContentNodesByModuleIdSuccess,
    FetchPrivateSectionContentNodesByModuleId,
    FetchPrivateSectionContentNodesByModuleIdFailure,
    FetchPrivateSectionContentNodesByModuleIdSuccess,
    FetchSharedContentNodeSuccess,
    FetchSharedContentNodesByModuleId,
    FetchSharedContentNodesByModuleIdFailure,
    FetchSharedContentNodesByModuleIdSuccess,
    RefreshModuleContentNode,
    RefreshModuleContentNodeFailure,
    RefreshModuleContentNodeSuccess,
    SearchModuleContentNodes,
    SearchPersonalContentNodes,
    SelectFilteredModuleContentNodes,
    SetModuleContentNode,
    SetModuleContentNodes,
    ToggleSelectedModuleContentNode,
    UnsetModuleContentNode,
    UnsetModuleContentNodes,
    UpdateSortFields,
} from '../actions';
import { ModuleContentNodesStateModel } from './module-content-nodes.state-model';

@State<ModuleContentNodesStateModel>({
    name: 'moduleContentNodes',
    defaults: new ModuleContentNodesStateModel(),
})
@Injectable()
export class ModuleContentNodesState extends SubentityState<ContentNode> {
    @Selector()
    static getEntity(state: ModuleContentNodesStateModel): ContentNode {
        return state.item;
    }

    @Selector()
    static getSearchTerm(state: ModuleContentNodesStateModel): string {
        return state.searchTerm;
    }

    @Selector([ContentNodeState.getEntities])
    static getEntities(state: ModuleContentNodesStateModel, allContentNodes: ContentNode[]): ContentNode[] {
        if (!allContentNodes) {
            return null;
        }

        const folders: ContentNode[] = [];
        const files: ContentNode[] = [];

        allContentNodes.forEach((contentNode) => {
            if (state.list.indexOf(contentNode.id) !== -1) {
                if (contentNode.isFolder) {
                    folders.push(contentNode);
                } else {
                    files.push(contentNode);
                }
            }
        });

        return [
            ...folders.sort((a, b) => {
                const textA = a.name.toLowerCase();
                const textB = b.name.toLowerCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            }),
            ...files.sort((a, b) => {
                const textA = a.name.toLowerCase();
                const textB = b.name.toLowerCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            }),
        ];
    }

    @Selector([ContentNodeState.getEntities])
    static getFilteredEntities(state: ModuleContentNodesStateModel, contentNodes: ContentNode[]): ContentNode[] {
        if (!contentNodes) {
            return null;
        }

        return contentNodes.filter((contentNode) => state.filtered.indexOf(contentNode.id) !== -1);
    }

    @Selector()
    static getSelectedEntityIds(state: ModuleContentNodesStateModel): string[] {
        return state.selected;
    }

    @Selector()
    static isFetchingList(state: ModuleContentNodesStateModel): boolean {
        return state.fetchingList;
    }

    constructor(private contentNodeService: ContentNodeApiService, private sectionApiService: SectionApiService, private action$: Actions) {
        super(null, 30);

        this._propstoSearch = [...ContentNode.props_to_search];
    }

    @Action(SetModuleContentNode)
    setModuleContentNode({ dispatch, patchState }: StateContext<ModuleContentNodesStateModel>, { payload }: SetModuleContentNode): void {
        dispatch(new AddContentNodes([payload]));

        patchState({
            item: payload,
        });
    }

    @Action(UnsetModuleContentNode)
    unsetModuleContentNode({ patchState }: StateContext<ModuleContentNodesStateModel>): void {
        patchState({
            item: null,
        });
    }

    @Action(RefreshModuleContentNode)
    refreshModuleContentNode({ dispatch }: StateContext<ModuleContentNodesStateModel>, { payload }: RefreshModuleContentNode): void {
        this.contentNodeService.metadata(payload).subscribe(
            (contentNode) => {
                dispatch([new AddContentNodes([contentNode]), new RefreshModuleContentNodeSuccess(contentNode)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(
                    new RefreshModuleContentNodeFailure({
                        response,
                        entityId: payload,
                    })
                );
            }
        );
    }

    @Action(FetchModuleContentNode)
    fetchModuleContentNode({ dispatch }: StateContext<ModuleContentNodesStateModel>, { payload }: FetchModuleContentNode): void {
        this.contentNodeService.metadata(payload).subscribe(
            (contentNode) => {
                dispatch([new SetModuleContentNode(contentNode), new FetchModuleContentNodeSuccess(contentNode)]);
            },
            (response: HttpErrorResponse) => {
                dispatch(
                    new FetchModuleContentNodeFailure({
                        response,
                        entityId: payload,
                    })
                );
            }
        );
    }

    @Action(SetModuleContentNodes)
    setAll(ctx: StateContext<ModuleContentNodesStateModel>, action: SetModuleContentNodes): void {
        this._setSubentities(ctx, action, AddContentNodes);
    }

    @Action(UnsetModuleContentNodes)
    unsetAll(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._unsetSubentities(ctx);
    }

    @Action(FetchPrivateContentNodesByModuleId)
    fetchPrivateContentNodesByModuleId(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchPrivateContentNodesByModuleId): void {
        this._fetchSubentities(
            ctx,
            action,
            SetModuleContentNodes,
            FetchPrivateContentNodesByModuleIdSuccess,
            FetchPrivateContentNodesByModuleIdFailure,
            this.contentNodeService.private(action.payload).pipe(
                tap((response) => {
                    ctx.dispatch([new SetModuleContentNode(response.parent), new FetchPrivateContentNodeSuccess(response.parent)]);
                }),
                map((response) => response.children)
            )
        );
    }

    @Action(FetchPrivateContentNodesByModuleIdSuccess)
    fetchPrivateContentNodesByModuleIdSuccess(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchPrivateContentNodesByModuleIdFailure)
    fetchPrivateContentNodesByModuleIdFailure(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(FetchPrivateSectionContentNodesByModuleId)
    fetchSectionContentNodesByModuleId(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchPrivateSectionContentNodesByModuleId): void {
        this._fetchSubentities(
            ctx,
            action,
            SetModuleContentNodes,
            FetchPrivateSectionContentNodesByModuleIdSuccess,
            FetchPrivateSectionContentNodesByModuleIdFailure,
            this.contentNodeService.privateSections(action.payload).pipe(map((response) => response.children))
        );
    }

    @Action(FetchPrivateSectionContentNodesByModuleIdSuccess)
    fetchSectionContentNodesByModuleIdSuccess(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchPrivateSectionContentNodesByModuleIdFailure)
    fetchSectionContentNodesByModuleIdFailure(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(FetchSharedContentNodesByModuleId)
    fetchSharedContentNodesByModuleId(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchSharedContentNodesByModuleId): void {
        this._fetchSubentities(
            ctx,
            action,
            SetModuleContentNodes,
            FetchSharedContentNodesByModuleIdSuccess,
            FetchSharedContentNodesByModuleIdFailure,
            this.contentNodeService.shared(action.payload).pipe(
                tap((response) => {
                    ctx.dispatch([new SetModuleContentNode(response.parent), new FetchSharedContentNodeSuccess(response.parent)]);
                }),
                map((response) => response.children)
            )
        );
    }

    @Action(FetchModuleGroupsContentNodes)
    fetchModuleGroupsContentNodes(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchModuleGroupsContentNodes): void {
        this._fetchSubentities(
            ctx,
            action,
            SetModuleContentNodes,
            FetchSharedContentNodesByModuleIdSuccess,
            FetchSharedContentNodesByModuleIdFailure,
            this.contentNodeService.moduleGroups(action.payload).pipe(
                tap((response) => {
                    ctx.dispatch([new SetModuleContentNode(response.parent), new FetchModuleGroupsContentNodesSuccess(response.parent)]);
                }),
                map((response) => response.children)
            )
        );
    }

    @Action(FetchGroupsContentNodes)
    fetchGroupsContentNodes(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchGroupsContentNodes): void {
        this._fetchSubentities(
            ctx,
            action,
            SetModuleContentNodes,
            FetchSharedContentNodesByModuleIdSuccess,
            FetchSharedContentNodesByModuleIdFailure,
            this.contentNodeService.groups(action.payload).pipe(
                tap((response) => {
                    ctx.dispatch([new SetModuleContentNode(response.parent), new FetchGroupsContentNodesSuccess(response.parent)]);
                }),
                map((response) => response.children)
            )
        );
    }

    @Action(FetchSharedContentNodesByModuleIdSuccess)
    fetchSharedContentNodesByModuleIdSuccess(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchSharedContentNodesByModuleIdFailure)
    fetchSharedContentNodesByModuleIdFailure(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(FetchModuleContentNodesByModuleId)
    fetchModuleContentNodesByModuleId(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchModuleContentNodesByModuleId): void {
        this._fetchSubentities(
            ctx,
            action,
            SetModuleContentNodes,
            FetchModuleContentNodesByModuleIdSuccess,
            FetchModuleContentNodesByModuleIdFailure,
            this.contentNodeService.module(action.payload).pipe(
                tap((response) => {
                    ctx.dispatch([new SetModuleContentNode(response.parent), new FetchModuleContentNodeSuccess(response.parent)]);
                }),
                map((response) => response.children)
            )
        );
    }

    @Action(FetchModuleContentNodesByModuleIdSuccess)
    fetchModuleContentNodesByModuleIdSuccess(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchModuleContentNodesByModuleIdFailure)
    fetchModuleContentNodesByModuleIdFailure(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(FetchModuleContentNodesBySectionId)
    fetchModuleContentNodesBySectionId(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchModuleContentNodesBySectionId): void {
        this._fetchSubentities(
            ctx,
            action,
            SetModuleContentNodes,
            FetchModuleContentNodesBySectionIdSuccess,
            FetchModuleContentNodesBySectionIdFailure,
            this.sectionApiService.moduleContentNodes(action.payload).pipe(
                tap((response) => {
                    ctx.dispatch([new SetModuleContentNode(response.parent), new FetchModuleContentNodeSuccess(response.parent)]);
                }),
                map((response) => response.children)
            )
        );
    }

    @Action(FetchModuleContentNodesBySectionIdSuccess)
    fetchModuleContentNodesBySectionIdSuccess(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchModuleContentNodesBySectionIdFailure)
    fetchModuleContentNodesBySectionIdFailure(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(FetchModuleSectionContentNodesByModuleId)
    fetchModuleSectionContentNodesByModuleId(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchModuleSectionContentNodesByModuleId): void {
        this._fetchSubentities(
            ctx,
            action,
            SetModuleContentNodes,
            FetchModuleSectionContentNodesByModuleIdSuccess,
            FetchModuleSectionContentNodesByModuleIdFailure,
            this.contentNodeService.moduleSections(action.payload).pipe(
                tap((response) => {
                    ctx.dispatch([new SetModuleContentNode(response.parent), new FetchModuleContentNodeSuccess(response.parent)]);
                }),
                map((response) => response.children)
            )
        );
    }

    @Action(FetchModuleSectionContentNodesByModuleIdSuccess)
    fetchModuleSectionContentNodesByModuleIdSuccess(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchModuleSectionContentNodesByModuleIdFailure)
    fetchModuleSectionContentNodesByModuleIdFailure(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(FetchModuleContentNodesByParentId)
    fetchModuleContentNodesByParentId(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchModuleContentNodesByParentId): void {
        this._fetchSubentities(
            ctx,
            action,
            SetModuleContentNodes,
            FetchModuleContentNodesByParentIdSuccess,
            FetchModuleContentNodesByParentIdFailure,
            this.contentNodeService.getChildren(action.payload).pipe(
                tap((response) => {
                    ctx.dispatch([new SetModuleContentNode(response.parent), new FetchModuleContentNodeSuccess(response.parent)]);
                }),
                map((response) => response.children)
            )
        );
    }

    @Action(FetchModuleContentNodesByParentIdSuccess)
    fetchModuleContentNodesByParentIdSuccess(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchModuleContentNodesByParentIdFailure)
    fetchModuleContentNodesByParentIdFailure(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(FetchPersonalContentNodesByModuleId)
    fetchPersonalContentNodesByModuleId(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchPersonalContentNodesByModuleId): void {
        ctx.patchState({
            fetchingList: true,
        });

        this.contentNodeService
            .getPersonalContentNodes(action.payload.moduleId, action.payload.userId)
            .pipe(
                tap((response) => {
                    ctx.dispatch([new SetModuleContentNode(response.parent), new FetchModuleContentNodeSuccess(response.parent)]);
                }),
                map((response) => response.children)
            )
            .subscribe(
                (entities) => {
                    ctx.dispatch([new SetModuleContentNodes(entities), new FetchPersonalContentNodesByModuleIdSuccess()]);
                },
                (error: HttpErrorResponse) => {
                    ctx.dispatch(new FetchPersonalContentNodesByModuleIdFailure());
                }
            );
    }

    @Action(FetchPersonalContentNodesByModuleIdSuccess)
    fetchPersonalContentNodesByModuleIdSuccess(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchPersonalContentNodesByModuleIdFailure)
    fetchPersonalContentNodesByModuleIdFailure(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(FetchPersonalManagersContentNodesByModuleId)
    fetchPersonalManagersContentNodesByModuleId(ctx: StateContext<ModuleContentNodesStateModel>, action: FetchPersonalManagersContentNodesByModuleId): void {
        ctx.patchState({
            fetchingList: true,
        });

        this.contentNodeService
            .getPersonalManagersContentNodes(action.payload.moduleId)
            .pipe(
                tap((response) => {
                    ctx.dispatch([new SetModuleContentNode(response.parent), new FetchModuleContentNodeSuccess(response.parent)]);
                }),
                map((response) => response.children)
            )
            .subscribe(
                (entities) => {
                    ctx.dispatch([new SetModuleContentNodes(entities), new FetchPersonalManagersContentNodesByModuleIdSuccess()]);
                },
                (error: HttpErrorResponse) => {
                    ctx.dispatch(new FetchPersonalManagersContentNodesByModuleIdFailure());
                }
            );
    }

    @Action(FetchPersonalManagersContentNodesByModuleIdSuccess)
    fetchPersonalManagersContentNodesByModuleIdSuccess(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchPersonalManagersContentNodesByModuleIdFailure)
    fetchPersonalManagersContentNodesByModuleIdFailure(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(ToggleSelectedModuleContentNode)
    toggleSelect(ctx: StateContext<ModuleContentNodesStateModel>, action: ToggleSelectedModuleContentNode): void {
        this._toggleSelectedSubentity(ctx, action);
    }

    @Action(SelectFilteredModuleContentNodes)
    selectAll(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._selectFilteredSubentities(ctx);
    }

    @Action(DeselectSelectedModuleContentNodes)
    deselectAll(ctx: StateContext<ModuleContentNodesStateModel>): void {
        this._deselectSelectedSubentities(ctx);
    }

    @Action(SearchModuleContentNodes)
    search({ dispatch, patchState }: StateContext<ModuleContentNodesStateModel>, { payload }: SearchModuleContentNodes): void {
        patchState({
            searchTerm: payload.searchTerm,
        });

        if (payload.contentNodeId) {
            if (payload.searchTerm) {
                this.contentNodeService.search(payload.contentNodeId, { query: payload.searchTerm }).subscribe((contentNodes) => dispatch(new SetModuleContentNodes(contentNodes)));
            } else {
                dispatch(new FetchModuleContentNodesByParentId(payload.contentNodeId));
            }
        }
    }

    @Action(SearchPersonalContentNodes)
    searchPersonalContentNodes({ dispatch, patchState }: StateContext<ModuleContentNodesStateModel>, { payload }: SearchPersonalContentNodes): void {
        patchState({
            searchTerm: payload.searchTerm,
        });

        if (payload.contentNodeId) {
            if (payload.searchTerm) {
                this.contentNodeService.search(payload.contentNodeId, { query: payload.searchTerm }).subscribe((contentNodes) => dispatch(new SetModuleContentNodes(contentNodes)));
            } else {
                dispatch(new FetchPersonalContentNodesByModuleId({ moduleId: payload.moduleId, userId: payload.userId }));
            }
        }
    }

    @Action(CreateFolder)
    createFolder({ getState, patchState }: StateContext<ModuleContentNodesStateModel>, { payload }: CreateFolder): void {
        const parentContentNode = getState().item;

        if (payload.node === parentContentNode.id) {
            this.action$.pipe(ofActionDispatched(CreateFolderSuccess)).subscribe((action: CreateFolderSuccess) => {
                const state = getState();

                patchState({
                    list: [...state.list, action.payload.id],
                    filtered: [...state.filtered, action.payload.id],
                });
            });
        }
    }

    @Action(UploadFiles)
    @Action(UploadPersonalFiles)
    uploadFiles({ getState, patchState }: StateContext<ModuleContentNodesStateModel>, { payload }: UploadFiles): void {
        const parentContentNode = getState().item;

        if (payload.node === parentContentNode.id) {
            let successCounter = 0;
            let failureCounter = 0;

            const failed = this.action$.pipe(ofActionDispatched(UploadFileFailure), take(payload.files.length)).subscribe(() => {
                failureCounter++;

                if (successCounter + failureCounter === payload.files.length) {
                    successfull.unsubscribe();
                    failed.unsubscribe();
                }
            });

            const successfull = this.action$.pipe(ofActionDispatched(UploadFileSuccess), take(payload.files.length)).subscribe((action: UploadFileSuccess) => {
                const state = getState();
                successCounter++;

                patchState({
                    list: [...state.list, action.payload.id],
                    filtered: [...state.filtered, action.payload.id],
                });

                if (successCounter + failureCounter === payload.files.length) {
                    successfull.unsubscribe();
                    failed.unsubscribe();
                }
            });
        }
    }

    @Action(DeleteContentNodeSuccess)
    deleteContentNodeSuccess({ getState, patchState }: StateContext<ModuleContentNodesStateModel>, { payload }: DeleteContentNodeSuccess): void {
        const state = getState();

        patchState({
            list: state.list.filter((val) => !payload.includes(val)),
            filtered: state.filtered.filter((val) => !payload.includes(val)),
            selected: state.selected.filter((val) => !payload.includes(val)),
        });
    }

    @Action(UpdateSortFields)
    updateSortFields({ patchState }: StateContext<ModuleContentNodesStateModel>, { payload }: UpdateSortFields): void {
        patchState({
            sortField: payload.sortField,
            sortDirection: payload.sortDirection,
        });
    }
}
