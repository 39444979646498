import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getStatus',
})
@Injectable()
export class GetStatusPipe implements PipeTransform {
    transform(statusName: string, entityStatuses: any, property: string): string {
        if (!statusName) {
            return '';
        }

        const entityStatus = entityStatuses.all.filter((status) => status.name === statusName)[0];

        if (!entityStatus) {
            return statusName;
        }

        return entityStatus[property];
    }
}
