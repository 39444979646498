export class MaxBrainAuthStateModel {
    accessToken: string;
    refreshToken: string;
    authenticating: boolean;
    authenticated: boolean;
    connection: string;
    expiresAt: number;
    redirect: string;
    userProfile: any;
    isCockpitUser: boolean;

    constructor() {
        this.accessToken = localStorage.getItem('accessToken');
        this.refreshToken = localStorage.getItem('refreshToken');
        this.expiresAt = Number.parseInt(localStorage.getItem('expiresAt'), 10) || null;
        this.authenticated = !!this.accessToken;
        this.authenticating = false;
        this.connection = null;
        this.redirect = localStorage.getItem('redirect');
        this.userProfile = JSON.parse(localStorage.getItem('userProfile') || null);
        this.isCockpitUser = localStorage.getItem('isCockpitUser') === 'true';
    }
}
