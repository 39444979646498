export class UpdateLanguage {
    static readonly type = '[User] UpdateLanguage';

    constructor(
        public payload: {
            ids: string[];
            language: string;
        }
    ) {}
}
