import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ILanguage, ILanguageService } from '../interfaces/language.interface';
import { MAXBRAIN_LANGUAGE_SERVICE } from '../services/language.token';

@Pipe({ name: 'language' })
export class LanguagePipe implements PipeTransform {
    constructor(@Inject(MAXBRAIN_LANGUAGE_SERVICE) public languageService: ILanguageService) {}

    transform(value: string): ILanguage {
        return this.languageService.languages.find((language) => language.id === value);
    }
}
