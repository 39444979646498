import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'initials' })
export class InitialsPipe implements PipeTransform {
    constructor() {}

    transform(value: string): string {
        return value
            ?.split(' ')
            .slice(0, 2)
            .map((word) => word.charAt(0).toUpperCase())
            .join('');
    }
}
