<div id="scroll-container">
    <div class="w-100-p table-wrapper" *ngIf="dataSource">
        <mat-table #table [dataSource]="dataSource" matSort>
            <!-- Avatar Column -->
            <ng-container matColumnDef="avatar">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let moduleEventAttendance">
                    <div class="relative" #avatarCell>
                        <img
                            class="avatar"
                            *ngIf="moduleEventAttendance.participant.profilePicture; else initials"
                            [alt]="moduleEventAttendance.participant.fullName"
                            [src]="moduleEventAttendance.participant.profilePicture.thumbUrl"
                        />
                        <ng-template #initials>
                            <div fxLayoutAlign="center center" class="avatar-initials avatar">{{ moduleEventAttendance.participant.fullName | initials }}</div>
                        </ng-template>
                        <ng-template [onlineStateIndicator]="moduleEventAttendance.participant.isOnline"> </ng-template>
                    </div>
                </mat-cell>
            </ng-container>

            <!-- First Name Column -->
            <ng-container matColumnDef="firstName">
                <mat-header-cell class="ml-16" *matHeaderCellDef mat-sort-header> {{ 'GENERAL.PROPERTY.FIRST_NAME' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let moduleEventAttendance" class="ml-16">
                    <p #firstNameRow class="text-truncate font-semibold">
                        {{ moduleEventAttendance.participant.firstName }}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- Last Name Column -->
            <ng-container matColumnDef="lastName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'GENERAL.PROPERTY.LAST_NAME' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let moduleEventAttendance">
                    <p #lastNameRow class="text-truncate font-semibold">
                        {{ moduleEventAttendance.participant.lastName }}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- Attendance Column -->
            <ng-container matColumnDef="attendance">
                <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs>
                    {{ 'PAGE.MODULE_DETAILS.TAB.ATTENDANCE.TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let moduleEventAttendance" fxHide fxShow.gt-xs>
                    <div *ngIf="moduleEventAttendance.status === AttendanceStatus.UNMARKED; else markedStatus">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div *ngFor="let attendanceStatus of attendanceStatuses">
                                <button
                                    *ngIf="attendanceStatus.value !== AttendanceStatus.UNMARKED"
                                    mat-raised-button
                                    class="mr-8 mat-elevation-z0"
                                    [color]="attendanceStatus.btnColor"
                                    (click)="updateModuleEventAttendanceStatus(moduleEventAttendance, attendanceStatus)"
                                >
                                    {{ attendanceStatus.translationLabel | translate }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <ng-template #markedStatus>
                        <div class="module-event-attendance-status-container w-100-p" fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field class="module-event-attendance-status" [class.attendance-status-selected]="moduleEventAttendance.status === AttendanceStatus.ATTENDED">
                                <mat-select [(value)]="moduleEventAttendance.status" (selectionChange)="onSelection(moduleEventAttendance, $event)">
                                    <div *ngFor="let attendanceStatus of attendanceStatuses">
                                        <mat-option [value]="attendanceStatus.value">
                                            {{ attendanceStatus.translationLabel | translate }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field #commentInput floatLabel="never" class="module-event-attendance-comment w-100-p">
                                <input
                                    matInput
                                    (focus)="focusCommentInput(commentInput)"
                                    [(ngModel)]="moduleEventAttendance.comment"
                                    [placeholder]="('FORM.EVENT.FIELD.DESCRIPTION' | translate) + '...'"
                                />
                                <div *ngIf="moduleEventAttendance.comment" class="module-event-attendance-comment-icons">
                                    <mat-icon
                                        class="clear mr-4"
                                        (click)="moduleEventAttendance.comment = ''; onCommentSave(moduleEventAttendance, commentInput); $event.stopPropagation()"
                                    >
                                        close
                                    </mat-icon>
                                    <mat-icon class="save" (click)="onCommentSave(moduleEventAttendance, commentInput); $event.stopPropagation()">checkmark</mat-icon>
                                </div>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </mat-cell>
            </ng-container>

            <!-- Marked By Column -->
            <ng-container matColumnDef="updatedBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>
                    {{ 'GENERAL.LABEL.MARKED_BY' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let moduleEventAttendance" fxHide fxShow.gt-sm>
                    <p class="text-truncate font-semibold">{{ moduleEventAttendance.updatedBy.fullName }}</p>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let user; columns: displayedColumns" class="user" matRipple> </mat-row>
        </mat-table>

        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!(filteredModuleEventAttendances$ | async)?.length && !(isFetchingModuleEventAttendances$ | async)">
            <div class="py-48" fxLayout="column" fxLayoutAlign="center center">
                <mat-icon svgIcon="empty"></mat-icon>
                <h3 class="mt-12">{{ 'EVENT.ATTENDANCE.NO_PARTICIPANTS' | translate }}</h3>
            </div>
        </div>
    </div>
</div>
