import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarTime } from 'app/projects/core/src/lib/constants/snack-bar';
import { UnsubscribeOnDestroy } from 'app/projects/core/src/lib/models/unsubscribe-on-destroy';
import { fuseAnimations } from 'app/projects/fuse/src/lib/animations';

@Component({
    selector: 'app-copy-to-clipboard',
    templateUrl: './copy-to-clipboard.component.html',
    styleUrls: ['./copy-to-clipboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CopyToClipboardDialogComponent extends UnsubscribeOnDestroy {
    @Input()
    title: string;

    @Input()
    actionButton: string;

    @Input()
    description: string;

    @Input()
    placeholder: string;

    @Input()
    textToCopy: string;

    constructor(public dialogRef: MatDialogRef<CopyToClipboardDialogComponent>, private _matSnackBar: MatSnackBar, private _translateService: TranslateService) {
        super();
    }

    /**
     * Copy Text to Clipboard
     *
     */
    copyToClipboard(): void {
        navigator.clipboard.writeText(this.textToCopy).then(() => {
            const successMessage = this._translateService.instant('QUIZ.MESSAGE.COPY_SUCCESS');

            this._matSnackBar.open(successMessage, 'OK', {
                verticalPosition: 'top',
                duration: SnackBarTime.medium,
            });

            this.dialogRef.close();
        });
    }
}
