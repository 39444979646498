<app-dialog-header [title]="dialogTitle" (onClick)="dialogRef.close(false)"></app-dialog-header>

<div mat-dialog-content fxLayout="column" fxLayoutAlign="start">
    <span class="block basic-text ml-16 mt-16">{{ description | translate }}</span>
    <div *ngIf="tinymceIsLoading" class="p-24 white" fxLayout="column" fxLayoutAlign="center center">
        <div class="p-24">
            <p class="mb-8">{{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}</p>
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <editor [init]="init" [(ngModel)]="data" (onInit)="handleEventInit($event)"></editor>
    <div class="pl-16" *ngIf="icCountingCharacters">
        <mat-hint align="start">{{ data?.length || 0 }}/{{ characterLimit }}</mat-hint>
        <mat-error *ngIf="data?.length > characterLimit">
            {{ 'GENERAL.MESSAGE.TOO_MANY_CHARACTERS' | translate: { total: characterLimit } }}
        </mat-error>
    </div>
</div>
<div mat-dialog-actions class="pt-24 action-buttons" fxLayout="row" fxLayoutAlign="end center">
    <app-white-button class="mr-8" [label]="'GENERAL.BUTTON.CANCEL'" (onClick)="dialogRef.close(false)"></app-white-button>

    <app-flat-button
        [color]="saveButtonColor"
        [label]="saveButtonText || 'GENERAL.BUTTON.APPLY' | translate"
        (onClick)="save()"
        [disabled]="data?.length > characterLimit"
    ></app-flat-button>
</div>
