import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getStatusByValue',
})
@Injectable()
export class GetStatusByValuePipe implements PipeTransform {
    transform(statusValue: number, entityStatuses: any, property: string): number {
        if (statusValue === undefined || statusValue === null) {
            return null;
        }

        const entityStatus = entityStatuses.all.find((status) => status.value === statusValue);

        if (!entityStatus) {
            return statusValue;
        }

        return entityStatus[property];
    }
}
