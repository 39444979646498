import { ISurveyReportQuestionQuery } from '../interfaces/survey-report-question.query.interface';
import { SurveyReportRow } from './survey-report-row.model';

export class SurveyReportQuestion {
    answers: string[];
    rows: SurveyReportRow[];
    subtype: string;
    title: string;
    type: string;

    constructor(query: ISurveyReportQuestionQuery) {
        this.answers = query.answers;
        this.rows = query.rows.map((row) => new SurveyReportRow(row));
        this.subtype = query.subtype;
        this.title = query.title;
        this.type = query.type;
    }
}
