import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AddExperienceUsers } from 'app/library/experience-user/actions/add-entities.action';
import { ExperienceUserExtended } from 'app/library/experience-user/models/experience-user.extended.model';
import { ExperienceUser } from 'app/library/experience-user/models/experience-user.model';
import { ExperienceUserState } from 'app/library/experience-user/models/experience-user.state';
import { FeatureService } from 'app/library/feature/services/feature.service';
import { UserService } from 'app/library/user/services/user.service';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';
import { SubentityState } from 'app/projects/subentity/src/lib/models/subentity.state';
import { UtilsState } from 'app/projects/subentity/src/lib/models/utils.state';
import { RefreshUsersOnlineState } from 'app/projects/user/src/lib/actions/refresh-users-online-state.action';
import { User } from 'app/projects/user/src/lib/models/user';
import { UserState } from 'app/projects/user/src/lib/models/user.state';
import { map } from 'rxjs/operators';
import {
    DeselectSelectedExperienceUsers,
    FetchExperienceByUserId,
    FetchExperienceByUserIdFailure,
    FetchExperienceByUserIdSuccess,
    FetchExperienceUsers,
    FetchExperienceUsersFailure,
    FetchExperienceUsersSuccess,
    SearchExperienceUsers,
    SelectFilteredExperienceUsers,
    SetExperienceUsers,
    SortExperienceUsers,
    ToggleSelectedExperienceUser,
    UnsetExperienceUsers,
} from '../actions';
import { ExperienceUsersApiService } from '../services/experience-users.api-service';
import { ExperienceUsersStateModel } from './experience-users.state-model';

@State<ExperienceUsersStateModel>({
    name: 'experienceUsers',
    defaults: new ExperienceUsersStateModel(),
})
@Injectable()
export class ExperienceUsersState extends SubentityState<ExperienceUser> {
    @Selector([ExperienceUserState.getEntitiesAsMap])
    static getEntities(state: ExperienceUsersStateModel, experienceUsers: Map<string, ExperienceUser>): ExperienceUser[] {
        if (!experienceUsers) {
            return null;
        }

        return UtilsState.getEntities(state, experienceUsers);
    }

    @Selector([ExperienceUserState.getEntitiesAsMap, UserState.getEntities])
    static getEntitiesWithUser(state: ExperienceUsersStateModel, experienceUsers: Map<string, ExperienceUser>, users: User[]): ExperienceUserExtended[] {
        const toReturn = ExperienceUsersState.getEntities(state, experienceUsers);

        if (toReturn && users) {
            const usersContainer = {};

            users.forEach((user) => {
                usersContainer[user.id] = user;
            });

            return toReturn.map<ExperienceUserExtended>((experienceUser) =>
                Object.assign<ExperienceUserExtended, ExperienceUser, Partial<ExperienceUserExtended>>(new ExperienceUserExtended(), experienceUser, {
                    user: usersContainer[experienceUser.userId] || null,
                })
            );
        }

        return null;
    }

    @Selector([ExperienceUserState.getEntitiesAsMap])
    static getFilteredEntities(state: ExperienceUsersStateModel, experienceUsers: Map<string, ExperienceUser>): ExperienceUser[] {
        if (!experienceUsers) {
            return null;
        }

        return UtilsState.getFilteredEntities(state, experienceUsers);
    }

    @Selector([ExperienceUserState.getEntitiesAsMap, UserState.getEntities])
    static getFilteredEntitiesWithUser(state: ExperienceUsersStateModel, experienceUsers: Map<string, ExperienceUser>, users: User[]): ExperienceUserExtended[] {
        const toReturn = ExperienceUsersState.getFilteredEntities(state, experienceUsers);

        if (toReturn && users) {
            const usersContainer = {};

            users.forEach((user) => {
                usersContainer[user.id] = user;
            });

            return toReturn.map<ExperienceUserExtended>((experienceUser) =>
                Object.assign<ExperienceUserExtended, ExperienceUser, Partial<ExperienceUserExtended>>(new ExperienceUserExtended(), experienceUser, {
                    user: usersContainer[experienceUser.userId] || null,
                })
            );
        }

        return null;
    }

    @Selector()
    static isFetchingList(state: ExperienceUsersStateModel): boolean {
        return state.fetchingList;
    }

    @Selector()
    static getSelectedEntityIds(state: ExperienceUsersStateModel): string[] {
        return state.selected;
    }

    constructor(private experienceUsersService: ExperienceUsersApiService, private _userService: UserService, private _featureService: FeatureService, private store: Store) {
        super(null, 0);

        this._propstoSearch = ['user', ...User.props_to_search];
    }

    @Selector()
    static getSearchTerm(state: ExperienceUsersStateModel): string {
        return state.searchTerm;
    }

    @Selector()
    static getTotalCount(state: ExperienceUsersStateModel): number {
        return state.totalCount;
    }

    @Selector()
    static getSortBy(state: ExperienceUsersStateModel): string {
        return state.sortBy;
    }

    @Selector()
    static getSortOrder(state: ExperienceUsersStateModel): SortDirection {
        return state.sortOrder;
    }

    @Selector()
    static getPageSize(state: ExperienceUsersStateModel): number {
        return state.pageSize;
    }

    @Selector()
    static getPageNumber(state: ExperienceUsersStateModel): number {
        return state.pageNumber || 1;
    }

    @Selector()
    static getPageIndex(state: ExperienceUsersStateModel): number {
        return state.pageNumber ? state.pageNumber - 1 : 0;
    }

    @Action(SetExperienceUsers)
    setAll(ctx: StateContext<ExperienceUsersStateModel>, action: SetExperienceUsers): void {
        this._setSubentities(ctx, action, AddExperienceUsers);

        setTimeout(() => {
            const experienceUsersWithUser = this.store.selectSnapshot(ExperienceUsersState.getEntitiesWithUser);

            if (experienceUsersWithUser && this._featureService.checkFeatureStatus(FeatureSwitchName.UserOnlineState)) {
                this.store.dispatch(new RefreshUsersOnlineState(experienceUsersWithUser.map((experienceUser) => experienceUser.user)));
                this._userService.setOnlineStateInterval(
                    RefreshUsersOnlineState,
                    experienceUsersWithUser.map((experienceUser) => experienceUser.user)
                );
            }
        });
    }

    @Action(UnsetExperienceUsers)
    unsetAll(ctx: StateContext<ExperienceUsersStateModel>): void {
        this._unsetSubentities(ctx);
    }

    @Action(FetchExperienceUsers)
    fetchAll(ctx: StateContext<ExperienceUsersStateModel>, { payload }: FetchExperienceUsers): void {
        let params = new HttpParams().set('page', payload.pageNumber.toString()).set('limit', payload.pageSize.toString());

        if (payload.search) {
            params = params.set('search', payload.search);
        }

        if (payload.sortBy) {
            params = params.set('sortBy', payload.sortBy).set('sortOrder', payload.sortOrder);
        }

        if (payload.experienceId) {
            params = params.set('experience', payload.experienceId);
        }

        this._fetchSubentities(
            ctx,
            { payload: payload.experienceId },
            SetExperienceUsers,
            FetchExperienceUsersSuccess,
            FetchExperienceUsersFailure,
            this.experienceUsersService.getAll(params).pipe(
                map((data) => {
                    ctx.patchState({
                        totalCount: data.pagination.number_of_results,
                    });

                    return data.data.map((query) => new ExperienceUserExtended(query));
                })
            )
        );
    }

    @Action(FetchExperienceUsersSuccess)
    fetchAllSuccess(ctx: StateContext<ExperienceUsersStateModel>): void {
        this._fetchSubentitiesSuccess(ctx);
    }

    @Action(FetchExperienceUsersFailure)
    fetchAllFailure(ctx: StateContext<ExperienceUsersStateModel>): void {
        this._fetchSubentitiesFailure(ctx);
    }

    @Action(ToggleSelectedExperienceUser)
    toggleSelect(ctx: StateContext<ExperienceUsersStateModel>, action: ToggleSelectedExperienceUser): void {
        this._toggleSelectedSubentity(ctx, action);
    }

    @Action(SelectFilteredExperienceUsers)
    selectAll(ctx: StateContext<ExperienceUsersStateModel>): void {
        this._selectFilteredSubentities(ctx);
    }

    @Action(DeselectSelectedExperienceUsers)
    deselectAll(ctx: StateContext<ExperienceUsersStateModel>): void {
        this._deselectSelectedSubentities(ctx);
    }

    @Action(SearchExperienceUsers)
    search(ctx: StateContext<ExperienceUsersStateModel>, action: SearchExperienceUsers): void {
        const users = this.store.selectSnapshot(UserState.getEntities);
        const experienceUsers = this.store.selectSnapshot(ExperienceUserState.getEntities);

        const extendedExperienceUsers = experienceUsers.map((experienceUser) => ({
            ...experienceUser,
            user: users.filter((user) => user.id === experienceUser.userId)[0],
            experience: null,
        }));

        this._searchSubentities(ctx, action, extendedExperienceUsers);
    }

    @Action(SortExperienceUsers)
    sort({ patchState }: StateContext<ExperienceUsersStateModel>, { payload }: SortExperienceUsers): void {
        sessionStorage.setItem('allUsersSortBy', payload.active);
        sessionStorage.setItem('allUsersSortOrder', payload.direction);

        patchState({
            sortBy: payload.active,
            sortOrder: payload.direction,
        });
    }

    @Action(FetchExperienceByUserId)
    fetchEntityById(ctx: StateContext<ExperienceUsersStateModel>, { payload }: FetchExperienceByUserId): void {
        this.experienceUsersService.getExperienceByUserId(payload).subscribe(
            (entities) => {
                // user can be assigned only to one experience
                const experienceUser = entities[0];
                ctx.dispatch(new FetchExperienceByUserIdSuccess(experienceUser));
            },
            (response: HttpErrorResponse) => {
                ctx.dispatch(new FetchExperienceByUserIdFailure({ response }));
            }
        );
    }
}
