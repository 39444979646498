import { IModuleQuery } from '../interfaces/module.query.interface';
import { Module } from './module.model';

export class ELearningModule extends Module {
    courseImportJobId: string;
    courseId: string;

    constructor(moduleQuery?: IModuleQuery) {
        super(moduleQuery);

        this._type = 'e_learning';

        if (moduleQuery) {
            this.courseImportJobId = moduleQuery.course_import_job_id;
            this.courseId = moduleQuery.course_id;
        }
    }
}
