import * as moment from 'moment';

export class PasswordlessAccessCommand {
    start: string;
    end: string;
    user_ids: string[] = [];
    access_ids: string[] = [];
    generate_code: boolean;
    module_id: string;

    constructor(model: { start?: string; end?: string; userIds?: string[]; accessIds?: string[]; generateAccessCode?: boolean; moduleId?: string }) {
        if (model.userIds?.length) {
            this.user_ids = model.userIds;
        }

        if (model.accessIds?.length) {
            this.access_ids = model.accessIds;
        }

        if (model.start) {
            this.start = moment(model.start).format('YYYY-MM-DDTHH:mm:ss');
        }

        if (model.moduleId) {
            this.module_id = model.moduleId;
        }

        this.end = moment(model.end).format('YYYY-MM-DDTHH:mm:ss');
        this.generate_code = model.generateAccessCode;
    }
}
