import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { NotifyType } from 'app/projects/shared/src/lib/enums/notify-type.enum';
import { GroupAvatar } from '../../group/models/group-avatar';
import { ModuleGroup } from '../models/module-group';

export class SetModuleGroup {
    static readonly type = '[ModuleGroup] SetModuleGroup';

    constructor(public payload: ModuleGroup) {}
}

export class UnsetModuleGroup {
    static readonly type = '[ModuleGroup] UnsetModuleGroup';
}

export class AddModuleGroups {
    static readonly type = '[ModuleGroup] AddModuleGroups';

    constructor(public payload: ModuleGroup[]) {}
}

export class CreateModuleGroup {
    static readonly type = '[ModuleGroup] CreateModuleGroup';

    constructor(public payload: ModuleGroup) {}
}

export class CreateModuleGroupSuccess {
    static readonly type = '[ModuleGroup] CreateModuleGroupSuccess';

    constructor(public payload: ModuleGroup) {}
}

export class CreateModuleGroupFailure {
    static readonly type = '[ModuleGroup] CreateModuleGroupFailure';
}

export class DeleteModuleGroup {
    static readonly type = '[ModuleGroup] DeleteModuleGroup';

    constructor(
        public payload: {
            id: string;
            notify: NotifyType;
        }
    ) {}
}

export class DeleteModuleGroupSuccess {
    static readonly type = '[ModuleGroup] DeleteModuleGroupSuccess';

    constructor(public payload: string) {}
}

export class DeleteModuleGroupFailure {
    static readonly type = '[ModuleGroup] DeleteModuleGroupFailure';
}

export class SearchModuleGroups {
    static readonly type = '[ModuleGroup] SearchModuleGroups';

    constructor(public payload: string) {}
}

export class FetchModuleGroup {
    static readonly type = '[ModuleGroup] FetchModuleGroup';

    constructor(public payload: string) {}
}

export class FetchModuleGroupSuccess {
    static readonly type = '[ModuleGroup] FetchModuleGroupSuccess';

    constructor(public payload: ModuleGroup) {}
}

export class FetchModuleGroupFailure {
    static readonly type = '[ModuleGroup] FetchModuleGroupFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class UpdateModuleGroup {
    static readonly type = '[ModuleGroup] UpdateModuleGroup';

    constructor(public payload: ModuleGroup) {}
}

export class UpdateModuleGroupSuccess {
    static readonly type = '[ModuleGroup] UpdateModuleGroupSuccess';

    constructor(public payload: ModuleGroup) {}
}

export class UpdateModuleGroupFailure {
    static readonly type = '[ModuleGroup] UpdateModuleGroupFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class SetModuleGroups {
    static readonly type = '[ModuleGroup] SetModuleGroups';

    constructor(public payload: ModuleGroup[]) {}
}

export class FetchModuleGroups {
    static readonly type = '[ModuleGroup] FetchModuleGroups';

    constructor(public payload: string) {}
}

export class FetchModuleGroupsSuccess {
    static readonly type = '[ModuleGroup] FetchModuleGroupsSuccess';

    constructor(public payload: ModuleGroup[]) {}
}

export class FetchModuleGroupsFailure {
    static readonly type = '[ModuleGroup] FetchModuleGroupsFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class UploadGroupImage {
    static readonly type = '[ModuleGroup] UploadGroupImage';

    constructor(public payload: { file: File; id: string }) {}
}

export class UploadGroupImageSuccess {
    static readonly type = '[ModuleGroup] UploadGroupImageSuccess';

    constructor(public payload: GroupAvatar) {}
}

export class UploadGroupImageFailure {
    static readonly type = '[ModuleGroup] UploadGroupImageFailure';
}

export class LeaveGroup {
    static readonly type = '[ModuleGroup] LeaveGroup';

    constructor(public payload: string) {}
}

export class LeaveModuleGroupSuccess {
    static readonly type = '[ModuleGroup] LeaveGroupSuccess';

    constructor(public payload: string) {}
}

export class LeaveGroupFailure {
    static readonly type = '[ModuleGroup] LeaveGroupFailure';
}
