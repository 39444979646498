import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { QuestionKind } from 'app/library/question-set/enums/question-kind.enum';
import { QuestionSetQuestion } from 'app/library/question-set/models/question-set-question.model';

@Pipe({
    name: 'getQuestionIconPipe',
})
@Injectable()
export class GetQuestionIconPipe implements PipeTransform {
    transform(question: QuestionSetQuestion): string {
        switch (question.kind) {
            case QuestionKind.TrueOrFalse:
                return 'true_or_false';
            case QuestionKind.Categorizer:
                return 'categorizer';
            case QuestionKind.CategorizerChechkboxes:
                return 'categorizer_checkboxes';
            case QuestionKind.HighlightText:
                return 'highlight_text';
            case QuestionKind.FillInTheGap:
                return 'fill_in_gap';
            case QuestionKind.HotSpot:
                return 'multiple_choice';
            case QuestionKind.CheckBoxes:
                return 'checkboxes';
            case QuestionKind.MultipleChoice:
                return 'multiple_choice';
            case QuestionKind.Sorter:
                return 'sorter';
            case QuestionKind.FreeText:
                return 'free_text';
            default:
                return '';
        }
    }
}
