import { ICreateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/create-entity-failure-payload.interface';
import { IFetchEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/fetch-entity-failure-payload.interface';
import { IUpdateEntityFailureActionPayload } from 'app/projects/entity/src/lib/interfaces/update-entity-failure-payload.interface';
import { MaxBrainTag } from '../models/tag.model';

export class SetTag {
    static readonly type = '[Tag] SetTag';

    constructor(public payload: MaxBrainTag) {}
}

export class UnsetTag {
    static readonly type = '[Tag] UnsetTag';
}

export class FetchTag {
    static readonly type = '[Tag] FetchTag';

    constructor(public payload: string) {}
}

export class FetchTagSuccess {
    static readonly type = '[Tag] FetchTagSuccess';

    constructor(public payload: MaxBrainTag) {}
}

export class FetchTagFailure {
    static readonly type = '[Tag] FetchTagFailure';

    constructor(public payload: IFetchEntityFailureActionPayload) {}
}

export class CreateTag {
    static readonly type = '[Tag] CreateTag';

    constructor(public payload: MaxBrainTag) {}
}

export class CreateTagSuccess {
    static readonly type = '[Tag] CreateTagSuccess';

    constructor(public payload: MaxBrainTag) {}
}

export class CreateTagFailure {
    static readonly type = '[Tag] CreateTagFailure';

    constructor(public payload: ICreateEntityFailureActionPayload) {}
}

export class UpdateTag {
    static readonly type = '[Tag] UpdateTag';

    constructor(public payload: MaxBrainTag) {}
}

export class UpdateTagSuccess {
    static readonly type = '[Tag] UpdateTagSuccess';

    constructor(public payload: MaxBrainTag) {}
}

export class UpdateTagFailure {
    static readonly type = '[Tag] UpdateTagFailure';

    constructor(public payload: IUpdateEntityFailureActionPayload) {}
}

export class DeleteTag {
    static readonly type = '[Tag] DeleteTag';

    constructor(public payload: string) {}
}

export class DeleteTagSuccess {
    static readonly type = '[Tag] DeleteTagSuccess';

    constructor(public payload: string) {}
}

export class DeleteTagFailure {
    static readonly type = '[Tag] DeleteTagFailure';

    constructor(public payload: string) {}
}

export class SetTags {
    static readonly type = '[Tag] SetTags';

    constructor(public payload: MaxBrainTag[]) {}
}

export class FetchTags {
    static readonly type = '[Tag] FetchTags';
}

export class FetchTagsSuccess {
    static readonly type = '[Tag] FetchTagsSuccess';
}

export class FetchTagsFailure {
    static readonly type = '[Tag] FetchTagsFailure';
}

export class ToggleSelectedTag {
    static readonly type = '[Tag] ToggleSelectedTag';

    constructor(public payload: string) {}
}

export class SelectFilteredTags {
    static readonly type = '[Tag] SelectFilteredTags';
}

export class DeselectSelectedTags {
    static readonly type = '[Tag] DeselectSelectedTags';
}

export class SearchTags {
    static readonly type = '[Tag] SearchTags';

    constructor(public payload: string) {}
}
