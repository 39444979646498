import { EntityStateModel } from 'app/projects/entity/src/lib/models/entity.state-model';
import { User } from './user';

export class UserStateModel<T = User> extends EntityStateModel<T> {
    me: string;
    local: string;

    constructor() {
        super();

        this.me = null;
    }
}
