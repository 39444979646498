<div class="header primary" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title ml-12" fxLayout="row" fxLayoutAlign="start center" (click)="talkService.toggleSidebar()">
        <mat-icon>chat</mat-icon>
        <h3 class="ml-12">{{ 'GENERAL.LABEL.CHAT_PANEL' | translate }}</h3>
    </div>

    <button
        mat-icon-button
        attr.aria-label="{{ 'GENERAL.BUTTON.CLOSE' | translate }} {{ 'GENERAL.LABEL.ARIA_ACTION' | translate }}"
        (focus)="talkService.openSidebar()"
        (click)="talkService.foldSidebar()"
    >
        <mat-icon class="clear">close</mat-icon>
    </button>
</div>

<div class="content">
    <!-- Contacts -->
    <div id="contacts-list-container">
        <div id="contacts-list">
            <div class="contacts-list-item chat-sidebar-button" (click)="chooseUserToChatWith()">
                <mat-icon>add</mat-icon>
            </div>
            <div class="contacts-list-item chat-sidebar-button" (focus)="talkService.openSidebar()" (click)="talkService.toggleSidebar()">
                <mat-icon>search</mat-icon>
            </div>
            <ng-container *ngIf="panelDetails">
                <div
                    *ngFor="let detail of panelDetails | filter: searchTerm:'searchableTerm'"
                    class="contacts-list-item"
                    [ngClass]="detail.status"
                    (click)="startConversation(detail.id)"
                >
                    <ng-container *ngTemplateOutlet="detail.photoUrl ? avatar : initials; context: { $implicit: detail }"> </ng-container>

                    <div class="unread-count" *ngIf="detail.lastMessageRead === false">
                        <span>...</span>
                    </div>
                </div>

                <div class="contacts-list-item transparent" (click)="fetchMoreConversations()" *ngIf="isThereMorePagesForCurrentSearchTerm">
                    <button
                        class="no-hover-effect"
                        #loadMore
                        mat-button
                        attr.aria-label="{{ 'GENERAL.BUTTON.EXPAND' | translate }} {{ 'GENERAL.LABEL.ARIA_ACTION' | translate }}"
                        [loading]="talkService.isFetchingMoreConversations"
                    >
                        <mat-icon>expand_more</mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>

        <div id="chat" fxLayout="column" fxFlex="1 1 auto">
            <div>
                <div class="contacts-list-item" (click)="chooseUserToChatWith()">
                    {{ 'GENERAL.BUTTON.NEW_CONVERSATION' | translate }}
                </div>
                <div class="contacts-list-item">
                    <mat-form-field floatLabel="never" fxFlex="1 0 auto" class="w-100-p">
                        <input
                            matInput
                            #searchInput
                            class="search-input"
                            attr.aria-label="{{ 'GENERAL.LABEL.SEARCH_CHAT' | translate }}"
                            [placeholder]="'GENERAL.SEARCH' | translate"
                            (input)="search($event.target.value)"
                        />
                        <button
                            mat-icon-button
                            class="close-button"
                            attr.aria-label="{{ 'GENERAL.BUTTON.CLOSE' | translate }} {{ 'GENERAL.LABEL.ARIA_ACTION' | translate }}"
                            (click)="searchInput.value = ''; search('')"
                            *ngIf="searchInput.value"
                        >
                            <mat-icon class="clear"> close </mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="panelDetails">
                    <div
                        *ngFor="let detail of panelDetails | filter: searchTerm:'searchableTerm'"
                        class="contacts-list-item"
                        [ngClass]="detail.status"
                        (click)="startConversation(detail.id)"
                    >
                        <div>{{ detail.title }}</div>
                    </div>

                    <div class="contacts-list-item" (click)="fetchMoreConversations()" *ngIf="isThereMorePagesForCurrentSearchTerm">
                        <strong>{{ 'GENERAL.LABEL.LOAD_MORE' | translate }}</strong>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- / Contacts -->
</div>

<ng-template #avatar let-detail>
    <img
        class="avatar"
        [ngClass]="{ group: detail.groupName }"
        [alt]="detail.groupName"
        [matTooltip]="!chatpanel.unfolded ? detail.title : ''"
        matTooltipPosition="left"
        [src]="detail.photoUrl"
    />
    <ng-template *ngIf="!detail.groupName" [onlineStateIndicator]="detail.isOnline"> </ng-template>
</ng-template>

<ng-template #initials let-detail>
    <div fxLayoutAlign="center center" class="initials avatar" [matTooltip]="!chatpanel.unfolded ? detail.title : ''" matTooltipPosition="left">
        {{ detail.initials }}
    </div>
    <ng-template *ngIf="!detail.groupName" [onlineStateIndicator]="detail.isOnline"> </ng-template>
</ng-template>
