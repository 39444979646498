import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExtendedAttributeType } from 'app/library/extended-attributes/enums/extended-attribute-type.enum';
import { ExtendedAttribute } from 'app/library/extended-attributes/models/extended-attribute';
import { UserExtendedAttributeWithValue } from 'app/projects/user/src/lib/models/user-attribute-with-value';
import * as moment from 'moment';

@Pipe({
    name: 'getUserExtendedAttributeValue',
})
@Injectable()
export class GetUserExtendedAttributeValuePipe implements PipeTransform {
    constructor(private _translateService: TranslateService) {}

    transform(attribute: ExtendedAttribute, extendedAttributes: UserExtendedAttributeWithValue[], isDateMoment = true, isFormField = false): string | number {
        if (!attribute) {
            return '';
        }

        let toReturn: string | number = null;

        extendedAttributes.forEach((extAttr) => {
            if (extAttr.attribute.id === attribute.id) {
                switch (attribute.type) {
                    case ExtendedAttributeType.Date:
                        if (isDateMoment) {
                            toReturn = extAttr.value?.valueDate ? moment(extAttr.value.valueDate).format('ll') : '';
                        } else {
                            toReturn = extAttr.value?.valueDate;
                        }
                        break;
                    case ExtendedAttributeType.Number:
                        toReturn = extAttr.value?.valueNumber;
                        break;
                    case ExtendedAttributeType.Address:
                        const address = extAttr.value?.valueAddress;
                        if (address) {
                            toReturn = `${address.streetName || ''} ${address.streetNumber || ''}${address.streetName || address.streetNumber ? ',' : ''}
                              ${address.zipCode || ''} ${address.city || ''}${address.zipCode || address.city ? ',' : ''} ${address.country || ''}`;
                        }

                        if (typeof toReturn === 'string') {
                            toReturn = toReturn.trim();
                        }
                        break;
                    case ExtendedAttributeType.Text:
                        if (isFormField) {
                            toReturn = extAttr.value?.valueText;
                        } else {
                            toReturn = extAttr.value?.translationKey ? this._translateService.instant(extAttr.value.translationKey) : extAttr.value?.valueText;
                        }
                        break;
                }
            }
        });

        return toReturn;
    }
}
