import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IModuleUserQuery } from 'app/library/module-user/interfaces/module-user.query.interface';
import { ModuleUserExtended } from 'app/library/module-user/models/module-user.extended.model';
import { ModuleUser } from 'app/library/module-user/models/module-user.model';
import { IEnvironment } from 'app/projects/core/src/lib/interfaces/environment.interface';
import { MAXBRAIN_ENVIRONMENT } from 'app/projects/core/src/lib/services/environment.token';
import { IPaginatedResponse } from 'app/projects/subentity/src/lib/interfaces/paginated-response.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ModuleParticipantsApiService {
    constructor(private http: HttpClient, @Inject(MAXBRAIN_ENVIRONMENT) private environment: IEnvironment) {}

    getAll(
        moduleId = '',
        search = '',
        sortBy = '',
        sortOrder = '',
        pageSize = 10,
        pageIndex = 1,
        withoutCertificate?: boolean,
        selectedCertificateIds?: string[]
    ): Observable<IPaginatedResponse<IModuleUserQuery>> {
        let params = new HttpParams()
            .set('page', pageIndex ? pageIndex.toString() : '1')
            .set('limit', pageSize ? pageSize.toString() : '50')
            .set('module', moduleId)
            .set('isParticipant', 'true');

        if (search) {
            params = params.set('search', search);
        }

        if (sortBy && sortOrder) {
            if (sortBy === 'name' || sortBy === 'fullName') {
                sortBy = 'name';
            }

            params = params.set('sortBy', `${sortBy}`);
            params = params.set('sortOrder', sortOrder);
        }

        if (selectedCertificateIds && selectedCertificateIds.length) {
            params = params.set('filter[certificate]', selectedCertificateIds.toString());
        }

        if (withoutCertificate) {
            params = params.set('filter[withoutCertificate]', String(withoutCertificate));
        }

        return this.http.get<IPaginatedResponse<IModuleUserQuery>>(`${this.environment.apiUrl}/modules-users`, { params });
    }
}
