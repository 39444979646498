<div [formGroup]="parts" class="time-input-container">
    <input
        #hoursInput
        class="hours-input-element"
        formControlName="hours"
        size="2"
        aria-label="Hours"
        (input)="handleHoursInput()"
        (focus)="onFocus($event)"
        (keydown.arrowup)="increaseHours($event)"
        (keydown.arrowdown)="decreaseHours($event)"
        (keydown.arrowleft)="$event.preventDefault()"
        (keydown.arrowright)="focusMinutesElement()"
    />
    <span class="time-input-spacer">:</span>
    <input
        #minutesInput
        class="minutes-input-element"
        formControlName="minutes"
        size="2"
        aria-label="Minutes"
        (input)="handleMinutesInput()"
        (focus)="onFocus($event)"
        (keydown.arrowup)="increaseMinutes($event)"
        (keydown.arrowdown)="decreaseMinutes($event)"
        (keydown.arrowleft)="focusHoursElement()"
        (keydown.arrowright)="$event.preventDefault()"
    />
</div>
