import { IEntity } from 'app/projects/entity/src/lib/interfaces/entity.interface';
import { ISocialNetworkQuery } from '../interfaces/social-network.query.interface';

export class SocialNetwork implements IEntity {
    id: string;
    user: IEntity;
    type: string;
    value: string;
    imageUrl: string;

    constructor(socialNetwork?: ISocialNetworkQuery) {
        if (socialNetwork) {
            this.id = socialNetwork.id.toString();
            this.user = socialNetwork.user
                ? {
                      id: socialNetwork.user.id.toString(),
                  }
                : null;
            this.type = socialNetwork.type;
            this.value = socialNetwork.value;
            this.imageUrl = socialNetwork.image_url;
        } else {
            this.id = '';
            this.user = null;
            this.type = '';
            this.value = '';
            this.imageUrl = '';
        }
    }
}
