<mat-expansion-panel class="mat-elevation-z2 chat-drawer mr-24" [expanded]="conversationDetails.expanded" hideToggle="true" (expandedChange)="onExpandedChange($event)">
    <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title" fxLayout="row" fxLayoutAlign="space-between start">
            <div class="header-wrapper closed" *ngIf="!conversationDetails.expanded">
                <ng-template [ngTemplateOutlet]="header"></ng-template>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="chatbox-container" style="width: 300px; height: 500px; margin: 0 -24px -16px">
        <div class="chatbox-header">
            <div class="accordion-title" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="header-wrapper">
                    <ng-template [ngTemplateOutlet]="header"></ng-template>
                </div>
            </div>
        </div>
        <ng-container *ngIf="conversationDetails.expanded">
            <div #talkjsContainer style="width: 100%; height: 100%; padding-top: 60px"></div>
        </ng-container>
    </div>
</mat-expansion-panel>

<ng-template #header>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div class="img-wrapper" [class.group]="conversationDetails.groupName">
            <img class="avatar" *ngIf="conversationDetails.photoUrl; else initials" [alt]="conversationDetails.title" [src]="conversationDetails.photoUrl" />
            <ng-template #initials>
                <div fxLayoutAlign="center center" class="avatar-initials avatar">{{ conversationDetails.initials }}</div>
            </ng-template>
            <ng-template *ngIf="!conversationDetails.groupName" [onlineStateIndicator]="onlineStateInfo?.isOnline || conversationDetails.isOnline"> </ng-template>
            <div class="unread-count" *ngIf="conversationDetails.lastMessageRead === false">
                <span>...</span>
            </div>
        </div>
        <div class="title-wrapper" fxLayout="column" fxLayoutAlign="center start">
            <h4>
                {{ conversationDetails.title }}
            </h4>
            <span class="members-subtitle" (click)="openMembersDialog(conversationDetails)" *ngIf="conversationDetails.groupName && conversationDetails.expanded"
                >{{ 'GROUPS.TAB.MEMBERS' | translate }} ({{ conversationDetails.chatParticipants.length }})</span
            >
        </div>
    </div>
    <div fxLayoutAlign="flex-end center">
        <mat-icon class="pointer mr-8 mb-4" *ngIf="conversationDetails.expanded" (click)="onExpandedChange(false)">minimize</mat-icon>
        <mat-icon class="pointer" (click)="$event.stopPropagation(); closeConversation()"> close </mat-icon>
    </div>
</ng-template>
