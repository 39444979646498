import {} from 'app/library/certificate/services/certificate.api-service';
import { NextEvent } from 'app/library/event/models/next-event.model';
import { IFinalGradeQuery } from 'app/library/final-grade/interfaces/final-grade.query.interface';
import { FinalGrade } from 'app/library/final-grade/models/final-grade.model';
import { ModuleUserPermissions } from '../enums/module-user-permissions.enum';
import { IModuleUserQuery } from '../interfaces/module-user.query.interface';
import { ModuleUserAttendance } from './module-user-attendance.model';
import { ModuleUserProgress } from './module-user-progress.model';
import { SimpleCertificate } from 'app/library/certificate/models/simple-certificate.model';

export class ModuleUser {
    static label_singular = 'moduleUser';
    static label_plural = 'moduleUsers';

    static props_to_search = [];

    id: string;
    moduleId: string;
    userId: string;
    isParticipant: boolean;
    isManager: boolean;
    isResponsible: boolean;
    roleLabel: string;
    permissions: string[];
    certificates: SimpleCertificate[];
    userFinalGrade: FinalGrade;
    moduleUserProgress: ModuleUserProgress;
    category: string;
    analytics: { timeSpent: number; visitedCount: number };
    attendance: ModuleUserAttendance;
    nextEvent: NextEvent;

    contentNodeId: string;
    isPinned: boolean;

    constructor(moduleUser?: IModuleUserQuery, finalGrade?: IFinalGradeQuery) {
        if (moduleUser) {
            this.moduleId = moduleUser.module.id.toString();
            this.userId = moduleUser.user.id.toString();
            this.id = moduleUser.id ? moduleUser.id.toString() : null;
            this.isParticipant = moduleUser.is_participant;
            this.isManager = moduleUser.is_manager;
            this.isResponsible = moduleUser.is_responsible;
            this.isPinned = moduleUser.is_pinned;
            this.permissions = moduleUser.permission;
            this.certificates = moduleUser.certificates;
            this.roleLabel = moduleUser.role_label;
            this.moduleUserProgress = moduleUser.module_user_progress ? new ModuleUserProgress(moduleUser.module_user_progress) : null;
            this.analytics = moduleUser.analytics ? { timeSpent: moduleUser.analytics.time_spent, visitedCount: moduleUser.analytics.visited_count } : null;
            this.category = moduleUser.category;
            this.attendance = moduleUser.attendance ? new ModuleUserAttendance(moduleUser.attendance) : null;
            this.nextEvent = moduleUser.next_event ? new NextEvent(moduleUser.next_event) : null;
        } else {
            this.moduleId = null;
            this.userId = null;
            this.id = null;
            this.isParticipant = false;
            this.isManager = false;
            this.isResponsible = false;
            this.isPinned = false;
            this.roleLabel = '';
            this.permissions = null;
            this.certificates = [];
            this.moduleUserProgress = null;
            this.analytics = null;
            this.category = null;
            this.attendance = null;
            this.nextEvent = null;
        }

        if (finalGrade) {
            this.userFinalGrade = new FinalGrade(finalGrade);
        } else {
            this.userFinalGrade = null;
        }

        this.contentNodeId = null;
    }

    static getAllPermissions(): string[] {
        return [
            ModuleUserPermissions.module_content_management,
            ModuleUserPermissions.module_display_management,
            ModuleUserPermissions.module_event_management,
            ModuleUserPermissions.module_structure_management,
            ModuleUserPermissions.module_grade_management,
            ModuleUserPermissions.module_certificate_management,
            ModuleUserPermissions.course_booking_management,
        ];
    }
}
