import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { MaxBrainLocation } from 'app/library/location/models/location.model';
import { LocationService } from 'app/library/location/services/location.service';
import { IEntityState } from 'app/projects/entity/src/lib/interfaces/entity.state.interface';
import { EntityState } from 'app/projects/entity/src/lib/models/entity.state';
import {
    AddLocations,
    CreateLocation,
    CreateLocationFailure,
    CreateLocationSuccess,
    DeleteLocation,
    DeleteLocationFailure,
    DeleteLocationSuccess,
    FetchLocation,
    FetchLocationFailure,
    FetchLocations,
    FetchLocationsFailure,
    FetchLocationsSuccess,
    FetchLocationSuccess,
    SetLocation,
    SetLocations,
    UnsetLocation,
    UpdateLocation,
    UpdateLocationFailure,
    UpdateLocationSuccess,
} from '../actions';
import { LocationStateModel } from './location.state-model';

@State<LocationStateModel>({
    name: 'location',
    defaults: new LocationStateModel(),
})
@Injectable()
export class LocationState extends EntityState<MaxBrainLocation> implements IEntityState<MaxBrainLocation> {
    @Selector()
    static getEntity(state: LocationStateModel, id: string = state.item): MaxBrainLocation {
        if (!state.map) {
            return null;
        }

        return state.map.get(id) || null;
    }

    @Selector()
    static getEntities(state: LocationStateModel): MaxBrainLocation[] {
        if (!state.map) {
            return null;
        }

        return Array.from(state.map.values());
    }

    constructor(private locationService: LocationService, private store: Store) {
        super(locationService, 30);

        this._propstoSearch = MaxBrainLocation.props_to_search;
    }

    @Action(AddLocations)
    addEntities(ctx: StateContext<LocationStateModel>, action: AddLocations): void {
        this._addEntities(ctx, action);
    }

    @Action(SetLocation)
    setEntity(ctx: StateContext<LocationStateModel>, action: SetLocation): void {
        this._setEntity(ctx, action, AddLocations);
    }

    @Action(UnsetLocation)
    unsetEntity(ctx: StateContext<LocationStateModel>): void {
        this._unsetEntity(ctx);
    }

    @Action(CreateLocation)
    createEntity(ctx: StateContext<LocationStateModel>, action: CreateLocation): void {
        this._createEntity(ctx, action, CreateLocationSuccess, CreateLocationFailure, AddLocations).then(() => {});
    }

    @Action(UpdateLocation)
    updateEntity(ctx: StateContext<LocationStateModel>, action: UpdateLocation): void {
        this._updateEntity(ctx, action, UpdateLocationSuccess, UpdateLocationFailure, AddLocations).then(() => {});
    }

    @Action(DeleteLocation)
    deleteEntity(ctx: StateContext<LocationStateModel>, action: DeleteLocation): void {
        this._deleteEntity(ctx, action, DeleteLocationSuccess, DeleteLocationFailure).then(() => {});
    }

    @Action(FetchLocation)
    fetchEntity(ctx: StateContext<LocationStateModel>, action: FetchLocation): void {
        this._fetchEntity(ctx, action, FetchLocationSuccess, FetchLocationFailure, AddLocations, SetLocation).then(() => {});
    }

    @Action(FetchLocationSuccess)
    fetchEntitySuccess(ctx: StateContext<LocationStateModel>, action: FetchLocationSuccess): void {
        this._fetchEntitySuccess(ctx, action);
    }

    @Action(FetchLocationFailure)
    fetchEntityFailure(ctx: StateContext<LocationStateModel>, action: FetchLocationFailure): void {
        this._fetchEntityFailure(ctx, action);
    }

    @Action(SetLocations)
    setEntities(ctx: StateContext<LocationStateModel>, action: SetLocations): void {
        this._setEntities(ctx, action);
    }

    @Action(FetchLocations)
    fetchEntities(ctx: StateContext<LocationStateModel>): void {
        this._fetchEntities(ctx, FetchLocationsSuccess, FetchLocationsFailure, SetLocations).then(() => {});
    }

    @Action(FetchLocationsSuccess)
    fetchEntitiesSuccess(ctx: StateContext<LocationStateModel>): void {
        this._fetchEntitiesSuccess(ctx);
    }

    @Action(FetchLocationsFailure)
    fetchEntitiesFailure(ctx: StateContext<LocationStateModel>): void {
        this._fetchEntitiesFailure(ctx);
    }
}
