import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from 'app/library/feature/models/feature.model';
import { FeatureService } from 'app/library/feature/services/feature.service';
import { FeatureSwitchName } from 'app/projects/shared/src/lib/enums/feature-switch.enum';

@Pipe({ name: 'featureSwitch' })
export class FeatureSwitchPipe implements PipeTransform {
    features: Feature[];

    constructor(private _featureService: FeatureService) {
        this.features = this._featureService.getFeatures();
    }

    transform(featureSwitch: FeatureSwitchName): boolean {
        return this.features && !!this.features.filter((feature) => feature.id === featureSwitch && feature.status === 'active').length;
    }
}
