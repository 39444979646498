import { SectionType } from 'app/library/section/enums/section-type.enum';
import { ScheduledJob } from '../../scheduler/models/scheduled-job.model';
import { ISectionQuery } from '../interfaces/section.query.interface';

export abstract class Section {
    static label_singular = 'section';
    static label_plural = 'sections';

    static props_to_search = ['name'];

    protected _type: SectionType;
    get type(): SectionType {
        return this._type;
    }

    id: string;
    name: string;
    moduleId: string;
    sort: number;
    accessible: boolean;
    completed: boolean;
    timeSpent: number;
    visitedCount: number;
    completedCount: number;
    schedules: ScheduledJob[];
    isMandatory: boolean;
    sessionName: string;
    sessionCode: string;
    sessionPreviewUrl: string;

    contentNodeId: string;
    userContentNodeId: string;

    constructor(query?: ISectionQuery) {
        if (query) {
            this.id = query.id ? query.id.toString() : null;
            this.name = query.name;
            this.moduleId = query.module && query.module.id ? query.module.id.toString() : null;
            this.sort = query.sort;
            this.accessible = query.accessible;
            this.completed = query.completed;
            this.timeSpent = query.time_spent;
            this.visitedCount = query.visited_count;
            this.completedCount = query.completed_count;
            this.isMandatory = query.is_mandatory;
            if (query.schedules?.length) {
                this.schedules = query.schedules.map((schedule) => new ScheduledJob(schedule));
            } else {
                this.schedules = [];
            }
            this.sessionName = query.session_name;
            this.sessionCode = query.session_code;
            this.sessionPreviewUrl = query.session_preview_url;
        } else {
            this.id = null;
            this.name = null;
            this.moduleId = null;
            this.sort = null;
            this.accessible = null;
            this.completed = null;
            this.timeSpent = null;
            this.visitedCount = null;
            this.completedCount = null;
            this.isMandatory = null;
        }

        this.contentNodeId = null;
        this.userContentNodeId = null;
    }
}
